import React from "react"
import MasterDetail from "../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {merge, validateName} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResourceComponent from "../../components/ResourceComponent";
import moment from "moment-timezone";
import {checkForDuplicateName} from "./PaymentTypesApi"

const api = createApiService('payment_types', 'payment_type', 'Payment type');

const actions = {...api, checkForDuplicateName}

class ServiceTypes extends ResourceComponent {
    defaultValues = {name: '', description: '', disabled: false};
    state = {errorMessages: {}};

    updateResources = resources => {
        this.setState({resources})
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {resource, resources} = this.state;
        if ((prevState.resources !== resources) && resource) {
            const selected = resources.find(r => r.id === resource.id)
            this.setState(() => ({resource: {...resource, updated_at: selected.updated_at}}))
        }
    }

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues)}, this.checkForDuplicateName);
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    validate = () => {
        let {resource, errorMessages} = this.state
        let validationName = validateName(resource.name)
        if (validationName.is_valid) {
            this.setState({errorMessages: {"name": null}})
            return true
        } else {
            !validationName.is_valid && this.setState({
                errorMessages: {
                    ...errorMessages,
                    "name": validationName.errorMessage
                }
            })
            return false
        }
    }

    checkForDuplicateName = () => {
        let {resource} = this.state
        let name = this.state.resource?.name
        if (name) {
            this.props.actions.checkForDuplicateName(resource, (response) => {
                if (response.status === 208) {
                    this.setState({isDuplicateNameError: true})
                } else if (response.status === 202) {
                    this.setState({isDuplicateNameError: false})
                }
            })
        } else {
            this.setState({isDuplicateNameError: false})
        }
    }

    render() {
        const {resource, resources, errorMessages, isDuplicateNameError} = this.state;
        return (
            resources ?
                <Grid fluid>
                    <MasterDetail
                        resourceName="Payment type"
                        resourceNamePlural="Payment types"
                        resources={resources}
                        resource={resource}
                        select={this.handleSelect}
                        blockDelete={!resource?.id || resource?.locked || resource?.is_used_in_payments}
                        blockSave={isDuplicateNameError || resource?.locked || Object.values(this.state.errorMessages).some((e) => e !== null)}
                        save={() => {
                            this.validate() && this.props.actions.save(resource, this.reloadResources)
                        }}
                        delete={() => this.props.actions.remove(resource, x => {
                            this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                        })}>
                        {resource &&
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>
                                                Name
                                            </ControlLabel>
                                            <FormControl
                                                name="name"
                                                disabled={resource.locked}
                                                onBlur={this.checkForDuplicateName}
                                                onChange={(e) => {
                                                    this.updateResourceAttr(e)
                                                    let validationName = validateName(resource.name)
                                                    !validationName.is_valid ?
                                                        this.setState({
                                                            errorMessages: {
                                                                ...errorMessages,
                                                                "name": validationName.errorMessage
                                                            }
                                                        })
                                                        : this.setState({
                                                            errorMessages: {
                                                                ...errorMessages,
                                                                "name": null
                                                            }
                                                        })

                                                }}
                                                value={resource.name}
                                            />
                                            {this.state.errorMessages.name &&
                                                <p className="error-message-master-detail ">{this.state.errorMessages.name}</p>}
                                            {isDuplicateNameError &&
                                                <p className="errorText mr-0-ml-0"
                                                   style={{"margin-bottom": "15px"}}>Payment type name
                                                    already taken</p>}
                                        </FormGroup>
                                        <FormGroup>
                                            Last
                                            updated {resource.updated_at ? moment(resource.updated_at).format('llll') :
                                            <i>Unknown</i>}
                                        </FormGroup>
                                        {resource.is_used_in_payments && !resource.locked && <FormGroup>
                                            Is used in {resource.payments_count} payments, can't be deleted
                                        </FormGroup>}
                                    </Col>
                                </Row>
                            </div>
                        }
                    </MasterDetail>
                </Grid> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(undefined, mapDispatchToProps)(ServiceTypes)
