import React from "react"
import ReactGA from "react-ga4";
import {createBrowserHistory} from 'history';
import RequireAuth from "../containers/RequireAuthHoc"
import CustomerAuth from "../containers/RequireAuthHoc/CustomerAuth"
//router
import {IndexRoute, Route, Router} from "react-router"
import AppWrapper from "../containers/AppWrapper"
import Login from "../containers/LoginPage"
import Register from "../containers/RegisterPage"
import Home from "../containers/HomePage"
import ComponentBrowser from "../containers/ComponentBrowserPage"
import ClientDashboard from "../containers/ClientDashboardPage"
import Clients from "../containers/ClientsPage"
import OpenProposals from "../containers/OpenProposalsPage/OpenProposals"
import SalesDashboardPage from "../containers/SalesDashboardPage"
import SalesDashboard from "../containers/SalesDashboard/SalesDashboard"
import ReferralReport from "../containers/RefferalReport/ReferralReport"
import AcceptProposals from "../containers/AcceptProposalsPage"
import ProposalView from "../containers/ProposalPage"
import ProposalsList from "../containers/ProposalsListPage"
import PendingApprovals from "../containers/PendingApprovalsPage"
import NewAdminPage from "../containers/AdminPage/NewAdminPage/AdminPage"
import MapView from "../containers/MapViewPage"
import MapViewPage from "../containers/MapViewPage/new/MapViewPage"
import MyProposals from "../containers/MyProposalsPage/MyProposals"
import ProposedServices from "../containers/ProposedServicesPage/ProposedServices"
import MyAccepted from "../containers/MyAcceptedPage/MyAccepted"
import ActiveCustomers from "../containers/ActiveCustomers/ActiveCustomers"
import CompletedProposals from "../containers/CompletedProposalsPage/CompletedProposals"
import SalesFunnel from "../containers/SalesFunnelPage"
import Plants from "../containers/lists/Plants"
import Equipment from "../containers/lists/Equipments"
import Glossaries from "../containers/lists/Glossaries"
import Factors from "../containers/lists/Factors"
import FactorTypes from "../containers/lists/FactorTypes"
import ApplicationTypes from "../containers/lists/ApplicationTypes"
import AssetTypes from "../containers/lists/AssetTypes"
import ApplicationSites from "../containers/lists/ApplicationSites"
import Consumables from "../containers/lists/Consumables"
import ContactTypes from "../containers/lists/ContactTypes"
import ServicePriorities from "../containers/lists/ServicePriorities"
import Referrals from "../containers/lists/Referrals"
import ServiceTypes from "../containers/lists/ServiceTypes"
import CustomerTypes from "../containers/lists/CustomerTypes"
import Trucks from "../containers/lists/Trucks"
import Hazards from "../containers/lists/Hazards"
import SchedulerEventTypes from "../containers/lists/SchedulerEventTypes"
import WorkOrders from "../containers/WorkOrdersPage"
import PrintWorkOrders from "../containers/PrintWorkOrdersPage"
import EmployeesPage from "../containers/EmployeesPage/EmployeesPage"
import Calendar from "../containers/Scheduler/Calendar";
import Leads from "../containers/LeadsPage/Leads"
import Lead from "../containers/LeadsPage/Lead"
import ProposalLead from "../containers/MapViewPage/new/ProposalLead"
import FindTime from "../containers/LeadsPage/FindTime"
import ProposalFindTime from "../containers/MapViewPage/new/ProposalFindTime"
import CustomerSearch from "../containers/CustomerSearchPage/CustomerSearch"
import Tasks from "../containers/TasksPage/Tasks"
import NewInvoices from "../containers/InvoicesPage/Invoices"
import OldCustomerSearch from "../containers/CustomerSearchPage"
import PrintProposalPage from "../containers/PrintProposalPage"
import PrintInvoicePage from "../containers/PrintInvoicePage"
import CustomerViewPage from "../containers/CustomerViewPage"
import PdfPrintPage from "../containers/CustomerViewPage/PdfPrintPage"
import ChangeServiceStatusPage from "../containers/ChangeServiceStatusPage"
import ProposalServiceStatusPage from "../containers/ProposalServiceStatusPage"
import Renewals from "../containers/RenewalsPage/Renewals"
import CustomerWorkHistory from "../containers/CustomerPage/WorkHistory"
import CustomerProposals from "../containers/CustomerPage/customerProposals/CustomerProposal"
import CustomerSites from "../containers/CustomerPage/CustomerSites"
import CustomerInvoices from "../containers/CustomerPage/CustomerInvoices/CustomerInvoices"
import CustomerManageInvoices from "../containers/CustomerPage/CustomerManageInvoices"
import Invoices from "../containers/InvoicesPage"
import Activities from "../containers/ActivitiesPage"
import NewActivities from "../containers/NewActivitiesPage/NewActivities"
import CompletedServices from "../containers/CompletedServicesPage/CompletedServices"
import CustomerWorkOrders from "../containers/CustomerPage/CustomerWorkOrders"
import CustomerContactForm from "../containers/CustomerPage/CustomerContactForm"
import FindWorkOrders from "../containers/FindWorkOrdersPage/FindWorkOrders"
import CrewWorkOrders from "../containers/CrewWorkOrdersPage/CrewWorkOrders"
import CrewWorkOrdersDetails from "../containers/CrewWorkOrdersPage/CrewWorkOrdersDetails";
import TimeTrackTypes from "../containers/lists/TimeTrackTypes";
import Conditions from "../containers/lists/Conditions";
import Reason from "../containers/lists/Reason";
import TimeTracks from "../containers/TimeTracksPage/TimeTracks";
import ProposalCost from "../containers/ProposalCostPage/ProposalCost";
import NewInvoice from "../containers/NewInvoicePage/NewInvoice";
import Terms from "../containers/lists/Terms";
import NewServices from "../containers/lists/Services";
import NewInvoicePrint from "../containers/NewInvoicePrintPage/NewInvoicePrint";
import PdfPrintInvoicePage from "../containers/NewInvoicePrintPage/PdfPrintInvoicePage";
import SalesCrossView from "../containers/Reports/SalesCrossView";
import Backlog from "../containers/Reports/Backlog";
import BacklogList from "../containers/Reports/BacklogList";
import SalesActual from "../containers/Reports/SalesActual";
import LeadAppointments from "../containers/Reports/LeadAppointments";
import ServiceSalesActual from "../containers/Reports/ServiceSalesActual";
import Reports from "../containers/Reports/Reports";
import SalesCrossViewList from "../containers/Reports/SalesCrossViewList";
import FollowUpsReport from "../containers/Reports/FollowUpsReport";
import MapSchedulerPage from "../containers/MapScheduler/MapSchedulerPage"
import FollowUpsList from "../containers/Reports/FollowUpsList";
import NewWorkOrderPrint from "../containers/NewWorkOrderPrintPage/NewWorkOrderPrint";
import PdfPrintWorkOrderPage from "../containers/NewWorkOrderPrintPage/PdfPrintWorkOrderPage";
import IssuesView from "../containers/IssuesContainer/IssuesView";
import QuickbooksAllCustomers from "../containers/Quickbooks/QuickbooksCustomers/QuickbooksAllCustomers";
import Quickbooks from "../containers/Quickbooks/Quickbooks";
import CustomerViewInvoice from "../containers/CustomerViewInvoice";
import Dashboard from "../containers/DashboardPrototype/Dashboard";
import Holidays from "../containers/HolidaysPage/Holidays";
import CustomerMap from "../containers/CustomerMap/CustomerMap";
import OAuthPage from '../containers/CustomerViewInvoice/Payment/Plaid/OAuthPage'
import CustomerViewProposals from "../containers/CustomerViewPage/CustomerViewProposals";
import CustomerViewSites from "../containers/CustomerViewPage/CustomerViewSites";
import CustomerViewInvoices from "../containers/CustomerViewPage/CustomerViewInvoices";
import CustomerViewWallet from "../containers/CustomerViewPage/CustomerViewWallet";
import CustomerViewMessages from "../containers/CustomerViewPage/CustomerViewMessages";
import CustomerViewLogout from "../containers/CustomerViewPage/CustomerViewLogout";
import SubscriptionRenewWidget from "../containers/AppSubscription/SubscriptionRenewWidget"
import SubscriptionExpired from "../containers/AppSubscription/SubscriptionExpired"
import TermsAndConditions from "../containers/TermsAndPolicy/TermsAndConditions";
import PrivacyPolicy from "../containers/TermsAndPolicy/PrivacyPolicy";
import ReferralReportList from "../containers/RefferalReport/ReferralReportList";
import TruckNewServicePage from "../containers/lists/TruckNewServicePage";
import NewCustomerInfoPage from "../containers/CustomerPage/NewCustomerInfo/NewCustomerInfoPage";
import OrganizationalChartPage from "../containers/OrganizationalChartPage/OrganizationalChartPage";
import ResetPasswordPage from "../containers/ResetPasswordPage/ResetPasswordPage";
import OrdinanceAndLaws from "../containers/lists/OrdinanceAndLaws";
import QuestionnairePage from "../containers/QuestionnairePage/QuestionnairePage";
import RevenueReports from "../containers/RevenueReports/RevenueReports";
import WorkOrdersListPage from "../containers/WorkOrdersListPage/WorkOrdersListPage";
import NewSalesDashboard from "../containers/NewSalesDashboard/NewSalesDashboard";
import config from "../config/environment";
import PaymentTypes from "../containers/lists/PaymentTypes";

ReactGA.initialize(config.ga4Key || "G-0C347D8SNL");

const buttonListener = () => {
    const bns = document.getElementsByTagName("button");
    for (let i = 0; i < bns.length; i++) {
        bns[i].addEventListener("click", () => {
            ReactGA.send(({ hitType: "pageview", page: history.location.pathname }));
            ReactGA.event({
                category: 'button',
                action: bns[i] && bns[i].innerText,
            });
        });
    }
}

window.addEventListener("load", () => {
    buttonListener()
})

const history = createBrowserHistory();

history.listen(location => {
    setTimeout(() => {
        buttonListener()
    }, 100);
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
});


const Routes = ({reduxRouterHistory, store}) => {
    const newMapView = store.getState().auth.newMapView;
    const newInvoicesView = store.getState().auth.newInvoicesView;
    const roles = store.getState().auth.roles;
    const isNotCrew = `sales_arborist, scheduler, it_engineering, admin`
    const allRoles = `sales_arborist, scheduler, it_engineering, admin, crew, subcontractor, technician`
    const adminRoles = `it_engineering, admin, owner`
    ReactGA.set({userId: `${store.getState().auth.user_id}`});

    return <Router history={reduxRouterHistory}>
        <Route path="/" history={history} component={AppWrapper}>
            <IndexRoute component={Home}/>
            <Route history={history} path="/login" component={Login}/>
            <Route history={history} path="/register" component={Register}/>
            <Route history={history} path="/subscription_reminder"
                   component={RequireAuth(SubscriptionRenewWidget, adminRoles)}/>
            <Route history={history} path="/subscription_renew"
                   component={RequireAuth(SubscriptionRenewWidget, adminRoles)}/>
            <Route history={history} path="/subscription_expired" component={RequireAuth(SubscriptionExpired)}/>
            <Route
                history={history} path="/component_browser"
                component={RequireAuth(ComponentBrowser, isNotCrew)}
            />
            <Route
                history={history} path="/client_dashboard"
                component={RequireAuth(ClientDashboard)}
            />
            <Route
                history={history} path="/clients/:clientId"
                component={RequireAuth(Clients, 'admin')}
            />
            <Route
                history={history} path="/open_proposals"
                component={RequireAuth(OpenProposals, isNotCrew)}
            />
            <Route
                history={history} path="/accept_proposals(/:proposalNo)"
                component={RequireAuth(AcceptProposals, isNotCrew)}
            />
            <Route
                history={history} path="/proposal_service_status(/:proposalId)"
                component={RequireAuth(ProposalServiceStatusPage, isNotCrew)}
            />
            <Route history={history} path="/proposals" component={RequireAuth(ProposalsList, isNotCrew)}/>
            <Route history={history} path="/recent_work_orders" component={RequireAuth(WorkOrdersListPage, isNotCrew)}/>
            <Route
                history={history} path="/proposals/:proposalId"
                component={RequireAuth(ProposalView, isNotCrew)}
            />
            <Route
                history={history} path="/new_invoice_print/:invoiceId"
                component={RequireAuth(NewInvoicePrint, isNotCrew)}
            />
            <Route
                history={history} path="/questionnaire/:token"
                component={RequireAuth(QuestionnairePage)}
            />
            <Route
                history={history} path="/customer_view/invoice_pdf/:token"
                component={RequireAuth(PdfPrintInvoicePage)}
            />
            <Route
                history={history} path="/customer_view/:token/proposals"
                component={CustomerAuth(CustomerViewProposals)}
            />
            <Route
                history={history} path="/customer_view/:token/sites"
                component={CustomerAuth(CustomerViewSites)}
            />
            <Route
                history={history} path="/customer_view/:token/invoices"
                component={CustomerAuth(CustomerViewInvoices)}
            />
            <Route
                history={history} path="/customer_view/:token/messages"
                component={CustomerAuth(CustomerViewMessages)}
            />
            <Route
                history={history} path="/customer_view/:token/wallet"
                component={CustomerAuth(CustomerViewWallet)}
            />
            <Route
                history={history} path="/customer_view/logout"
                component={RequireAuth(CustomerViewLogout)}
            />
            <Route
                history={history} path="/new_work_order_print/:woId"
                component={RequireAuth(NewWorkOrderPrint, isNotCrew)}
            />
            <Route
                history={history} path="/customer_view/wo_pdf/:token"
                component={RequireAuth(PdfPrintWorkOrderPage)}
            />

            <Route history={history} path="/customer_map" component={RequireAuth(CustomerMap, isNotCrew)}/>
            <Route
                history={history} path="/customer_search"
                component={RequireAuth(CustomerSearch, isNotCrew)}
            />
            <Route
                history={history} path="/quickbooks"
                component={RequireAuth(Quickbooks, isNotCrew)}
            />
            <Route
                history={history} path="/quickbooks_all_customers"
                component={RequireAuth(QuickbooksAllCustomers, isNotCrew)}
            />
            <Route
                history={history} path="/tasks"
                component={RequireAuth(Tasks, allRoles)}
            />
            <Route
                history={history} path="/time_tracks"
                component={RequireAuth(TimeTracks, isNotCrew)}
            />
            <Route history={history} path="/invoices" component={RequireAuth(NewInvoices, isNotCrew)}/>
            <Route history={history} path="/old_invoices" component={RequireAuth(Invoices, isNotCrew)}/>
            <Route
                history={history} path="/proposals/:proposalId/invoices"
                component={RequireAuth(NewInvoice, isNotCrew)}
            />
            <Route
                history={history} path="/crew_work_orders"
                component={RequireAuth(CrewWorkOrders)}
            />
            <Route
                history={history} path="/reports"
                component={RequireAuth(Reports, isNotCrew)}
            />
            <Route
                history={history} path="/sales_cross_view"
                component={RequireAuth(SalesCrossView, isNotCrew)}
            />
            <Route
                history={history} path="/sales_cross_view/:id"
                component={RequireAuth(SalesCrossViewList, isNotCrew)}
            />
            <Route
                history={history} path="/backlog"
                component={RequireAuth(Backlog, isNotCrew)}
            />
            <Route
                history={history} path="/backlog/:id"
                component={RequireAuth(BacklogList, isNotCrew)}
            />
            <Route
                history={history} path="/referral_report/:id"
                component={RequireAuth(ReferralReportList, isNotCrew)}
            />
            <Route
                history={history} path="/sales_actual"
                component={RequireAuth(SalesActual, isNotCrew)}
            />
            <Route
                history={history} path="/service_sales_actual"
                component={RequireAuth(ServiceSalesActual, isNotCrew)}
            />
            <Route
                history={history} path="/lead_appointments"
                component={RequireAuth(LeadAppointments, isNotCrew)}
            />
            <Route
                history={history} path="/follow_ups"
                component={RequireAuth(FollowUpsReport, isNotCrew)}
            />
            <Route
                history={history} path="/follow_ups/:id"
                component={RequireAuth(FollowUpsList, isNotCrew)}
            />
            <Route
                history={history} path="/crew_work_orders/:id"
                component={RequireAuth(CrewWorkOrdersDetails)}
            />
            <Route
                history={history} path="/crew_work_order_details/:id"
                component={RequireAuth(CrewWorkOrdersDetails)}
            />
            <Route
                history={history} path="/proposal_cost(/:id)"
                component={RequireAuth(ProposalCost, isNotCrew)}
            />
            <Route
                history={history} path="/old_customer_search"
                component={RequireAuth(OldCustomerSearch, isNotCrew)}
            />
            <Route
                history={history} path="/customer/info(/:id)"
                component={RequireAuth(NewCustomerInfoPage, isNotCrew)}
            />
            <Route
                history={history} path="/customer/work_history/:id"
                component={RequireAuth(CustomerWorkHistory, isNotCrew)}
            />

            <Route
                history={history} path="/customer/proposals/:id"
                component={RequireAuth(CustomerProposals, isNotCrew)}
            />

            <Route
                history={history} path="/customer/sites/:id"
                component={RequireAuth(CustomerSites, isNotCrew)}
            />

            <Route
                path="/contact_form"
                component={CustomerContactForm}
            />

            <Route
                path="/terms_and_conditions"
                component={TermsAndConditions}
            />

            <Route
                path="/privacy_policy"
                component={PrivacyPolicy}
            />

            <Route
                history={history} path="/customer/manage_invoices/:id"
                component={RequireAuth(CustomerManageInvoices, isNotCrew)}
            />

            <Route
                history={history} path="/customer/invoices/:id"
                component={RequireAuth(CustomerInvoices, isNotCrew)}
            />
            <Route history={history} path="/old_activities" component={RequireAuth(Activities, isNotCrew)}/>
            <Route history={history} path="/activities" component={RequireAuth(NewActivities, isNotCrew)}/>
            <Route history={history} path="/completed_services" component={RequireAuth(CompletedServices, isNotCrew)}/>

            <Route
                history={history} path="/customer/work_orders/:id"
                component={RequireAuth(CustomerWorkOrders, isNotCrew)}
            />

            <Route history={history} path="/revenue_reports" component={RequireAuth(RevenueReports, isNotCrew)}/>
            <Route history={history} path="/sales_dashboard" component={RequireAuth(SalesDashboardPage, isNotCrew)}/>
            <Route history={history} path="/new_sales_dashboard_v1" component={RequireAuth(SalesDashboard, isNotCrew)}/>
            <Route history={history} path="/new_sales_dashboard_v2" component={RequireAuth(NewSalesDashboard, isNotCrew)}/>
            <Route history={history} path="/referral_report" component={RequireAuth(ReferralReport, isNotCrew)}/>
            <Route
                history={history} path="/pending_approvals"
                component={RequireAuth(PendingApprovals, isNotCrew)}
            />
            <Route history={history} path="/mapview(/:proposalId)" component={RequireAuth(MapViewPage, isNotCrew)}/>
            <Route history={history} path="/mapview_old(/:proposalId)" component={RequireAuth(MapView, isNotCrew)}/>
            <Route path="/proposal(/:proposalId)" component={RequireAuth(MapViewPage, isNotCrew)}/>
            <Route history={history} path="/my_proposals(/:proposalId)"
                   component={RequireAuth(MyProposals, isNotCrew)}/>
            <Route history={history} path="/proposed_services(/:proposalId)"
                   component={RequireAuth(ProposedServices, isNotCrew)}/>
            <Route history={history} path="/active_customers(/:proposalId)"
                   component={RequireAuth(ActiveCustomers, isNotCrew)}/>
            <Route history={history} path="/my_accepted(/:proposalId)" component={RequireAuth(MyAccepted, isNotCrew)}/>
            <Route history={history} path="/completed_proposals(/:proposalId)"
                   component={RequireAuth(CompletedProposals, isNotCrew)}/>
            <Route history={history} path="/sales_funnel(/:proposalId)"
                   component={RequireAuth(SalesFunnel, isNotCrew)}/>
            <Route
                history={history} path="/print_proposal/:proposalId"
                component={RequireAuth(PrintProposalPage, isNotCrew)}
            />
            <Route
                history={history} path="/print_invoice/:proposalId"
                component={RequireAuth(PrintInvoicePage, isNotCrew)}
            />
            <Route
                history={history} path="/customer_view/:token"
                component={RequireAuth(CustomerViewPage)}
            />
            <Route
                history={history} path="/cv/:token"
                component={RequireAuth(CustomerViewPage)}
            />
            <Route
                history={history} path="/customer_view/invoice/:token"
                component={RequireAuth(CustomerViewInvoice)}
            />
            <Route
                history={history} path="/cv/inv/:token"
                component={RequireAuth(CustomerViewInvoice)}
            />
            <Route history={history} path="/oauth_page" component={RequireAuth(OAuthPage)}/>
            <Route
                history={history} path="/customer_view/pdf/:token"
                component={RequireAuth(PdfPrintPage)}
            />
            <Route
                history={history} path="/reset_password/:token"
                component={RequireAuth(ResetPasswordPage)}
            />
            <Route
                history={history} path="/application_sites"
                component={RequireAuth(ApplicationSites, isNotCrew)}
            />
            <Route
                history={history} path="/map_scheduler"
                component={RequireAuth(MapSchedulerPage, isNotCrew)}
            />
            <Route
                history={history} path="/application_types"
                component={RequireAuth(ApplicationTypes, isNotCrew)}
            />
            <Route history={history} path="/asset_types" component={RequireAuth(AssetTypes, isNotCrew)}/>
            <Route history={history} path="/conditions" component={RequireAuth(Conditions, isNotCrew)}/>
            <Route history={history} path="/consumables" component={RequireAuth(Consumables, isNotCrew)}/>
            <Route history={history} path="/contact_types" component={RequireAuth(ContactTypes, isNotCrew)}/>
            <Route history={history} path="/customer_types" component={RequireAuth(CustomerTypes, isNotCrew)}/>
            <Route history={history} path="/equipment" component={RequireAuth(Equipment, isNotCrew)}/>
            <Route
                history={history} path="/service_priorities"
                component={RequireAuth(ServicePriorities, isNotCrew)}
            />
            <Route history={history} path="/plants" component={RequireAuth(Plants, isNotCrew)}/>
            <Route history={history} path="/factors" component={RequireAuth(Factors, isNotCrew)}/>
            <Route history={history} path="/glossaries" component={RequireAuth(Glossaries, isNotCrew)}/>
            <Route history={history} path="/ordinance_and_laws" component={RequireAuth(OrdinanceAndLaws, isNotCrew)}/>
            <Route history={history} path="/factor_types" component={RequireAuth(FactorTypes, isNotCrew)}/>
            <Route history={history} path="/referrals" component={RequireAuth(Referrals, isNotCrew)}/>
            <Route history={history} path="/service_types" component={RequireAuth(ServiceTypes, isNotCrew)}/>
            <Route history={history} path="/payment_types" component={RequireAuth(PaymentTypes, isNotCrew)}/>
            <Route history={history} path="/employees" component={RequireAuth(EmployeesPage, 'admin')}/>
            {["/calendar", "/scheduler"].map((path, index) =>
                <Route history={history} path={path} component={RequireAuth(Calendar, isNotCrew)} key={index}/>
            )}
            <Route history={history} path="/leads" component={RequireAuth(Leads, isNotCrew)}/>
            <Route history={history} path="/lead(/:id)" component={RequireAuth(Lead, isNotCrew)}/>
            <Route history={history} path="/proposal_lead(/:id)" component={RequireAuth(ProposalLead, isNotCrew)}/>
            <Route history={history} path="/proposal_lead(/:id)/time(/:eventId)"
                   component={RequireAuth(ProposalFindTime, isNotCrew)}/>
            <Route history={history} path="/lead(/:id)/time(/:eventId)" component={RequireAuth(FindTime, isNotCrew)}/>
            <Route history={history} path="/trucks" component={RequireAuth(Trucks, isNotCrew)}/>
            <Route history={history} path="/trucks(/:id)/new_service" component={RequireAuth(Trucks)}/>
            <Route history={history} path="/trucks(/:id)/new_service_page"
                   component={RequireAuth(TruckNewServicePage)}/>
            <Route history={history} path="/hazards" component={RequireAuth(Hazards, isNotCrew)}/>
            <Route history={history} path="/scheduler_event_types"
                   component={RequireAuth(SchedulerEventTypes, isNotCrew)}/>
            <Route history={history} path="/time_track_types" component={RequireAuth(TimeTrackTypes, isNotCrew)}/>
            <Route history={history} path="/reason" component={RequireAuth(Reason, isNotCrew)}/>
            <Route history={history} path="/terms" component={RequireAuth(Terms, isNotCrew)}/>
            <Route history={history} path="/services" component={RequireAuth(NewServices, isNotCrew)}/>
            <Route history={history} path="/issues" component={RequireAuth(IssuesView, isNotCrew)}/>
            <Route history={history} path="/organizational_chart" component={RequireAuth(OrganizationalChartPage)}/>
            <Route history={history} path="/holidays" component={RequireAuth(Holidays, isNotCrew)}/>
            <Route
                history={history} path="/work_orders(/:proposalId)"
                component={RequireAuth(WorkOrders, isNotCrew)}
            />

            <Route
                history={history} path="/complete_work_orders(/:workOrderNo)"
                component={RequireAuth(ChangeServiceStatusPage, isNotCrew)}
            />

            <Route history={history} path="/renewals" component={RequireAuth(Renewals, isNotCrew)}/>

            <Route
                history={history} path="/print_work_orders(/:woIds)"
                component={RequireAuth(PrintWorkOrders, allRoles)}
            />
            <Route
                history={history} path="/print_work_orders/all"
                component={RequireAuth(PrintWorkOrders, allRoles)}
            />
            <Route history={history} path="/dashboard" component={RequireAuth(Dashboard, isNotCrew)}/>
            <Route
                history={history} path="/find_work_orders"
                component={RequireAuth(FindWorkOrders, isNotCrew)}
            />
            <Route history={history} path="/admin" component={RequireAuth(NewAdminPage, 'it_engineering')}/>
            <Route history={history} path="*" component={Home}/>

        </Route>
    </Router>
}

export default Routes
