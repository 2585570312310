import React, {useState} from "react";
import {Button, Col, ControlLabel, FormGroup, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import {defaultDateFormat} from "../../../common/commonHandlers";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";

const dateTypes = [
    {value: "Custom", label: "Custom"},
    {value: "Today", label: "Today"},
    {value: "Yesterday", label: "Yesterday"},
    {value: "This Week", label: "This Week"},
    {value: "This Week-to-date", label: "This Week-to-date"},
    {value: "This Month", label: "This Month"},
    {value: "This Month-to-date", label: "This Month-to-date"},
    {value: "This Quarter", label: "This Quarter"},
    {value: "This Quarter-to-date", label: "This Quarter-to-date"},
    {value: "This Year", label: "This Year"},
    {value: "This Year-to-date", label: "This Year-to-date"},
    {value: "Last Week", label: "Last Week"},
    {value: "Last Week-to-date", label: "Last Week-to-date"},
    {value: "Last Month", label: "Last Month"},
    {value: "Last Month-to-date", label: "Last Month-to-date"},
    {value: "Last Quarter", label: "Last Quarter"},
    {value: "Last Quarter-to-date", label: "Last Quarter-to-date"},
    {value: "Last Year", label: "Last Year"},
    {value: "Last Year-to-date", label: "Last Year-to-date"},
]

const NewSalesDashboardFilterModal = ({onHide, baseFilters, filtersArrays, onSave}) => {
    const [newFilters, setNewFilters] = useState(baseFilters)
    const [focusedSent, setFocusedSent] = useState(null)
    const [focusedPromised, setFocusedPromised] = useState(null)
    const [focusedScheduled, setFocusedScheduled] = useState(null)
    const [focusedPaid, setFocusedPaid] = useState(null)

    const select = (label, name, options, isMulti) => {
        return <FormGroup className="select-wrapper">
            <ControlLabel className="font10 text-black">
                {label}
            </ControlLabel>
            <Select
                className="Select"
                classNamePrefix="select"
                name={name}
                value={newFilters[name]}
                options={options || []}
                isMulti={isMulti}
                placeholder={label}
                isClearable
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={e => {
                    setNewFilters({
                        ...newFilters,
                        [name]: e
                    })
                }}
            />
        </FormGroup>
    }

    const datePicker = (label, from, to, focusedInput, onFocusChange) => {
        return <FormGroup className="date-picker-wrapper">
            <ControlLabel className="font10">
                {label}
            </ControlLabel>
            <DateRangePicker
                selected={false}
                startDatePlaceholderText={'From'}
                endDatePlaceholderText={'To'}
                startDateId={'P From'}
                endDateId={'P To'}
                startDate={newFilters[from] ? moment.utc(newFilters[from]) : ""}
                endDate={newFilters[to] ? moment.utc(newFilters[to]) : ""}
                showClearDates={true}
                reopenPickerOnClearDate={true}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onDatesChange={({startDate, endDate}) => {
                    setNewFilters({
                        ...newFilters,
                        [from]: startDate,
                        [to]: endDate,
                    })
                }}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => {onFocusChange(focusedInput)}}
                isOutsideRange={() => {return false}}
                displayFormat={defaultDateFormat}
            />
        </FormGroup>
    }

    const checkbox = (label, name) => {
        return <FormGroup>
            <ColorCheckbox className='no-left-padding'
                           label={label}
                           value={newFilters[name]}
                           onChange={e => {
                               setNewFilters({
                                   ...newFilters,
                                   [name]: e
                               })
                           }}
            />
        </FormGroup>
    }

    return <Modal
        id="filter-sales-dashboard-id"
        show={true}
        onHide={onHide}
        backdrop="static"
    >
        <Modal.Header closeButton>
            <Modal.Title className="break-word-issue">Filter Sales Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12}>
                    {select("Sent Date Type", "sent_label_name", dateTypes, false)}
                </Col>
                {newFilters.sent_label_name?.value === "Custom" && <Col xs={12}>
                    {datePicker("Sent Dates", "sent_from", "sent_to", focusedSent, setFocusedSent)}
                </Col>}
            </Row>
            <Row>
                <Col xs={12}>
                    {select("Promised Date Type", "promised_label_name", dateTypes, false)}
                </Col>
                {newFilters.promised_label_name?.value === "Custom" && <Col xs={12}>
                    {datePicker("Promised Dates", "promised_from", "promised_to", focusedPromised, setFocusedPromised)}
                </Col>}
            </Row>
            <Row>
                <Col xs={12}>
                    {select("Scheduled Date Type", "scheduled_label_name", dateTypes, false)}
                </Col>
                {newFilters.scheduled_label_name?.value === "Custom" && <Col xs={12}>
                    {datePicker("Scheduled Dates", "scheduled_from", "scheduled_to", focusedScheduled, setFocusedScheduled)}
                </Col>}
            </Row>
            <Row>
                <Col xs={12}>
                    {select("Paid Date Type", "paid_label_name", dateTypes, false)}
                </Col>
                {newFilters.paid_label_name?.value === "Custom" && <Col xs={12}>
                    {datePicker("Paid Dates", "paid_from", "paid_to", focusedPaid, setFocusedPaid)}
                </Col>}
            </Row>
            <hr/>
            <Row>
                <Col md={6} xs={12}>
                    {select("Sales Arborist", "sales_arborist_ids", filtersArrays?.sales_arborists, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Assignee", "assignee_ids", filtersArrays?.sales_arborists, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Service Type", "service_type_ids", filtersArrays?.service_types, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Service", "service_ids", filtersArrays?.services, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Equipment", "equipment_ids", filtersArrays?.equipments, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Priorities", "priority_ids", filtersArrays?.priorities, true)}
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md={6} xs={12}>
                    {select("Customer", "customer_ids", filtersArrays?.customers, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Customer Type", "customer_type_ids", filtersArrays?.customer_types, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("City", "city", filtersArrays?.cities, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Zip", "zip", filtersArrays?.zips, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Plant", "plant_ids", filtersArrays?.plants, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Proposal", "proposal_ids", filtersArrays?.proposals, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Reason", "reason_ids", filtersArrays?.reasons, true)}
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md={6} xs={12}>
                    {select("Excluded Service", "ex_service", filtersArrays?.services, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Excluded Service Type", "ex_service_type", filtersArrays?.service_types, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Excluded Priority", "ex_priority", filtersArrays?.priorities, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Excluded Equipment", "ex_equipment", filtersArrays?.equipments, true)}
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    {select("Excluded Customer", "ex_customers", filtersArrays?.customers, true)}
                </Col>
                <Col md={6} xs={12}>
                    {select("Excluded Customer Type", "ex_customer_type", filtersArrays?.customer_types, true)}
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md={6} xs={12}>
                    {checkbox("Show Only Proposed", "show_only_proposed")}
                </Col>
                <Col md={6} xs={12}>
                    {checkbox("Excluded Scheduled", "exclude_scheduled")}
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsSize="small"
                bsStyle="danger"
                onClick={() => onHide()}
            >
                Cancel
            </Button>
            <Button
                bsSize="small"
                bsStyle="success"
                onClick={() => {
                    onSave(newFilters)
                    onHide()
                }}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default NewSalesDashboardFilterModal;