import React, {useState} from "react";
import {Button, Col, Glyphicon, Row} from "react-bootstrap";
import ReactDock from "react-dock";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const NewSalesDashboardDock = ({setShowDock, currentColumns, saveColumns}) => {
    const [columns, setColumns] = useState(currentColumns)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newColumns = reorder(
            columns,
            result.source.index,
            result.destination.index
        )

        setColumns(newColumns)
    }

    const formatName = (str) => {
        const words = str.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }

    return <ReactDock
        position='right'
        isVisible={true}
        dimMode="none"
        defaultSize={window.innerWidth > 990 ? 0.24 : window.innerWidth > 600 ? 0.6 : 1}
        dockStyle={{overflow: 'hidden'}}
        zIndex={1031}
    >
        <Row className="no-margin">
            <Col xs={12} className="space-between-end">
                <h4>Sales dashboard settings</h4>
                <Glyphicon
                    glyph='remove'
                    className="pointer margin10"
                    onClick={() => setShowDock(false)}
                />
            </Col>
        </Row>
        <Row className="no-margin">
            <Col xs={12} className="pb-10">
                <Button
                    bsSize="small"
                    bsStyle="success"
                    className="full-width"
                    onClick={() => saveColumns(columns)}
                >
                    Save
                </Button>
            </Col>
            <Col xs={6}>
                <Button
                    bsSize="small"
                    bsStyle="success"
                    className="full-width"
                    onClick={() => setColumns(columns.map(c => {return {...c, display: true}}))}
                >
                    Check All
                </Button>
            </Col>
            <Col xs={6}>
                <Button
                    bsSize="small"
                    bsStyle="success"
                    className="full-width"
                    onClick={() => setColumns(columns.map(c => {return {...c, display: false}}))}
                >
                    Uncheck All
                </Button>
            </Col>
            <Col xs={12}>
                <h4>Column list:</h4>
            </Col>
            <Col xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {columns.map((c, index) => (
                                    <Draggable key={c.key} draggableId={c.key} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <ColorCheckbox
                                                    label={formatName(c.key)}
                                                    value={c.display}
                                                    onChange={(e) => {
                                                        setColumns(columns.map(col => {
                                                            if (col.key === c.key) {
                                                                col.display = e
                                                            }
                                                            return col
                                                        }))
                                                    }}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Col>
        </Row>
    </ReactDock>
}

export default NewSalesDashboardDock;