import React, {useEffect, useState} from "react";
import {InfoWindow, Marker, Polygon} from "react-google-maps";
import {Link} from "react-router";
import {Button, Col, Glyphicon, OverlayTrigger, Panel, Row, Tooltip} from "react-bootstrap";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import EventWindowContent from "./EventWindowContent";
import {reverseColor} from "../../common/commonHandlers";
import moment from "moment/moment";
import {getBasicMarker} from "../../utilities";

const MemoizedMarker = ({
                            item,
                            onInfoOpen,
                            onInfoClose,
                            eventType,
                            onAddToRoute,
                            markerOrderNumber,
                            removePsFromWo,
                            sites,
                            onInfoLinkClick,
                            showExclusive,
                            locationState,
                            scheduledServices,
                            checkedRoute,
                            index,
                            getColor,
                            handleToggleRouteRemoveHelper,
                            resource,
                            activeMarkerData,
                            reload,
                            proposalServiceWithSchedulerEventId,
                            spiderfy,
                            spiderfied,
                            spiderfiedIndex,
                            spiderfiedCount,
                            googleMapZoom,
                            setSpiderfiedLocation
                        }) => {
    const [isOpen, setIsOpen] = useState(null);
    const representProposalService = proposalServiceWithSchedulerEventId || item[0];

    useEffect(() => {
        const currentOpen = activeMarkerData && item?.some(i => i.site_id === activeMarkerData?.site_id)
        if (currentOpen) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }

        if (currentOpen) {
            const idsDiffer = activeMarkerData.site_id !== representProposalService.site_id;
            const eventGuestIdsDiffer = activeMarkerData.scheduler_event_guest_id !== representProposalService.scheduler_event_guest_id;
            if (idsDiffer || eventGuestIdsDiffer) {
                setIsOpen(false);
            }
        }
    }, [item?.[0]?.id, activeMarkerData])

    useEffect(() => {
        spiderfy(null)
        setSpiderfiedLocation({lat: null, lng: null})
    }, [googleMapZoom])

    const displayEventWindow = eventType && Object.values(sites).every((item) => item[0].id === null);
    const today = moment().toDate().toLocaleDateString()

    const getMarkerStyles = () => {
        if (representProposalService.mixedMarker && !spiderfied && representProposalService.scheduler_event_guest_id){
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#f8e500',
                    textColor: reverseColor('#f8e500'),
                    label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                }),
                zIndex: 2000 + markerOrderNumber
            }
        } else if (representProposalService.mixedMarker && !spiderfied) {
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#56C1FE',
                    textColor: reverseColor('#56C1FE'),
                    label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                }),
                zIndex: 0 + markerOrderNumber
            }
        }

        if (isOpen) {
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#f8e500',
                    textColor: reverseColor('#f8e500'),
                    label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                }),
                zIndex: 3000 + markerOrderNumber
            }
        } else if (representProposalService.scheduler_event_guest_id) {
            if (resource.showTodaysRouteAllUsers) {
                return {
                    icon: getBasicMarker({
                        selected: false,
                        color: getColor({person_id: item[0].person_id}),
                        textColor: reverseColor(getColor({person_id: item[0].person_id})),
                        label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                    }),
                    zIndex: (representProposalService.mixedMarker && spiderfied) ? 3000 + markerOrderNumber : 1000 + markerOrderNumber + index
                }
            } else {
                return {
                    icon: getBasicMarker({
                        selected: false,
                        color: getColor({person_id: resource.person_id}),
                        textColor: reverseColor(getColor({person_id: resource.person_id})),
                        label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                    }),
                    zIndex: (representProposalService.mixedMarker && spiderfied) ? 3000 + markerOrderNumber : 1000 + markerOrderNumber + index
                }
            }
        } else {
            return {
                icon: getBasicMarker({
                    selected: false,
                    color: '#56C1FE',
                    label: representProposalService.mixedMarker && !spiderfied ? "✚" : markerOrderNumber && item?.filter(i => i.scheduler_event_id)?.length > 0 ? markerOrderNumber.toString() : ""
                }),
                zIndex: (representProposalService.mixedMarker && spiderfied) ? 3000 + markerOrderNumber : 1000
            }
        }
    }

    const {icon, zIndex, textColor, opacity} = getMarkerStyles();
    const handleInfoClose = () => {
        setIsOpen(false)
        onInfoClose()
    };

    function calculateSpiderfiedPosition(marker, markerIndex) {
        const zoom = googleMapZoom || 1

        const additionalIntercept = spiderfiedCount >= 8 ? (spiderfiedCount - 8) * 0.06 : 0;
        const radius = Math.exp(-0.674 * zoom + 4.287 + additionalIntercept)

        let offset
        let mockedMarkersCount
        switch (spiderfiedCount) {
            case 2:
                mockedMarkersCount = 5
                offset = 2
                break;
            case 3:
                mockedMarkersCount = 4
                offset = 1.5
                break;
            case 4:
                mockedMarkersCount = 3
                offset = 1
                break;
            case 5:
                mockedMarkersCount = 2
                offset = 0.5
                break;
            case 6:
                mockedMarkersCount = 1
                offset = 0
                break;
            default:
                mockedMarkersCount = 0
                offset = 0
        }

        const angle = (2 * Math.PI /(spiderfiedCount + mockedMarkersCount)) * (markerIndex + offset) ;

        const lat = marker.latitude + radius * Math.cos(angle);
        const lng = marker.longitude + radius * Math.sin(angle);

        return {lat, lng};
    }

    const defaultPosition = {
        lat: representProposalService.latitude,
        lng: representProposalService.longitude
    }
    const position = spiderfied ? calculateSpiderfiedPosition(representProposalService, spiderfiedIndex) : defaultPosition;

    return item && item.length > 0 && representProposalService.latitude && (
        <>
            <Marker
                position={position}
                icon={icon}
                title={representProposalService.site_name}
                zIndex={zIndex}
                onClick={() => {
                    if (representProposalService.mixedMarker && !spiderfied) {
                        if (representProposalService.scheduler_event_guest_id) {
                            spiderfy(representProposalService.site_id);
                            setSpiderfiedLocation({lat: representProposalService.latitude, lng: representProposalService.longitude})
                        } else {
                            setSpiderfiedLocation({lat: representProposalService.latitude, lng: representProposalService.longitude})
                            spiderfy(null)
                        }
                        handleInfoClose();
                    } else {
                        onInfoOpen(() => setIsOpen(true))
                        if (!representProposalService.mixedMarker) {
                            spiderfy(null);
                            setSpiderfiedLocation({lat: null, lng: null})
                        }
                    }
                }}
            >
                {isOpen && !displayEventWindow &&
                    <InfoWindow
                        onCloseClick={handleInfoClose}>
                        <div>
                            {Object.values(sites).map((item) => {
                                if (!!item[0].id) {
                                    const types = [];
                                    item.map(site => {
                                        if (site.service_type && !types.includes(site.service_type)) {
                                            types.push(site.service_type);
                                        }
                                    });
                                    const formattedTypes = types.map(type => {
                                        let typeObject = {};
                                        typeObject.fullName = type;
                                        if (type !== 'PHC') {
                                            typeObject.shortName = type.split(' ').map(s => s.charAt(0)).join('');
                                        } else {
                                            typeObject.shortName = type;
                                        }
                                        return typeObject;
                                    });
                                    formattedTypes.map(type => {
                                        let hours = 0;
                                        let total = 0;
                                        item.filter((thing, index, self) => self.findIndex(t => t.service_no === thing.service_no) === index).map(item => {
                                            hours += item.service_type === type.fullName ? +item.man_hours : 0;
                                            total += item.service_type === type.fullName ? +item.service_total : 0;
                                            type.hours = hours;
                                            type.total = total;
                                        })
                                    });

                                    const isStatusOverThenAccepted = (status) => {
                                        return status === "Scheduled" ||
                                            status === "Completed" ||
                                            status === "Invoiced" ||
                                            status === "Payment" ||
                                            status === "Paid"
                                    }

                                    return <div className="info-window">
                                        <h6 className={`text-blue`}>{item[0].customer_full_name} (
                                            <Link
                                                onClick={() => onInfoLinkClick(item[0].id)}>
                                                Proposal
                                                #{item[0].proposal_no}
                                            </Link>
                                            )
                                            <span className={`text-green`}>
                                                                                            {
                                                                                                formattedTypes.map(type => {
                                                                                                    return `  ${type.shortName}: ${(type.hours).toFixed(2)} hr  $${type.total.toFixed(2)}`
                                                                                                })
                                                                                            }
                                                                                        </span>
                                        </h6>
                                        <span><strong>{item[0].full_address}</strong></span>
                                        {item.sort((a, b) => a.service_no - b.service_no).filter((item, index, self) =>
                                            index === self.findIndex((t) => (
                                                t.proposal_service_id === item.proposal_service_id
                                            ))).map((ps, idx) => {
                                                const service_total = '$' + parseFloat(ps.service_total).toFixed(2);

                                            const label = <span
                                                className={`${(representProposalService.mixedMarker ? ps.date_from && moment(ps.date_from).toDate().toLocaleDateString() === today : scheduledServices.includes(ps.proposal_service_id)) && 'text-orange'} ${(showExclusive && ps.exclusive || locationState && locationState.ids.includes(ps.proposal_service_id)) && 'text-purple'}`}>{`#${ps.service_no} ${ps.name} ${ps.man_hours}hrs ${service_total} ${ps.work_order_no && `WO #${ps.work_order_no}`}`}</span>;
                                            return (
                                                <Row key={idx}>
                                                    <Col xs={12}>
                                                        <div
                                                            className={ps.is_bundle_service && 'bundle-services'}>
                                                            {!ps.is_bundle_service
                                                                ? <div className="vertical-align">
                                                                    <ColorCheckbox
                                                                        value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                        label={ps.schedulable ?
                                                                            <b>{label}</b> :
                                                                            <b className='text-gray'>{label}</b>}
                                                                        key={idx}
                                                                        onChange={() => handleToggleRouteRemoveHelper(ps)}/>
                                                                    {ps.work_order_id && !isStatusOverThenAccepted(ps.service_status_name) &&
                                                                        <OverlayTrigger placement="top"
                                                                                        overlay={<Tooltip
                                                                                            id="tooltip">Remove
                                                                                            Proposal Service from
                                                                                            Work Order</Tooltip>}>
                                                                                                                        <span
                                                                                                                            onClick={() => {
                                                                                                                                removePsFromWo(ps.proposal_service_id, () => {
                                                                                                                                    reload()
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            className="text-danger ml-9 pointer">
                                                                                                                                <Glyphicon
                                                                                                                                    glyph="remove-sign"/>
                                                                                                                            </span>
                                                                            </OverlayTrigger>}
                                                                    </div>
                                                                    : <ColorCheckbox
                                                                        value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                        key={idx}
                                                                        onChange={() => handleToggleRouteRemoveHelper(ps)}/>
                                                                }
                                                                {ps.is_bundle_service &&
                                                                    <Panel
                                                                        collapsible
                                                                        expanded={true}
                                                                        header={
                                                                            <span>
                                                                                                                        {label}
                                                                            {ps.work_order_id && !isStatusOverThenAccepted(ps.service_status_name) &&
                                                                                <OverlayTrigger placement="top"
                                                                                                overlay={<Tooltip
                                                                                                    id="tooltip">Remove
                                                                                                    Proposal Service
                                                                                                    from Work
                                                                                                    Order</Tooltip>}>
                                                                                                                        <span
                                                                                                                            onClick={() => {
                                                                                                                                removePsFromWo(ps.proposal_service_id, () => {
                                                                                                                                    reload()
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            className="text-danger ml-9 pointer">
                                                                                                                                <Glyphicon
                                                                                                                                    glyph="remove-sign"/>
                                                                                                                            </span>
                                                                                    </OverlayTrigger>}
                                                                                                                    </span>

                                                                        }
                                                                        className={'bundle-services__panel'}
                                                                    >
                                                                        <div
                                                                            className='bundle-services__body'>
                                                                            {ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map(bs => {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className='bundle-services__status'>
                                                                                            <ColorCheckbox
                                                                                                value={checkedRoute && checkedRoute.length > 0 && checkedRoute.includes(ps.proposal_service_id)}
                                                                                                disabled={true}
                                                                                                key={idx}
                                                                                                label={bs.name}
                                                                                            />
                                                                                            {bs.wo_notes &&
                                                                                                <div
                                                                                                    className='colorRed ml-10'
                                                                                                    style={{whiteSpace: 'normal'}}>
                                                                                                    <p>{bs.wo_notes}</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </Panel>
                                                                }
                                                            </div>
                                                            {['In Work Order', 'Accepted', 'Scheduled'].includes(ps.service_status_name) &&
                                                                <div
                                                                    className='text-green'
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                                                                    {ps.priorities && ps.priorities.length > 0 &&
                                                                        <span>
                                                                                                                        {ps.priorities.map(p => p.name).join(', ')}
                                                                                                                    </span>
                                                                    }
                                                                    {ps.equipments && ps.equipments.length > 0 &&
                                                                        <span
                                                                            className="text-green">{ps.equipments.map(p => p).join(', ')}
                                                                                                                    </span>
                                                                    }
                                                                </div>
                                                            }
                                                            {ps.wo_notes &&
                                                                <div
                                                                    className='colorRed ml-10'
                                                                    style={{whiteSpace: 'normal'}}>
                                                                    <p>{ps.wo_notes}</p>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        )}
                                        <Col xs={12}
                                             className='save-work-order'>
                                            <Button
                                                className="pointer btn-smaller"
                                                bsStyle="success"
                                                disabled={item.filter(x => checkedRoute.includes(x.proposal_service_id)).length < 1}
                                                onClick={() => {
                                                    onAddToRoute(item, () => onInfoOpen())
                                                }}>
                                                Add To Route
                                            </Button>
                                        </Col>
                                    </div>
                                } else {
                                    return <EventWindowContent site={item}/>
                                }
                            })
                            }

                        </div>
                    </InfoWindow>}
                {isOpen && displayEventWindow &&
                    <InfoWindow
                        onCloseClick={handleInfoClose}>
                        <div>
                            {Object.values(sites).map((item) => {
                                return <EventWindowContent site={item}/>
                            })}
                        </div>
                    </InfoWindow>
                }
            </Marker>
            {spiderfied && (
                <Polygon
                    paths={[defaultPosition, position]}
                    options={{
                        strokeColor: "#ff0000",
                        strokeOpacity: 0.9,
                        strokeWeight: 3
                    }}
                />
            )}
        </>)
}
export default MemoizedMarker;