import React, {useRef} from "react";
import {BootstrapTable} from "react-bootstrap-table";
import MDSpinner from "react-md-spinner";
import {columnsSelect} from "./NewSalesDashboardColumns";
import moment from "moment";
import {Col, Row} from "react-bootstrap";

const NewSalesDashboardTable = ({
                                    tab,
                                    data,
                                    per_page,
                                    page,
                                    total,
                                    setPagination,
                                    isBacklog,
                                    columns,
                                    loadingData,
                                    sort,
                                    setSort,
                                    saveNewCsvDownload,
                                    tableButtons,
                                    selectedRecords,
                                    setSelectedRecords,
                                    allSelectedRecords,
                                    setAllSelectedRecords,
                                    showEmailDialog,
                                    loadProposalData,
                                    defaultEmail
                                }) => {

    const tableRef = useRef()

    const showProposalEmailDialog = proposalId => {
        loadProposalData(proposalId, proposal => {
            let recipientEmail
            const {contactEmail, contactEmail2, secondContactEmail, secondContactEmail2, additionalContactEmails, additionalContactEmails2} = proposal
            const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
            const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

            recipientEmail = `${primarySiteContactEmails || ""}`
            if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`
            if (additionalContactEmails?.length > 0) recipientEmail += `, ${additionalContactEmails.toString()}`
            if (additionalContactEmails2?.length > 0) recipientEmail += `, ${additionalContactEmails2.toString()}`

            showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: recipientEmail,
                    defaultEmail: defaultEmail
                });
        });
    };

    const noDataText = () => {
        return loadingData ?
            <MDSpinner size={70} borderSize={2}/>
            :
            "No data found"
    }

    const renderSizePerPageDropDown = ({currSizePerPage, sizePerPageList, changeSizePerPage}) => (
        <div className="btn-group dropup">
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{currSizePerPage}</span>
                <span className="ml-6 caret"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {sizePerPageList.map(({text, value}) =>
                    <li className='pointer'>
                        <a
                            className='table-pagination dropdown-item'
                            onClick={() => changeSizePerPage(value)}
                        >{text}</a>
                    </li>
                )}
            </ul>
        </div>
    );

    return <BootstrapTable
        ref={tableRef}
        id='new-sales-dashboard-table'
        data={data}
        fetchInfo={{dataTotalSize: total}}
        striped
        bordered={false}
        hover
        remote
        className={`${tab}-select-table`}
        pagination
        exportCSV={true}
        csvFileName={() => {
            const fileName = `Sales-Dashboard-${tab}-${moment().format()}.csv`
            if (tableRef?.current) {
                saveNewCsvDownload(tableRef.current, fileName, data, "sales dashboard")
            }
            return fileName
        }}
        options={
            {
                sizePerPageDropDown: renderSizePerPageDropDown,
                onPageChange: (page, per_page) => setPagination({page, per_page}),
                page,
                sizePerPageList: [
                    {text: '10', value: 10},
                    {text: '25', value: 25},
                    {text: '30', value: 30},
                    {text: '50', value: 50},
                    {text: '100', value: 100},
                    {text: '200', value: 200},
                    {text: 'All', value: total}
                    ],
                sizePerPage: per_page,
                noDataText: noDataText(),
                sortName: sort.sort_by,
                sortOrder: sort.sort,
                onSortChange: (sort_by, sort) => {
                    setSort({sort_by: sort_by, sort: sort})
                },
                toolBar: tableButtons
            }
        }
        selectRow={{
            mode: "checkbox",
            clickToSelect: false,
            columnWidth: "10%",
            hideSelectColumn: data?.length === 0 || loadingData,
            selected: allSelectedRecords ? data.map(x => isBacklog ? x.proposal_service_id : x.proposal_id) : selectedRecords.map(x => isBacklog ? x.proposal_service_id : x.proposal_id),
            onSelect: (row, selected) => {
                if (selected) {
                    const selectedData = selectedRecords.concat(row)
                    setAllSelectedRecords(selectedData.length === data.length)
                    setSelectedRecords(selectedData)
                } else {
                    setAllSelectedRecords(false)
                    setSelectedRecords(selectedRecords.filter(c => isBacklog ? c.proposal_service_id !== row.proposal_service_id : c.proposal_id !== row.proposal_id))
                }
            },
            onSelectAll: (isAllSelected, selectedData) => {
                if (isAllSelected) {
                    setAllSelectedRecords(isAllSelected)
                    setSelectedRecords(selectedData)
                } else {
                    setAllSelectedRecords(isAllSelected)
                    setSelectedRecords([])
                }
            }
        }}
    >
        {columns.concat('defaultColumn').map(c => (
            columnsSelect(c, isBacklog, showProposalEmailDialog)
        ))}
    </BootstrapTable>
}

export default NewSalesDashboardTable;