import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {CirclePicker} from "react-color";
import {colors, updateEmbeddedLink} from "../../../common/commonHandlers";
import {fetchColors, updateClient} from './ServiceConfigurationApi';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const actions = {
    updateClient,
    fetchColors
};

class Embedded extends Component {

    state = {
        client: {
            id: this.props.clientId,
            embedded_background_color: '',
            embedded_font_color: '',
            ordinance_background_color: '',
            embedded_email_recipient: ''

        },
    }

    copyToClipboard = () => {
        const textBox = document.getElementById("embedded_form");
        textBox.select();
        document.execCommand("copy");
    }
    copyToClipboard2 = () => {
        const textBox = document.getElementById("ordinance_and_laws_embedded_form");
        textBox.select();
        document.execCommand("copy");
    }

    handleColorChange = (newColor, element) => {
        let client = {
            id: this.props.clientId,
            embedded_background_color: element === 'bg' ? newColor : this.state.client.embedded_background_color,
            embedded_font_color: element === 'font' ? newColor : this.state.client.embedded_font_color,
            ordinance_background_color: element === 'ordinance' ? newColor : this.state.client.ordinance_background_color,
        }
        this.setState({client: client}, () => this.props.actions.updateClient(this.state.client))
    }


    handleEmailRecipientChange = (e) =>
        this.setState({
            client: {
                ...this.state.client,
                embedded_email_recipient: e.target.value
            }
        })

    handleEmailRecipientBlur = () => this.props.actions.updateClient({...this.state.client, id: this.props.clientId})


    componentDidMount() {
        const token = this.props.client_token
        if (token) {
            this.props.actions.fetchColors(token, (formColors) => this.setState({
                client: {
                    ...formColors,
                    embedded_email_recipient: this.props.embeddedEmailRecipient
                }
            }))
        }
    }

    render() {
        const {client_token} = this.props;
        const {showPicker1, showPicker2, showPicker3, client} = this.state;
        let iFrameAddress = `<iframe src="https://embedded.treehub.me/?token=${client_token}"  frameborder="0" allowfullscreen style="width:100%;height:700px;"></iframe>`
        let iFrameOrdinanceAndLawAddress = `<iframe src="https://embedded.treehub.me/ordinance_and_laws?token=${client_token}" allowfullscreen style="width:100%;height:700px;"></iframe>`

        iFrameAddress = updateEmbeddedLink(iFrameAddress)
        iFrameOrdinanceAndLawAddress = updateEmbeddedLink(iFrameOrdinanceAndLawAddress)

        return (
            <Grid fluid className="top25">
                <Row>
                    <Col md={3}/>
                    <Col md={6}>
                        <Row>
                            <h4>Embedded Form</h4>
                        </Row>
                        <Row>
                            {
                                <FormGroup>
                                    <ControlLabel>
                                        Email Recipient
                                    </ControlLabel>
                                    <FormControl
                                        name="embedded_email_recipient"
                                        onChange={this.handleEmailRecipientChange}
                                        onBlur={this.handleEmailRecipientBlur}
                                        value={this.state.client.embedded_email_recipient}
                                    />
                                    <br/>
                                    <ControlLabel>
                                        Font Color
                                    </ControlLabel>
                                    <Col
                                        className={`c${colors.findIndex(c => c === client.embedded_font_color)} color-picker pointer visible bigger`}
                                        onClick={() => this.setState({showPicker1: !showPicker1})}/>
                                    {showPicker1 && <CirclePicker width={400} colors={colors} onChange={e => {
                                        this.setState({
                                            showPicker1: false
                                        }, () => {
                                            this.handleColorChange(e.hex, "font")
                                        });
                                    }}/>}
                                    <br/>
                                    <ControlLabel>
                                        Background Color
                                    </ControlLabel>

                                    <Col
                                        className={`c${colors.findIndex(c => c === client.embedded_background_color)} color-picker pointer visible bigger`}
                                        onClick={() => this.setState({showPicker2: !showPicker2})}/>
                                    {showPicker2 && <CirclePicker width={400} colors={colors} onChange={e => {
                                        this.setState({
                                            showPicker2: false
                                        }, () => {
                                            this.handleColorChange(e.hex, "bg")
                                        });
                                    }}/>}
                                </FormGroup>
                            }

                            <FormGroup>
                                <FormControl
                                    value={iFrameAddress}
                                    className="textAreaInput"
                                    componentClass="textarea"
                                    name="embedded_form"
                                    id="embedded_form"
                                    rows="6"
                                    spellCheck={false}
                                    style={{textAlign: 'left'}}
                                />
                            </FormGroup>
                        </Row>
                        <Row style={{textAlign: 'right'}}>
                            <Button onClick={this.copyToClipboard}>Copy</Button>
                        </Row>
                    </Col>
                    <Col md={3}/>
                </Row>
                <Row>
                    <Col md={3}/>
                    <Col md={6}>
                        <Row>
                            <h4>Embedded Ordinances</h4>
                        </Row>
                        <Row>
                            <FormGroup>
                                <ControlLabel>
                                    Background Color
                                </ControlLabel>

                                <Col
                                    className={`c${colors.findIndex(c => c === client.ordinance_background_color)} color-picker pointer visible bigger`}
                                    onClick={() => this.setState({showPicker3: !showPicker3})}/>
                                {showPicker3 && <CirclePicker width={400} colors={colors} onChange={e => {
                                    this.setState({
                                        showPicker1: false
                                    }, () => {
                                        this.handleColorChange(e.hex, "ordinance")
                                    });
                                }}/>}
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <FormControl
                                    value={iFrameOrdinanceAndLawAddress}
                                    className="textAreaInput"
                                    componentClass="textarea"
                                    name="ordinance_and_laws_embedded_form"
                                    id="ordinance_and_laws_embedded_form"
                                    rows="6"
                                    spellCheck={false}
                                    style={{textAlign: 'left'}}
                                />
                            </FormGroup>
                        </Row>
                        <Row style={{textAlign: 'right'}}>
                            <Button onClick={this.copyToClipboard2}>Copy</Button>
                        </Row>
                    </Col>
                    <Col md={3}/>
                </Row>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(Embedded)
