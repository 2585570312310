import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapPersonSearch} from "../../common/commonHandlers";

export const load = (clientId, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/scheduler_event_types`},
        {url: `/api/search/all_employees?disabled=true`},
        {url: `/api/search/search_for_guest`, params: {q: '', customer_id: null}},
        {url: `/api/clients/${clientId}`},
        {url: `/api/users/profile`},
        {url: `/api/customers/name_zip_city_search`, params: {q: ''}}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const employees = all[1].data;

        let disabledEmployees = [];

        if (employees?.length > 0) {
            employees.map(e => {
                if (e.disabled) {
                    e.label = `${e.label} (Disabled)`
                }
                disabledEmployees.push(e)
            })
        }

        const data = {
            guestOptions: mapPersonSearch(all[2].data),
            schedulerEventTypes: all[0].data,
            employees: disabledEmployees,
            client: all[3].data,
            person: all[4].data,
            customerSearchResults: all[5].data
        };
        callback(data);
    }, dispatch, getState, false);
};

export const loadWorkOrder = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/${id}/show_no_assets/`
    };

    apiCall("Loading work order", config, async config => {
        const response = await axios.request(config);
        const contextResource = response.data;
        getCustomer(contextResource.customer_id, result => {
            callback({contextResource, ...result});
        })(dispatch, getState);
    }, dispatch, getState, false);
};

export const loadLead = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/leads/${id}`
    };

    apiCall("Loading lead", config, async config => {
        const response = await axios.request(config);
        const contextResource = response.data;
        getCustomer(contextResource.customer_id, result => {
            callback({contextResource, ...result});
        })(dispatch, getState);
    }, dispatch, getState, false);
};


export const getCustomer = (id, callback) => (dispatch, getState) => {
    const config = {url: `/api/customers/name_zip_city_search`, params: {id}};
    apiCall("Loading customer", config, async config => {
        const response = await axios.request(config);
        const customerSearchResults = response.data;
        callback({customerSearchResults});
    }, dispatch, getState, false);
};

export const markAsCompleted = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_completed`,
        params: {start: resource.start, end: resource.end, complete_all_events: resource.complete_all_events, complete_all_future_events: resource.complete_all_future_events},
        method: 'PUT'
    };
    apiCall("Marking as completed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const markAsConfirmed = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_confirmed`,
        params: {start: resource.start, end: resource.end, confirm_all_events: resource.confirm_all_events, confirm_all_future_events: resource.confirm_all_future_events},
        method: 'PUT'
    };
    apiCall("Marking as confirmed", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};
export const markAsOnSite = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/toggle_on_site`,
        params: {start: resource.start, end: resource.end, on_site_all_events: resource.on_site_all_events, on_site_all_future_events: resource.on_site_all_future_events},
        method: 'PUT'
    };
    apiCall("Saving on site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const completeAllServices = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}/complete`,
        method: 'PUT'
    };

    apiCall("Completing all services", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};


export const loadEvent = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${id}`,
        method: 'GET'
    };
    apiCall("Loading event", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadEvents = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events`,
        params
    };
    apiCall("Loading events", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const search = (q, customer_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/search/search_for_guest`,
        params: {q, customer_id}
    };
    apiCall("Searching for guests", config, async config => {
        const response = await axios.request(config);
        callback(mapPersonSearch(response.data));
    }, dispatch, getState, false);
};

export const updateEvent = (scheduler_event, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${scheduler_event.id}`,
        method: 'PUT',
        data: {scheduler_event}
    };

    apiCall("Updating scheduler event", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const syncCalendar = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/sync_calendar`,
        method: 'POST'
    };
    apiCall("Scheduler refresh", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadSite = (siteId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/new_sites/${siteId}/show_no_assets`
    };

    apiCall("Loading site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const geocodeAddress = (address, callback) => (dispatch, getState) => {

    const config = {
        url: "/api/sites/new_geocode",
        params: {
            address,
        },
    };
    apiCall("Loading site", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getPerson = (person_id, callback) => (dispatch, getState) => {
    const config = {
        url: "/api/search/search_customer_person",
        params: {person_id}
    };

    apiCall("Get person", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getUserDisabledStatus = (callback) => (dispatch, getState) => {
    const config = {
        url: "/api/users/show_disabled_users",
    };

    apiCall("Get user disabled status", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadConsumables = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/ps_consumables?proposal_service_id=` + proposal_service_id,
    };

    apiCall("Load consumables", config, async config => {
        const result = await axios.request(config);
        callback && callback({consumables: result.data.consumables});
    }, dispatch, getState, false);
};

export const loadManyConsumables = (proposal_service_ids, callback) => (dispatch, getState) => {
    const config = {
        url: '/api/consumables/ps_many_consumables',
        params: {proposal_service_ids}
    };

    apiCall("Load Many consumables", config, async config => {
        const result = await axios.request(config);
        callback && callback({consumables: result.data.consumables});
    }, dispatch, getState, false);
};

export const sendConsumablesConsumption = (consumption, costs, selectedMeasure, proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/add_ps_consumption`,
        method: 'POST',
        data: {
            consumption,
            costs,
            selectedMeasure,
            proposal_service_id
        }
    };

    apiCall("Saving consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const removeEvent = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/${resource.id}`,
        params: {start: resource.start, end: resource.end, delete_all_events: resource.delete_all_events, delete_all_future_events: resource.delete_all_future_events},
        method: 'DELETE'
    };
    apiCall("Scheduler events removing", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};