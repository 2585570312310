import React, {Component} from "react"
import * as MyActions from "./actions"
import {ProposalPrint} from "../PrintProposalPage/ProposalPrint";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import axios from "axios";

class PdfPrintPage extends Component {

    state = {};

    componentWillMount = () => {
        this.props.actions.fetchProposal(this.props.params.token, (proposal) => {
            proposal.site_maps.forEach(sitemap => {
                const property = {};
                property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                this.setState(property);
            });
            if(proposal.client_header) {
                axios.get(proposal.client_header).catch(() => {
                    proposal.client_header = 'https://s3-us-east-2.amazonaws.com/treereserve/production/images/trees.150605.1710640155.original.png?1710640155'
                });
            }
            this.setState({proposal})
        }, true);
    };

    render() {
        const {proposal} = this.state;
        if (!proposal) {
            return <div>Loading...</div>
        }
        return <ProposalPrint logoHeight={proposal && proposal.logo_height}
                              logoPosition={proposal && proposal.logo_position}
                              options={proposal.print_options}
                              proposal={proposal} client={this.props.client}/>
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.customerInfo
    }
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(MyActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(PdfPrintPage)
