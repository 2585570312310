import React, {Component} from "react"
import {Glyphicon, MenuItem, Nav, Navbar, NavDropdown, NavItem, Badge} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import moment from "moment-timezone"
import Secured from "../common/Secured";
import {connect} from "react-redux";
import * as dockActions from "./dock/actions";
import {changeLocale, showIssueDialog, showTaskDialog} from "../containers/AppWrapper/contents/actions";
import {isProcessing} from "../containers/App/actions";
import {bindActionCreators} from "redux";
import MDSpinner from "react-md-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import html2canvas from 'html2canvas';
import {getQuickbooksCredentials, getStripeCredentials} from '../containers/ClientsPage/tabs/ServiceConfigurationApi';
import {browserHistory} from "react-router";
import config from "../config/environment";
import {updateUserLocale} from "../containers/AppWrapper/contents/actions";
import {languages} from "../containers/AppWrapper/contents/constants";

const smMobile = window.screen.width <= 450;

const adminRoles = ["sales_arborist", "scheduler", "it_engineering", "admin"];
const actions = {
    showTaskDialog,
    showIssueDialog,
    isProcessing,
    getStripeCredentials,
    getQuickbooksCredentials,
    changeLocale,
    updateUserLocale,
    ...dockActions,
};

class ThNav extends Component {

    constructor(props) {
        super(props);
        this.settingsNavRef = React.createRef();
        this.profileNavRef = React.createRef();
        this.leadsRef = React.createRef();
        this.navbarRef = React.createRef();
    }

    state = {
        unread: false,
        quickbooksCredentials: {},
        isProfileClicked: false,
        isSettingsOpen: false,
        expanded: false,
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentWillMount() {
        this.updateDimensions();
    };

    componentDidMount() {
        const {roles} = this.props;
        window.addEventListener("resize", this.updateDimensions);
        if (window.location.href.indexOf("?scope=read_write&code=") > -1 && this.props.clientId) {
            const verification_code = window.location.href.split('=').pop();
            window.location.replace(`${window.location.href.split('#')[0]}#/clients/${this.props.clientId}?scope=read_write&code=${verification_code}`);
        }
        if (window.location.href.indexOf("?code") > -1 && this.props.clientId) {
            setTimeout(() => {
                const correctUrl = window.location.href.replace('#/', '');
                let url = new URL(correctUrl);
                let {quickbooksCredentials} = this.state;
                quickbooksCredentials.code = url.searchParams.get("code");
                quickbooksCredentials.state = url.searchParams.get("state");
                quickbooksCredentials.realmId = url.searchParams.get("realmId");
                this.setState(quickbooksCredentials, () => {
                    this.props.actions.getQuickbooksCredentials(this.state.quickbooksCredentials, () => {
                        window.location.href = `/clients/${this.props.clientId}?tab=12`;

                    })
                })
            }, 1000);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isMobile = this.state.windowWidth < 768;
        if(isMobile && (prevState.expanded !== this.state.expanded && prevState.expanded === false)) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        let userStatus = nextProps.userStatus;
        const newMessage =
            userStatus.last_message_date > this.props.last_message_date
            || userStatus.last_message_date > userStatus.messages_read_date;
        const newFollowUp = userStatus.last_follow_up_date && new Date(userStatus.last_follow_up_date) < new Date();
        const newAction = userStatus.last_notification_date > this.props.last_notification_date
            || userStatus.last_notification_date > userStatus.notifications_read_date;
        this.setState({newMessage, newFollowUp, newAction});
    }

    updateDimensions = () => {
        if (window.innerWidth !== this.state.windowWidth) {
            this.setState({windowWidth: window.innerWidth})
        }
    };

    excludeScreenElements = () => {
        const classNames = ['.gm-control-active', '.gm-svpc', '.gmnoprint','.navbar-collapse']

        classNames.map(className => {
            const elements = document.querySelectorAll(className)
            return [...elements].map(element => element.setAttribute('data-html2canvas-ignore', 'true'))
        })
    }

    runIssueDialog = () => {
        this.props.actions.isProcessing(true);
        this.excludeScreenElements()
        html2canvas(document.body, {
            useCORS: true, onclone: document => {
                const pagination = document.querySelector('.react-bs-table-pagination')
                if (pagination) pagination.style.bottom = '-30px'
            }
        }).then(canvas => {
            canvas.toBlob((blob) => {
                URL.createObjectURL(blob);
                const file = new File([blob], 'screenshot.png', blob)
                this.props.actions.showIssueDialog(file);
                this.props.actions.isProcessing(false);
            });
        });
    }

    renderUserNav() {
        const {windowWidth} = this.state;
        const isMobile = windowWidth < 768;
        const newCrewMessages = this.props.userStatus && this.props.userStatus.new_crew_messages;
        return (
            <Nav>
                <LinkContainer to="/leads" active={window.location.href.includes('/lead')}>
                    <NavItem eventKey={7} onClick={() => this.handleCollapse()}>
                        <FontAwesomeIcon icon="chart-line" className="bigger"/>
                        {isMobile && <span ref={this.leadsRef} className="pad-left">Leads</span>}
                    </NavItem>
                </LinkContainer>

                <LinkContainer to="/customer_search" active={window.location.href.includes('/customer')}>
                    <NavItem eventKey={8} onClick={() => this.handleCollapse()}>
                        <Glyphicon glyph="user" className="bigger"/>
                        {isMobile && <span className="pad-left">Customers</span>}
                    </NavItem>
                </LinkContainer>

                <LinkContainer to="/tasks" active={window.location.href.includes('/task')}>
                    <NavItem eventKey={9} onClick={() => this.handleCollapse()}>
                        <Glyphicon glyph="tasks" className="bigger"/>
                        {isMobile && <span className="pad-left">Tasks</span>}
                    </NavItem>
                </LinkContainer>

                <NavDropdown
                    eventKey={2}
                    title={<div><Glyphicon glyph="list-alt" className="bigger"/>{isMobile &&
                        <span className="pad-left">Proposals</span>}</div>}
                    noCaret
                >
                    <LinkContainer to="/proposals">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>Recent Proposals</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/proposal_service_status">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>Edit A Proposal Status</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/open_proposals">
                        <MenuItem eventKey={2.3} onClick={() => this.handleCollapse()}>Open Proposals</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/mapview/">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>New Proposal</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/renewals">
                        <MenuItem eventKey={2.7} onClick={() => this.handleCollapse()}>Renewals</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/completed_proposals">
                        <MenuItem eventKey={2.7} onClick={() => this.handleCollapse()}>Completed PHC Report</MenuItem>
                    </LinkContainer>

                </NavDropdown>

                <NavDropdown
                    eventKey={2}
                    title={<div><FontAwesomeIcon icon="truck" className="bigger"/>{isMobile &&
                        <span className="pad-left">Work Orders</span>}</div>}
                    noCaret
                >
                    <LinkContainer to="/recent_work_orders">
                        <MenuItem eventKey={2.8} onClick={() => this.handleCollapse()}>Recent Work Orders</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/find_work_orders">
                        <MenuItem eventKey={2.6} onClick={() => this.handleCollapse()}>Search Work Orders</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/work_orders">
                        <MenuItem eventKey={2.5} onClick={() => this.handleCollapse()}>Edit a Work Order</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/complete_work_orders">
                        <MenuItem eventKey={2.7} onClick={() => this.handleCollapse()}>Complete Work Orders</MenuItem>
                    </LinkContainer>

                </NavDropdown>

                <NavDropdown
                    eventKey={2}
                    title={<div><Glyphicon glyph="usd" className="bigger"/>{isMobile &&
                        <span className="pad-left">Invoices</span>}</div>}
                    noCaret
                >
                    <LinkContainer to="/invoices">
                        <MenuItem eventKey={2.6} onClick={() => this.handleCollapse()}>Search Invoices</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/completed_services">
                        <MenuItem eventKey={2.5} onClick={() => this.handleCollapse()}>Batch Invoice</MenuItem>
                    </LinkContainer>

                </NavDropdown>

                <NavDropdown
                    eventKey={2}
                    title={<div><Glyphicon glyph="dashboard" className="bigger"/>{isMobile &&
                        <span className="pad-left">Data</span>}</div>}
                    noCaret
                >
                    <LinkContainer to="/sales_dashboard/">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>Sales Dashboard</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/reports/">
                        <MenuItem eventKey={2.6} onClick={() => this.handleCollapse()}>Reports</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/pending_approvals/">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>Approvals</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/my_proposals">
                        <MenuItem eventKey={2.4} onClick={() => this.handleCollapse()}>My Open Proposals</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/mapview/">
                        <MenuItem eventKey={2.2} onClick={() => this.handleCollapse()}>New Proposal</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/revenue_reports/">
                        <MenuItem eventKey={2.7} onClick={() => this.handleCollapse()}>Revenue Reports</MenuItem>
                    </LinkContainer>

                    {/*<LinkContainer to="/referral_report/">*/}
                    {/*<MenuItem eventKey={2.5}>Referral Report</MenuItem>*/}
                    {/*</LinkContainer>*/}

                </NavDropdown>

                <NavDropdown
                    eventKey={3}
                    title={<div><Glyphicon glyph="calendar" className="bigger"/>{isMobile &&
                        <span className="pad-left">Schedulers</span>}</div>}
                    noCaret
                >
                    <LinkContainer to="/calendar">
                        <MenuItem eventKey={3.1} onClick={() => this.handleCollapse()}>Calendar</MenuItem>
                    </LinkContainer>

                    <LinkContainer to="/map_scheduler">
                        <MenuItem eventKey={3.2} onClick={() => this.handleCollapse()}>Map Scheduler</MenuItem>
                    </LinkContainer>
                </NavDropdown>

                <LinkContainer to="/crew_work_orders">
                    <NavItem onClick={() => this.handleCollapse()}
                             eventKey={7}
                             active={window.location.href.includes('/crew_work_order')}>
                        <Glyphicon glyph="tree-deciduous" className="bigger"/>
                        {isMobile && <span className="pad-left">Crew Work Orders</span>}
                    </NavItem>
                </LinkContainer>
                <LinkContainer to="/time_tracks">
                    <NavItem onClick={() => this.handleCollapse()}
                             eventKey={8}
                             active={window.location.href.includes('/time_track')}>
                        <Glyphicon glyph="time" className="bigger"/>
                        {isMobile && <span className="pad-left">Time Tracks</span>}
                    </NavItem>
                </LinkContainer>
                {/*<LinkContainer to="/reports">
                    <NavItem eventKey={0} active={location.href.includes('/reports')}>
                        <Glyphicon glyph="folder-open" className="bigger"/>
                    </NavItem>
                </LinkContainer>*/}
            </Nav>
        )
    }

    renderAdminNav() {
        return (
            <Nav>
                <LinkContainer to="/admin">
                    <NavItem onClick={() => this.handleCollapse()} eventKey={2}>Admin</NavItem>
                </LinkContainer>
            </Nav>
        )
    }

    handleChangeLocale(newLocale){
        this.props.actions.updateUserLocale(newLocale, () => this.props.actions.changeLocale(newLocale))
    }

    onSettingsNavClick(){
        const isMobile = this.state.windowWidth < 768;
        this.setState({isSettingsOpen: !this.state.isSettingsOpen}, () => {
            if(isMobile) {
                setTimeout(() => {
                    this.settingsNavRef.current.scrollIntoView();
                }, 0)
            }
        });
    }

    handleCollapse(){
        const isMobile = this.state.windowWidth < 768;
        if(isMobile) {
            this.setState({expanded: false});
        }
    }

    render() {
        const {logout, isProcessing, email, name, client, clientId, userStatus, roles, translations_enabled, updateVersion} = this.props;
        const {newMessage, newFollowUp, newIssue, newAction, windowWidth, isProfileClicked} = this.state;
        const isSmMobile = windowWidth < 450;
        const isMobile = windowWidth < 768;
        const isCrewRole = roles && roles.map(ur => adminRoles.includes(ur)).every(r => r === false)
        const newMessages = userStatus && userStatus.new_messages;
        const newFollowUps = userStatus && userStatus.new_follow_ups;
        const newActivities = userStatus && userStatus.new_activities;
        const newIssues = userStatus && userStatus.new_issues;
        const newCrewMessages = userStatus && userStatus.new_crew_messages;
        const externalUrls = (window.location.href.indexOf("contact_form") > -1 || window.location.href.indexOf("oauth_page") > -1)
        const showLanguageSelect = translations_enabled && window.location.href.includes('/crew_work_orders')
        return this.props.isAuthenticated && externalUrls ? null : this.props.isAuthenticated
            ? <Navbar className={`mobile-nav-fixed ${updateVersion && isSmMobile ? 'min-height-0' : ''}`} style={{zIndex: "100"}} ref={this.navbarRef} expanded={isMobile ? this.state.expanded : "expanded"} fluid collapseOnSelect onToggle={() => {
                if(isMobile && this.state.expanded === "expanded") {
                    setTimeout(() => {
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    }, 1000)
                }
                this.setState({
                    isProfileClicked: false,
                    isSettingsOpen: false
                })
            }}>
                <Navbar.Header>
                    <Navbar.Brand>
                        {client ?
                            <a href={isCrewRole ? `/crew_work_orders` : `/`}>{client.split(' ').map(i => i.charAt(0))}</a> : null}
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => this.setState({expanded: this.state.expanded ? false : "expanded"})}/>
                </Navbar.Header>
                <Navbar.Collapse className="mobile-nav-menu mobile-nav-fixed">
                    <Secured roles="it_engineering">
                        {this.renderAdminNav()}
                    </Secured>
                    {!isCrewRole && (!isMobile || (isMobile && !(this.state.isSettingsOpen || this.state.isProfileClicked))) && <Secured roles="!it_engineering">
                        {this.renderUserNav()}
                    </Secured>
                    }

                    {isCrewRole && <>
                        <Nav>
                            <LinkContainer to="/crew_work_orders">
                                <NavItem eventKey={7} active={window.location.href.includes('/crew_work_order')} onClick={() => this.handleCollapse()}>
                                    <Glyphicon glyph="tree-deciduous" className="bigger"/>
                                    {isMobile && <span className="pad-left">Crew Work Orders</span>}
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to="/tasks" active={window.location.href.includes('/task')}>
                                <NavItem eventKey={9} onClick={() => this.handleCollapse()}>
                                    <Glyphicon glyph="tasks" className="bigger"/>
                                    {isMobile && <span className="pad-left">Tasks</span>}
                                </NavItem>
                            </LinkContainer>
                        </Nav>
                    </>}

                    <Nav pullRight collapseOnSelect style={{marginBottom: smMobile && '-4px', paddingBottom: smMobile && '50px'}}>
                        {showLanguageSelect && <NavDropdown
                            title={<div><Glyphicon glyph="globe" className="bigger"/>{isMobile &&
                                <span className="pad-left">Languages</span>}</div>}
                            noCaret
                        >
                            {languages.map(({label, value}) => <MenuItem
                                onClick={() => this.handleChangeLocale(value)}
                                active={value === this.props.userStatus.locale}>{label}</MenuItem>)}

                        </NavDropdown>}
                        {!isCrewRole && (!isMobile || (isMobile && !(this.state.isSettingsOpen || this.state.isProfileClicked))) && <Nav className="pad-left" style={{width: isMobile ? "100%" : "unset"}}>
                            {!isMobile && <NavItem className="loader">
                                {isProcessing ? <MDSpinner size={20}/> : null}
                            </NavItem>}
                            <NavItem onClick={() => this.props.actions.showTaskDialog()} className="menu-button">
                                <FontAwesomeIcon icon="tasks" className="bigger"/>
                                {isMobile && <span className="pad-left">Add Task</span>}
                            </NavItem>
                            <NavItem onClick={() => this.handleCollapse()} onClick={() => this.props.actions.showDock('activities')}
                                     className="menu-button notification_btn">
                                <Glyphicon glyph="fire" className={`bigger  ${newAction ? 'notification' : ''}`}/>
                                <Badge variant="danger" className={`message-notification ${newActivities === 0 || !newActivities ? "hidden-notification" : ""}`}
                                           style={{right: newActivities && newActivities > 99 && 5}}><span
                                        className="font10">{newActivities > 25 ? "25+" : newActivities}</span>
                                </Badge>
                                {isMobile && <span>Activities</span>}
                            </NavItem>
                            <NavItem onClick={() => this.handleCollapse()} onClick={() => this.props.actions.showDock('messages')}
                                     className="menu-button d-flex notification_btn">
                                <Glyphicon glyph="envelope"
                                           className={`bigger ${(newMessages > 0 || newMessage) && 'notification'}`}
                                />
                                <Badge variant="danger" className={`message-notification ${newMessages === 0 || !newMessages ? "hidden-notification" : ""}`}
                                           style={{right: newMessages && newMessages > 99 && 5}}><span
                                        className="font10">{newMessages > 25 ? "25+" : newMessages}</span>
                                </Badge>
                                {isMobile && <span>Customer Messages</span>}
                            </NavItem>
                            <NavItem onClick={() => this.handleCollapse()} onClick={() => this.props.actions.showDock('follow ups')}
                                     className="menu-button d-flex notification_btn">
                                <Glyphicon glyph="time" className={`bigger ${newFollowUp ? 'notification' : ''}`}/>
                                <Badge variant="danger" className={`message-notification ${newFollowUps === 0 || !newFollowUps ? "hidden-notification" : ""}`}><span
                                        className="font10">{newFollowUps > 25 ? "25+" : newFollowUps}</span>
                                </Badge>
                                {isMobile && <span>Follow Ups</span>}
                            </NavItem>
                            <NavItem onClick={() => this.handleCollapse()} onClick={() => this.props.actions.showDock('crew messages')}
                                     className="menu-button notification_btn">
                                <Glyphicon glyph="comment"
                                           className={`bigger ${(newCrewMessages > 0) && 'notification'}`}/>
                                <Badge variant="danger" className={`message-notification ${newCrewMessages === 0 || !newCrewMessages ? "hidden-notification" : ""}`}
                                           style={{right: newCrewMessages && newCrewMessages > 99 && 5}}><span
                                        className="font10">{newCrewMessages > 25 ? "25+" : newCrewMessages}</span>
                                </Badge>
                                {isMobile && <span>Crew Messages</span>}
                            </NavItem>
                            <NavItem onClick={() => this.handleCollapse()} onClick={() => this.runIssueDialog()} className="menu-button">
                                <Glyphicon glyph="question-sign" className='bigger width-20'/>
                                {isMobile && <span className="pad-left">New Issue</span>}
                            </NavItem>
                        </Nav>}
                            {!roles.includes('it_engineering') && (!isMobile || (isMobile && !(this.state.isSettingsOpen || this.state.isProfileClicked))) && <LinkContainer to="/organizational_chart" active={window.location.href.includes('/organizational_chart')}>
                                <NavItem onClick={() => this.handleCollapse()} className="menu-button">
                                    <Glyphicon glyph="blackboard" className='bigger width-20'/>
                                    {isMobile && <span className="pad-left">Organizational Chart</span>}
                                </NavItem>
                            </LinkContainer>}
                        {!isCrewRole && (!isMobile || (isMobile && !isProfileClicked)) && <NavDropdown
                            eventKey={5}
                            onClick={() => this.onSettingsNavClick()}
                            noCaret
                            className={isMobile ? "mobile-nav-width" : ""}
                            title={<div ref={this.settingsNavRef}>
                                <Glyphicon glyph="cog" className={`bigger width-20`}/>
                                {isMobile && <span className="pad-left">Settings</span>}
                            </div>}
                        >

                                <LinkContainer to="/application_sites">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.5}>Application Sites</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/application_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.3}>Application Types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/asset_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.4}>Asset Types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/conditions">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.17}>Conditions</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/consumables">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.7}>Consumables</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/contact_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.6}>Contact Types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/customer_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.11}>Customer Types</MenuItem>
                                </LinkContainer>

                                <Secured roles="admin">
                                    <LinkContainer to="/employees">
                                        <MenuItem onClick={() => this.handleCollapse()} eventKey={5.2}>Employees</MenuItem>
                                    </LinkContainer>
                                </Secured>

                                <LinkContainer to="/equipment">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.2}>Equipment</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/holidays">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.4}>Holidays</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/factors">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Factors</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/factor_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Factor Types</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/glossaries">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Glossary</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/ordinance_and_laws">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Ordinances</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/payment_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Payment Types</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/plants">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Plants</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/reason">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.16}>Reason</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/referrals">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.9}>Referrals</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/service_priorities">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.8}>Service Priorities</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/services">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.13}>Services</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/service_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.10}>Service Types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/trucks">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.12}>Fleet</MenuItem>
                                </LinkContainer>
                                <LinkContainer to="/activities">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.12}>Activity</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/hazards">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.13}>Hazards</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/scheduler_event_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.1}>Scheduler event types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/time_track_types">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.14}>Time track types</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/terms">
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.15}>Terms</MenuItem>
                                </LinkContainer>

                                <LinkContainer to="/issues" className='full-width'>
                                    <MenuItem onClick={() => this.handleCollapse()} eventKey={5.16} className='d-flex notification_btn'>
                                        <div className='d-flex'>
                                            <span className='mr-11'>Issues</span>
                                            {newIssues > 0 &&
                                                <Badge variant="danger" className="issue-notification issue_badge"
                                                       style={{position: 'relative'}}><span
                                                    className="font10">{newIssues > 25 ? "25+" : newIssues}</span></Badge>}
                                        </div>
                                    </MenuItem>
                                </LinkContainer>

                            </NavDropdown>
                        }

                        {isCrewRole &&
                            <NavItem onClick={() => {
                                this.props.actions.showDock('crew messages');
                                this.handleCollapse();
                            }}
                                     className="menu-button notification_btn">
                                <Glyphicon glyph="comment"
                                           className={`bigger ${(newCrewMessages > 0) && 'notification'}`}/>
                                {newCrewMessages > 0 &&
                                    <Badge variant="danger" className="message-notification"
                                           style={{right: newCrewMessages && newCrewMessages > 99 && 5}}><span
                                        className="font10">{newCrewMessages > 25 ? "25+" : newCrewMessages}</span></Badge>}
                                {isMobile && <span className="pad-left">Crew Messages</span>}
                            </NavItem>}

                        {(!isMobile || (isMobile && !this.state.isSettingsOpen)) && <NavDropdown
                            eventKey={7}
                            title={<div ref={this.profileNavRef}><Glyphicon glyph="option-vertical" className="bigger width-20"/>{isMobile &&
                                <span className="pad-left">Profile</span>}</div>}
                            style={{width: smMobile && '100%', paddingBottom: smMobile && 15}}
                            onClick={() => {
                                if(isMobile) {
                                    this.setState({isProfileClicked: !isProfileClicked})
                                }
                            }}
                            noCaret
                        >
                            <Secured roles="admin">
                                <LinkContainer to={`/clients/${clientId}`}>
                                    <MenuItem eventKey={2.1} onClick={() => this.handleCollapse()}>Admin</MenuItem>
                                </LinkContainer>
                            </Secured>
                            <div className='version'>
                                Version: {config.appVersion}
                            </div>
                            <hr/>
                            <NavItem
                                onClick={() => {
                                    logout();
                                    this.handleCollapse();
                                }}
                                title={`${name} @ ${client} ~ ${moment.tz.guess()}`}
                                eventKey={1}
                            >
                                Logout {email}
                            </NavItem>
                        </NavDropdown>}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            : null
    }
}


function mapStateToProps(state) {
    return {
        userStatus: state.userStatus.userStatus,
        isProcessing: state.app.isProcessing,
        clientId: state.auth.client_id,
        translations_enabled: state.client.customerInfo?.translations_enabled,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ThNav)
