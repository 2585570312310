import axios from 'axios'
import {browserHistory} from 'react-router'
import {apiCall} from "../../../common/apiActionHandler";
import {addAlert} from "../../App/actions";
import {IMPERSONATE_SUCCESS} from "../constants";
import {mapForSelect} from "../../../common/commonHandlers";

const adminLocation = window.location.href.indexOf("/admin") > -1;

export const fetchClientsList = (callback) => async (dispatch, getState) => {
    const config = {
        method: 'get',
        url: `/api/admin/clients_list`
    }

    apiCall("Getting client list", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const deleteClient = (id, callback) => (dispatch, getState) => {
    const config = {
        method: 'delete',
        url: `/api/clients/${id}`
    }

    apiCall("Deleting client", config, async config => {
        await axios.request(config);
        callback();
    }, dispatch, getState);
};

export const deleteUser = (userId, clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/admin/user_destroy?id=${userId}`
    };

    apiCall("Deleting user", config, async config => {
        await axios.request(config);
        dispatch(fetchUsers(clientId));  // Where should it be placed?
        callback && callback()
    }, dispatch, getState);
};

export const saveOrCreateClient = (client, callback) => async (dispatch, getState) => {
    const config = {
        method: client.id ? 'PUT' : 'POST',
        url: client.id ? `/api/admin/client_update?id=${client.id}` : '/api/admin/client_create',
        data: {client}
    };

    apiCall(`${client.id ? "Updating client" : "Creating client"}`, config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const impersonate = (id, coords, callback) => (dispatch, getState) => {
    const store = getState();
    const config = {
        method: 'post',
        url: `/api/admin/impersonate`,
        headers: {Authorization: store.auth.token},
        data: {
            id: id,
            coords: {lat: coords && coords.latitude, lng: coords && coords.longitude}
        },
    };

    axios.request(config).then(response => {
        dispatch(
            addAlert({message: `You are impersonating ${response.data.email}`})
        );
        dispatch({
            type: IMPERSONATE_SUCCESS,
            isAuthenticated: true,
            client: response.data.client,
            client_id: response.data.client_id,
            email: response.data.email,
            token: response.data.access_token,
            roles: response.data.roles,
            name: response.data.name,
            employee_id: response.data.employee_id,
            user_id: response.data.user_id,
            download_notifications: response.data.download_notifications,
            password: '',
        });
        let next_config = {
            url: `/api/users/${response.data.user_id}/refresh_activity`,
            method: 'PUT',
            headers: {
                Authorization: response.data.access_token
            }
        };
        axios.request(next_config);
        callback && callback()
        browserHistory.push('/login');
    })
};

export const refreshActivity = (user_id, token) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/users/${user_id}/refresh_activity`,
        headers: {Authorization: token}
    };

    apiCall("Refresh activity", config, async config => {
        await axios.request(config);
        browserHistory.push('/');
    }, dispatch, getState);
};

export const refreshToken = (id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/admin/refresh_token`,
        data: {id: id}
    };

    apiCall("Refreshing token", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const editClient = (clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/clients/${clientId}`,
    };

    apiCall("Editing client", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const fetchUsers = (clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/users/?clientId=${clientId}`
    };

    apiCall("Fetch client users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, adminLocation && false);
};

export const fetchEmployees = (clientId, callback, q = "") => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/employees/`,
        params: {
            clientId: clientId,
            q: q
        }
    };

    apiCall("Fetch employees", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, adminLocation && false);
};

export const fetchRoles = (callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/roles/`
    };

    apiCall("Fetch roles", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, adminLocation && false);
};

export const saveUserInfo = (user, clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: user.id ? "PUT" : "POST",
        url: user.id ? `/api/users/${user.id}` : `/api/users/`,
        data: {user, clientId}
    };

    apiCall("Save user info", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const createNewClient = (client, callback) => async (dispatch, getState) => {
    const config = {
        method: "POST",
        url: '/api/admin/client_create',
        data: {client}
    };

    apiCall("Create new client", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const fetchCustomerInfo = (clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/admin/client?id=${+clientId}`
    };

    apiCall("Fetch customer info", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, adminLocation && false);
};

export const fetchClientInfo = (clientId, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/clients/${clientId}`
    };

    apiCall("Fetch client info", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState);
};

export const fetchUserActivities = (params, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/admin/activities`,
        params: params
    };

    apiCall("Fetch user activities", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const fetchUserIssues = (tab, resource, callback) => async (dispatch, getState) => {
    const config = {
        method: 'GET',
        url: `/api/issues`,
        params: {tab, page: resource?.page, per_page: resource?.per_page}
    };

    apiCall(`Fetch ${tab} issues`, config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data)
    }, dispatch, getState, false);
};

export const deleteIssue = (id, callback) => async (dispatch, getState) => {
    const config = {
        method: "DELETE",
        url: `/api/issues/${id}`,
    };

    apiCall("Deleting issue", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const updatePayments = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/admin/update_transaction_fee`,
        method: 'PUT',
        params: params
    };
    apiCall("Saving payments settings", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const fetchStripeTransactions = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_transactions`,
        method: 'GET',
    };

    apiCall("Fetch stripe transactions", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const fetchClientPayments = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/get_payments`,
        method: 'GET',
    };

    apiCall("Fetch transactions", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const changeUserCapacity = (userCapacity, clientId, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/change_user_capacity`,
        method: 'PUT',
        data: {
            client_id: clientId,
            user_capacity: userCapacity
        }
    };

    apiCall("Changing user capacity", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const fetchAllUsers = (active, params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/all_users`,
        params:{
            status: active,
            ...params
        }
    };
    apiCall("Fetching users", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState,false);
}

export const loadDevices = (id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/access_tokens/${id}`,
    };

    apiCall("Getting devices", config, async config => {
        const response = await axios.request(config);
        callback && callback(response?.data);
    }, dispatch, getState, false);
};

export const deleteDevice = (params, callback) => (dispatch, getState) => {
    const config = {
        method: "delete",
        url: `/api/access_tokens/${params.user_id}`,
        params
    };

    apiCall("Deleting device", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const disableAllUsers = (params, callback) => (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/users/disable_all_users`,
        params: params
    };

    apiCall("Disabling all users", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true);
};

export const getClientsSearch = (searchText, callback, usersMap = false) => async (dispatch, getState) => {
    const config = {
        url: `/api/clients/search_client`,
        params: {q: searchText, usersMap}
    };

    apiCall("Getting clients", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const loadNotSelectedEmployee = (q, client_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/search/not_assigned_employees`,
        params: {q, client_id}
    };

    apiCall("Get employees", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const translateClientData = (client_id, callback) => async (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/admin/translate_client_data`,
        params: {client_id}
    };

    apiCall("Translating client data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const duplicateClient = (params, callback, errorCallback) => async (dispatch, getState) => {
    const config = {
        url: `/api/admin/duplicate_client`,
        params: params
    };

    apiCall("Duplicate Client", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, true, errorCallback);
};