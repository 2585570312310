import axios from "axios";
import {addAlert} from "../App/actions";
import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../common/apiActionHandler";
import * as FileSaver from "file-saver";
import {dataUpload} from "../../common/upload";

export const updateWorkOrderPrintOptions = (woId, value, callback) => async (dispatch, getState) => {
    const store = getState();
    const token = store.auth.token;

    const config = {
        method: 'PUT',
        url: `/api/work_order_print/${woId}/print_options`,
        headers: {Authorization: token},
        params: {
            value
        },
    };

    try {
        const response = await axios.request(config);
        callback  && callback(response)
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update work order print option" + exception.toString(),
            })
        );
        throw exception
    }
};

export const getWorkOrder = (workOrderNumber, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_order_print/${workOrderNumber}`
    };

    apiCall("Getting work order", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const downloadPdf = (work_order, callback, errorCallback) => async (dispatch, getState) => {
    let config = {
        url: `/api/restricted/${work_order.token}/wo_pdf`,
        method: 'GET',
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        const fileName = `${work_order.client_name}-WorkOrder #${work_order.number}.pdf`
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {params: JSON.stringify(work_order ? work_order : {}), source: "work order pdf"}, false)(dispatch, getState)
        FileSaver.saveAs(blob, fileName);
        callback && callback()
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download pdf" + exception.toString(),
            })
        );
        errorCallback && errorCallback()
        throw exception
    }
};

export const fetchWorkOrder = (workOrderToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${workOrderToken}/load_work_order`,
    };

    apiCall("Fetching work order", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveLogoSettings = (client_id, visibility, height, position) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/change_logo_settings`,
        method: 'PUT',
        data: {
            client_id: client_id,
            logo_visible: visibility,
            logo_height: height,
            logo_position: position,
        }
    };

    apiCall("Saving logo settings", config, async config => {
        await axios.request(config);
    }, dispatch, getState);
};