import React, {useEffect, useState} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Col, Grid} from 'react-bootstrap'
import PageTitle from '../../components/PageTitle'
import LoginWidget from './LoginWidget'
import Helmet from 'react-helmet'
import {addAlert} from '../App/actions'
import * as MyActions from './actions'
import {geolocated} from "react-geolocated";
import {checkTerms} from "./checkLoginParams/checkLoginParams";
import {ROLES} from "../../constants";
import {TermsModal} from "../Terms/TermsModal";
import {browserHistory} from "react-router";
import {LocationModal} from "../Terms/LocationModal/LocationModal";
import Logo from '../HomePage/HomePageLogo.png'
import ReactGA from "react-ga4";

const Actions = Object.assign({}, MyActions, {addAlert: addAlert})

const Login = ({
                   isAuthenticated,
                   client,
                   user_id,
                   roles,
                   email,
                   password,
                   actions,
                   isGeolocationAvailable,
                   isGeolocationEnabled,
                   coords
               }) => {
    const [resources, setResources] = useState(null)
    const [termsAcceptance, setTermsAcceptance] = useState({checked: false})
    const [browserGeolocationStatus, setBrowserGeolocationStatus] = useState(true)
    const [showGeolocationModal, setShowGeolocationModal] = useState(false)
    const [allowRegistration, setAllowRegistration] = useState(false)

    const checkBrowserGeolocationAccess = () => {
        if ('geolocation' in navigator)
            navigator.geolocation.getCurrentPosition(
                () => {
                    return setBrowserGeolocationStatus(true)
                },
                () => {
                    return setBrowserGeolocationStatus(false)
                }
            );
    }
    useEffect(() => {
        actions.getAppSettings((settings) => {
            setAllowRegistration(settings.allowRegistration)
        })
    },[])

    useEffect(() => {
        if (isAuthenticated) {
          console.log("Login component says You're already authenticated. Deciding where to send you")
            actions.loadAllResources(user_id, (result) => {
                setResources(result)
                setTermsAcceptance(checkTerms(result, roles))
            })
            checkBrowserGeolocationAccess()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (isAuthenticated && termsAcceptance.checked && termsAcceptance.admin && termsAcceptance.user) {
            ReactGA.set({userId: `${user_id}`});
            if (roles.includes(ROLES.superAdmin) || resources.optional_geolocation || browserGeolocationStatus) {
                setShowGeolocationModal(false)
                if (resources.subscription_reminder && resources.subscription_active
                    && roles.includes(ROLES.admin)) {
                    browserHistory.push('/subscription_reminder')
                } else if (roles.includes(ROLES.superAdmin)) {
                    browserHistory.push('/admin')
                } else if (resources.subscription_active) {
                    if (roles.includes(ROLES.admin)) browserHistory.push('/')
                    else if (roles.includes(ROLES.crew)) browserHistory.push('/crew_work_orders')
                    else if (roles.includes(ROLES.scheduler)) browserHistory.push('/client_dashboard')
                    else if (roles.includes(ROLES.sales_arborist)) browserHistory.push('/sales_dashboard')
                    else browserHistory.push('/')
                } else if (roles.includes(ROLES.admin)) {
                    browserHistory.push('/subscription_renew')
                } else {
                    browserHistory.push('/subscription_expired')
                }
            } else {
                setShowGeolocationModal(true)
            }
        }
    }, [isAuthenticated, termsAcceptance, resources, browserGeolocationStatus])

    const acceptTerms = (id, role) => actions.saveTermsAcceptance(id, role, () => {
        if (role === ROLES.admin) {
            setResources({...resources, admin_term: {...resources.admin_term, term_accepted: true}})
            setTermsAcceptance({...termsAcceptance, admin: true})
        } else {
            setResources({...resources, user_term: {...resources.user_term, term_accepted: true}})
            setTermsAcceptance({...termsAcceptance, user: true})
        }
    })

    return <Grid fluid>
        <Helmet title="Login"/>
        <PageTitle pageLogo={Logo} pageName="TreeHub" pageDetail="login"/>
        <LocationModal show={showGeolocationModal} logoutButton={actions.logout}
                       refreshButton={checkBrowserGeolocationAccess}/>
        {(termsAcceptance.checked && !termsAcceptance.admin) && <TermsModal
            title="Welcome To TreeHub"
            show={true}
            onHide={() => acceptTerms(resources.admin_term.term.id, ROLES.admin)}
            closeButton={actions.logout}>
            <div dangerouslySetInnerHTML={{__html: resources?.admin_term?.term?.content}}/>
        </TermsModal>}

        {termsAcceptance.checked && !termsAcceptance.user && <TermsModal
            title="Welcome To TreeHub "
            show={true}
            onHide={() => acceptTerms(resources.user_term.term.id, ROLES.user)}
            closeButton={actions.logout}>
            <div dangerouslySetInnerHTML={{__html: resources?.user_term?.term?.content}}/>
        </TermsModal>}
        <Col xs={6} xsOffset={3}>
            <LoginWidget
                isAuthenticated={isAuthenticated}
                client={client}
                email={email}
                password={password}
                updateClient={actions.updateClient}
                updateEmail={actions.updateEmail}
                allowRegistration={allowRegistration}
                updatePassword={actions.updatePassword}
                authenticate={() => actions.authenticate((isGeolocationAvailable && isGeolocationEnabled && coords) && coords, new URL(window.location.href).searchParams.get("url"))}
                logout={actions.logout}
            />
        </Col>
    </Grid>
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        client: state.auth.client,
        email: state.auth.email,
        roles: state.auth.roles,
        password: state.auth.password,
        user_id: state.auth.user_id
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(Login));
