export const getValidFontSize = (size) => {
    if(!size || size < 13){
        return 13;
    }
    else if(size > 28){
        return 28;
    }
    else{
        return size;
    }
}

export const validateFontSize = (sectionName, size, errors) => {
    const updatedErrors = {...errors};
    if(size < 13){
        updatedErrors[sectionName] = "Minimum font size is 13";
    }
    else if(size > 28){
        updatedErrors[sectionName] = "Maximum font size is 28"
    }
    else{
        updatedErrors[sectionName] && delete updatedErrors[sectionName]
    }
    return updatedErrors;
}

export const windowPrint = () => {
    try {
        if(!document.execCommand('print', false, null)) {
            window.print()
        }
    } catch {
        window.print()
    }
}

export const filterProposalServices = (services, options) => {
    let proposal_services = []
    const statusOptions = [
        {option: options.servicesSpecificallyNotOffered, status: "Not Offered"},
        {option: options.servicesDraft, status: "Draft"},
        {option: options.servicesScheduled, status: "Scheduled"},
        {option: options.servicesInWorkOrder, status: "In Work Order"},
        {option: options.servicesDeclined, status: "Declined"},
        {option: options.servicesAccepted, status: "Accepted"},
        {option: options.servicesProposed, status: "Proposed"},
        {option: options.servicesCompleted, status: "Completed"},
        {option: options.servicesInvoiced, status: "Invoiced"},
        {option: options.servicesPayment, status: "Payment"},
        {option: options.servicesPaid, status: "Paid"},
    ];

    statusOptions.forEach(({option, status}) => {
        if (option) {
            proposal_services = proposal_services.concat(services.filter(ps => ps.status === status));
        }
    })

    if (!statusOptions.some(({ option }) => option)) {
        proposal_services = services;
    }
    return proposal_services
}