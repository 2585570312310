import React from "react";
import {defaultDateTimeFormat} from "../../../common/commonHandlers";
import moment from "moment/moment";
import {Badge, MenuItem, Nav, NavDropdown, OverlayTrigger, Popover} from "react-bootstrap";
import {Link} from "react-router";
import Dollar from "../../../components/Dollars";
import Dollars from "../../../components/Dollars";
import {LinkContainer} from "react-router-bootstrap";

export const textFormatter = (cell, row) => {
    return cell ? <span className='word-wrap pre-line'>{cell}</span> : <span className='word-break text-gray'>N/A</span>
}

export const dateFormatter = (cell, row) => {
    return <span className='word-wrap pre-line'>{cell ? moment(cell).format(defaultDateTimeFormat) : 'N/A'}</span>
}

export const preLineFormatter = (cell, row) => {
    return cell ? <span className="pre-line">{cell}</span> : 'N/A'
}

export const booleanFormatter = (cell, row) => {
    return <span className={`text-uppercase bold ${cell ? "text-success" : "text-danger"}`}>{`${cell}`}</span>
}

export const dollarFormatter = (cell, row) => {
    return <Dollars amount={cell}/>
}

export const proposalFormatter = (cell, row, onEmail) => {
    return <Nav>
        <NavDropdown
            title={cell}
            id={`proposal-menu-${row.id}`}
            className="menu-show-fixer no-caret-in-dropdown"
        >
            <LinkContainer target="_blank" to={`/mapview/${row.proposal_id}`}>
                <MenuItem>Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/work_orders/${row.proposal_id}`}>
                <MenuItem>Work Orders</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/proposal_service_status/${row.proposal_id}`}>
                <MenuItem>Service Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_proposal/${row.proposal_id}`}>
                <MenuItem>Print Proposal</MenuItem>
            </LinkContainer>
            <li className="pointer">
                <Link onClick={() => onEmail(row.proposal_id)}>Email</Link>
            </li>
        </NavDropdown>
    </Nav>
}

export const invoicesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        <span className="flex-column-start">
            {data.map(invoice => {
                return <Link target="_blank" to={`/proposals/${invoice.proposal_id}/invoices`} onlyActiveOnIndex>#{invoice.invoice_no}</Link>
            })}
        </span>
        : 'N/A'
}

export const workOrdersFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        <span className="flex-column-start">
            {data.map(wo => {
                return <Link target="_blank" to={`/work_orders/${wo.proposal_id}`} onlyActiveOnIndex>#{wo.work_order_no}</Link>
            })}
        </span>
        : 'N/A'
}

export const invoicedAtFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((invoice, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${invoice.service_name}`}</span>
                    <span className="text-left">{moment(invoice.invoiced_at).format(defaultDateTimeFormat)}</span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const siteFormatter = (cell, row) => {

    return cell ? <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">{cell}</Popover>}
    >
        <Badge className="proposalBadge">1</Badge>
    </OverlayTrigger> : "N/A"
}

export const paidFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((p, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">
                        <span className="bold">{`${i +1}. #${p.invoice_no}`}</span>
                        <span>{` (${p.status?.toUpperCase()})`}</span>
                    </span>
                    <span className="text-left">
                        <span>Amount:</span>
                        <span className="pl-5 bold"><Dollar amount={p.amount}/></span>
                    </span>
                    <span className="text-left">
                        <span>Date:</span>
                        <span className="pl-5 bold">{moment(p.date).format(defaultDateTimeFormat)}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const scheduledFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((wo, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left">
                        <span className="bold">{i +1}.</span>
                        <span className="pl-5">WO number:</span>
                        <span className="pl-5 bold">#${wo.work_order_number}</span>
                    </span>
                    <span className="text-left">
                        <span>Date:</span>
                        <span className="pl-5 bold">{moment(wo.date_from).format(defaultDateTimeFormat)}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const servicesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i +1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Created At:</span>
                        <span className="pl-5 bold">{moment(s.proposal_service_created_at).format(defaultDateTimeFormat)}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const workOrderNotesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((wo, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${wo.service_name}${wo.work_order_number ? ` #${wo.work_order_number}` : ""}`}</span>
                    <span className="text-left">{wo.work_order_note}</span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const serviceTypesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Service Type:</span>
                        <span className="bold pl-5">{s.service_type_name}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const serviceTotalsFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Service Total:</span>
                        <span className="bold pl-5"><Dollar amount={s.service_total}/></span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const manHoursFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Hours:</span>
                        <span className="bold pl-5">{s.man_hour}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const statusFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Status:</span>
                        <span className="bold pl-5">{s.status_name}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const acceptedDatesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {data.length > 0 ? data.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Accepted Date:</span>
                        <span className="bold pl-5">{moment(s.accepted_date).format(defaultDateTimeFormat)}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{data.length}</Badge>
    </OverlayTrigger>
}

export const prioritiesFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    const mergedData = Object.values(data.reduce((acc, curr) => {
        const key = `${curr.id}-${curr.service_name}`;

        if (!acc[key]) {
            acc[key] = { ...curr, priorities: [] };
        }

        acc[key].priorities.push(curr.priorities);

        return acc;
    }, {})).map(item => ({
        ...item,
        priorities: item.priorities.join(', ')
    }));

    return <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={<Popover id="sales_dashboard_popover">
            {mergedData.length > 0 ? mergedData.map((s, i) => {
                return <div className="flex-column-start pb-10" key={i}>
                    <span className="text-left bold">{`${i + 1}. ${s.service_name}`}</span>
                    <span className="text-left">
                        <span>Priorities:</span>
                        <span className="bold pl-5">{s.priorities}</span>
                    </span>
                </div>
            }) : 'N/A'}
        </Popover>}
    >
        <Badge className="proposalBadge">{mergedData.length}</Badge>
    </OverlayTrigger>
}
