import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from './ReferralReportApi'
import {Button, Col, FormGroup, Glyphicon, Grid, Row, Well} from "react-bootstrap";
import {dollarsFormatter, options} from "../../components/BootStrapTableCellFormatters";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import Datetime from "react-datetime";
import {saveNewCsvDownload, defaultDateFormat} from "../../common/commonHandlers";

class ReferralReport extends Component {

    state = {from: null, to: null, data: [], accepted: null, customers: null};

    constructor(props, context) {
        super(props, context);
        this.loadData();
    }

    tableRef = React.createRef()

    loadData = () => {
        const {from, to} = this.state;
        this.props.actions.loadData({from, to}, result => this.setState({...result}))
    };

    queryParamsBuilder = () => {
        const {from, to} = this.state;
        let queryString = '';
        if (from || to) {
            queryString = '?'
        }
        else {
            queryString = ''
        }
        if (from) {
            queryString += `&from=${from}`
        }
        if (to) {
            queryString += `&to=${to}`
        }
        return queryString
    }

    referralFormatter = (cell, row) => {
        return <a target="_blank" className="bold"
                  href={`/referral_report/${row.referral_id}` + this.queryParamsBuilder()}>{row.referral_name}</a>
    };

    _csvDollarFormatter = cell => {
        return parseFloat(cell).toFixed(2)
    }

    _percentFormatter = cell => {
        return parseFloat(cell).toFixed(2)
    }

    render() {
        const {
            data, from, to, accepted_count, accepted_percentage, proposed,
            declined_count, declined_percentage, open_count, open_percentage, customers
        } = this.state;
        return (
            <Grid fluid>
                <h1>Referral Report</h1>
                <Row>
                    <Col md={5}>
                        <FormGroup bsSize="sm">
                            <Datetime
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={null}
                                value={from}
                                className="text-center"
                                inputProps={{placeholder: 'Created From'}}
                                onChange={e => {
                                    this.setState({from: e});
                                }}/>
                            {" "}
                            <span className='text-default'><Glyphicon glyph='minus'/></span>
                            {" "}
                            <Datetime
                                dateFormat={defaultDateFormat}
                                isClearable
                                timeFormat={null}
                                value={to}
                                className="text-center"
                                inputProps={{placeholder: 'Created To'}}
                                onChange={e => {
                                    this.setState({to: e});
                                }}/>
                            <Button bsSize="small" onClick={this.loadData} bsStyle="success">
                                Find Referrals
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
                <BootstrapTable
                    ref={this.tableRef}
                    data={data}
                    striped
                    bordered={false}
                    hover
                    search
                    exportCSV={true}
                    csvFileName={() => {
                        const fileName = `ReferralsReport.csv`
                        if (this.tableRef?.current) {
                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, {from, to}, "referral report")
                        }
                        return fileName
                    }}
                    pagination
                    options={options}
                    className="wrapped padding-bottom-150"
                >
                    <TableHeaderColumn dataField="referral_name" width="10%" dataSort
                                       dataFormat={this.referralFormatter}
                                       isKey={true}>Referral</TableHeaderColumn>
                    <TableHeaderColumn dataField="amount" width="10%" dataSort># Customer</TableHeaderColumn>
                    <TableHeaderColumn dataField="proposals_count" width="10%" dataSort># Proposals</TableHeaderColumn>
                    <TableHeaderColumn dataField="proposals_price" width="10%" dataSort dataFormat={dollarsFormatter} csvFormat={this._csvDollarFormatter}>$
                        Proposals</TableHeaderColumn>
                    <TableHeaderColumn dataField="closed_count" width="10%" dataSort># Closed</TableHeaderColumn>
                    <TableHeaderColumn dataField="closed_price" width="10%" dataSort dataFormat={dollarsFormatter} csvFormat={this._csvDollarFormatter}>Closed
                        $</TableHeaderColumn>
                    <TableHeaderColumn dataField="count_percentage" width="10%" dataSort dataFormat={this._percentFormatter} csvFormat={this._percentFormatter}>% Count</TableHeaderColumn>
                    <TableHeaderColumn dataField="price_percentage" width="10%" dataSort dataFormat={this._percentFormatter} csvFormat={this._percentFormatter}>% Dollars</TableHeaderColumn>
                </BootstrapTable>
                <footer className="navbar-fixed-bottom"
                        style={{backgroundColor: 'black', opacity: '.8', maxHeight: '180', zIndex: '00000'}}>
                    <div className="container">
                        <div className="row">
                            <Well style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                paddingTop: "5px",
                                backgroundColor: 'transparent',
                                opacity: '.8',
                                borderColor: 'black'
                            }}>
                                <Row>

                                    <Col md={6}>
                                        <h4>Summary:</h4>
                                    </Col>
                                    <Col md={2} className="text-success text-right">
                                        <p>Customers:</p>
                                        <p>Proposed:</p>
                                        <p>Accepted:</p>
                                        <p>Declined:</p>
                                        <p>Open:</p>
                                    </Col>
                                    <Col md={2} className="text-warning">
                                        <p>{customers}</p>
                                        <p>{proposed}</p>
                                        <p>{accepted_count}</p>
                                        <p>{declined_count}</p>
                                        <p>{open_count}</p>
                                    </Col>
                                    <Col md={2} className="text-danger">
                                        <p>&nbsp; </p>
                                        <p>&nbsp; </p>
                                        <p>{accepted_percentage}%</p>
                                        <p>{declined_percentage}%</p>
                                        <p>{open_percentage}%</p>
                                    </Col>
                                </Row>
                            </Well>
                        </div>
                    </div>
                </footer>
            </Grid>
        );
    }
}

ReferralReport.propTypes = {
    //myProp: PropTypes.object.isRequired
};

ReferralReport.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, saveNewCsvDownload}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralReport);