import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, OverlayTrigger, Panel, Row, Tooltip} from 'react-bootstrap'
import Dollars from '../../components/Dollars'
import moment from "moment-timezone";
import * as MyActions from "./actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import {defaultDateFormat, defaultTimeFormat, sortAssetsLabels} from "../../common/commonHandlers";
import PlantName from "../../components/PlantName";
import BundleServices from "../BundleServices/BundleServices";
import ConsumablesConsumptionModal from "../../common/ConsumablesConsumptionModal";
import './WoProposalServiceList.scss'
import {debounce} from "throttle-debounce";
import MemoizedCompletedDate from "./MemoizedCompletedDate";

const Actions = {...MyActions}

class WoProposalServicesList extends Component {

    constructor(props, context) {
        super(props, context);
        this.delayedSave = debounce(1000, this.save);
        this.state = {
            completed_at: null,
            service_to_update: null,
            proposal_services_info: this.props.proposalServicesInfo,
            consumableConsumptionModal: {
                show: false,
                proposal_service_id: null,
                consumables: null
            }
        };
    }

    componentDidMount() {
        this.calculateAndSetGlobalCompletedDate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.proposalServicesInfo !== this.props.proposalServicesInfo) {
            this.setState({proposal_services_info: this.props.proposalServicesInfo}, () => {
                if (!this.props.updateInProgress) {
                    this.calculateAndSetGlobalCompletedDate()
                }
            })
        }
    }

    calculateAndSetGlobalCompletedDate = () => {
        let moments = this.state.proposal_services_info.filter(ps => ps.completed_at).map(ps => moment(ps.completed_at))
        if (moments && moments.length > 0) {
            let maxDate = moment.max(moments)
            this.props.setGlobalCompletedDate(maxDate)
        } else if (!moments || moments && moments.length === 0) {
            this.props.setGlobalCompletedDate(null)
        }
    }

    getColor = status => {
        return ['Not Scheduled', 'Scheduled', 'In Work Order'].includes(status)
            ? undefined
            : 'grey'
    }

    save = () => {
        const {service_to_update, completed_at} = this.state;
        this.setState({isLoading: true})
        this.props.actions.loadConsumables(service_to_update, (res)=>{
            if(res.consumables && res.consumables.length>0){
                this.setState({consumableConsumptionModal: {
                        show: true,
                        proposal_service_id: service_to_update,
                        service_name: this.state.service_name,
                        consumables: res.consumables
                    }})
            }

        })
        this.props.actions.saveWorkOrderProposalService(
            {
                id: service_to_update,
                completed_at: completed_at
            }, (completed_at) => {
                const copiedServices = [...this.state.proposal_services_info];
                copiedServices.find(ps => ps.id === service_to_update).completed_at = completed_at;

                this.setState({completed_at: completed_at, proposal_services_info: copiedServices, isLoading: false})
                this.props.actions.searchPsByWorkOrderNo();
            })
    };
    handleDateChange = (e, proposalService) => {
        let date = e
        const copiedServices = [...this.state.proposal_services_info];
        proposalService = copiedServices.find(ps => ps.id === proposalService.id)
        if (proposalService.completed_at) {
            proposalService.completed_at = date;
        } else {
            let dateString = date.format(defaultDateFormat)
            date = moment(dateString, defaultDateFormat).set({
                hour: moment().hour(),
                minute: moment().minute(),
            });
            proposalService.completed_at = date
        }

        this.setState({
            isLoading: true,
            completed_at: date,
            service_to_update: proposalService.id,
            service_name: "#" + proposalService.serviceNo + " " + proposalService.serviceName,
            proposal_services_info: copiedServices
        }, () => this.delayedSave())
    };

    render() {
        const {selectedPs, selectPs} = this.props;
        const {consumableConsumptionModal, proposal_services_info: proposalServicesInfo} = this.state;

        return (
            <div id="wo-proposal-service-list">
                {consumableConsumptionModal.show && <ConsumablesConsumptionModal
                    consumables={consumableConsumptionModal.consumables}
                    sendConsumablesConsumption={this.props.actions.sendConsumablesConsumption}
                    proposal_service_id={consumableConsumptionModal.proposal_service_id}
                    proposal_service_name={consumableConsumptionModal.service_name}
                    showModal={consumableConsumptionModal.show}
                    closeModal={()=>{this.setState({consumableConsumptionModal: {
                            show: false,
                            proposal_service_id: null
                        }})}}
                />}

                {proposalServicesInfo && proposalServicesInfo
                    .slice()
                    .sort((a, b) => parseInt(a.serviceNo, 10) - parseInt(b.serviceNo, 10))
                    .map(proposalService => {
                        const renderTooltip = proposalService && (<div>
                                {proposalService.created_at &&
                                    <span>Draft {moment(proposalService.created_at).format(defaultDateFormat)}<br/></span>}
                                {proposalService.proposal_date &&
                                    <span>Proposed {moment(proposalService.proposal_date).format(defaultDateFormat)}<br/></span>}
                                {proposalService.accepted_date &&
                                    <span>Accepted {moment(proposalService.accepted_date).format(defaultDateFormat)}<br/></span>}
                                {proposalService.scheduled_date &&
                                    <span>Scheduled {moment(proposalService.scheduled_date).format(defaultDateFormat)}<br/></span>}
                                {proposalService.completed_at &&
                                    <span>Completed {moment(proposalService.completed_at).format(defaultDateFormat)}<br/></span>}
                                {proposalService.invoiced_at &&
                                    <span>Invoiced {moment(proposalService.invoiced_at).format(defaultDateFormat)}<br/></span>}
                                {proposalService.paid_at &&
                                    <span>Paid {moment(proposalService.paid_at).format(defaultDateFormat)}<br/></span>}
                                {proposalService.declined_date &&
                                    <span>Declined {moment(proposalService.declined_date).format(defaultDateFormat)}</span>}
                            </div>
                        );
                        let statusDate = null;
                        if (proposalService.proposalServiceStatus === 'Draft')
                            statusDate = proposalService.created_at && moment(proposalService.created_at).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Invoiced')
                            statusDate = proposalService.invoiced_at && moment(proposalService.invoiced_at).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Proposed')
                            statusDate = proposalService.proposal_date && moment(proposalService.proposal_date).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Accepted')
                            statusDate = proposalService.accepted_date && moment(proposalService.accepted_date).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Scheduled')
                            statusDate = proposalService.scheduled_date && moment(proposalService.scheduled_date).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Completed')
                            statusDate = proposalService.completed_at && moment(proposalService.completed_at).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Paid')
                            statusDate = proposalService.paid_at && moment(proposalService.paid_at).format(defaultDateFormat);
                        if (proposalService.proposalServiceStatus === 'Declined')
                            statusDate = proposalService.declined_date && moment(proposalService.declined_date).format(defaultDateFormat);
                        return <Panel key={proposalService.id}>
                            <div
                                style={{
                                    color: this.getColor(proposalService.proposalServiceStatus),
                                }}
                            >
                                <Row>
                                    <Col xs={1}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                disabled={
                                                    this.getColor(proposalService.proposalServiceStatus) ===
                                                    'grey'
                                                }
                                                onChange={e => selectPs(e, proposalService.id)}
                                                checked={selectedPs.includes(proposalService.id)}
                                            />
                                            {' '}
                                            #{proposalService.order_number_in_proposal}
                                        </label>
                                    </Col>
                                    <Col xs={2}>
                                        {proposalService.serviceName}
                                    </Col>
                                    <Col xs={2}>
                                        {proposalService.plants && proposalService.plants.sort((a, b) => sortAssetsLabels(a, b, 'label')).map(p =>
                                            <span>
                                                <PlantName plant={p.label}/>
                                                {p.plant_count > 1 && `[#${p.plant_count}]`}
                                                {" "}
                                                </span>
                                        )}
                                    </Col>
                                    <Col xs={2}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                                            <div>
                                                <strong>{proposalService.proposalServiceStatus}</strong>{' '}
                                                <span>{statusDate}</span>
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col xs={1}>
                                        <Dollars amount={proposalService.serviceCost}/>
                                    </Col>
                                    <Col xs={2}>
                                        {proposalService.workOrderNo}
                                    </Col>
                                    <Col xs={2}>
                                        <MemoizedCompletedDate
                                            completedDate={moment(proposalService.completed_at)}
                                            updateInProgress={(this.state.isLoading && proposalService.id === this.state.service_to_update) || this.props.updateInProgress}
                                            onChange={(e) => this.handleDateChange(e, proposalService)}
                                            className="full-width table-auto justify-flex-end"
                                        />
                                    </Col>
                                </Row>

                                <br/>
                                <Row>
                                    <Col xs={6} className="preserve-text-area-format">
                                        ServiceNotes:<br/>
                                        {proposalService.notes}
                                    </Col>

                                    <Col xs={6} className="preserve-text-area-format">
                                        WO Notes:<br/>
                                        {proposalService.woNotes}
                                    </Col>
                                </Row>
                            </div>
                            {proposalService.is_bundle_service &&
                                <BundleServices
                                    bundleServices={proposalService.bundle_services}
                                    view='WoProposalServiceList'
                                    markedCheckboxes={selectedPs}
                                    proposalServiceId={proposalService.id}
                                    dateTimeCompletedAt={proposalService.completed_at}
                                />
                            }
                        </Panel>
                    })}
            </div>
        )
    }
}

WoProposalServicesList.propTypes = {
    proposalServicesInfo: PropTypes.array.isRequired,
    selectedPs: PropTypes.array.isRequired,
    selectPs: PropTypes.func.isRequired,
}

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    WoProposalServicesList
)