import React from "react";
import moment from "moment";
import {defaultDateTimeFormat} from "../../../common/commonHandlers";

export const textCsvFormatter = (cell, row) => {
    return cell ? cell : 'N/A'
}

export const booleanCsvFormatter = (cell, row) => {
    return cell
}

export const dateCsvFormatter = (cell, row) => {
    return cell ? moment(cell).format(defaultDateTimeFormat) : 'N/A'
}

export const proposalCsvFormatter = (cell, row) => {
    return cell ? `#${cell}` : 'N/A'
}

export const dollarCsvFormatter = (cell, row) => {
    return `$${(+cell || 0).toFixed(2)}`
}

export const seapCsvFormatter = (cell, row) => {
    return cell ? `${cell.replaceAll('\n', ', ')}` : 'N/A'
}

export const invoicedAtCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((invoice, i) => `${i + 1}. ${invoice.service_name} - ${moment(invoice.invoiced_at).format(defaultDateTimeFormat)}`).join('\n')
        :
        'N/A'
}

export const invoicesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map(invoice => `#${invoice.invoice_no}`).join('\n')
        :
        'N/A'
}

export const paidCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((p, i) => `${i +1}. #${p.invoice_no} (${p.status?.toUpperCase()}), Amount: $${(p.amount || 0).toFixed(2)}, Date: ${moment(p.date).format(defaultDateTimeFormat)}`).join('\n')
        :
        'N/A'
}

export const scheduledCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((wo, i) => `${i +1}. WO number: #${wo.work_order_number}, Date: ${moment(wo.date_from).format(defaultDateTimeFormat)}`).join('\n')
        :
        'N/A'
}

export const servicesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i +1}. ${s.service_name}, Created At: ${moment(s.proposal_service_created_at).format(defaultDateTimeFormat)}`).join('\n')
        :
        'N/A'
}

export const workOrdersCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map(wo => `#${wo.work_order_no}`).join('\n')
        : 'N/A'
}

export const workOrderNotesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((wo, i) => `${i + 1}. ${wo.service_name}${wo.work_order_number ? ` #${wo.work_order_number}` : ""} - ${(wo.work_order_note || "").replaceAll('\n', ' ')}`).join('\n').toString()
        :
        'N/A'
}

export const serviceTypesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i + 1}. ${s.service_name}, Service Type: ${s.service_type_name}`).join('\n')
        :
        'N/A'
}

export const serviceTotalsCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i + 1}. ${s.service_name}, Service Total: $${(+s.service_total || 0).toFixed(2)}`).join('\n')
        :
        'N/A'
}

export const manHoursCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i + 1}. ${s.service_name}, Hours: ${s.man_hour}`).join('\n')
        :
        'N/A'
}

export const statusCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i + 1}. ${s.service_name}, Status: ${s.status_name}`).join('\n')
        :
        'N/A'
}

export const acceptedDatesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    return data.length > 0 ?
        data.map((s, i) => `${i + 1}. ${s.service_name}, Accepted Date: ${moment(s.accepted_date).format(defaultDateTimeFormat)}`).join('\n')
        :
        'N/A'
}

export const prioritiesCsvFormatter = (cell, row) => {
    const data = cell ? JSON.parse(cell) : []

    const mergedData = Object.values(data.reduce((acc, curr) => {
        const key = `${curr.id}-${curr.service_name}`;

        if (!acc[key]) {
            acc[key] = { ...curr, priorities: [] };
        }

        acc[key].priorities.push(curr.priorities);

        return acc;
    }, {})).map(item => ({
        ...item,
        priorities: item.priorities.join(', ')
    }));

    return mergedData.length > 0 ?
        mergedData.map((s, i) => `${i + 1}. ${s.service_name}, Priorities: ${s.priorities}`).join('\n')
        :
        'N/A'
}

