import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import {Button, Checkbox, Col, Grid, MenuItem, Nav, NavDropdown, Row,} from "react-bootstrap"
import Helmet from "react-helmet"
import {LinkContainer} from "react-router-bootstrap"
import PageTitle from "../../components/PageTitle"
import Select from "react-select"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import * as Actions from "./FindWorkOrdersApi"
import Dollars from "../../components/Dollars"
import moment from "moment"
import {saveNewCsvDownload, defaultDateFormat, defaultDateFormatShort, select} from "../../common/commonHandlers";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";

const PrintWos = ({woNumbers}) => (
    <LinkContainer to={`/print_work_orders/${woNumbers}`}>
        <Button disabled={woNumbers === ""} bsSize="small" bsStyle="info">
            Print Wo's
        </Button>
    </LinkContainer>
)
PrintWos.propTypes = {
    woNumbers: PropTypes.string,
}

const dollarsFormatter = cell => <Dollars amount={cell}/>;
const dateFormatter = cell => {
    if(cell.length === 1 && cell[0] === ""){
        return ""
    }
    return moment(cell).format(defaultDateFormat);
}


const proposalNoFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            bsSize="small"
            bsStyle="default"
            title={cell || "..."}
            id={`proposal-menu-${row.id}`}
            className="no-caret-in-dropdown"
        >
            <LinkContainer target="_blank" to={`/mapview/${row.proposal_id}`}>
                <MenuItem>Edit Proposal</MenuItem>
            </LinkContainer>
            <LinkContainer
                target="_blank"
                to={`/proposal_service_status/${row.proposal_id}`}
            >
                <MenuItem>Edit Service Statuses</MenuItem>
            </LinkContainer>
            <LinkContainer target="_blank" to={`/print_proposal/${row.proposal_id}`}>
                <MenuItem>Print Proposal</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

const workOrderNoFormatter = (cell, row) => (
    <Nav>
        <NavDropdown
            bsSize="small"
            bsStyle="default"
            title={cell || "..."}
            id={`wo-menu-${row.id}`}
            className="no-caret-in-dropdown"
        >
            <LinkContainer to={`/work_orders/${row.proposal_id}`}>
                <MenuItem>Manage WO</MenuItem>
            </LinkContainer>
            <LinkContainer

                to={`/complete_work_orders/${row.work_order_no}`}
            >
                <MenuItem>Complete WO</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/print_work_orders/${row.work_order_no}`}>
                <MenuItem>Print Work Order</MenuItem>
            </LinkContainer>
        </NavDropdown>
    </Nav>
)

const customerNameFormatter = (cell, row) => (
    <div>
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
            >
                <LinkContainer to={`/customer/info/${row.customer_id}`}>
                    <MenuItem bsSize="small">Customer Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.customer_id}`}>
                    <MenuItem bsSize="small">Customer Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.customer_id}`}>
                    <MenuItem bsSize="small">Customer Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.customer_id}`}>
                    <MenuItem bsSize="small">Customer Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.customer_id}`}>
                    <MenuItem bsSize="small">Customer Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>

    </div>
)


const addressFormatter = (cell, row) => (
    <div>
        {row.site_address}, {" "} {row.site_city} {" "} {row.zip}
    </div>
)


class FindWorkOrders extends ResourceComponent {
    state = {
        selected: [],
        showFilters: false,
        resource: {
            selectedServiceType: null,
            selectedService: [],
            selectedPriority: [],
            selectedEquipment: [],
            selectedReason: [],
            promisedStart: "",
            promisedEnd: "",
            eventFromDate: "",
            eventToDate: "",
            unscheduled: false,
            inactive: false,
        }
    }

    tableRef = React.createRef()

    _onSelectAll = (isSelected, rows) => {
        let selected = []

        if (isSelected) {
            selected = rows.map(w => w.work_order_no)
        }
        this.setState({selected})
    }

    _onRowSelect = (row, isSelected, e) => {
        let selected = [...this.state.selected]
        const woNumber = parseInt(row.work_order_no, 10)

        if (isSelected) {
            selected = selected.concat(woNumber) // prob concatted a dup #
            const uniqSelecteds = new Set(selected) //uniqu them
            selected = [...uniqSelecteds] //transform uniqs back into an array
        } else {
            selected = selected.filter(s => s !== woNumber)
        }
        this.setState({selected})
    }

    _toggleFilter = () => {
        this.setState({showFilters: !this.state.showFilters})
    }

    _onSingleSelect = (name, selected) => {
        let {resource} = this.state;
        resource[name] = (selected && selected.value) ? selected.value : ["promisedStart", "promisedEnd"].includes(name) ? "" : null
        this.setState(resource)
    }

    _createReport = () => {
        let {resource} = this.state;
        resource.promisedStart = resource.promisedStart ? moment(resource.promisedStart).format(defaultDateFormat) : null;
        resource.promisedEnd = resource.promisedEnd ? moment(resource.promisedEnd).format(defaultDateFormat) : null;
        this.props.actions.findWorkOrders(resource, result => {
            this.setState({workOrders: result})
        })
    }
    componentDidMount = () => {
        this.props.actions.load(result => {
            this.setState(result);
        });
    };

    _eventFormatter = (cell, row) => {
        if (row.scheduledEvents && row.scheduledEvents.length > 1) {
            return <div className="columnDirection">{row.scheduledEvents.map(ps => ps !== "" ?
                <span>{moment(ps).format(defaultDateFormatShort)}</span> : '')}</div>;
        }
        if (row.scheduledEvents && row.scheduledEvents[0]) {
            return <div className="columnDirection"><span>{moment(row.scheduledEvents[0]).format(defaultDateFormatShort)}</span>
            </div>;
        }
        return null
    };

    _csvEventFormatter = (cell) =>{
        cell=cell.map((e)=>{
            e=dateFormatter(e)
            return e
        })
        cell.join(', ')
        return cell
    }

    promisedStartDateFormatter = (cell, row) => {
        if (row.promisedStart && row.promisedStart.length > 1) {
            return <div className="columnDirection">{row.promisedStart.map(ps => ps !== "" ?
                <span>{moment(ps).format(defaultDateFormatShort)}</span> : '')}</div>;
        }
        if (row.promisedStart && row.promisedStart[0].length > 0) {
            return <div className="columnDirection"><span>{moment(row.promisedStart[0]).format(defaultDateFormatShort)}</span></div>;
        }
        return null
    };

    promisedEndDateFormatter = (cell, row) => {
        if (row.promisedEnd && row.promisedEnd.length > 1) {
            return <div className="columnDirection">{row.promisedEnd.map(ps => ps !== "" ?
                <span>{moment(ps).format(defaultDateFormatShort)}</span> : '')}</div>;
        }
        if (row.promisedEnd && row.promisedEnd[0].length > 0) {
            return <div className="columnDirection"><span>{moment(row.promisedEnd[0]).format(defaultDateFormatShort)}</span></div>;
        }
        return null
    };

    rowStyleFormat = (cell, row) => {
        const {workOrders} = this.state;
        return {
            background: workOrders && workOrders.map((work_order, i) => work_order.inactive === true ? i : -1).filter(index => index !== -1).includes(row) ? '#EBEBE4' : null
        }
    };

    onExportToCSV = () => {
        const {workOrders} = this.state
        const selectedRows = this.state.selected;
        return workOrders.filter(i => {
            if (selectedRows.indexOf(i.work_order_no) > -1) {
                return i;
            }
        });
    }

    csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    render() {
        const {
            reasons,
            showFilters,
            totalHours,
            totalCost,
            woCount,
            resource,
            workOrders,
            serviceTypes,
            services,
            priorities,
            equipments
        } = this.state;
        const selectedWorkOrders = this.state.selected.join(",");
        return (<Grid fluid>
                <Helmet title="Find Work Orders"/>
                <PageTitle pageName="Find Work Orders"/>

                <Row>
                    <Col md={1}>
                        <PrintWos woNumbers={selectedWorkOrders}/>
                    </Col>

                    <Col md={1}>
                        <Select className="Select" classNamePrefix="select"
                                placeholder="Service Type"
                                value={select(serviceTypes, resource.selectedServiceType)}
                                onChange={this.selectResourceAttr('selectedServiceType')}
                                options={serviceTypes}
                                isClearable
                        />
                    </Col>

                    <Col md={1}>
                        <Checkbox
                            name="unscheduled"
                            checked={resource.unscheduled}
                            onChange={this.updateResourceAttr}
                            inline
                            disabled={resource.eventFromDate || resource.eventToDate}
                        >
                            Unscheduled only
                        </Checkbox>
                    </Col>

                    <Col md={2}>
                        <Select className="Select" classNamePrefix="select"
                                placeholder="Service"
                                isMulti
                                simpleValue={true}
                                value={select(services, resource.selectedService)}
                                onChange={this.selectResourceAttr('selectedService')}
                                options={services}
                        />
                        <ColorCheckbox value={resource.inactive}
                                       className="top15"
                                       label='Show inactive work orders'
                                       onChange={(e) => this.setState({resource: {...resource, inactive: !resource.inactive}})}
                        />
                    </Col>

                    <Col md={3}>
                        <Select className="Select" classNamePrefix="select"
                                placeholder="Priority"
                                isMulti
                                simpleValue={true}
                                value={select(priorities, resource.selectedPriority)}
                                onChange={this.selectResourceAttr('selectedPriority')}
                                options={priorities}
                        />
                    </Col>

                    <Col md={2}>
                        <Select className="Select" classNamePrefix="select"
                                isMulti
                                simpleValue={true}
                                placeholder="Equipment"
                                value={select(equipments, resource.selectedEquipment)}
                                onChange={this.selectResourceAttr('selectedEquipment')}
                                options={equipments}
                        />
                    </Col>
                    <Col md={2}>
                        <Select className="Select" classNamePrefix="select"
                                isMulti
                                simpleValue={true}
                                placeholder="Reason"
                                value={select(reasons, resource.selectedReason)}
                                onChange={this.selectResourceAttr('selectedReason')}
                                options={reasons}
                        />
                    </Col>
                    <Col md={1}>
                        <Row>
                            <Col md={12}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={resource.promisedStart ? moment(resource.promisedStart) : ""}
                                    inputProps={{placeholder: 'Promised From'}}
                                    onChange={e => {
                                        this.setState({resource: {...resource, promisedStart: e === "" ? null : e}})
                                    }
                                    }/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={resource.promisedEnd ? moment(resource.promisedEnd) : ""}
                                    inputProps={{placeholder: 'Promised To'}}
                                    onChange={e => {
                                        this.setState({resource: {...resource, promisedEnd: e === "" ? null : e}})
                                    }
                                    }/>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={1}>
                        <Row>
                            <Col md={12}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={
                                        moment(resource.eventFromDate).isValid() ? moment(resource.eventFromDate) : null
                                    }
                                    inputProps={{placeholder: 'Scheduled From'}}
                                    onChange={m =>
                                        this._onSingleSelect("eventFromDate", {
                                            value: moment(m).isValid() ? moment(m).format() : null,
                                        })}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={
                                        moment(resource.eventToDate).isValid() ? moment(resource.eventToDate) : null
                                    }
                                    inputProps={{placeholder: 'Scheduled To'}}
                                    onChange={m =>
                                        this._onSingleSelect("eventToDate", {
                                            value: moment(m).isValid() ? moment(m).format() : null,
                                        })}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={1} mdOffset={10}>
                        <Button bsSize="small" onClick={this._createReport} bsStyle="success">
                            Create Report
                        </Button>
                    </Col>

                    <Col md={1}>
                        <Button
                            bsSize="small"
                            disabled={!(workOrders && workOrders.length > 0)}
                            onClick={this._toggleFilter}
                            bsStyle="warning"
                        >
                            Filter Rows
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        {"    "}
                    </Col>
                    <Col md={5} className="text-right text-success">
                        Total Hours & Costs:
                        <br/>
                        {woCount > 0 ? woCount : 0} WO's Returned
                    </Col>

                    <Col md={1} className="text-success">
                        {totalHours ?
                            <span>{totalHours} hours </span>
                            : null
                        }
                    </Col>
                    <Col md={1} className="text-success">
                        {totalCost ?
                            <Dollars amount={parseFloat(totalCost, 10)}/>
                            : null
                        }
                    </Col>
                    <Col md={1}/>
                </Row>

                <Row>
                    <Col md={12}>
                        {/* consider onExportToCSV method to format exported csv */}
                        <BootstrapTable
                            ref={this.tableRef}
                            data={workOrders}
                            striped={true}
                            bordered={false}
                            hover={true}
                            exportCSV={true}
                            search={showFilters}
                            trStyle={(cell, row) => this.rowStyleFormat(cell, row)}
                            csvFileName={() => {
                                const fileName = `find-work-orders-${moment().format()}.csv`
                                if (this.tableRef?.current) {
                                    this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "find work orders")
                                }
                                return fileName
                            }}
                            options={{
                                afterColumnFilter: (_, filtered) => {
                                    let totalHours = filtered.reduce((sum, wo) => sum + wo.hours, 0).toFixed(2);
                                    let totalCost = filtered.reduce((sum, wo) => sum + wo.cost, 0).toFixed(2);
                                    let woCount = filtered.length;
                                    if (this.state.totalHours !== totalHours && this.state.totalCost !== totalCost) {
                                        this.setState({totalCost, totalHours, woCount});
                                    }
                                },
                                onExportToCSV: this.onExportToCSV
                            }}
                            selectRow={{
                                mode: "checkbox",
                                clickToSelect: false,
                                onSelect: this._onRowSelect,
                                onSelectAll: this._onSelectAll,
                            }}
                        >
                            <TableHeaderColumn hidden isKey dataField="id">
                                Wo Id
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="15%"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                dataSort={true}
                                columnClassName="menu-show-fixer"
                                dataFormat={customerNameFormatter}
                                dataField="name"
                            >
                                Customer Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="15%"
                                tdStyle={{whiteSpace: 'normal'}}
                                thStyle={{whiteSpace: 'normal'}}
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                dataFormat={addressFormatter}
                                dataField="site_address"
                            >
                                Address
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="5%"
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                dataSort={true}
                                dataField="zip"
                            >
                                Zip
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="7%"
                                dataSort={true}
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                dataField="arborist"
                            >
                                Arborist
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="5%"
                                dataSort={true}
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                columnClassName="menu-show-fixer"
                                dataFormat={proposalNoFormatter}
                                dataField="proposal_no"
                            >
                                Proposal #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="5%"
                                filter={showFilters ? {type: "TextFilter", delay: 500} : null}
                                dataSort={true}
                                columnClassName="menu-show-fixer"
                                dataFormat={workOrderNoFormatter}
                                dataField="work_order_no"
                            >
                                WO #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                filter={showFilters ? {type: "DateFilter"} : null}
                                dataSort={true}
                                filterFormatted={false}
                                dataFormat={dateFormatter}
                                csvFormat={dateFormatter}
                                dataField="accepted_date"
                            >
                                Accepted Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                filter={showFilters ? {type: "DateFilter"} : null}
                                dataSort={true}
                                filterFormatted={false}
                                // dataFormat={ cell => cell[item] ? cell[item] : '0' }>{ item }
                                dataFormat={"promisedStart" ? this.promisedStartDateFormatter : null}
                                csvFormat={dateFormatter}
                                dataField={"promisedStart"}
                            >
                                Promised From
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                filter={showFilters ? {type: "DateFilter"} : null}
                                dataSort={true}
                                filterFormatted={false}
                                dataFormat={"promisedEnd" ? this.promisedEndDateFormatter : null}
                                csvFormat={dateFormatter}
                                dataField={"promisedEnd"}
                            >
                                Promised To
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="10%"
                                // filter={showFilters ? {type: "DateFilter"} : null}
                                dataSort={true}
                                filterFormatted={false}
                                // dataFormat={ cell => cell[item] ? cell[item] : '0' }>{ item }
                                dataFormat={this._eventFormatter}
                                csvFormat={this._csvEventFormatter}
                                dataField={"scheduledEvents"}
                            >
                                Scheduled On
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="5%"
                                filter={showFilters ? {type: "NumberFilter", delay: 500} : null}
                                dataSort={true}
                                dataField="hours"
                            >
                                Hours
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="5%"
                                filter={showFilters ? {type: "NumberFilter", delay: 500} : null}
                                dataSort={true}
                                dataFormat={dollarsFormatter}
                                csvFormat={this.csvDollarFormatter}
                                filterFormatted={false}
                                dataField="cost"
                            >
                                Cost
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>

                <p/>

                <Row>
                    <Col md={4}>
                        <PrintWos woNumbers={selectedWorkOrders}/>

                    </Col>

                    <Col md={5} className="text-right">
                        Total Hours & Costs
                        <br/>
                        {woCount > 0 ? woCount : 0} WO's Returned
                    </Col>
                    <Col md={1}>
                        {totalHours ?
                            <span>{totalHours} hours </span>
                            : null
                        }
                    </Col>
                    <Col md={1}>
                        {totalCost ?
                            <Dollars amount={parseFloat(totalCost, 10)}/>
                            : null
                        }
                    </Col>
                    <Col md={1}/>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({...Actions, saveNewCsvDownload}, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(FindWorkOrders)
