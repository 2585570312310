import {TableHeaderColumn} from "react-bootstrap-table";
import {
    acceptedDatesFormatter,
    booleanFormatter,
    dateFormatter,
    dollarFormatter,
    invoicedAtFormatter,
    invoicesFormatter, manHoursFormatter,
    paidFormatter,
    preLineFormatter, prioritiesFormatter,
    proposalFormatter,
    scheduledFormatter,
    servicesFormatter, serviceTotalsFormatter,
    serviceTypesFormatter,
    siteFormatter, statusFormatter,
    textFormatter,
    workOrderNotesFormatter,
    workOrdersFormatter
} from "./NewSalesDashboardFormatters";
import React from "react";
import {
    acceptedDatesCsvFormatter,
    booleanCsvFormatter,
    dateCsvFormatter, dollarCsvFormatter,
    invoicedAtCsvFormatter,
    invoicesCsvFormatter, manHoursCsvFormatter,
    paidCsvFormatter, prioritiesCsvFormatter,
    proposalCsvFormatter,
    scheduledCsvFormatter,
    seapCsvFormatter,
    servicesCsvFormatter, serviceTotalsCsvFormatter, serviceTypesCsvFormatter, statusCsvFormatter,
    textCsvFormatter, workOrderNotesCsvFormatter, workOrdersCsvFormatter
} from "./NewSalesDashboardCsvFormatters";

export const columnsSelect = (dataField, isBacklog, showProposalEmailDialog) => {
    switch(dataField) {
        case "age":
            return <TableHeaderColumn
                dataField="age"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Age"
            >
                Age
            </TableHeaderColumn>
        case "arborist_name":
            return <TableHeaderColumn
                dataField="arborist_name"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Arborist Name"
            >
                Arborist Name
            </TableHeaderColumn>
        case "completed_at":
            return <TableHeaderColumn
                dataField="completed_at"
                width="10%"
                dataSort
                dataFormat={dateFormatter}
                csvFormat={dateCsvFormatter}
                csvHeader="Completed at"
            >
                Completed at
            </TableHeaderColumn>
        case "customer_name":
            return <TableHeaderColumn
                dataField="customer_name"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Customer Name"
            >
                Customer Name
            </TableHeaderColumn>
        case "email_address":
            return <TableHeaderColumn
                dataField="email_address"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Email Address"
            >
                Email Address
            </TableHeaderColumn>
        case "email_count":
            return <TableHeaderColumn
                dataField="email_count"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Email Count"
            >
                Email Count
            </TableHeaderColumn>
        case "invoiced_at":
            return <TableHeaderColumn
                dataField="invoiced_at"
                width="10%"
                dataSort
                dataFormat={invoicedAtFormatter}
                csvFormat={invoicedAtCsvFormatter}
                csvHeader="Invoiced at"
            >
                Invoiced at
            </TableHeaderColumn>
        case "invoices":
            return <TableHeaderColumn
                dataField="invoices"
                width="10%"
                dataSort
                dataFormat={invoicesFormatter}
                csvFormat={invoicesCsvFormatter}
                csvHeader="Invoices"
            >
                Invoices
            </TableHeaderColumn>
        case "last_follow_up_date":
            return <TableHeaderColumn
                dataField="last_follow_up_date"
                width="10%"
                dataSort
                dataFormat={dateFormatter}
                csvFormat={dateCsvFormatter}
                csvHeader="Last follow up date"
            >
                Last follow up date
            </TableHeaderColumn>
        case "paid_dates":
            return <TableHeaderColumn
                dataField="paid_dates"
                width="10%"
                dataSort
                dataFormat={paidFormatter}
                csvFormat={paidCsvFormatter}
                csvHeader="Paid Dates"
            >
                Paid Dates
            </TableHeaderColumn>
        case "phone_number":
            return <TableHeaderColumn
                dataField="phone_number"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Phone Number"
            >
                Phone Number
            </TableHeaderColumn>
        case "promised_dates":
            return <TableHeaderColumn
                dataField="promised_dates"
                width="10%"
                dataSort
                dataFormat={preLineFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Promised Dates"
            >
                Promised Dates
            </TableHeaderColumn>
        case "proposal_created_at":
            return <TableHeaderColumn
                dataField="proposal_created_at"
                width="10%"
                dataSort
                dataFormat={dateFormatter}
                csvFormat={dateCsvFormatter}
                csvHeader="Proposal Created At"
            >
                Proposal Created At
            </TableHeaderColumn>
        case "proposal_no":
            return <TableHeaderColumn
                dataField="proposal_no"
                width="10%"
                dataSort
                dataFormat={(cell, row) => proposalFormatter(cell, row, showProposalEmailDialog)}
                csvFormat={proposalCsvFormatter}
                csvHeader="Proposal"
            >
                Proposal
            </TableHeaderColumn>
        case "scheduled_on_dates":
            return <TableHeaderColumn
                dataField="scheduled_on_dates"
                width="10%"
                dataSort
                dataFormat={scheduledFormatter}
                csvFormat={scheduledCsvFormatter}
                csvHeader="Scheduled on"
            >
                Scheduled on
            </TableHeaderColumn>
        case "scheduler_event_assigned_persons":
            return <TableHeaderColumn
                dataField="scheduler_event_assigned_persons"
                width="10%"
                dataSort
                dataFormat={preLineFormatter}
                csvFormat={seapCsvFormatter}
                csvHeader="Assignee"
            >
                Assignee
            </TableHeaderColumn>
        case "sent_date":
            return <TableHeaderColumn
                dataField="sent_date"
                width="10%"
                dataSort
                dataFormat={dateFormatter}
                csvFormat={dateCsvFormatter}
                csvHeader="Sent date"
            >
                Sent date
            </TableHeaderColumn>
        case "services":
            return <TableHeaderColumn
                dataField="services"
                width="10%"
                dataSort
                dataFormat={servicesFormatter}
                csvFormat={servicesCsvFormatter}
                csvHeader="Services"
            >
                Services
            </TableHeaderColumn>
        case "site":
            return <TableHeaderColumn
                dataField="site"
                width="10%"
                dataSort
                dataFormat={siteFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Site"
            >
                Site
            </TableHeaderColumn>
        case "stickies_completed":
            return <TableHeaderColumn
                dataField="stickies_completed"
                width="10%"
                dataSort
                dataFormat={booleanFormatter}
                csvFormat={booleanCsvFormatter}
                csvHeader="Stickies Completed"
            >
                Stickies Completed
            </TableHeaderColumn>
        case "total_paid":
            return <TableHeaderColumn
                dataField="total_paid"
                width="10%"
                dataSort
                dataFormat={dollarFormatter}
                csvFormat={dollarCsvFormatter}
                csvHeader="Total Paid"
            >
                Total Paid
            </TableHeaderColumn>
        case "work_orders":
            return <TableHeaderColumn
                dataField="work_orders"
                width="10%"
                dataSort
                dataFormat={workOrdersFormatter}
                csvFormat={workOrdersCsvFormatter}
                csvHeader="Work Orders"
            >
                Work Orders
            </TableHeaderColumn>
        case "work_order_notes":
            return <TableHeaderColumn
                dataField="work_order_notes"
                width="10%"
                dataSort
                dataFormat={workOrderNotesFormatter}
                csvFormat={workOrderNotesCsvFormatter}
                csvHeader="Work Order Notes"
            >
                Work Order Notes
            </TableHeaderColumn>
        case "service_type_name":
            return isBacklog && <TableHeaderColumn
                dataField="service_type_name"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Service Type Name"
            >
                Service Type Name
            </TableHeaderColumn>
        case "service_total":
            return isBacklog && <TableHeaderColumn
                dataField="service_total"
                width="10%"
                dataSort
                dataFormat={dollarFormatter}
                csvFormat={dollarCsvFormatter}
                csvHeader="Service Total"
            >
                Service Total
            </TableHeaderColumn>
        case "man_hour":
            return isBacklog && <TableHeaderColumn
                dataField="man_hour"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Man Hour"
            >
                Man Hour
            </TableHeaderColumn>
        case "status_name":
            return isBacklog && <TableHeaderColumn
                dataField="status_name"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Status Name"
            >
                Status Name
            </TableHeaderColumn>
        case "priority":
            return isBacklog && <TableHeaderColumn
                dataField="priority"
                width="10%"
                dataSort
                dataFormat={textFormatter}
                csvFormat={textCsvFormatter}
                csvHeader="Priority"
            >
                Priority
            </TableHeaderColumn>
        case "proposal_accepted_date":
            return isBacklog && <TableHeaderColumn
                dataField="proposal_accepted_date"
                width="10%"
                dataSort
                dataFormat={dateFormatter}
                csvFormat={dateCsvFormatter}
                csvHeader="Proposal Accepted Date"
            >
                Proposal Accepted Date
            </TableHeaderColumn>
        case "service_type_names":
            return !isBacklog && <TableHeaderColumn
                dataField="service_type_names"
                width="10%"
                dataSort
                dataFormat={serviceTypesFormatter}
                csvFormat={serviceTypesCsvFormatter}
                csvHeader="Service Type Names"
            >
                Service Type Names
            </TableHeaderColumn>
        case "service_totals":
            return !isBacklog && <TableHeaderColumn
                dataField="service_totals"
                width="10%"
                dataSort
                dataFormat={serviceTotalsFormatter}
                csvFormat={serviceTotalsCsvFormatter}
                csvHeader="Service Totals"
            >
                Service Totals
            </TableHeaderColumn>
        case "man_hours":
            return !isBacklog && <TableHeaderColumn
                dataField="man_hours"
                width="10%"
                dataSort
                dataFormat={manHoursFormatter}
                csvFormat={manHoursCsvFormatter}
                csvHeader="Man Hours"
            >
                Man Hours
            </TableHeaderColumn>
        case "status_names":
            return !isBacklog && <TableHeaderColumn
                dataField="status_names"
                width="10%"
                dataSort
                dataFormat={statusFormatter}
                csvFormat={statusCsvFormatter}
                csvHeader="Status Names"
            >
                Status Names
            </TableHeaderColumn>
        case "proposal_accepted_dates":
            return !isBacklog && <TableHeaderColumn
                dataField="proposal_accepted_dates"
                width="10%"
                dataSort
                dataFormat={acceptedDatesFormatter}
                csvFormat={acceptedDatesCsvFormatter}
                csvHeader="Proposal accepted dates"
            >
                Proposal accepted dates
            </TableHeaderColumn>
        case "priorities":
            return !isBacklog && <TableHeaderColumn
                dataField="priorities"
                width="10%"
                dataSort
                dataFormat={prioritiesFormatter}
                csvFormat={prioritiesCsvFormatter}
                csvHeader="Priorities"
            >
                Priorities
            </TableHeaderColumn>
        case "defaultColumn":
            return <TableHeaderColumn
                isKey={true}
                dataField={isBacklog ? "proposal_service_id" : "proposal_id"}
                width="10%"
                hidden={true}
            >
                ID
            </TableHeaderColumn>
        default:
            return <div></div>
    }
}