import React, {useState} from "react";
import {bindActionCreators} from "redux";
import * as actions from "./RevenueReportsApi";
import {connect} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";
import './RevenueReports.scss'
import RevenueReportsTab from "./tabs/RevenueReportsTab";
import CustomerReportTab from "./tabs/CustomerReportTab";
import PaidReportTab from "./tabs/PaidReportTab";
import UnbilledHoursReportTab from "./tabs/UnbilledHoursReportTab";
import InvoicedReportTab from "./tabs/InvoicedReportTab";
import CompletedReport from "./tabs/CompletedReport";

const RevenueReports = ({actions}) => {
    const [key, setKey] = useState(1)

    return (
        <Tabs defaultActiveKey={1}
              animation={false}
              id="revenue_reports"
              onSelect={key => setKey(key)}
              activeKey={key}
        >
            <Tab eventKey={1} title="Revenue Reports">
                {key === 1 && <RevenueReportsTab
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
            <Tab eventKey={2} title="Customer Reports">
                {key === 2 && <CustomerReportTab
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
            <Tab eventKey={3} title="Paid Report">
                {key === 3 && <PaidReportTab
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
            <Tab eventKey={4} title="Unbilled Hours">
                {key === 4 && <UnbilledHoursReportTab
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
            <Tab eventKey={5} title="Invoiced Report">
                {key === 5 && <InvoicedReportTab
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
            <Tab eventKey={6} title="Completed Report">
                {key === 6 && <CompletedReport
                    getRevenueReport={actions.getRevenueReport}
                />}
            </Tab>
        </Tabs>
    )
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(undefined, mapDispatchToProps)(RevenueReports)