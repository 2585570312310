import {
    FETCH_PROPOSAL_SERVICE_STATUSES,
    SELECT_DESTINATION_STATUS_CODE,
    STATUS_FETCH_PROPOSAL_INFO,
    STATUS_FETCH_PS_INFO,
    STATUS_SELECT_PROPOSAL_SERVICE,
    STATUS_UN_SELECT_PROPOSAL_SERVICE,
    UPDATE_PROPOSAL_DATE,
    UPDATE_PROPOSAL_NO_SEARCH_TEXT,
} from "./constants"
import {addAlert} from "../App/actions"
import axios from "axios"
import {browserHistory} from "react-router"
import {apiCall} from "../../common/apiActionHandler";
import moment from "moment"
import {FETCH_PRIORITIES} from "../MapViewPage/constants";

export {addAlert}

export const updateProposalNoSearchText = proposalNoSearchText => {
    return {type: UPDATE_PROPOSAL_NO_SEARCH_TEXT, proposalNoSearchText}
}

export const upsertPromise = (proposalServiceId, startDate, endDate) => (dispatch, getState) => {
    const config = {
        url: `/api/promised_dates`,
        method: 'POST',
        data: {
            proposal_service_id: proposalServiceId,
            start_date: startDate,
            end_date: endDate
        }
    };
    apiCall("Saved Promised Date", config, async config => {
        const response = await axios.request(config);
        dispatch(fetchProposalServicesInfo(response.data.proposal_id, true))
    }, dispatch, getState);
};

const fetchProposalInfo = proposalId => (dispatch, getState) => {
    const store = getState()
    const token = store.auth.token

    const config = {
        url: `/api/proposals/${proposalId}/info`,
        headers: {Authorization: token},
    }
    return axios
        .request(config)
        .then(response => {
            dispatch({
                type: STATUS_FETCH_PROPOSAL_INFO,
                proposalInfo: response.data,
            })
            dispatch(updateProposalNoSearchText(response.data.proposalNo))
            dispatch({
                type: UPDATE_PROPOSAL_DATE,
                newProposalAcceptedDate: response.data.acceptedDate,
            })
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                })
            )
            throw error
        })
}

const fetchProposalServicesInfo = (proposalId, preserveSelectedPs = false) => (
    dispatch,
    getState
) => {
    const store = getState()
    const token = store.auth.token

    const config = {
        url: "/api/proposal_services/info",
        headers: {Authorization: token},
        params: {
            proposal_id: proposalId,
        },
    }

    return axios
        .request(config)
        .then(response => {
            dispatch({
                type: STATUS_FETCH_PS_INFO,
                proposalServicesInfo: response.data,
                selectedPs: preserveSelectedPs
                    ? store.proposalServiceStatus.selectedPs
                    : [],
            })
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: error.toString(),
                    mood: "warning",
                    dismissAfter: 3000,
                })
            )
            throw error
        })
}

export const updateProposalAcceptedOn = () => async (dispatch, getState) => {
    const store = getState()
    const id = store.proposalServiceStatus.proposalInfo.id
    const accepted_date = store.proposalServiceStatus.newProposalAcceptedDate
    const selected_ps = store.proposalServiceStatus.selectedPs
    const config = {
        method: "put",
        url: `/api/proposals/${id}`,
        headers: {Authorization: store.auth.token},
        data: {
            proposal: {accepted_date, selected_ps},
        },
    }

    try {
        const _ = await axios.request(config)
        dispatch(
            addAlert({
                message: "Updated proposal accepted date!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
        dispatch(fetchProposalServicesInfo(store.proposalServiceStatus.proposalInfo.id))
        dispatch(fetchProposalInfo(id))
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update proposal accepted date because " +
                    exception.toString(),
            })
        )
    }
}

export const updateProposalAcceptedDate = newProposalAcceptedDate => dispatch => {
    dispatch({type: UPDATE_PROPOSAL_DATE, newProposalAcceptedDate})
    dispatch(updateProposalAcceptedOn())
}

export const changePsStatus = () => async (dispatch, getState) => {
    const store = getState()
    const {
        destinationStatusCode,
        selectedPs,
        proposalInfo,
        newProposalAcceptedDate,
    } = store.proposalServiceStatus

    if (destinationStatusCode === "-") {
        return dispatch(
            addAlert({
                message: "Please select a valid destination status!",
                dismissAfter: 1500,
            })
        )
    }

    console.log(
        "moving these psids to this status",
        selectedPs,
        destinationStatusCode
    )

    const config = {
        method: "put",
        url: "/api/proposal_services/change_status",
        headers: {Authorization: store.auth.token},
        data: {
            ps_ids: selectedPs.join(","),
            destination_status_code: destinationStatusCode,
            accepted_date: moment().format(),
        },
    }

    try {
        const _ = await axios.request(config)
        dispatch(fetchProposalServicesInfo(proposalInfo.id))
        dispatch(fetchProposalInfo(proposalInfo.id))
        dispatch(
            addAlert({
                message: "Updated Service Status(es)!",
                mood: "success",
                dismissAfter: 1500,
            })
        )
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to update status because " + exception.toString(),
            })
        )
    }
}

export const deleteConsumption = (proposal_service_id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/consumables/delete_ps_consumption`,
        method: 'DELETE',
        data: {
            proposal_service_id,
        }
    };

    apiCall("Saving consumables consumption", config, async config => {
        const result = await axios.request(config);
        callback && callback(result.data);
    }, dispatch, getState, false);
};

export const selectPs = (e, psId) => {
    if (e.target.checked) {
        return {type: STATUS_SELECT_PROPOSAL_SERVICE, psId}
    } else {
        return {type: STATUS_UN_SELECT_PROPOSAL_SERVICE, psId}
    }
}

export const loadProposalInfo = proposalId => dispatch => {
    dispatch(fetchProposalInfo(proposalId))
    dispatch(fetchProposalServicesInfo(proposalId))
}

export const fetchProposalServiceStatuses = all => async (
    dispatch,
    getState
) => {
    const store = getState()
    const config = {
        url: "/api/proposal_service_statuses",
        headers: {Authorization: store.auth.token},
        params: {all},
    }

    try {
        const response = await axios.request(config)
        dispatch({
            type: FETCH_PROPOSAL_SERVICE_STATUSES,
            proposalServiceStatuses: response.data,
        })
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to fetch proposal service statuses because " +
                    exception.toString(),
            })
        )
    }
}

export const selectDestinationStatusCode = destinationStatusCode => {
    return {type: SELECT_DESTINATION_STATUS_CODE, destinationStatusCode}
}

export const searchByProposalNo = () => (dispatch, getState) => {
    const store = getState()
    const proposalNo = store.proposalServiceStatus.proposalNoSearchText

    if (proposalNo === undefined || proposalNo.length < 1) {
        dispatch(
            addAlert({
                message: "Proposal Number is required",
                mood: "warning",
                dismissAfter: 3000,
            })
        )

        return false
    }

    const config = {
        method: "get",
        url: `/api/proposals/`,
        headers: {Authorization: store.auth.token},
        params: {q: proposalNo},
    }

    axios
        .request(config)
        .then(response => {
            const proposalId = response.data.id
            browserHistory.push(`/proposal_service_status/${proposalId}`)
            dispatch({type: "found_proposal_no_whatever"})
        })
        .catch(error => {
            dispatch(
                addAlert({
                    message: "Proposal Number not found",
                    mood: "warning",
                    dismissAfter: 3000,
                })
            )
        })
}


export const updateDeclinedDate = (id, declinedDate, selectedPs) => (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposals/${id}`,
        data: {
            proposal: {declined_date: declinedDate, selected_ps: selectedPs},
        }
    };
    apiCall("Updating declined date", config, async config => {
        const response = await axios.request(config);
        const store = getState()
        dispatch(fetchProposalServicesInfo(store.proposalServiceStatus.proposalInfo.id))
        dispatch(fetchProposalInfo(id))
    }, dispatch, getState);
};
export const fetchPriorities = () => (dispatch, getState) => {
    const store = getState()
    const config = {
        method: "get",
        url: "/api/priorities/search",
        headers: {Authorization: store.auth.token},
    }

    return axios.request(config).then(response => {
        dispatch({type: FETCH_PRIORITIES, priorities: response.data})
    })
}
export const updatePriority = (proposalServiceId, priorityIds, proposalId, callback) => (dispatch, getState) => {
    const config = {
        method: 'PUT',
        url: `/api/proposal_services/${proposalServiceId}`,
        data: {proposal_service: {priority_ids: priorityIds.length > 0 ? priorityIds : [""]}}
    }
    apiCall("Update priority", config, async config => {
        const response = await axios.request(config);
        dispatch(fetchProposalServicesInfo(proposalId, true))
        callback && callback(response.data);
    }, dispatch, getState, false);
}

export const updatePONumberInPS = (ps_id, po_number, callback) => async (dispatch, getState) => {
    const config = {
        method: "put",
        url: `/api/proposal_services/update_po_number`,
        data: {
            id: ps_id,
            po_number
        },
    };

    apiCall("Updating proposal service PO number", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};