import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import * as MyActions from "./MyProposalsApi"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import Select from 'react-select'


import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    Row,
    Well,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"

import moment from "moment/moment"
import {saveNewCsvDownload, defaultDateFormat, defaultDateFormatShort, select} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";

const isMobile = window.screen.width <= 1024;

const Actions = {...MyActions, saveNewCsvDownload}

class MyProposals extends ResourceComponent {
    state = {
        resource: {},
        myProposals: []
    }

    tableRef = React.createRef()

    componentDidMount() {
        this.props.actions.load(response => this.setState(response))
    }

    render() {
        const {salesArboristId, proposalProposedStart, proposalProposedEnd, status} = this.state.resource
        const {myProposals, salesArborists} = this.state

        return (
            <Grid fluid>
                <Helmet title="My Proposals"/>
                <form onSubmit={this.fetchMyProposals}>
                    <Row>
                        <Col md={6}>
                            <h3 className="text-primary" style={{marginBottom: "0px", marginTop: "0px"}}>
                                {`My Proposals ${myProposals ? `${myProposals.length} found.` : "Pick some dates!"} `}
                            </h3>
                            <FormGroup bsSize="sm">
                                <ControlLabel>
                                    {" "}&nbsp;
                                </ControlLabel>
                                <br/>
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={proposalProposedStart ? moment(proposalProposedStart) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'Start Date'}}
                                    onChange={this.dateResourceAttr('proposalProposedStart')}/>
                                {" "}
                                <span className='text-default'><Glyphicon glyph='minus'/></span>
                                {" "}
                                <Datetime
                                    dateFormat={defaultDateFormat}
                                    isClearable
                                    timeFormat={null}
                                    value={proposalProposedEnd ? moment(proposalProposedEnd) : ""}
                                    className="text-center"
                                    inputProps={{placeholder: 'End Date'}}
                                    onChange={this.dateResourceAttr('proposalProposedEnd')}/>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            name="sales-arborist-select"
                                            value={select(salesArborists, salesArboristId)}
                                            options={salesArborists}
                                            onChange={this.selectResourceAttr('salesArboristId')}
                                            placeholder="Sales Arborist"
                                    />
                                </Col>
                                <Col md={2}>
                                    <FormControl
                                        value={status}
                                        componentClass="select"
                                        onChange={e => {
                                            let {resource} = this.state;
                                            resource.status = e.target.value;
                                            this.setState(resource);
                                        }}
                                    >
                                        <option value="all">All</option>
                                        <option value="open">Open</option>
                                        <option value="closed">Closed</option>
                                    </FormControl>
                                </Col>
                                {' '}
                                <Button bsSize="small" type="submit" bsStyle="success">
                                    Find Proposals
                                </Button>
                            </FormGroup>
                            {/*jank way of valigning button to bottom*/}
                            <FormGroup bsSize="sm">
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {myProposals ?
                                <Well style={{paddingBottom: "0px", marginBottom: "0px", paddingTop: "5px"}}>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={4} className="text-left text-primary">
                                                    {myProposals ? "Close Rate " : null}
                                                    <br/>
                                                    {myProposals ? "By Number: " : null}
                                                    <span>
                            {myProposals
                                ?
                                Math.floor((myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0) / myProposals.length) * 100) + "%"
                                : null}
                            </span>
                                                    <br/>
                                                    {myProposals ? "By Dollars: " : null}
                                                    <span>
                            {myProposals
                                ?
                                Math.floor((
                                    myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + parseInt(p.acceptedTotal, 10), 0)
                                    /
                                    myProposals.reduce((sum, p) => sum + parseInt(p.proposalTotal, 10), 0) * 100))
                                +
                                "%"
                                : null}
                              </span>
                                                </Col>
                                                <Col md={2} className="text-right text-warning">
                                                    <Row>
                                                        {myProposals ? `Bids: ${myProposals.length}` : null}
                                                        <br/>
                                                        {myProposals
                                                            ? <Dollars
                                                                amount={parseFloat(
                                                                    myProposals.reduce((sum, p) => sum + p.proposalTotal, 0),
                                                                    10
                                                                )}
                                                            />
                                                            : null}
                                                    </Row>
                                                    <Row>
                                                        {myProposals ? `Tree` : null}
                                                        <br/>
                                                        {myProposals
                                                            ? <Dollars
                                                                amount={parseFloat(
                                                                    myProposals.reduce((sum, p) => sum + p.proposalTreeTotal, 0),
                                                                    10
                                                                )}
                                                            />
                                                            : null}
                                                    </Row>
                                                    <Row>
                                                        {myProposals ? `PHC` : null}
                                                        <br/>
                                                        {myProposals
                                                            ? <Dollars
                                                                amount={parseFloat(
                                                                    myProposals.reduce((sum, p) => sum + p.proposalPhcTotal, 0),
                                                                    10
                                                                )}
                                                            />
                                                            : null}
                                                    </Row>
                                                </Col>
                                                <Row>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col md={6} className="text-right text-info">
                                                                {myProposals ? `Closed: ${myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0)}` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.acceptedTotal, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                            <Col md={6} className="text-right text-success"
                                                                 style={{paddingRight: "30px"}}>
                                                                {myProposals ? `Open: ${myProposals.reduce((a, b) => b.acceptedDate === null ? ++a : a, 0)}` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.reduce((sum, p) => sum + (p.proposalTotal - p.acceptedTotal), 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6} className="text-right text-info">
                                                                {myProposals ? `Tree` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.filter((accepted) => accepted.acceptedDate !== null).reduce((sum, p) => sum + p.acceptedTree, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                            <Col md={6} className="text-right text-success"
                                                                 style={{paddingRight: "30px"}}>
                                                                {myProposals ? `Tree` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.reduce((sum, p) => sum + (p.proposalTreeTotal - p.acceptedTree), 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6} className="text-right text-info">
                                                                {myProposals ? `PHC` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.reduce((sum, p) => sum + p.acceptedPHC, 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                            <Col md={6} className="text-right text-success"
                                                                 style={{paddingRight: "30px"}}>
                                                                {myProposals ? `PHC` : null}
                                                                <br/>
                                                                {myProposals ?
                                                                    <Dollars
                                                                        amount={parseFloat(myProposals.reduce((sum, p) => sum + (p.proposalPhcTotal - p.acceptedPHC), 0),
                                                                            10
                                                                        )}
                                                                    />
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <h6 className="text-left text-danger">
                                                        {myProposals ?
                                                            "Declined Services: "
                                                            : null}
                                                        {myProposals ?
                                                            <Dollars
                                                                amount={parseFloat(myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.declinedServiceTotal, 0),
                                                                    10
                                                                )}
                                                            />
                                                            : null}
                                                    </h6>
                                                </Col>
                                                <Col md={8}>
                                                    <h6 className="text-right text-danger">
                                                        {myProposals && status === "all" ?
                                                            (myProposals.filter((openP) => openP.acceptedDate === null).reduce((sum, p) => sum + p.age, 0)
                                                                /
                                                                myProposals.reduce((a, b) => b.acceptedDate === null ? ++a : a, 0)).toFixed(1)
                                                            : null}
                                                        {myProposals && status === "all" ?
                                                            " average Age "
                                                            : null}
                                                        {myProposals && status === "all" ? `for ${myProposals.reduce((a, b) => b.acceptedDate === null ? ++a : a, 0)} open bids`
                                                            : null}
                                                    </h6>
                                                    <h6 className="text-right text-danger">
                                                        {myProposals ?
                                                            (myProposals.filter((openP) => openP.acceptedDate !== null).reduce((sum, p) => sum + p.age, 0)
                                                                /
                                                                myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0)).toFixed(1)
                                                            : null}
                                                        {myProposals ?
                                                            " average Days To Close "
                                                            : null}
                                                        {myProposals ? `for ${myProposals.reduce((a, b) => b.acceptedDate !== null ? ++a : a, 0)} won bids`
                                                            : null}
                                                    </h6>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Well>
                                :
                                null
                            }
                        </Col>

                    </Row>
                </form>
                <p>&nbsp;</p>

                <Row>
                    <Col md={12}>
                        {myProposals ? this._renderTable(myProposals) : null}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}/>
                </Row>

            </Grid>
        )
    }

    fetchMyProposals = e => {
        const {resource} = this.state;
        e.preventDefault()
        this.props.actions.fetchMyProposals(resource, response => this.setState({myProposals: response}))
    }

    _filterRenewalsBy = kind => {
        this.props.actions.filterRenewalsBy(kind)
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _dollarFormatter = cell => <Dollars amount={cell}/>

    _csvDollarFormatter = cell => {
        return parseFloat(cell ? cell : 0).toFixed(2)
    }

    _phoneFormatter = (cell) => {
        return cell && isMobile ? <a media="screen" href={`tel:${cell.replace(/\\D/g, '')}`}>
        {cell}
    </a> : <>{cell}</>}

    _renderTable = data => {
        const options = {
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            page: 1,  //which page you want to show as default
            sizePerPageList: [{
                    text: '10', value: 10
                }, {
                    text: '25', value: 25
                }, {
                        text: '30', value: 30
                }, {
                        text: '50', value: 50
                }, {
                        text: '100', value: 100
                }, {
                        text: '200', value: 200
            }],
            //you can change the dropdown list for size per page
            sizePerPage: 50,  //which size per page you want to locate as default
            paginationSize: 3,  //the pagination bar size.
        }
        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                options={options}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={true}
                csvFileName={() => {
                    const fileName = `proposals-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "my proposals")
                    }
                    return fileName
                }}
            >
                <TableHeaderColumn
                    dataField="name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._linkFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Name
                </TableHeaderColumn>

                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="arborist"
                    dataSort={true}
                >
                    Arborist
                </TableHeaderColumn>

                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="siteAddress"
                    dataSort={true}
                >
                    Site Address
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="proposedDate"
                    dataFormat={this._dateFormatter}
                    csvFormat={this._dateFormatter}
                    dataSort={true}>
                    Proposal Date
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="acceptedDate"
                    dataFormat={this._dateFormatter}
                    csvFormat={this._dateFormatter}
                    dataSort={true}>
                    Accepted Date
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="proposalNo"
                    dataFormat={this._proposalLinkFormatter}
                    isKey={true}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Proposal No
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="proposalTotal"
                    dataFormat={this._dollarFormatter}
                    csvFormat={this._csvDollarFormatter}
                    dataSort={true}>
                    Proposal Total
                </TableHeaderColumn>

                <TableHeaderColumn
                    tdStyle={{whiteSpace: 'normal', wordBreak: 'break-word'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataField="email"
                    dataSort={true}>
                    Email
                </TableHeaderColumn>

                <TableHeaderColumn dataField="phone" dataSort={true} dataFormat={this._phoneFormatter}>
                    Contact Phone
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="age"
                    dataSort={true}>
                    Age
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }

    _optCustomer = e => {
        const customerId = e.target.value
        const optedOut = e.target.checked
        this.props.actions.optCustomer(customerId, optedOut)
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposalId}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposalId}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposalId}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _acceptFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'><Glyphicon glyph='ok'/></span>
        </Link>
    )

    _blankFormatter = (cell, row) => (
        <Link to={`/proposal_service_status/${row.proposalId}`}>
            <span className='text-success'></span>
        </Link>
    )
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProposals)
