import {Button} from "react-bootstrap";
import React from "react";
import {Link} from "react-router";

export const FilterButton = ({filtersArrays, setShowFilterModal, getFilters, setFiltersArrays}) => {
    return <Button
        bsSize="small"
        className="ml-6"
        bsStyle="success"
        onClick={() => {
            if (filtersArrays) {
                setShowFilterModal(true)
            } else {
                getFilters(res => {
                    setFiltersArrays(res)
                    setShowFilterModal(true)
                })
            }
        }}
    >
        Show filter modal
    </Button>
}

export const ColumnsButton = ({setShowDock}) => {
    return <Button
        bsSize="small"
        bsStyle="success"
        className="ml-6"
        onClick={() => setShowDock(true)}
    >
        Show columns
    </Button>
}

export const DeclinedButton = ({setIsDeclinedModalOpened, selectedRecords}) => {
    return <Button
        bsSize="small"
        className="ml-6"
        onClick={() => setIsDeclinedModalOpened(true)}
        disabled={selectedRecords.length === 0}
    >
        Decline Selected
    </Button>
}

export const EmailButton = ({selectedRecords, tab, setEmailModalOpened}) => {
    return <Button
        bsSize="small"
        className="ml-6"
        onClick={() => {
            const selectedProposalIds = selectedRecords.map(e => e.proposal_id)

            setEmailModalOpened({
                show: true,
                data: {
                    recipients: selectedProposalIds,
                    selectedRows: {
                        records: selectedProposalIds,
                        status: tab
                    }
                }
            })
        }}
        disabled={selectedRecords.length === 0}
    >
        Email Selected
    </Button>
}

export const MapSchedulerButton = ({selectedRecords}) => {
    return <Link to={{pathname: selectedRecords.length === 0 ? "" : "/map_scheduler", state: {ids: selectedRecords.map(e => e.proposal_service_id)}}}>
                <Button
                    bsSize="small"
                    className="ml-6"
                    disabled={selectedRecords.length === 0}
                >
                    Map Scheduler
                </Button>
            </Link>
}