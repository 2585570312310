import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormControl, Glyphicon, Grid, Panel, Row} from "react-bootstrap";
import Select from "react-select";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as actions from "./ProposalCostApi";
import {defaultDateFormat, convertMillisToTime, hhMM, saveNewCsvDownload, select} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {debounce} from "throttle-debounce";
import Datetime from "react-datetime";
import moment from "moment-timezone"
import {browserHistory, Link} from "react-router";
import "./ProposalCost.scss"
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import _ from 'lodash'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import {dateNoTimeFormatter, dollarsFormatter} from "../../components/BootStrapTableCellFormatters";
import {dollar} from "../../components/Dollars";

const isMobile = window.screen.width <= 1024;

const fullDaySeconds = 86400

class ProposalCost extends ResourceComponent {
    state = {
        filterVisible: true,
        time_tracks: [],
        resource: {label: null},
        employees: [],
        center: true,
        time_track_types: [],
        time_track_id_to_update: null,
        proposals: [],
        work_orders: [],
        cost_info_key: null,
    };

    tableRef = React.createRef()

    stopTimeTracks = (timeTrackIds) => {
        console.log('stop timer')
        let {time_tracks} = this.state;
        const now = moment();
        const timeString = now.format('HH:mm:ss');
        const to = moment.duration(timeString).asSeconds();
        this.props.actions.stopTimeTracks(timeTrackIds, now, (res) => {
            time_tracks = time_tracks.map(tt => {
                if (timeTrackIds.includes(tt.id)) {
                    tt.time_to = to
                    const diff = to - tt.time_from;
                    tt.total_seconds = moment.duration(diff * 1000).asSeconds();
                    return tt
                } else {
                    return tt
                }
            })
            this.setState({time_tracks});
        })
    }

    rowFormatter = (cell, row) => {
        return <div>
            <Row>
                <Col xs={6}>Resource</Col>
                <Col xs={6}>{this.repFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Work Order #</Col>
                <Col xs={6}>{this.workOrderNoFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Time Type</Col>
                <Col xs={6}>{this.typeFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Date</Col>
                <Col xs={6}>{this.dateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Start</Col>
                <Col xs={6}>{this.timeStartFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Stop</Col>
                <Col xs={6}>{this.timeStopFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Total time</Col>
                <Col xs={6} className='text-center padding-top-10'>{this.totalTimeFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6} className='text-center padding-top-10'>{this.optionsFormatter(cell, row)}</Col>
            </Row>
        </div>
    };

    repFormatter = (cell, row) => {
        const {employees, time_tracks} = this.state;
        const employee = employees.find(e => e.value === row.person_id);
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        return <Select className="Select" classNamePrefix="select"
                       options={employeeOptions}
                       isClearable
                       value={select(employeeOptions, row.person_id)}
                       onChange={e => {
                           row.person_id = e && e.value;
                           this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                               this.delayedSave();
                           })
                       }
                       }
        />
    };

    timeStartFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return <TimePicker
            showSecond={false}
            value={moment.utc(row.time_from * 1000)}
            onChange={e => {
                if (moment.duration(e.format('HH:mm:ss')).asSeconds() < row.time_to) {
                    row.time_from = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                    this.setState({invalidDate: false, changesMade: true})
                } else {
                    this.setState({invalidDate: true, changesMade: false})
                }
            }}
            onClose={() => {
                if (!this.state.invalidDate && this.state.changesMade) {
                    const diff = row.time_to - row.time_from;
                    row.total_seconds = moment.duration(diff * 1000).asSeconds();
                    this.setState({time_tracks, time_track_id_to_update: row.id}, this.delayedSave);
                }
            }
            }
            format={'h:mm:ss a'}
            use12Hours
            allowEmpty={false}
            disabledHours={() => this.getDisabledHours(row.time_from, row.time_to, 'higher')}
            disabledMinutes={() => this.getDisabledMinutes(row.time_from, row.time_to, 'higher')}
        />
    };

    timeStopFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return row.time_to ? <TimePicker
                showSecond={false}
                value={moment.utc(row.time_to * 1000)}
                onChange={e => {
                    if (moment.duration(e.format('HH:mm:ss')).asSeconds() > row.time_from) {
                        row.time_to = moment.duration(e.format('HH:mm:ss')).asSeconds() % fullDaySeconds
                        this.setState({invalidDate: false, changesMade: true})
                    } else {
                        this.setState({invalidDate: true, changesMade: false})
                    }

                }}
                onClose={() => {
                    if (!this.state.invalidDate && this.state.changesMade) {
                        const diff = row.time_to - row.time_from;
                        row.total_seconds = moment.duration(diff * 1000).asSeconds();
                        this.setState({time_tracks, time_track_id_to_update: row.id}, this.delayedSave);
                    }
                }
                }
                format={'h:mm:ss a'}
                use12Hours
                allowEmpty={false}
                disabledHours={() => this.getDisabledHours(row.time_to, row.time_from, 'lower')}
                disabledMinutes={() => this.getDisabledMinutes(row.time_to, row.time_from, 'lower')}
            /> :
            <Button className='btn btn-danger'
                    onClick={() => this.stopTimeTracks([row.id])}>
                Stop
            </Button>
    };

    totalTimeFormatter = (cell, row) => {
        let total
        total = row.total_seconds > 0 ? convertMillisToTime(row.total_seconds * 1000) : 'In progress'
        return total;
    }

    handleProposalIdChange = (id) => {
        const {resource} = this.state;
        resource.id = id;
        this.setState(resource, () => {
            if (resource.id) {
                browserHistory.push(`/proposal_cost/${resource.id}`);
                this.loadWorkOrders();
                this.delayedSearch();
            } else {
                this.setState({workOrders: []});
                browserHistory.push(`/proposal_cost`);
            }
        })
    };

    optionsFormatter = (cell, row) => {
        return <div className={!isMobile && 'vcenter'}><Link onClick={() => {
            const choice = window.confirm('Are you sure?');
            if (choice) {
                this.props.actions.remove(cell, this.reload);
            }
        }} className={`pointer small-margin`}>
            <FontAwesomeIcon icon="trash-alt" className="bigger"/>
        </Link>
            <Link onClick={() => this.duplicateTimeTrack(row)} className={`pointer`}>
                <Glyphicon glyph="duplicate" className="bigger"/>
            </Link></div>;
    };

    typeFormatter = (cell, row) => {
        const {time_track_types} = this.state;
        return <Select className="Select"
                       classNamePrefix="select"
                       options={time_track_types}
                       value={select(time_track_types, row.time_track_type_id)}
                       placeholder="Type"
                       onChange={e => {
                           let {time_tracks} = this.state;
                           row.time_track_type_id = e !== null && e.value;
                           this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                               this.delayedSave();
                           })
                       }}/>
    };

    dateFormatter = (cell, row) => {
        let {time_tracks} = this.state;
        return <Datetime
            dateFormat={defaultDateFormat}
            timeFormat={false}
            defaultValue={moment(row.track_date)}
            value={moment(row.track_date)}
            className="full-width table-auto"
            inputProps={{placeholder: 'Date'}}
            onChange={e => {
                row.track_date = e;
                this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                    this.delayedSave();
                });
            }}/>
    };

    saveTimeTracks = () => {
        const {time_tracks, time_track_id_to_update, resource, work_orders} = this.state;
        const time_track = time_tracks.find(track => track.id === time_track_id_to_update);
        this.props.actions.saveTimeTracks(
            {
                id: time_track.id,
                person_id: time_track.person_id,
                time_from: time_track.time_from,
                time_to: time_track.time_to,
                time_track_type_id: time_track.time_track_type_id,
                total_seconds: time_track.total_seconds,
                track_date: time_track.track_date,
                proposal_id: resource.id,
                work_order_id: time_track.work_order_id
            }, this.reload)
    };

    constructor(props, context) {
        super(props, context);
        this.proposalSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.debouncedSearch = debounce(500, this.search);
        this.delayedSearch = () => {
            this.setState({loading: true});
            this.debouncedSearch();
        }
        this.debouncedSave = debounce(500, this.saveTimeTracks);
        this.delayedSave = () => {
            this.setState({loading: true});
            this.debouncedSave();
        };
    }

    componentDidMount() {
        this.proposalSearch = this.buildSearchComponent('proposals', this.props.actions.searchForProposal);
        this.reload();
    }

    reload = () => {
        let {resource} = this.state;
        const {params} = this.props;
        this.setState({loading: true})
        this.props.actions.load(resource.id, result => this.setState({...result}, () => {
            if (params.id) {
                resource.id = parseInt(params.id);
            }
            this.setState(resource, () => {
                if (resource.id) {
                    this.loadWorkOrders();
                    this.delayedSearch();
                }
                this.setState({cost_info_key: new Date()})
            });

        }));
    };

    loadWorkOrders = () => {
        const {resource} = this.state;
        this.props.actions.getWorkOrders(resource.id, work_orders => {
            this.setState({work_orders})
        });
    };
    handleShowFilter = () => {
        this.setState({filterVisible: !this.state.filterVisible})
    }

    addTimeTrack = () => {
        const {time_track_types, resource, work_orders} = this.state;
        let {time_tracks} = this.state;
        const startOfDay = moment().startOf('day');
        const diffFrom = moment().startOf('day').hours(8) - startOfDay;
        const diffTo = moment().startOf('day').hours(9) - startOfDay;
        const from = moment.duration(diffFrom).asSeconds();
        const to = moment.duration(diffTo).asSeconds();
        const type = time_track_types[0];
        const work_order_id = work_orders[0];
        const newTimeTrack = {
            person_id: null,
            track_date: moment(),
            time_from: from,
            time_to: to,
            total_seconds: 60 * 60,
            time_track_type_id: type.value,
            proposal_id: resource.id,
            work_order_id: work_order_id.value,
            work_order_no: work_order_id.label
        };
        time_tracks.push(newTimeTrack);
        this.setState({time_tracks, time_track_id_to_update: newTimeTrack.id}, this.delayedSave)
    };

    workOrderNoFormatter = (cell, row) => {
        const {work_orders} = this.state;
        return <Select className="Select"
                       classNamePrefix="select"
                       value={select(work_orders, row.work_order_id)}
                       options={work_orders}
                       placeholder="Work Order #"
                       onChange={e => {
                           let {time_tracks} = this.state;
                           row.work_order_id = e !== null && e.value;
                           row.work_order_no = e !== null && e.label;
                           this.setState({time_tracks, time_track_id_to_update: row.id}, () => {
                               this.delayedSave();
                           })
                       }}/>
    };

    duplicateTimeTrack(row) {
        const {time_tracks} = this.state;
        const duplicate = {
            person_id: row.person_id,
            track_date: row.track_date,
            time_from: row.time_from,
            time_to: row.time_to,
            total_seconds: row.total_seconds,
            time_track_type_id: row.time_track_type_id,
            proposal_id: row.id,
            work_order_id: row.work_order_id,
            work_order_no: row.work_order_no
        };
        time_tracks.push(duplicate);
        this.setState({time_tracks, time_track_id_to_update: duplicate.id}, this.delayedSave)
    };

    search() {
        const {resource} = this.state;
        this.props.actions.getProposalCost({...resource}, results => {
            this.setState({...results, loading: false})
        })
    }

    timeTracksCSVFormatter = (array) => {
        return array.map(data => {
            Object.keys(data).forEach(key => {
                if (['id', 'updated_at', 'work_order_id', 'proposal_id', 'latitude', 'longitude', 'customer_id'].includes(key)) {
                    delete data[key]
                } else if (['person_id'].includes(key)) {
                    data[key] = select(this.state.employees.filter(x => x.label !== 'Not assigned'), data[key])?.label
                } else if (['track_date'].includes(key)) {
                    data[key] = dateNoTimeFormatter(data[key])
                } else if (['time_track_type_id'].includes(key)) {
                    data[key] = select(this.state.time_track_types, data[key])?.label
                } else if (['total_seconds', 'actual_wo_time'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000) : 'In progress'
                } else if (['time_from', 'time_to'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000) : 'N/A'
                } else if (['est_wo_time'].includes(key)) {
                    data[key] = data[key] > 0 ? convertMillisToTime(data[key] * 1000 * 60 * 60) : 'N/A'
                } else if (['pmh', 'estimated_total'].includes(key)) {
                    data[key] = data[key] ? dollar(data[key]) : 'N/A'
                } else {
                    data[key] = data[key]
                }
            })
            return data
        })
    }
    createCustomToolBar = props => {
        const {time_tracks} = this.state
        return (
            <div className='table-toolbar ml-15 mr-15'>
                <div className='buttons'>
                    {props.components.btnGroup}
                    <Button
                        bsStyle="success"
                        disabled={time_tracks.length < 1}
                        onClick={async () => {
                            const timeTracks = this.timeTracksCSVFormatter(_.cloneDeep(time_tracks))

                            const workbook = new ExcelJS.Workbook();
                            const worksheet = workbook.addWorksheet('Time Tracks');

                            worksheet.addRow(['Employee',
                                'Est Time',
                                'Actual WO Time',
                                'Total Time',
                                'Time Track Type',
                                'Track Date',
                                'Time From',
                                'Time To',
                                'Proposal No',
                                'Work Order No',
                                'Estimated Total',
                                'PMH',
                            ]);

                            timeTracks.forEach((track) => {
                                worksheet.addRow([
                                    track.person_id,
                                    track.est_wo_time,
                                    track.actual_wo_time,
                                    track.total_seconds,
                                    track.time_track_type_id,
                                    track.track_date,
                                    track.time_from,
                                    track.time_to,
                                    track.proposal_no,
                                    track.work_order_no,
                                    track.estimated_total,
                                    track.pmh,
                                ]);
                            });

                            worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
                                if (rowNumber === 1) {
                                    row.eachCell((cell) => {
                                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'ffb2b2b2'}};
                                        cell.font = {bold: true};
                                    })
                                }
                                if (rowNumber > 1) {
                                    const cellValueB = row.getCell(2).value;
                                    const cellValueC = row.getCell(3).value;

                                    const actTime = moment.duration(cellValueC, 'seconds')
                                    const positiveTime = moment.duration(cellValueB, 'seconds').add(30, 'minutes')
                                    const moderateTime = moment.duration(cellValueB, 'seconds').add(60, 'minutes')
                                    if (actTime.asSeconds() < positiveTime.asSeconds()) {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffa4ffa4'}
                                            };
                                        });
                                    } else if (actTime.asSeconds() < moderateTime.asSeconds()) {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffd2b48c'}
                                            };
                                        });
                                    } else {
                                        row.eachCell((cell) => {
                                            cell.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: {argb: 'ffff6347'}
                                            };
                                        });
                                    }
                                }
                            });
                            worksheet.columns.forEach(column => {
                                const lengths = column.values.map(v => v.toString().length);
                                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                                column.width = maxLength;
                            });

                            const excelBuffer = await workbook.xlsx.writeBuffer();
                            const dataBlob = new Blob([excelBuffer], {type: 'application/octet-stream'});
                            saveAs(dataBlob, 'time-tracks.xlsx');
                            dataBlob.name = 'time-tracks.xlsx'
                            this.props.actions.saveDownloadedFile(dataBlob, null, "proposal cost")
                        }}>
                        <i class="fa glyphicon glyphicon-export fa-download"/> Export worksheet
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {
            filterVisible, time_tracks, employees, resource,
            time_track_types, service_price, service_hours,
            full_name, full_address, site_address, customer_id, work_orders
        } = this.state;
        const proposalsOptions = this.proposalSearch.searchOptions();
        let actual_hours = time_tracks && time_tracks.length
            && time_tracks.map(item => item.total_seconds).reduce((prev, next) => prev + next);
        let nonBillTime = [];
        time_track_types && time_track_types.length && time_track_types.forEach(track => {
            if (track.label !== 'Billable') {
                let type = {};
                type.name = track.label;
                type.value = time_tracks.filter(t => t.time_track_type_id === track.value)
                    .filter(t => t.time_track_type_id === track.value)
                    .map(item => item.total_seconds || 0)
                    .reduce((prev, next) => prev + next, 0);
                nonBillTime.push(type);
            }
        });
        nonBillTime.push({
            name: 'Others',
            value: time_tracks
                .filter(t => !t.time_track_type_id)
                .map(item => item.total_seconds || 0)
                .reduce((prev, next) => prev + next, 0)
        });
        const label = select(proposalsOptions, resource.id) && select(proposalsOptions, resource.id).label;
        return (
            <Grid fluid id='proposal-cost'>
                <ProposalMenu
                    reloadKey={this.state.cost_info_key}
                    id={resource.id}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                    onShowFilter={this.handleShowFilter}
                />
                <Row>
                    <Panel
                        collapsible
                        expanded={filterVisible}
                    >
                        <div>
                            <Row>
                                <Col md={3}>
                                    <FormControl
                                        name="q"
                                        placeholder="Search..."
                                        value={resource.q}
                                        onChange={e => this.updateResourceAttr(e, this.delayedSearch)}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(employees, resource.person_id)}
                                            options={employees}
                                            isClearable
                                            onChange={this.selectResourceAttr('person_id', this.delayedSearch)}
                                            placeholder="Employee"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(time_track_types, resource.time_track_type_id)}
                                            options={time_track_types}
                                            isClearable
                                            onChange={this.selectResourceAttr('time_track_type_id', this.delayedSearch)}
                                            placeholder="Type"
                                    />
                                </Col>
                                <Col md={2}>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(work_orders, resource.work_order_id)}
                                            options={work_orders}
                                            placeholder="Work Order #"
                                            isClearable
                                            onChange={this.selectResourceAttr('work_order_id', this.delayedSearch)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Row>
                {resource.id && <div className={`table-container ${this.state.loading ? 'loading' : ''}`}>
                    {isMobile ?
                        <Row>
                            <Col xs={12} className="full-height">
                                <Row className='bottom10'>
                                    <Col md={2}>
                                        <button type="submit" className="btn btn-success" onClick={this.addTimeTrack}
                                                disabled={!resource.id || _.isEmpty(work_orders)}>
                                            New row +
                                        </button>
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    ref={this.tableRef}
                                    data={time_tracks}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    exportCSV={false}
                                    remote
                                    className='wrapped full-height'
                                    csvFileName={() => {
                                        const fileName = `proposal-cost-${moment().format()}.csv`
                                        if (this.tableRef?.current) {
                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "proposal cost")
                                        }
                                        return fileName
                                    }}
                                    options={{
                                        toolBar: this.createCustomToolBar,
                                    }}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        width={"5%"}
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        dataFormat={this.rowFormatter}
                                        width={"20%"}
                                    >
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={12} className="full-height">
                                <Row className='bottom10'>
                                    <Col md={2}>
                                        <button type="submit" className="btn btn-success" onClick={this.addTimeTrack}
                                                disabled={!resource.id || _.isEmpty(work_orders)}>
                                            New row +
                                        </button>
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    ref={this.tableRef}
                                    data={time_tracks}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    exportCSV={false}
                                    remote
                                    className="wrapped"
                                    trClassName={this.selectedMarkerClass}
                                    csvFileName={() => {
                                        const fileName = `proposal-cost-${moment().format()}.csv`
                                        if (this.tableRef?.current) {
                                            this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "proposal cost")
                                        }
                                        return fileName
                                    }}
                                    options={{
                                        toolBar: this.createCustomToolBar,
                                    }}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        width={"5%"}
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        dataFormat={this.repFormatter}
                                        width={"20%"}
                                    >
                                        Resource
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="work_order_no"
                                        dataFormat={this.workOrderNoFormatter}
                                        width={"15%"}
                                    >
                                        Work Order #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width={"15%"}
                                        dataFormat={this.typeFormatter}
                                    >
                                        Time Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="updated_at"
                                        dataFormat={this.dateFormatter}
                                        width={"15%"}
                                    >
                                        Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="time_from"
                                        dataFormat={this.timeStartFormatter}
                                        width={"15%"}
                                    >
                                        Start
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="time_to"
                                        dataFormat={this.timeStopFormatter}
                                        width={"15%"}
                                    >
                                        Stop
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataFormat={this.totalTimeFormatter}
                                        width={"10%"}
                                    >
                                        Total time
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="estimated_total"
                                        width={"15%"}
                                        dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                    >
                                        Estimated Total
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="pmh"
                                        width={"15%"}
                                        dataFormat={(cell) => dollarsFormatter(cell ? cell.replace(/[^0-9.-]/g, '') : cell)}
                                    >
                                        PMH
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataFormat={this.optionsFormatter}
                                        dataField="id"
                                        width={"10%"}
                                    >
                                        Options
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>}
                </div>}
            </Grid>
        );
    }
}

ProposalCost.propTypes = {
    //myProp: PropTypes.object.isRequired
};

ProposalCost.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, saveNewCsvDownload}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalCost);
