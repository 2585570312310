import React from 'react';
import PropTypes from "prop-types"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ResourceComponent from "../../../components/ResourceComponent";
import createApiService from "../../../common/clientActionsBuilder";
import {Button, Clearfix, Col, ControlLabel, Form, FormControl, FormGroup, Grid, Row} from "react-bootstrap";

const api = createApiService('clients', 'client', 'Client');

class Client extends ResourceComponent {

    state = {resource: {}};

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.props.actions.get(this.props.id, resource => this.setState({resource}));
    }

    render() {
        const {resource} = this.state;
        return (
            <Grid fluid className="top25">
                <Form horizontal onSubmit={e => {
                    e.preventDefault();
                    this.props.actions.save(resource);
                }}>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col mdOffset={4}>
                                    <Col md={8}>
                                        <h4>Organization Info</h4>
                                    </Col>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Company Name
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="name"
                                        onChange={this.updateResourceAttr}
                                        value={resource.name}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Address 1
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="address_1"
                                        onChange={this.updateResourceAttr}
                                        value={resource.address_1}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Address 2
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="address_2"
                                        onChange={this.updateResourceAttr}
                                        value={resource.address_2}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9} xsHidden smHidden>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    City / State / Zip
                                </Col>
                                <Col md={4} className="small-right-padding">
                                    <FormControl
                                        name="city"
                                        onChange={this.updateResourceAttr}
                                        value={resource.city}
                                    />
                                </Col>
                                <Col md={2} className="small-padding">
                                    <FormControl
                                        name="state"
                                        onChange={this.updateResourceAttr}
                                        value={resource.state}
                                    />
                                </Col>
                                <Col md={2} className="small-left-padding">
                                    <FormControl
                                        name="zip"
                                        onChange={this.updateResourceAttr}
                                        value={resource.zip}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Clearfix visibleXsBlock visibleSmBlock>
                            <Col md={9}>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} md={4}>
                                        City
                                    </Col>
                                    <Col md={8}>
                                        <FormControl
                                            name="city"
                                            onChange={this.updateResourceAttr}
                                            value={resource.city}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={9}>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} md={4}>
                                        State
                                    </Col>
                                    <Col md={8}>
                                        <FormControl
                                            name="state"
                                            onChange={this.updateResourceAttr}
                                            value={resource.state}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={9}>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} md={4}>
                                        Zip
                                    </Col>
                                    <Col md={8}>
                                        <FormControl
                                            name="zip"
                                            onChange={this.updateResourceAttr}
                                            value={resource.zip}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Clearfix>
                    </Row>
                    <Row>
                        <Col md={9} xsHidden smHidden>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Country / County
                                </Col>
                                <Col md={4} className="small-right-padding">
                                    <FormControl
                                        name="country"
                                        onChange={this.updateResourceAttr}
                                        value={resource.country}
                                    />
                                </Col>
                                <Col md={4} className="small-left-padding">
                                    <FormControl
                                        name="county"
                                        onChange={this.updateResourceAttr}
                                        value={resource.county}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Phone
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="phone_number"
                                        onChange={this.updateResourceAttr}
                                        value={resource.phone_number}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Email
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="email_address"
                                        onChange={this.updateResourceAttr}
                                        value={resource.email_address}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Special Info For Forms
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="web_url"
                                        placeholder="Keep license numbers or anything you may want to have on a document here"
                                        onChange={this.updateResourceAttr}
                                        value={resource.web_url}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <FormGroup>
                                <Col componentClass={ControlLabel} md={4}>
                                    Entity ID
                                </Col>
                                <Col md={8}>
                                    <FormControl
                                        name="entity_id"
                                        onChange={this.updateResourceAttr}
                                        value={resource.entity_id}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col mdOffset={3} md={1}>
                            <Button
                                block
                                type="submit"
                                bsStyle="success"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Grid>
        );
    }
}

Client.propTypes = {
    id: PropTypes.string.isRequired
};

Client.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Client);