import React, {Component} from "react"
import * as MyActions from "./NewInvoicePrintApi"
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {NewInvoicePrintOutput} from "./NewInvoicePrintOutput";
import {ProposalPrint} from "../PrintProposalPage/ProposalPrint";
import axios from "axios";

class PdfPrintInvoicePage extends Component {

    state = {};

    componentWillMount = () => {
        this.props.actions.fetchInvoice(this.props.params.token, (invoice) => {
            invoice.site_maps.forEach(sitemap => {
                const property = {};
                property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                this.setState(property);
            });
            if(invoice.client_header) {
                axios.get(invoice.client_header).catch(() => {
                    invoice.client_header = 'https://s3-us-east-2.amazonaws.com/treereserve/production/images/trees.150605.1710640155.original.png?1710640155'
                });
            }
            this.setState({invoice})
        }, true);
    };

    render() {
        const {invoice} = this.state;
        if (!invoice) {
            return <div>Loading...</div>
        }
        return <NewInvoicePrintOutput logoHeight={invoice && invoice.logo_height}
                                      logoPosition={invoice && invoice.logo_position}
                                      invoice={invoice && invoice}
                                      options={invoice && JSON.parse(invoice.print_options)}
                                      client={this.props.client}/>
    }
}
const mapStateToProps = state => {
    return {
        client: state.client.customerInfo
    }
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(MyActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(PdfPrintInvoicePage)
