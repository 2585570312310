import React, {Component, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {Button, Col, ControlLabel, FormGroup, Glyphicon, Grid, Jumbotron, Panel, Row, Well} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import {Link} from "react-router"
import moment from "moment"
import * as MyActions from "./actions"
import * as showDock from "../../components/dock/actions"
import Dollars from "../../components/Dollars"

import {addAlert} from '../App/actions'
import {showEmailDialog} from "../../components/email/actions"
import {complete} from "../Notifications/NotificationsApi"
import Select from "react-select";
import config from "../../config/environment";
import Logo from './HomePageLogo.png'

const Actions = {...MyActions, showDock, addAlert: addAlert, showEmailDialog, complete}

class Home extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {},
            stickies: [],
            users: [],
            stats: [],
            proposals: [],
            dollars: "",
            numProposals: "",
        };
    }


    componentDidMount() {
        let {resource} = this.state;
        if (this.props.location.pathname !== '/') {
            this.props.actions.addAlert({message: "Page not found!", mood: 'warning', dismissAfter: 1500})
        }
        //TODO: check the state. if it was > a few seconds ago then dont reload all the proposals. give the user a refresh button?
        if (this.props.isAuthenticated) {
            this.props.actions.fetchProposals((proposals) => {
                this.setState({proposals})
            })
            this.props.actions.fetchStats((stats) => {
                this.setState({stats})
            })

            this.props.actions.fetchSalesArboristStats((data) => {
                const {dollars, numProposals} = data
                this.setState({dollars, numProposals})
            })

            resource.user = {value: this.props.auth.user_id, label: 'ME'}
            this.setState(resource, this.reload);
        }
    }

    reload = (users) => {
        let {resource} = this.state;
        let filter = {};
        if (!filter.user && users && users.length > 0) {
            filter.user = users.find(u => u.label === "ME")
            filter.user = filter.user && filter.user.value;
        } else {
            filter.user = resource.user.value;
        }
        filter.arborist = resource.arborist && resource.arborist.value;
        // this.props.actions.loadStickies(filter, result => {
        //     this.setState({stickies: result})
        // });
    };

    email = proposalId => {
        const {email} = this.props;
        this.props.actions.loadProposalData(proposalId, proposal => {
            this.props.actions.showEmailDialog(
                {
                    emailType: "proposal",
                    referenceId: proposalId,
                    recipient: proposal.customer_email_address,
                    defaultEmail: email
                });
        });
    };

    handleUserSelect = (e, users) => {
        let {resource} = this.state;
        resource.user = e;
        this.setState(resource, () => this.reload(users));
    };

    handleArboristSelect = e => {
        let {resource} = this.state;
        resource.arborist = e;
        this.setState(resource, this.reload);
    };

    render() {
        const {isAuthenticated, users} = this.props;
        const {stickies, resource, stats, proposals, numProposals, dollars} = this.state;


        return (
            <div>
                <Grid fluid>
                    {isAuthenticated ?
                        <div style={{zIndex: '2'}}>
                            <Row style={{marginLeft: "15px", marginRight: "15px"}}>
                                <Col md={9}>
                                    <Row>
                                        <Col lg={12} sm={12}>
                                            <Panel style={{
                                                border: "solid lightGrey 0.5px",
                                                backgroundColor: 'transparent'
                                            }}>
                                                <ul className="ch-grid">
                                                    <li>
                                                        <div className="ch-date">
                                                            <h3 className="info">{moment(`${Date()}`).format("MMM, YYYY")}</h3>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <LinkContainer to='/customer_search'>
                                                            <div className="ch-item ch-bg-6">
                                                                <div className="ch-info">
                                                                    <h3>
                                                                        {stats.customers_this_month}
                                                                    </h3>

                                                                    <h2>{stats.customers_this_month > 1 ? "New Customers" : "New Customer"}</h2>
                                                                </div>
                                                            </div>
                                                        </LinkContainer>
                                                    </li>
                                                    <li>
                                                        <LinkContainer to='/my_proposals'>
                                                            <div className="ch-item ch-bg-7">
                                                                <div className="ch-info">
                                                                    <h3>
                                                                        {stats.proposals_count && stats.proposals_count !== null ?
                                                                            <strong>
                                                                                {stats.proposals_count}
                                                                            </strong>
                                                                            : null
                                                                        }
                                                                    </h3>
                                                                    <h2>New Proposals</h2>
                                                                    <h2>{stats.proposals_dollars && stats.proposals_dollars !== null ?
                                                                        <strong>
                                                                            <Dollars amount={stats.proposals_dollars}/>
                                                                        </strong>
                                                                        : null
                                                                    }</h2>
                                                                </div>
                                                            </div>
                                                        </LinkContainer>
                                                    </li>
                                                    <li>
                                                        <LinkContainer to='/my_accepted'>
                                                            <div className="ch-item ch-bg-8">
                                                                <div className="ch-info">
                                                                    <h3>
                                                                        {stats.accepted_services_count && stats.accepted_services_count !== null ?
                                                                            <strong>
                                                                                {stats.accepted_services_count}
                                                                            </strong>
                                                                            : null
                                                                        }
                                                                    </h3>
                                                                    <h2>Accepted</h2>
                                                                    <h2>{stats.accepted_services_dollars && stats.accepted_services_dollars !== null ?
                                                                        <strong>
                                                                            <Dollars
                                                                                amount={stats.accepted_services_dollars}/>
                                                                        </strong>
                                                                        : null
                                                                    }</h2>
                                                                    <h6>
                            <span>
                            {stats.accepted_services_dollars && stats.accepted_services_dollars !== null && stats.proposals_dollars && stats.proposals_dollars ?
                                Math.floor((stats.accepted_services_dollars.toFixed(2)
                                    /
                                    stats.proposals_dollars.toFixed(2) * 100))
                                +
                                "% closed"
                                :
                                null}
                            </span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </LinkContainer>
                                                    </li>
                                                    <li>
                                                        <LinkContainer to='/calendar'>
                                                            <div className="ch-item ch-bg-9">
                                                                <div className="ch-info">
                                                                    <h3>
                                                                        <strong>
                                                                            {stats.scheduled_count}
                                                                        </strong>
                                                                    </h3>
                                                                    <h2>Scheduled</h2>
                                                                    <h2 className="text-center">
                                                                        {stats.scheduled_sum &&
                                                                            <strong>
                                                                                ${stats.scheduled_sum}
                                                                            </strong>
                                                                        }
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </LinkContainer>
                                                    </li>
                                                    <li>
                                                        <LinkContainer to='/proposed_services'>
                                                            <div className="ch-item ch-bg-10">
                                                                <div className="ch-info">
                                                                    <h3>
                                                                        {stats.completed_services_count && stats.completed_services_count !== null ?
                                                                            <strong>
                                                                                {stats.completed_services_count}
                                                                            </strong>
                                                                            : null
                                                                        }
                                                                    </h3>
                                                                    <h2>Completed</h2>
                                                                    <h2>{stats.completed_services_dollars && stats.completed_services_dollars !== null ?
                                                                        <strong>
                                                                            <Dollars
                                                                                amount={stats.completed_services_dollars}/>
                                                                        </strong>
                                                                        : null
                                                                    }</h2>
                                                                </div>
                                                            </div>
                                                        </LinkContainer>
                                                    </li>
                                                </ul>
                                            </Panel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col lg={12} sm={12} className="text-center">
                                                    <Panel style={{
                                                        border: "solid lightGrey 0.5px",
                                                        backgroundColor: 'transparent'
                                                    }}>
                                                        <ul className="ch-grid">
                                                            <li>
                                                                <div className="ch-item ch-bg-1">
                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "15px"}}>
                                                                        <h4 style={{fontSize: "17px"}}>{numProposals || 0}</h4>
                                                                        <LinkContainer to="/pending_approvals"
                                                                                       className="pointer biggy">
                                                                            <Glyphicon glyph="ok" className="biggy"/>
                                                                        </LinkContainer>
                                                                        <h4>
                                                                            Proposals to approve
                                                                            <br/>
                                                                            <Dollars amount={dollars}/>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="ch-item ch-bg-2">
                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "35px"}}>
                                                                        <LinkContainer to="/calendar"
                                                                                       className="pointer biggest">
                                                                            <Glyphicon glyph="calendar"
                                                                                       className="biggest"/>
                                                                        </LinkContainer>
                                                                        <h4>View Scheduler</h4>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="ch-item ch-bg-3">
                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "35px"}}>
                                                                        <LinkContainer to="/customer_search"
                                                                                       className="pointer biggest">
                                                                            <Glyphicon glyph="user"
                                                                                       className="biggest"/>
                                                                        </LinkContainer>
                                                                        <h4>Customers</h4>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="ch-item ch-bg-4">
                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "35px"}}>
                                                                        <LinkContainer to="/new_sales_dashboard_v1"
                                                                                       className="pointer bigger" style={{color: 'white', fontSize: '20px'}}>
                                                                            <Glyphicon glyph="dashboard"
                                                                                       className="bigger"/>
                                                                        </LinkContainer>
                                                                        <h4>Ex. Dashboard V1</h4>
                                                                    </div>

                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "15px"}}>
                                                                        <LinkContainer to="/new_sales_dashboard_v2"
                                                                                       className="pointer bigger" style={{color: 'white', fontSize: '20px'}}>
                                                                            <Glyphicon glyph="dashboard"
                                                                                       className="bigger"/>
                                                                        </LinkContainer>
                                                                        <h4>Ex. Dashboard V2</h4>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="ch-item ch-bg-4">
                                                                    <div className="ch-info text-center"
                                                                         style={{marginTop: "35px"}}>
                                                                        <LinkContainer to="/revenue_reports"
                                                                                       className="pointer biggest">
                                                                            <Glyphicon glyph="usd"
                                                                                       className="biggest"/>
                                                                        </LinkContainer>
                                                                        <h4>Revenue</h4>
                                                                        <h4>Reports</h4>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Panel>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col lg={4} xs={12}>
                                                    <Panel className="home-lists-panel"
                                                           style={{height: '200px', overflowY: "scroll"}}>
                                                        <h4 className="text-warning">
                                                            {
                                                                proposals.open && proposals.open.length > 0 ?
                                                                    <span>{proposals.open.length} Proposals Created This Week<small> not accepted</small></span> : "You Haven't Created A Proposal Yet This Week!"
                                                            }
                                                        </h4>
                                                        <ul style={{listStyle: "none", paddingLeft: "0"}}>
                                                            <li>
                                                                {proposals.open && proposals.open.length > 0 ?
                                                                    proposals.open
                                                                        .sort((a, b) => new Date(b.proposedDate) - new Date(a.proposedDate))
                                                                        .map(p => (
                                                                            <Row key={p.id} className="text-left">
                                                                                <Col md={12}>
                                                                                    <h6>
                                                                                        <Link
                                                                                            className="attention-menu-item"
                                                                                            to={`mapview/${p.id}`}>
                                                                                            {p.customerName}{" "}<Dollars
                                                                                            amount={p.proposalTotal}/>{" "}
                                                                                            <small>
                                                                                                <strong>{moment(`${p.proposedDate}`).format("MM/DD h:mmA")}</strong>
                                                                                            </small>
                                                                                        </Link>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                        ))
                                                                    :
                                                                    <div>
                                                                        <p style={{marginBottom: "60px"}}>
                                                                        </p>
                                                                        <LinkContainer to="/mapview">
                                                                            <Button bsStyle="warning"
                                                                                    bsSize="sm">Get Started & Create
                                                                                a Proposal</Button>
                                                                        </LinkContainer>
                                                                    </div>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </Panel>
                                                </Col>
                                                <Col lg={4} xs={12}>
                                                    <Panel className="home-lists-panel"
                                                           style={{height: '200px', overflowY: "scroll"}}>
                                                        <h4 className="text-success">
                                                            {
                                                                proposals.accepted && proposals.accepted.length > 0 ?
                                                                    `${proposals.accepted.length} Proposals Accepted This Week` : "No Accepted Proposals This Week"
                                                            }
                                                        </h4>
                                                        <ul style={{listStyle: "none", paddingLeft: "0"}}>
                                                            <li>
                                                                {proposals.accepted && proposals.accepted.length > 0 ?
                                                                    proposals.accepted
                                                                        .sort((a, b) => new Date(b.acceptedDate) - new Date(a.acceptedDate))
                                                                        .map(p => (
                                                                            <Row key={p.id} className="text-left">
                                                                                <Col md={12}>
                                                                                    <h6>
                                                                                        <Link
                                                                                            className="accepted-menu-item"
                                                                                            to={`mapview/${p.id}`}>
                                                                                            {p.customerName}{" "}<Dollars
                                                                                            amount={p.acceptedTotal}/>{" "}
                                                                                            <small>
                                                                                                <strong>{moment(`${p.acceptedDate}`).format("MM/DD h:mmA")}</strong></small>
                                                                                        </Link>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                        ))
                                                                    :
                                                                    <div>
                                                                        <p style={{marginBottom: "60px"}}>
                                                                        </p>
                                                                        <LinkContainer to="/proposed_services">
                                                                            <Button bsStyle="warning btn-block"
                                                                                    bsSize="medium">Run a Report &
                                                                                Generate Work!</Button>
                                                                        </LinkContainer>
                                                                    </div>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </Panel>
                                                </Col>
                                                <Col lg={4} xs={12}>
                                                    <Panel className="home-lists-panel"
                                                           style={{height: '200px', overflowY: "scroll"}}>
                                                        <h4 className="text-danger">
                                                            {
                                                                proposals.declined && proposals.declined.length > 0 ?
                                                                    `${proposals.declined.length} Proposals Declined This Week` : "No Declined Proposals This Week"
                                                            }
                                                        </h4>
                                                        <ul style={{listStyle: "none", paddingLeft: "0"}}>
                                                            <li>
                                                                {proposals.declined && proposals.declined.length > 0 ?
                                                                    proposals.declined
                                                                        .sort((a, b) => new Date(b.declinedDate) - new Date(a.declinedDate))
                                                                        .map(p => (
                                                                            <Row key={p.id} className="text-left">
                                                                                <Col md={12}>
                                                                                    <h6>
                                                                                        <Link
                                                                                            className="danger-menu-item"
                                                                                            to={`proposal_service_status/${p.id}`}>
                                                                                            {p.customerName}{" "}<Dollars
                                                                                            amount={p.proposalTotal}/>{" "}
                                                                                            <small>
                                                                                                <strong>{moment(`${p.declinedDate}`).format("MM/DD h:mmA")}</strong></small>
                                                                                        </Link>
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                        ))
                                                                    :
                                                                    <div>
                                                                        <p style={{marginBottom: "60px"}}>
                                                                        </p>
                                                                        <LinkContainer to="/proposed_services">
                                                                            <Button bsStyle="success btn-block"
                                                                                    bsSize="medium">Run a Sales
                                                                                Report</Button>
                                                                        </LinkContainer>
                                                                    </div>
                                                                }
                                                            </li>
                                                        </ul>
                                                    </Panel>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Panel className="home-dash-panel">
                                        <Row>
                                            <Well
                                                bsSize="small"
                                                style={{
                                                    maxHeight: '400px',
                                                    overflowY: "scroll",
                                                    backgroundColor: "transparent",
                                                    border: "0"
                                                }}
                                            >
                                                <Col lg={12} sm={12}>
                                                    <h5 style={{marginTop: "0px", marginBottom: '0px'}}>Follow Ups
                                                        Due <small>(last 30 days)</small></h5>
                                                    <hr style={{marginTop: "5px", marginBottom: '5px'}}/>
                                                    <FormGroup>
                                                        <ControlLabel>Created by</ControlLabel>
                                                        <Select className="Select" classNamePrefix="select"
                                                                value={resource.user}
                                                                isClearable
                                                                options={users}
                                                                onChange={e => this.handleUserSelect(e, users)}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel>Arborist</ControlLabel>
                                                        <Select className="Select" classNamePrefix="select"
                                                                value={resource.arborist}
                                                                isClearable
                                                                options={users}
                                                                onChange={this.handleArboristSelect}
                                                        />
                                                    </FormGroup>
                                                    <ul className="follow-up">
                                                        {stickies
                                                            .sort((a, b) => new Date(a.follow_up_date) - new Date(b.follow_up_date))
                                                            .map(s => (
                                                                <li key={s.id} className="follow-up">
                                                                    <blockquote className="custom-blockquote mb-15">
                                                                        <div>
                                                                            <Link className="pointer text-primary"
                                                                                  onClick={() => this.email(s.proposal_id)}>
                                                                                {" Due on "}{moment(`${s.follow_up_date}`).format("MM/DD h:mmA")}
                                                                                {" "}
                                                                            </Link>
                                                                            {s.customer_name}{" "}
                                                                            <Link className="pointer text-primary"
                                                                                  to={`/mapview/${s.proposal_id}`}>
                                                                                #{s.proposal_no}
                                                                            </Link>
                                                                            {" "}<Dollars amount={s.proposal_total}/>
                                                                        </div>
                                                                        {s.follow_up_date && !s.complete &&
                                                                            <span><Glyphicon
                                                                                className="pointer text-success"
                                                                                glyph="ok"
                                                                                onClick={() => {
                                                                                    this.props.actions.complete(s, () => {
                                                                                        this.reload()
                                                                                    })
                                                                                }}/></span>}
                                                                    </blockquote>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </Col>
                                            </Well>
                                        </Row>
                                        <Row>
                                            <Well
                                                bsSize="small"
                                                style={{
                                                    maxHeight: '400px',
                                                    overflowY: "scroll",
                                                    backgroundColor: "transparent",
                                                    border: "0"
                                                }}
                                            >
                                                <Col lg={12} sm={12}>
                                                    <h5 className="text-left"
                                                        style={{marginTop: "15px", marginBottom: '0px'}}>Todays
                                                        Jobs{' '}
                                                        <small>
                                                            (
                                                            {stats.jobs_today_count ?
                                                                stats.jobs_today_count
                                                                : 0
                                                            })
                                                        </small>
                                                    </h5>
                                                    <hr style={{marginTop: "5px", marginBottom: '5px'}}/>
                                                    <div>
                                                        {stats.jobs_today && stats.jobs_today.length > 0 ?
                                                            <ul className="follow-up">
                                                                {stats.jobs_today
                                                                    .filter(a => moment(a.from)
                                                                        .isBetween(moment()
                                                                            .add(0, 'day').startOf('day').toString(), moment()
                                                                            .add(0, 'day').endOf('day').toString()))
                                                                    .sort((a, b) => new Date(a.from) - new Date(b.from))
                                                                    .map(s => (
                                                                        <li key={s.id} className="follow-up">
                                                                            <blockquote
                                                                                className="jobs-blockquote mb-15">
                                                                                <Link className="pointer text-primary"
                                                                                      to={`/work_orders/${s.proposal_id}`}>
                                                                                    <div>
                                                                                        {moment(`${s.from}`).format("ddd MM/DD h:mmA")}{" "}Proposal
                                                                                        #{s.proposal_no}
                                                                                        {" "}
                                                                                        {s.customer}{" "}WO
                                                                                        #{s.wo_no}{" $"}{s.services.toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2}) }
                                                                                        {" ~"}{s.arborist}
                                                                                    </div>
                                                                                </Link>
                                                                            </blockquote>
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                            : "Nothing Scheduled"
                                                        }
                                                    </div>
                                                    <h5 className="text-left"
                                                        style={{marginTop: "15px", marginBottom: '0px'}}>Jobs Next 3
                                                        Days{' '}
                                                        <small>
                                                            (
                                                            {stats.jobs_next_three_days_count
                                                                ? stats.jobs_next_three_days_count
                                                                : 0
                                                            }
                                                            )
                                                        </small>
                                                    </h5>
                                                    <hr style={{marginTop: "5px", marginBottom: '5px'}}/>
                                                    <div>
                                                        {stats.jobs_next_three_days && stats.jobs_next_three_days.length > 0 ?
                                                            <ul className="follow-up">
                                                                {stats.jobs_next_three_days
                                                                    .filter(a => moment(a.from)
                                                                        .isBetween(moment()
                                                                            .add(1, 'day').startOf('day').toString(), moment()
                                                                            .add(1, 'weeks').endOf('isoWeek').toString()))
                                                                    .sort((a, b) => new Date(a.from) - new Date(b.from))
                                                                    .map(s => (
                                                                        <li key={s.id} className="follow-up">
                                                                            <blockquote
                                                                                className="jobs-blockquote mb-15">
                                                                                <Link className="pointer text-primary"
                                                                                      to={`/work_orders/${s.proposal_id}`}>
                                                                                    <div>
                                                                                        {moment(`${s.from}`).format("ddd MM/DD h:mma")}{" "}Proposal
                                                                                        #{s.proposal_no}
                                                                                        {" "}
                                                                                        {s.customer}{" "}WO
                                                                                        #{s.wo_no}{" $"}{s.services.toLocaleString('en-US', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2})}
                                                                                        {" ~"}{s.arborist}
                                                                                    </div>
                                                                                </Link>
                                                                            </blockquote>
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                            : "Nothing Scheduled"
                                                        }
                                                    </div>
                                                    <hr/>
                                                </Col>
                                            </Well>
                                        </Row>
                                    </Panel>
                                </Col>
                            </Row>
                        </div>
                        :
                        null
                    }
                    {isAuthenticated ?
                        null :
                        <div>
                            <Jumbotron>
                                <div className="logo text-center">
                                    <img src={Logo} alt='Logo' height={120}/>
                                </div>
                                <h1 className="text-center">Welcome To TreeHub</h1>
                                <h2 className="text-center">Commercial Tree Care Management Software</h2>
                                <h4 className="text-center">info@treehub.me</h4>
                                <h4 className="text-center">(843) 501-4297</h4>
                                <div className='version'>
                                    Version: {config.appVersion}
                                </div>
                            </Jumbotron>
                            <Row>
                                <Col xs={12} className='text-center'>
                                    <LinkContainer to='/login'>
                                        <Button bsStyle='success'>Login</Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                            <Row className='position-relative'>
                                <Col xs={12} style={{position: 'absolute', bottom: 10}}
                                     className=' space-evenly text-center start-50 translate-middle'>
                                    <LinkContainer to='/terms_and_conditions'><span className='wo-link pointer mr-10'>Terms & Conditions</span></LinkContainer>
                                    <LinkContainer to='/privacy_policy'><span className='wo-link pointer ml-10'>Privacy Policy</span></LinkContainer>
                                </Col>
                            </Row>
                        </div>
                    }
                </Grid>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        email: state.auth.email,
        auth: state.auth,
        users: state.users.users
    }
}

const mapDispatchToProps = (dispatch) => {
    //we only want to wrap dispatch around 1 method so no reason to pass it in as const Actions = Obj.assign({}, addAlert) - just wrap {addAlert} to an object
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
