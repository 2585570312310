import React, {Component} from 'react';
import {defaultDateFormat, fitBounds, select} from "../common/commonHandlers";
import {debounce} from "throttle-debounce";

const google = window.google;


export default class ResourceComponent extends Component {
    getDisabledHours(selectedTimeInSeconds, timeLimitInSeconds, direction) {
        const fullDaySeconds = 86400;
        selectedTimeInSeconds = selectedTimeInSeconds % fullDaySeconds;
        timeLimitInSeconds = timeLimitInSeconds % fullDaySeconds;

        // Convert time to hours and minutes
        const selectedHours = Math.floor(selectedTimeInSeconds / 3600);
        const selectedMinutes = Math.floor((selectedTimeInSeconds % 3600) / 60);

        const limitHours = Math.floor(timeLimitInSeconds / 3600);
        const limitMinutes = Math.floor((timeLimitInSeconds % 3600) / 60);

        // Create an array to store the disabled hours
        const disabledHours = [];

        if (direction === 'higher') {
            // Add the disabled hours after the given time
            for (let i = limitHours + 1; i < 24; i++) {
                disabledHours.push(i);
            }

            // Check if the selected minutes are lower or equal to the limit minutes and the limit hours are not already added
            if (selectedMinutes >= limitMinutes && !disabledHours.includes(limitHours)) {
                disabledHours.push(limitHours);
            }
        } else if (direction === 'lower') {
            // Add the disabled hours before the given time
            for (let i = 0; i < limitHours; i++) {
                disabledHours.push(i);
            }

            // Check if the selected minutes are lower or equal to the limit minutes and the limit hours are not already added
            if (selectedMinutes <= limitMinutes && !disabledHours.includes(limitHours)) {
                disabledHours.push(limitHours);
            }
        }

        return disabledHours;
    }
    getDisabledMinutes(selectedTimeInSeconds, timeLimitInSeconds, direction) {
        // Convert selected time and time limit to minutes
        const fullDaySeconds = 86400
        selectedTimeInSeconds = selectedTimeInSeconds % fullDaySeconds
        timeLimitInSeconds = timeLimitInSeconds % fullDaySeconds

        const selectedMinutes = Math.floor(selectedTimeInSeconds / 60);
        const timeLimitMinutes = Math.floor(timeLimitInSeconds / 60);

        // Check if selected minutes and time limit minutes are in the same hour
        if (Math.floor(selectedMinutes / 60) === Math.floor(timeLimitMinutes / 60)) {
            // Create an array to store the disabled minutes
            const disabledMinutes = [];

            if (direction === 'higher') {
                // Disable minutes equal to or greater than the time limit minutes
                for (let i = timeLimitMinutes % 60; i < 60; i++) {
                    disabledMinutes.push(i);
                }
            } else if (direction === 'lower') {
                // Disable minutes lower than or equal to the time limit minutes
                for (let i = 0; i <= timeLimitMinutes % 60; i++) {
                    disabledMinutes.push(i);
                }
            }

            return disabledMinutes;
        }

        // Return an empty array if the hours are different
        return [];
    }
    updateDecimal = (e, callback) => {
        this.state.resource[e.target.name] = parseFloat(e.target.value).toFixed(2);
        this.setState({resource: this.state.resource}, callback);
    };

    updateResourceAttr = (e, callback) => {
        this.state.resource[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    updateResourceNestedAttr = (e, fieldName, callback) => {
        this.state.resource[e.target.name][fieldName] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    dollarFormatAttr = (e, callback) => {
        this.state.resource[e.target.name] = (e.target.value && e.target.value !== '') ? parseFloat(e.target.value.toString().replace(/[^0-9.-]/g,'').replace(/(?!^)-/g,''))
            .toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2}) : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    dollarFormatNestedAttr = (e, fieldName, callback) => {
        this.state.resource[e.target.name][fieldName] = (e.target.value && e.target.value !== '') ? parseFloat(e.target.value.toString().replace(/[^0-9.]/g,'')).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2}) : e.target.value;
        this.setState({resource: this.state.resource}, callback);
    };

    reformatDollars = (name) => () => {
        const {resource} = this.state;
        resource[name] = resource[name]==='' ? '' : parseFloat(resource[name].toString().replace(/[^0-9.-]/g,'').replace(',', ''));
        this.setState({resource});
    };

    numericInputValueFormatterDollar = (name) => {
        const {resource} = this.state
        return resource[name]===null ? '' : resource[name]?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    percentFormatNestedAttr = (e, fieldName, callback) => {
        let value = e.target.value.toString().replace(/[^0-9.]/g,'')
        this.state.resource[e.target.name][fieldName] = value ? value + '%' : 0;
        this.setState({resource: this.state.resource}, callback);
    };

    selectCheckboxAttr = (name, callback) => e => {
        this.state.resource[name] = e;
        this.setState({resource: this.state.resource}, callback);
    };
    selectCreatableResourceAttr = (name, callback) => e => {
        if (e && e.constructor.name === 'Array') {
            this.state.resource[name] = e.map(x => x.id);
        } else {
            this.state.resource[name] = e ? e.id : null;
        }
        this.setState({resource: this.state.resource}, callback);
    };
    selectCreatableResourceNestedAttr = (name, fieldName, callback) => e => {
        if (e && e.constructor.name === 'Array') {
            this.state.resource[name][fieldName] = e.map(x => x.id);
        } else {
            this.state.resource[name][fieldName] = e ? e.id : null;
        }
        this.setState({resource: this.state.resource}, callback);
    };
    selectResourceAttr = (name, callback) => e => {
        if (e && e.constructor.name === 'Array') {
            this.state.resource[name] = e.map(x => x.value);
        } else {
            this.state.resource[name] = e ? e.value : null;
        }
        this.setState({resource: this.state.resource}, callback);
    };
    selectResourceNestedAttr = (name, fieldName, callback) => e => {
        if (e && e.constructor.name === 'Array') {
            this.state.resource[name][fieldName] = e.map(x => x.value);
        } else {
            this.state.resource[name][fieldName] = e ? e.value : null;
        }
        this.setState({resource: this.state.resource}, callback);
    };
    selectResourceAttrWithName = (name, callback) => e => {
        if (e && e.constructor.name === 'Array') {
            this.state.resource[name] = e.map(x => ({id: x.value, name: x.label}));
        } else {
            this.state.resource[name] = e ? {id: e.value, name: e.label} : null;
        }
        this.setState({resource: this.state.resource}, callback);
    };

    dateResourceAttr = (name, callback) => e => {
        this.state.resource[name] = (e && e !== '') ? e : null;
        this.setState({resource: this.state.resource}, callback);
    };
    dateNoTimeResourceAttr = (name, callback) => e => {
        if (typeof e === 'string' || e instanceof String) {
            if (e.length >= 10) {
                this.state.resource[name] = 'Invalid Date'
                this.setState({resource: this.state.resource}, callback);
            }
            else{
                callback && callback()
            }
        }else{
            this.state.resource[name] = (e && e !== '') ? e.format(defaultDateFormat) : null;
            this.setState({resource: this.state.resource}, callback);
        }
    };

    dateResourceNestedAttr = (name, fieldName, callback) => e => {
        if (typeof e === 'string' || e instanceof String) {
            if (e.length >= 10) {
                this.state.resource[name][fieldName] = 'Invalid Date'
                this.setState({resource: this.state.resource}, callback);
            }
            else{
                callback && callback()
            }
        } else {
            this.state.resource[name][fieldName] = (e && e !== '') ? e : null;
            this.setState({resource: this.state.resource, isCalendarOpen: false}, callback);
        }
    };

    handleSearchResults = (field, searchResultsField, callback) => searchResults => {
        const currentSearchResults = this.state[searchResultsField];
        (Array.isArray(this.state.resource[field]) ? this.state.resource[field] : []).forEach(selected => {
            const alreadyIn = searchResults.some(x => selected.value === x.value);
            if (!alreadyIn) {
                searchResults.push({
                    value: selected,
                    label: currentSearchResults.find(x => x.value === selected).label
                });
            }
        });
        const newState = {};
        newState[searchResultsField] = searchResults;
        this.setState(newState, callback)
    };

    handleSearchWoResults = (field, searchResultsField, callback) => searchResults => {
        const currentSearchResults = this.state[searchResultsField];
        (this.state.workOrdersFilter[field] || []).forEach(selected => {
            const alreadyIn = searchResults.some(x => selected.value === x.value);
            if (!alreadyIn) {
                searchResults.push({
                    value: selected,
                    label: currentSearchResults.find(x => x.value === selected).label
                });
            }
        });
        const newState = {};
        newState[searchResultsField] = searchResults;
        this.setState(newState, callback)
    };

    renderSizePerPageDropDown = (props) => {
        return (
        <div className="btn-group dropup">
            <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>{props.currSizePerPage}</span>
                <span className="ml-6 caret"></span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {props.sizePerPageList.map(item =>
                    <li>
                        <a
                        className='table-pagination dropdown-item'
                        onClick={() => props.changeSizePerPage(item.value)}
                    >{item.text}</a>
                    </li>
                )}
            </ul>
        </div>
        );
    }

    renderPaginationPanel = (props, current_page = null, current_per_page = null) => {
        let page = current_page || this.state?.resource?.page
        let per_page = current_per_page || this.state?.resource?.per_page
        if(!page){
            page = props.currPage
        }
        if(!per_page){
            per_page = props.sizePerPage
        }

        return (
            <>
                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    { props.components.sizePerPageDropdown }
                </div>
                <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6" style={{display: 'block'}}>
                    <ul className="react-bootstrap-table-page-btns-ul pagination">
                        {props.totalPages>5 && page!==1 && page > 3 && <li className={"page-item "+`${page===1 ? 'disabled': 'pointer'}`} title="first page">
                            <span onClick={()=>{props.changePage(1, per_page)}} className="page-link">&lt;&lt;</span>
                        </li>}
                        {props.totalPages>1 && page!==1 && <li className={"page-item "+`${page===1 ? 'disabled': 'pointer'}`} title="previous page">
                            <span onClick={()=>{props.changePage(page-1, per_page)}} className="page-link">&lt;</span>
                        </li>}
                        {page-4 > 0 && page+3>props.totalPages && <li className={"page-item pointer"} title={page-4}>
                            <span onClick={()=>{props.changePage(page-4, per_page)}} className="page-link">{page-4}</span>
                        </li>}
                        {page-3 > 0 && page+2>props.totalPages && <li className={"page-item pointer"} title={page-3}>
                            <span onClick={()=>{props.changePage(page-3, per_page)}} className="page-link">{page-3}</span>
                        </li>}
                        {page-2 > 0 && <li className={"page-item pointer"} title={page-2}>
                            <span onClick={()=>{props.changePage(page-2, per_page)}} className="page-link">{page-2}</span>
                        </li>}
                        {page-1 > 0 && <li className={"page-item pointer"} title={page-1}>
                            <span onClick={()=>{props.changePage(page-1, per_page)}} className="page-link">{page-1}</span>
                        </li>}
                        <li className="page-item active"><span className="page-link">{page}</span></li>
                        {page+1 <= props.totalPages && <li className={"page-item pointer"} title={page+1}>
                            <span onClick={()=>{props.changePage(page+1, per_page)}} className="page-link">{page+1}</span>
                        </li>}
                        {page+2 <= props.totalPages && <li className={"page-item pointer"} title={page+2}>
                            <span onClick={()=>{props.changePage(page+2, per_page)}} className="page-link">{page+2}</span>
                        </li>}
                        {page+3 <= props.totalPages && page<3 && <li className={"page-item pointer"} title={page+3}>
                            <span onClick={()=>{props.changePage(page+3, per_page)}} className="page-link">{page+3}</span>
                        </li>}
                        {page+4 <= props.totalPages && page<2 && <li className={"page-item pointer"} title={page+4}>
                            <span onClick={()=>{props.changePage(page+4, per_page)}} className="page-link">{page+4}</span>
                        </li>}
                        {props.totalPages>1 && page!==props.totalPages && <li className={"page-item pointer"} title="next page">
                            <span onClick={()=>{props.changePage(page+1, per_page)}} className="page-link">&gt;</span>
                        </li>}
                        {props.totalPages>5 && page!==props.totalPages && page < props.totalPages-3 && <li className={"page-item "+`${page===props.totalPages ? 'disabled': 'pointer'}`} title="last page">
                            <span onClick={()=>{props.changePage(props.totalPages, per_page)}} className="page-link">&gt;&gt;</span>
                        </li>}
                    </ul>
                </div>
            </>
        );
    }

    refGoogleMap = (it, markers, callback) => {
        window._googleMapComponent = it;
        if (!this.state.loaded && markers.length > 0) {
            fitBounds(it, markers, () => {
                this.setState({loaded: true});
                callback && callback(it);
            });
        } else if (markers.length === 0 && !this.state.loaded) {
            markers = [{latitude: 40.52583179354073, longitude: -125.85685785644564}, {
                latitude: 47.17360592131549,
                longitude: -89.19249224665968
            }, {latitude: 30.002020144327556, longitude: -105.60215703740533}];
            fitBounds(it, markers, () => {
                this.setState({loaded: true});
                callback && callback(it);
            });
        }
    };

    rowSynchronizedClass = (row) => {
        return (row.qb && row.th && row.qb.quickbooks_id && row.th.quickbooks_id && row.qb.quickbooks_id === row.th.quickbooks_id) ? 'background-green' : '';
    }

    selectedMarkerClass = (row) => {
        return row.selected ? 'highlight highlight-marker-row' : '';
    };

    selectedMarkerClassWithChangeTextColor = (row) => {
        return row.selected ? 'highlight highlight-marker-row' : '';
    };

    selectMarkerNoScroll = (allField) => {
        return this.selectMarker(allField, (e, x) => e.id === x.id, undefined, false);
    };

    selectMarker = (allField, selector = (e, x) => e.id === x.id, callback, scroll = true, loaded = false) => (element) => {
        if ((typeof element === 'object' && element.id) || typeof element !== 'object') {
            const all = this.state[allField];
            all.forEach(x => x.selected = false);
            all.find(x => selector(element, x)).selected = true;
            const data = {};
            data[allField] = all;
            data.center = true;
            data.loaded = loaded;
            this.setState(data, callback);
            if (scroll) {
                let elmnt = document.getElementsByClassName("highlight-marker-row")[0];
                elmnt && elmnt.scrollIntoView({behavior: "smooth"})
            }
        }
    };


    getAssetValues = (type, e) => {
        if (type === 'rectangle') {
            const bounds = e.getBounds()
            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();
            const southWest = new google.maps.LatLng(sw.lat(), sw.lng());
            const northEast = new google.maps.LatLng(ne.lat(), ne.lng());
            const southEast = new google.maps.LatLng(sw.lat(), ne.lng());
            const northWest = new google.maps.LatLng(ne.lat(), sw.lng());
            const field = google.maps.geometry.spherical.computeArea([northEast, northWest, southWest, southEast]);
            const circuit = google.maps.geometry.spherical.computeLength([northEast, northWest, southWest, southEast]);
            return {field: this.sqft(field), circuit: this.ft(circuit)}
        }
        if (type === 'polygon') {
            const field = google.maps.geometry.spherical.computeArea(e.getPath())
            let circuit = google.maps.geometry.spherical.computeLength(e.getPath())
            return {field: this.sqft(field), circuit: this.ft(circuit)}
        }
        if (type === 'circle') {
            const radius = e.radius !== undefined ? e.radius : e.getRadius()
            const field = Math.PI * Math.pow(radius, 2);
            let circuit = 2 * Math.PI * radius;
            return {field: this.sqft(field), circuit: this.ft(circuit)}
        }
        if (type === 'polyLine') {
            let circuit = google.maps.geometry.spherical.computeLength(e.getPath())
            return {circuit: this.ft(circuit)}
        }
        return null
    }

    selectRecord = (allField, selector = (e, x) => e.id === x.id && e.created_at === x.created_at
        && e.price === x.price, callback, scroll = false) => (element) => {
        const all = this.state[allField];
        all.forEach(x => x.selected = false);
        let selected_record = all.find(x => selector(element, x));
        selected_record.selected = true;
        const data = {};
        data[allField] = all;
        data.selected_string = selected_record.proposalId.toString();
        data.center = true;
        data.loaded = true;
        this.setState(data, callback);
        if (scroll) {
            let elmnt = document.getElementsByClassName("highlight")[0];
            let container = document.getElementsByClassName("react-bs-container-body")[1];
            container.scrollTo({
                top: elmnt.offsetTop,
                behavior: "smooth"
            });
        }
    };

    selectMarkerOneToMany = (allField, selector = (e, x) => e.longitude === x.longitude && e.latitude === x.latitude, callback, scroll = true, isBacklog = false) => element => {
        const all = this.state[allField];
        let selected = [];
        all.forEach(x => x.selected = false);
        all.filter(x => selector(element, x)).forEach(item => {
            item.selected = true;
            selected.push((isBacklog ? item.id : item.proposalId))
        });
        const data = {};
        data[allField] = all;
        data.selected_string = selected.toString();
        data.center = true;
        data.loaded = true;
        this.setState(data, callback);
        if (scroll) {
            let elmnt = document.getElementsByClassName("highlight")[0];
            let container = document.getElementsByClassName("react-bs-container-body")[1];
            container && container.scrollTo({
                top: elmnt.offsetTop,
                behavior: "smooth"
            });
        }
    }

    buildSearchComponent = (field, searchFn) => {
        const tempFieldName = `${field}Temp`;

        const searchOptions = () => {
            const {resource} = this.state;
            const tempOptions = this.state[tempFieldName] || [];
            let currentOptions = this.state[field] || [];
            currentOptions = concat(currentOptions, tempOptions);
            return currentOptions;
        };


        const savePreviousSearch = () => {
            const {resource} = this.state;
            const options = searchOptions();
            const found = select(options, resource[field]);
            if (found && found.length > 0) {
                const newState = {};
                newState[tempFieldName] = select(options, resource[field]);
                this.setState(newState);
            }
        };

        function concat(a1, a2) {
            if (!Array.isArray(a1)) {
                a1 = [a1]
            }
            return [...new Set([...(a1 || []), ...(a2 || [])])];

        }

        const initTempOptions = (values) => {
            const newState = {};
            newState[tempFieldName] = concat(this.state[tempFieldName], values);
            this.setState(newState)
        };

        const debouncedSearchFn = debounce(500, searchFn);

        const search = (e) => {
            savePreviousSearch();
            e !== '' && debouncedSearchFn(e, result => {
                const newState = {};
                newState[field] = result;
                this.setState(newState)
            })
        };

        const searchWithCallback = (e, callback) => {
            savePreviousSearch();
            e !== '' && debouncedSearchFn(e, result => {
                const newState = {};
                newState[field] = result;
                this.setState(newState)
                callback && callback()
            })
        };

        const searchGuess = (e) => {
            const {resource} = this.state
            savePreviousSearch();
            e !== '' && debouncedSearchFn(e, resource.customer_id, result => {
                const newState = {};
                newState[field] = result;
                this.setState(newState)
            })
        };

        const setSearchOptions = () => {
            const newState = {};
            newState[tempFieldName] = [];
            this.setState(newState);
        };

        setSearchOptions(field);
        return {search, searchOptions, initTempOptions, searchGuess, searchWithCallback};
    };

    buildSearchComponentWithParams = (field, searchFn) => {
        const tempFieldName = `${field}Temp`;

        const searchOptions = () => {
            const {resource} = this.state;
            const tempOptions = this.state[tempFieldName] || [];
            let currentOptions = this.state[field] || [];
            currentOptions = concat(currentOptions, tempOptions);
            return currentOptions;
        };


        const savePreviousSearch = () => {
            const {resource} = this.state;
            const options = searchOptions();
            const found = select(options, resource[field]);
            if (found && found.length > 0) {
                const newState = {};
                newState[tempFieldName] = select(options, resource[field]);
                this.setState(newState);
            }
        };

        function concat(a1, a2) {
            if (!Array.isArray(a1)) {
                a1 = [a1]
            }
            return [...new Set([...(a1 || []), ...(a2 || [])])];

        }

        const initTempOptions = (values) => {
            const newState = {};
            newState[tempFieldName] = concat(this.state[tempFieldName], values);
            this.setState(newState)
        };

        const debouncedSearchFn = debounce(500, searchFn);

        const search = (e) => {
            savePreviousSearch();
            e !== '' && debouncedSearchFn(e, this.state.resource, result => {
                const newState = {};
                newState[field] = result;
                this.setState(newState)
            })
        };

        const searchWithCallback = (e, callback) => {
            savePreviousSearch();
            e !== '' && debouncedSearchFn(e, this.state.resource, result => {
                const newState = {};
                newState[field] = result;
                this.setState(newState)
                callback && callback()
            })
        };

        const setSearchOptions = () => {
            const newState = {};
            newState[tempFieldName] = [];
            this.setState(newState);
        };

        setSearchOptions(field);
        return {search, searchOptions, initTempOptions, searchWithCallback};
    };
}
