import React from "react";
import MemoizedMarker from "./MemoizedMarker";

const ProposalServiceMarkers = ({
                                    data,
                                    groupedByLatitude,
                                    route_today_site,
                                    events,
                                    resource,
                                    compareEvents,
                                    flattenPSEvents,
                                    personIdForMonth,
                                    scheduledServices,
                                    handleToggleOpen,
                                    handleToggleClose,
                                    groupBy,
                                    goToProposalStatus,
                                    checkedRoute,
                                    handleToggleRouteRemoveHelper,
                                    onAddToRoute,
                                    locationState,
                                    removePsFromWo,
                                    getColor,
                                    showExclusive,
                                    activeMarkerData,
                                    reload,
                                    spiderfiedSiteId,
                                    spiderfiedIndexRef,
                                    spiderfiedCountRef,
                                    spiderfy,
                                    setSpiderfiedLocation,
                                    googleMapZoom,
                                    spiderfiedLocation,
                                    proposal_services
                                }) => {
    return Object.keys(data).map((key, i) => {
        let markerNumber = 0;
        let todaySites = [];
        const proposals = [];
        let markerData = data[key].sort((a, b) => new Date(a.date_from) - new Date(b.date_from));
        const proposalServiceWithSchedulerEventId = markerData.find(m => m.scheduler_event_id)
        const schedulerEventGuestId = markerData.find(m => m.scheduler_event_guest_id)?.scheduler_event_guest_id
        const sites = groupBy(groupedByLatitude[markerData[0]?.latitude]?.filter(m => m.scheduler_event_guest_id === schedulerEventGuestId || (!m.scheduler_event_guest_id && !proposalServiceWithSchedulerEventId.mixedMarker)), (c) => c.proposal_no);

        markerData && markerData.length > 0 && groupedByLatitude[markerData[0]?.latitude].map(a => {
            let item = {};
            item.proposal_no = a.proposal_no;
            item.id = a.id;
            item.site_id = a.site_id;
            proposals.push(item);


            todaySites.push(route_today_site.map(x => x && Object.keys(x)).reduce((arr, b) => arr.concat(b), []))


            if (events && events.length > 0) {
                const sortedEvents = events.sort((a, b) => {
                    if (new Date(a.start).getTime() > new Date(b.start).getTime()) {
                        return 1
                    } else {
                        return -1
                    }
                })

                sortedEvents.forEach(e => {
                    if (e.site_id === a.site_id) {
                        const eventIndex = events.sort(compareEvents).findIndex(e => e.site_id === a.site_id)
                        e.event_no = eventIndex + 1
                        e.proposal_no = a.proposal_no
                        markerNumber = e.event_no;
                    }
                })
            }
        });
        let markerOrderNumber = 0
        let mixedMarker = false
        const scheduledMarkerData = markerData && markerData.length > 0 && markerData.filter(i => i.scheduler_event_id)
        if (scheduledMarkerData && scheduledMarkerData.length > 0 && scheduledMarkerData[0].latitude && scheduledMarkerData[0].scheduler_event_id && (resource.calendar_view_type !== "dayGridMonth" || (scheduledMarkerData[0].person_id && personIdForMonth === scheduledMarkerData[0].person_id) || (scheduledMarkerData[0].person_id && resource.person_id === scheduledMarkerData[0].person_id))) {
            const uniqEvents = [...new Map(flattenPSEvents.map(item => [item['scheduler_event_guest_id'], item])).values()]
            markerOrderNumber = uniqEvents.findIndex(ps => scheduledMarkerData.some(e => ps.scheduler_event_guest_id === e.scheduler_event_guest_id)) + 1
            mixedMarker = flattenPSEvents.some(e => e.mixedMarker && e.site_id === data?.[key]?.[0]?.site_id)

        }
        proposal_services.forEach(data => {
            if (Object.keys(data).some(key => data[key].some(e => e.latitude === markerData[0].latitude && e.longitude === markerData[0].longitude && e.site_id !== markerData[0].site_id))) {
                markerData.forEach(e => {
                    e.mixedMarker = true;
                })
            }
        })
        const spiderfied = spiderfiedSiteId === markerData[0]?.site_id || (spiderfiedLocation.lat === markerData[0].latitude && spiderfiedLocation.lng === markerData[0].longitude)
        if (spiderfied) {
            spiderfiedIndexRef.current++
        }
        const onInfoOpen = (callback) => handleToggleOpen(proposals, proposalServiceWithSchedulerEventId || markerData[0], callback)
        return <MemoizedMarker key={`marker-${data?.[key]?.[0]?.site_id}`}
                               eventType={proposals[0]?.id === null}
                               index={i}
                               getColor={getColor}
                               resource={resource}
                               onInfoOpen={onInfoOpen}
                               onInfoClose={handleToggleClose}
                               item={markerData} sites={sites} onInfoLinkClick={goToProposalStatus}
                               checkedRoute={checkedRoute}
                               removePsFromWo={removePsFromWo} showExclusive={showExclusive}
                               locationState={locationState} scheduledServices={scheduledServices}
                               handleToggleRouteRemoveHelper={handleToggleRouteRemoveHelper}
                               onAddToRoute={onAddToRoute}
                               markerOrderNumber={markerOrderNumber}
                               activeMarkerData={activeMarkerData}
                               reload={reload}
                               mixedMarker={mixedMarker}
                               proposalServiceWithSchedulerEventId={proposalServiceWithSchedulerEventId}
                               spiderfy={(siteId) => {
                                   spiderfy(siteId)
                                   spiderfiedIndexRef.current = 0
                               }}
                               setSpiderfiedLocation={(lat, lng) => {
                                   setSpiderfiedLocation(lat, lng)
                                   spiderfiedIndexRef.current = 0
                               }}
                               spiderfiedIndex={spiderfiedIndexRef.current}
                               spiderfiedCount={spiderfiedCountRef.current}
                               spiderfied={spiderfied}
                               googleMapZoom={googleMapZoom}
        />

    })

}
export default ProposalServiceMarkers;



