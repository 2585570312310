import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const checkForDuplicateName = (resource, callback) => async (dispatch, getState) => {
    const method = "POST";
    const url = `/api/payment_types/check_for_duplicate_name`;
    const config = {
        method,
        url,
        data: {
            id: resource?.id,
            name: resource?.name
        },
    };

    apiCall(`Search for duplicates`, config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState, false);

};