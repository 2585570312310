import {apiCall} from "../../common/apiActionHandler";
import axios from "axios";

export const getSummary = (filters, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: '/api/sales_dashboard/get_new_summary',
        params: {
            filters: filters,
        }
    };

    apiCall("Getting Data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const getData = (tab, pagination, columns, sort, filters, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: '/api/sales_dashboard/get_new_data',
        params: {
            ...sort,
            ...pagination,
            tab: tab,
            show_only_proposed: true,
            columns: columns,
            filters: filters,
        }
    };

    apiCall("Getting Data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const getFilters = (callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: '/api/sales_dashboard/get_filters'
    };

    apiCall("Getting Data", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const declineProposals = (proposalIds, isBacklog = false, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/sales_dashboard/decline`,
        params: {
            isBacklog: isBacklog,
            ps_ids: proposalIds.join(',')
        }
    };

    apiCall("Declining proposals", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const sendBulkEmail = (recipients, email, selectedRows, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/emails/send_bulk_email`,
        method: 'POST',
        data: {email: {...email, recipients}, selected_rows: selectedRows}
    };

    apiCall("Email sending", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState);
};

export const sendBulkNotification = (email, recipients, emailType, emailTemplateId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/twilio/send_notification`,
        method: 'POST',
        data: {email: email, recipients: recipients, email_type: emailType, email_template_id: emailTemplateId}
    };

    apiCall("Notification sent", config, async config => {
        const response = await axios.request(config);
        callback && callback(response);
    }, dispatch, getState);
};

export const loadProposalData = (proposalId, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/proposals/${proposalId}`
    };

    apiCall("Loading proposal", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};