import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, FormGroup, Glyphicon, Grid, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Select from "react-select";
import {GoogleMap, Marker} from "react-google-maps";
import moment from "moment-timezone";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import * as actions from "./LeadsApi";
import {
    defaultDateFormat, defaultDateFormatShort,
    defaultDateTimeFormat,
    defaultMapParams,
    defaultTimeFormat,
    select
} from "../../common/commonHandlers";
import {getColorIcon} from "../MapViewPage/IconProvider";
import ResourceComponent from "../../components/ResourceComponent";
import Datetime from "react-datetime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {browserHistory, Link} from "react-router";
import SplitPane from "react-split-pane";
import LeadsFilterModal from "./LeadsFilterModal";
import './Leads.css';
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import {debounce} from "throttle-debounce";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import DuplicatesModal from "../CustomerPage/CustomerInfo/DuplicatesModal";
import _ from 'lodash'
import {getBasicMarker} from "../../utilities";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

class Leads extends ResourceComponent {
    state = {
        filterVisible: true,
        leads: [],
        resource: {
            status: 'LEAD',
            customer_id: [],
            label: "Custom",
            sort: 'DESC',
            sort_by: 'leads',
            page: 1,
            on_site: false,
            confirmed: false,
            per_page: 25,
            inBounds: false,
            filterByNewCustomer: false,
            inactive: false
        },
        employees: [],
        center: true,
        total: 0,
        citySearchResults: [],
        zipSearchResults: [],
        assetSearchResults: [],
        LeadsFilterModalShow: false,
        dateFilterOptions: [
            {value: 'All', label: 'All'},
            {value: 'Today', label: 'Today'},
            {value: 'Yesterday', label: 'Yesterday'},
            {value: 'This Month', label: 'This Month'},
            {value: 'Custom', label: 'Custom'}],
        selectedLeads: [],
        allSelected: false,
        showDuplicatesModal: false
    };

    constructor(props, context) {
        super(props, context);
        this.customerSearch = {
            searchOptions: () => [],
            search: () => {
            }
        };
        this.delayedInBounds = debounce(2000, this.inBounds);
    }

    componentDidMount() {
        let {resource} = this.state;
        const {roles} = this.props;
        this.customerSearch = this.buildSearchComponent('customer_id', this.props.actions.searchForCustomer);

        let hasSchedulerRole;
        if (roles && roles.length > 0) {
            hasSchedulerRole = roles.some(r => r === "scheduler")
        }

        this.props.actions.load(result => {
            this.setState(result);

            if (hasSchedulerRole) {
                resource.person_id = null;
            } else if (this.props && this.props.roles.includes('sales_arborist')) {
                resource.person_id = result.employees.find(e => e.label === this.props.auth.name).value;
            }

            resource.created_from = moment().subtract(30, "days").startOf('day');
            resource.created_to = moment().endOf('day');
            this.setState(resource, () => this.handleFilterChange())
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {resource} = this.state;
        if (resource.label === "All") {
            resource.created_from = null;
            resource.created_to = null;
        }
    }

    eventsFormatter = (cell, row) => {
        return <ul className={'no-padding'}>
            {row.scheduler_events.map((event, index) =>
                <li key={index}
                    className={`wrap`}>{event.type}: {moment(event.date_from).format(defaultDateTimeFormat)}</li>
            )}
        </ul>
    };
    customerFormatter = (cell, row) => {
        return <Link to={`/customer/info/${row.customer_id}`} className="clean-menu-item">
            <div>
                <div>{row.customer_name}</div>
                <div>{row.site_name ? row.site_name : "Billing Address"}</div>
                <div>{row.address && row.address}</div>
            </div>
        </Link>
    };
    dateFormatter = (cell, row) => {
        return <div>
            <div className={'wrapped-column'}><div className='date-part'>{moment(row.created_at).format(defaultDateFormatShort)}</div><div className='time-part'>{moment(row.created_at).format("ha")}</div></div>
        </div>
    };

    rowStyleFormat = (cell, row) => {
        const {leads} = this.state;
        if (cell && cell.inactive) {
            return {backgroundColor: '#EBEBE4'}
        }
        return {backgroundColor: leads && leads.map((e, i) => (e.person_id === null && e.scheduler_events.length === 0) ? i : -1).filter(index => index !== -1).includes(row) ? '#ffebee' : null}
    };

    classNameFormat = (row, rowIndex) => {
        const {leads} = this.state;
        const selectedMarker = this.selectedMarkerClass(row)
        return selectedMarker + " " + (leads && leads.map((e, i) => (e.person_id === null && e.scheduler_events.length === 0) ? i : -1).filter(index => index !== -1).includes(rowIndex) ? 'inactive' : null)
    }

    indexN = (cell, row, enumObject, index) => {
        return (<div>{index + 1}</div>)
    };

    rowFormatter = (cell, row, enumObject, index) => {
        return <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{this.indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Lead #</Col>
                <Col xs={6}>{row.id}</Col>
            </Row>
            <Row>
                <Col xs={6}>Customer</Col>
                <Col xs={6}>{this.customerFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Scheduler Event</Col>
                <Col xs={6}>{this.eventsFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Arborist</Col>
                <Col xs={6}>{row.person_name}</Col>
            </Row>
            <Row>
                <Col xs={6}>Created</Col>
                <Col xs={6}>{this.dateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6}>
                    <span>
                        <Link onClick={(e) => {
                            e.stopPropagation()
                            this.props.actions.loadCustomerInfo(row.customer_id, (customer) => {
                                const value = {
                                    customer_id: customer?.id,
                                    company_name: customer?.company_name,
                                    first_name: customer?.first_name,
                                    last_name: customer?.last_name,
                                    phone_number: customer?.phone_number,
                                    phone_number2: customer?.phone_number2,
                                    address_1: customer?.address_1
                                }
                                this.props.actions.showDuplicates(value, res => {
                                    if (_.isEmpty(res.company) && _.isEmpty(res.address) && _.isEmpty(res.name) && _.isEmpty(res.phone)) {
                                        browserHistory.push(`${row.proposal_id ? 'proposal_lead' : 'lead'}/${row.id}`)
                                    } else {
                                        this.setState({
                                            customer: customer,
                                            showDuplicatesModal: true,
                                            selectedRow: row
                                        })
                                    }
                                })
                            });
                        }}
                              className="pointer"><FontAwesomeIcon icon="edit" className="bigger"/></Link>
                        {this.state.resource.show_duplicated_leads && <>
                            <Link onClick={(e) => {
                                e.stopPropagation();
                                const choice = window.confirm('Please be sure! You are about to delete this Lead and all tasks and scheduled events associated with it!');
                                if (choice) {
                                    this.props.actions.deleteLead(row.id, () => {
                                        this.setState({leads: this.state.leads.filter(l => l.id !== row.id)})
                                    })
                                }
                            }} className="pointer">
                                <FontAwesomeIcon icon={"trash-alt"} className="bigger"/>
                            </Link>
                            <Link onClick={(e) => {
                                e.stopPropagation();
                                const choice = window.confirm('The lead will be mark as not duplicated.');
                                if (choice) {
                                    this.props.actions.setAsUnique(row.id, () => {
                                        this.setState({leads: this.state.leads.filter(l => l.id !== row.id)})
                                    })
                                }
                            }} className="pointer">
                                <FontAwesomeIcon icon={"check"} className="bigger"/>
                            </Link>
                        </>}
                    </span>
                </Col>
            </Row>
        </div>
    };

    handleFilterChange() {
        this.props.actions.getLeads({...this.state.resource}, results => {
            let uniqueSites = [];
            results.content.map(lead => {
                uniqueSites.push(lead.site_id);
                const singularSiteCount = uniqueSites.filter(s => s === lead.site_id).length;
                if (singularSiteCount > 1) {
                    lead.longitude += singularSiteCount * 0.000015;
                }
            })
            this.setState({leads: results.content, total: results.total, loaded: this.state.resource.inBounds});
        });
    };

    inBounds = () => {
        let {resource} = this.state;
        if (resource.inBounds) {
            resource.min_lng = this.map.getBounds().getNorthEast().lng();
            resource.max_lng = this.map.getBounds().getSouthWest().lng();
            resource.min_lat = this.map.getBounds().getNorthEast().lat();
            resource.max_lat = this.map.getBounds().getSouthWest().lat();
            this.setState({resource}, this.handleFilterChange)
        }
    };

    render() {
        const {
            filterVisible,
            leads,
            employees,
            resource,
            LeadsFilterModalShow,
            customers,
            customerTypes,
            assetTypes,
            assets,
            total,
            center,
            citySearchResults,
            zipSearchResults,
            assetSearchResults,
            referrals,
            dateFilterOptions,
            serviceTypes,
            services,
            reasons,
            filterByNewCustomer,
            showDuplicatesModal
        } = this.state;
        const {client} = this.props;
        const customerOptions = this.customerSearch.searchOptions();
        const {page, per_page, sort, sort_by} = resource;
        const statusOptions = [
            {value: true, label: 'Complete'},
            {value: false, label: 'To Do'}
        ];
        const statuses = [
            {value: 'CREATE', label: 'All'},
            {value: 'LEAD', label: 'Lead'},
            {value: 'PROPOSED', label: 'Proposed'},
            {value: 'ACTIVE', label: 'Active'},
            {value: 'DONE', label: 'Done'}
        ];
        if (center) {
            this.setState({center: false});
        }
        const selectedLead = leads.filter(x => x.selected);

        return (
            <Grid fluid id='leads'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter">
                        <h2 className="no-top-margin mr-9">Leads</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{total} returned</h5>
                    </Col>
                    <Col md={8} className="text-right">
                        <Link to='/lead'>
                            <Button bsStyle="primary" className="no-top-margin small-margin">New {" "} <FontAwesomeIcon
                                icon="plus" className="small"/></Button>
                        </Link>
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => this.setState({
                                LeadsFilterModalShow: !this.state.LeadsFilterModalShow,
                                oldResource: {...resource}
                            })}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <hr className={'mb-10-mt-0'}/>
                <LeadsFilterModal
                    title="Leads Filter"
                    show={LeadsFilterModalShow}
                    onHide={() => {
                        this.setState({
                            LeadsFilterModalShow: !this.state.LeadsFilterModalShow,
                        })
                    }}
                    closeButton={() => this.setState({
                        LeadsFilterModalShow: !this.state.LeadsFilterModalShow,
                        resource: this.state.oldResource
                    })}
                >
                    <Row className={'modalLeadsFilter'}>
                        <Col md={12}>
                            <Select className="Select bottom15 top15"
                                    classNamePrefix="select"
                                    options={dateFilterOptions}
                                    value={select(dateFilterOptions, resource.label)}
                                    placeholder="Date"
                                    onChange={this.selectResourceAttr('label', () => {
                                        this.handleFilterChange()
                                    })}
                            />
                        </Col>
                        {resource.label === "Custom" && <Col md={12} className={"bottom15"}>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={resource.created_from}
                                className="dataTimeLeads"
                                inputProps={{placeholder: 'Created from'}}
                                onChange={this.dateResourceAttr('created_from', () => this.handleFilterChange())}/>
                            <span className='text-default margin10'><Glyphicon glyph='minus'/></span>
                            <Datetime
                                dateFormat={defaultDateFormat}
                                timeFormat={defaultTimeFormat}
                                value={resource.created_to}
                                className="dataTimeLeads"
                                inputProps={{placeholder: 'Created to'}}
                                onChange={this.dateResourceAttr('created_to', () => this.handleFilterChange())}
                            />
                        </Col>}
                        <Col md={6}>
                            <Select className="Select bottom15" classNamePrefix="select"
                                    value={select(employees, resource.person_id)}
                                    options={employees}
                                    isClearable
                                    onChange={this.selectResourceAttr('person_id', () => this.handleFilterChange())}
                                    placeholder="Arborist"
                            />
                        </Col>
                        <Col md={6}>
                            <Select className="Select bottom15"
                                    classNamePrefix="select"
                                    value={select(statusOptions, resource.completed)}
                                    options={statusOptions}
                                    placeholder="Status"
                                    isClearable
                                    onChange={this.selectResourceAttr('completed', () => this.handleFilterChange())}
                            />
                        </Col>
                    </Row>
                    <hr className={'marginBottom10'}/>
                    <Row className={'modalLeadsFilter'}>
                        <Col md={6}>
                            {customerTypes && <FormGroup>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(customerTypes, resource.customerType_id)}
                                        options={customerTypes}
                                        onChange={e => {
                                            this.selectResourceAttr('customerType_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Customer Type"
                                        isClearable
                                />
                            </FormGroup>}
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(customerOptions, resource.customer_id)}
                                        options={customerOptions}
                                        placeholder="Customer"
                                        isMulti
                                        isClearable
                                        onInputChange={this.customerSearch.search}
                                        onChange={this.selectResourceAttr('customer_id', () => this.handleFilterChange())}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {referrals && referrals.length > 0 && <FormGroup>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(referrals, resource.referral_id)}
                                        options={referrals}
                                        onChange={e => {
                                            this.selectResourceAttr('referral_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Referral"
                                        isClearable
                                />
                            </FormGroup>}
                        </Col>
                        <Col md={6}>
                            {reasons && reasons.length > 0 && <FormGroup>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(reasons, resource.reason_id)}
                                        options={reasons}
                                        onChange={e => {
                                            this.selectResourceAttr('reason_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Reason"
                                        isClearable
                                />
                            </FormGroup>}
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(citySearchResults, resource.city)}
                                        options={citySearchResults}
                                        placeholder="City"
                                        isMulti
                                        onInputChange={e =>
                                            this.props.actions.searchForCity(e, this.handleSearchResults('city', 'citySearchResults'))
                                        }
                                        onChange={e => {
                                            this.selectResourceAttr('city', this.handleFilterChange)(e);
                                        }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(zipSearchResults, resource.zip)}
                                        options={zipSearchResults}
                                        placeholder="Zip"
                                        isMulti
                                        onInputChange={e =>
                                            this.props.actions.searchForZip(e, this.handleSearchResults('zip', 'zipSearchResults'))
                                        }
                                        onChange={e => {
                                            this.selectResourceAttr('zip', this.handleFilterChange)(e);
                                        }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className={'marginBottom10'}/>
                    <Row className={'modalLeadsFilter'}>
                        <Col md={6} className="bottom15">
                            {serviceTypes && serviceTypes.length > 0 &&
                                <Select className="Select" classNamePrefix="select"
                                        value={select(serviceTypes, resource.serviceType_id)}
                                        options={serviceTypes}
                                        onChange={e => {
                                            this.selectResourceAttr('serviceType_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Service Type"
                                        isClearable
                                />}
                        </Col>
                        <Col md={6} className="bottom15">
                            {services && services.length > 0 &&
                                <Select className="Select" classNamePrefix="select"
                                        value={select(services, resource.services_id)}
                                        options={services}
                                        onChange={e => {
                                            this.selectResourceAttr('services_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Service"
                                        isClearable
                                />}
                        </Col>
                        <Col md={6} className="bottom15">
                            {assetTypes && assetTypes.length > 0 &&
                                <Select className="Select" classNamePrefix="select"
                                        value={select(assetTypes, resource.assetTypes_id)}
                                        options={assetTypes}
                                        onChange={e => {
                                            this.selectResourceAttr('assetTypes_id', this.handleFilterChange)(e)
                                        }}
                                        placeholder="Asset Type"
                                        isClearable
                                />}
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Select className="Select"
                                        classNamePrefix="select"
                                        value={select(assetSearchResults, resource.asset_id)}
                                        options={assetSearchResults}
                                        placeholder="Asset"
                                        isClearable
                                        onInputChange={e =>
                                            this.props.actions.searchForAsset(e, this.handleSearchResults('asset_id', 'assetSearchResults'))
                                        }
                                        onChange={e => {
                                            this.selectResourceAttr('asset_id', this.handleFilterChange)(e);
                                        }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr className={'marginBottom10'}/>
                    <Row>
                        <Col md={6} xs={12}>
                            <ColorCheckbox value={resource.inBounds}
                                           className={isMobile ? null : 'leadsCheckbox'}
                                           label='Show leads in bounds'
                                           onChange={this.selectCheckboxAttr('inBounds', this.handleFilterChange)}
                            />
                        </Col>
                        <Col md={6} xs={12} style={{paddingLeft: 0}}>
                            <ColorCheckbox value={resource.filterByNewCustomer}
                                           className={isMobile ? null : 'leadsCheckbox'}
                                           label='Sort by new customer'
                                           onChange={this.selectCheckboxAttr('filterByNewCustomer', this.handleFilterChange)}
                            />
                        </Col>
                        <Col md={6} xs={12} className="top10">
                            <ColorCheckbox value={resource.inactive}
                                           className={isMobile ? null : 'leadsCheckbox'}
                                           label='Show inactive leads'
                                           onChange={this.selectCheckboxAttr('inactive', this.handleFilterChange)}
                            />
                        </Col>
                        <Col md={6} xs={12} className="top10" style={{paddingLeft: 0}}>
                            <ColorCheckbox value={resource.show_duplicated_leads}
                                           className={isMobile ? null : 'leadsCheckbox'}
                                           label='Show possible duplicated leads'
                                           onChange={this.selectCheckboxAttr('show_duplicated_leads', this.handleFilterChange)}
                            />
                        </Col>
                        <Col md={6} xs={12} className="top10">
                            <ColorCheckbox value={resource.show_duplicated_customers}
                                           className={isMobile ? null : 'leadsCheckbox'}
                                           label='Show possible duplicated customers'
                                           onChange={this.selectCheckboxAttr('show_duplicated_customers', this.handleFilterChange)}
                            />
                        </Col>
                    </Row>
                </LeadsFilterModal>
                {isMobile ?
                    <Row>
                        <div className="padding10 text-center">
                            <ToggleButtonGroup type="radio"
                                               name="options"
                                               value={resource.status}
                                               onChange={this.selectCheckboxAttr('status', () => this.handleFilterChange())}
                            >
                                {statuses.map((s, i) =>
                                    <ToggleButton
                                        key={i}
                                        value={s.value}
                                    >
                                        {s.label}
                                    </ToggleButton>)
                                }
                            </ToggleButtonGroup>
                        </div>
                        <div className="padding10 flex-column-start flex-gap-10">
                            <Button bsStyle="success"
                                    onClick={() => this.props.actions.downloadCsv({
                                        ...resource,
                                        selected: this.state.allSelected ? 'all' : this.state.selectedLeads
                                    })}>Export to CSV</Button>
                            <div className="d-flex flex-align-center">
                                <div className="no_se_arborist_legend"></div>
                                <span className="marginLeft5">- No Arborist and Scheduler Event</span>
                            </div>
                            <div className="d-flex flex-align-center">
                                <div className="inactive_customers_legend"></div>
                                <span className="marginLeft5">- Inactive Leads</span>
                            </div>
                        </div>
                        <Col md={7} className="remaining vertical-responsive-height">
                            <BootstrapTable
                                ref="table"
                                data={leads}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={true}
                                remote
                                fetchInfo={{dataTotalSize: total}}
                                trStyle={this.rowStyleFormat}
                                className="wrapped"
                                trClassName={this.classNameFormat}
                                options={
                                    {
                                        onPageChange: (page, per_page) => {
                                            let {resource} = this.state;
                                            resource = {...resource, page, per_page};
                                            this.setState({resource}, this.handleFilterChange);
                                        },
                                        page,
                                        sizePerPageDropDown: this.renderSizePerPageDropDown,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '25', value: 25
                                        },
                                            {
                                                text: '30', value: 30
                                            },
                                            {
                                                text: '50', value: 50
                                            },
                                            {
                                                text: '100', value: 100
                                            },
                                            {
                                                text: '200', value: 200
                                            },
                                            {
                                                text: 'All', value: total
                                            }],
                                        sizePerPage: per_page,
                                        onRowClick: row => {
                                            this.selectMarker('leads')(row);
                                        },
                                        sortName: sort_by,
                                        sortOrder: sort,
                                        onSortChange: (sort_by, sort) => {
                                            let {resource} = this.state;
                                            resource = {...resource, sort_by, sort};
                                            this.setState({resource}, this.handleFilterChange);
                                        }
                                    }
                                }
                                selectRow={{
                                    mode: "checkbox",
                                    clickToSelect: false,
                                    selected: selectedLead,
                                    onSelect: () => {
                                    },
                                    onSelectAll: () => {
                                    },
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="id"
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        <Col md={5} className="bottom15">
                            <GoogleMapLoader
                                containerElement={
                                    <div
                                        id="leadsMap"
                                        className="remaining vertical-responsive-height"
                                    />
                                }
                                googleMapElement={
                                    <GoogleMap
                                        ref={it => this.refGoogleMap(it, leads.filter(lead => lead.latitude), (res) => {
                                            this.map = it;
                                        })}
                                        onIdle={() => {
                                            this.delayedInBounds()
                                        }}
                                        {...defaultMapParams(leads, center, null, client)}
                                    >
                                        {leads.map((lead, index) => {
                                            const employee = select(employees, lead.person_id);
                                            const employeeColor = (employee ? employee.color : '#3a87ad');
                                            return lead.latitude && (
                                                <Marker
                                                    key={lead.id}
                                                    position={{lat: lead.latitude, lng: lead.longitude}}
                                                    icon={getBasicMarker({selected: lead.selected, color: employeeColor, label: (index + 1).toString()})}
                                                    title={lead.customer_name}
                                                    onClick={() => {
                                                        const page = this.refs.table.getPageByRowKey(lead.id);
                                                        this.setState({page});
                                                        this.selectMarker('leads')(lead);
                                                    }}
                                                    zIndex={lead.selected ? 1000 : null}
                                                />
                                            )
                                        })}
                                    </GoogleMap>
                                }
                            />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <SplitPane split="vertical" defaultSize={"50%"} minSize={500}>
                            <div>
                                <div className={'leadsToggleButtonGroup'}>
                                    <ToggleButtonGroup type="radio"
                                                       name="options"
                                                       value={resource.status}
                                                       onChange={this.selectCheckboxAttr('status', () => this.handleFilterChange())}
                                    >
                                        {statuses.map((s, i) =>
                                            <ToggleButton
                                                key={i}
                                                value={s.value}
                                            >
                                                {s.label}
                                            </ToggleButton>)
                                        }
                                    </ToggleButtonGroup>
                                </div>
                                <Row className="vertical-align">
                                    <Col md={2} className={isMobile ? null : "pl-20"}>
                                        <Button bsStyle="success"
                                                onClick={() => this.props.actions.downloadCsv({
                                                    ...resource,
                                                    selected: this.state.allSelected ? 'all' : this.state.selectedLeads
                                                })}>Export to CSV</Button>
                                    </Col>
                                    <Col md={5} className="vertical-align marginLeft10 bottom5 max_width_36 font12">
                                        <div>
                                            <div className="vertical-align">
                                                <div className="no_se_arborist_legend"></div>
                                                <span className="marginLeft5">- No Arborist and Scheduler Event </span>
                                            </div>
                                            <div className="vertical-align">
                                                <div className="inactive_customers_legend"></div>
                                                <span className="marginLeft5">- Inactive Leads</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    ref="table"
                                    data={leads}
                                    striped={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={true}
                                    remote
                                    fetchInfo={{dataTotalSize: total}}
                                    trStyle={this.rowStyleFormat}
                                    className="wrapped"
                                    trClassName={this.classNameFormat}
                                    options={
                                        {
                                            onPageChange: (page, per_page) => {
                                                let {resource} = this.state;
                                                resource = {...resource, page, per_page};
                                                this.setState({resource}, this.handleFilterChange);
                                            },
                                            page,
                                            sizePerPageDropDown: this.renderSizePerPageDropDown,
                                            sizePerPageList: [
                                                {
                                                    text: '10', value: 10
                                                },
                                                {
                                                    text: '25', value: 25
                                                },
                                                {
                                                    text: '30', value: 30
                                                },
                                                {
                                                    text: '50', value: 50
                                                },
                                                {
                                                    text: '100', value: 100
                                                },
                                                {
                                                    text: '200', value: 200
                                                },
                                                {
                                                    text: 'All', value: total
                                                }],
                                            sizePerPage: per_page,
                                            onRowClick: row => {
                                                this.selectMarker('leads')(row);
                                            },
                                            sortName: sort_by,
                                            sortOrder: sort,
                                            onSortChange: (sort_by, sort) => {
                                                let {resource} = this.state;
                                                resource = {...resource, sort_by, sort};
                                                this.setState({resource}, this.handleFilterChange);
                                            }
                                        }
                                    }
                                    selectRow={{
                                        mode: "checkbox",
                                        clickToSelect: false,
                                        selected: this.state.allSelected ? leads.map(x => x.id) : this.state.selectedLeads,
                                        onSelect: (row, selected) => {
                                            let {selectedLeads} = this.state;
                                            if (!selected) {
                                                const index = selectedLeads.indexOf(row.id);
                                                if (index > -1) {
                                                    selectedLeads.splice(index, 1);
                                                }
                                            } else {
                                                selectedLeads.push(row.id);
                                            }
                                            this.setState(selectedLeads)
                                        },
                                        onSelectAll: () => {
                                            let {allSelected} = this.state;
                                            this.setState({allSelected: !allSelected})
                                        },
                                    }}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        hidden
                                        isKey
                                    />
                                    <TableHeaderColumn
                                        dataField="any"
                                        dataFormat={this.indexN}
                                        width={"5%"}
                                    >
                                        #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataSort
                                        width={"10%"}
                                        className={'word-break'}
                                    >
                                        Lead #
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="customer_name"
                                        dataFormat={this.customerFormatter}
                                        dataSort
                                        width={"25%"}
                                    >
                                        Customer
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="scheduler_events"
                                        dataSort
                                        width={"20%"}
                                        dataFormat={this.eventsFormatter}
                                    >
                                        Scheduler Event
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="person_name"
                                        dataSort
                                        width={"15%"}
                                    >
                                        Arborist
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="created_at"
                                        dataSort
                                        width={"10%"}
                                        dataFormat={this.dateFormatter}
                                    >
                                        Created
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        width={"15%"}
                                        dataFormat={(cell, row) => {
                                            return <span>
                                    <Link onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.actions.loadCustomerInfo(row.customer_id, (customer) => {
                                            const value = {
                                                customer_id: customer?.id,
                                                company_name: customer?.company_name,
                                                first_name: customer?.first_name,
                                                last_name: customer?.last_name,
                                                phone_number: customer?.phone_number,
                                                phone_number2: customer?.phone_number2,
                                                address_1: customer?.address_1
                                            }
                                            this.props.actions.showDuplicates(value, res => {
                                                if (_.isEmpty(res.company) && _.isEmpty(res.address) && _.isEmpty(res.name) && _.isEmpty(res.phone)) {
                                                    browserHistory.push(`${row.proposal_id ? 'proposal_lead' : 'lead'}/${row.id}`)
                                                } else {
                                                    this.setState({
                                                        customer: customer,
                                                        showDuplicatesModal: true,
                                                        selectedRow: row
                                                    })
                                                }
                                            })
                                        });
                                    }}
                                          className="pointer"><FontAwesomeIcon icon="edit" className="bigger"/></Link>
                                                {resource.show_duplicated_leads && <>
                                                    <Link onClick={(e) => {
                                                        e.stopPropagation();
                                                        const choice = window.confirm('Please be sure! You are about to delete this Lead and all tasks and scheduled events associated with it!');
                                                        if (choice) {
                                                            this.props.actions.deleteLead(row.id, () => {
                                                                this.setState({leads: leads.filter(l => l.id !== row.id)})
                                                            })
                                                        }
                                                    }} className="pointer">
                                                        <FontAwesomeIcon icon={"trash-alt"} className="bigger"/>
                                                    </Link>
                                                    <Link onClick={(e) => {
                                                        e.stopPropagation();
                                                        const choice = window.confirm('The lead will be mark as not duplicated.');
                                                        if (choice) {
                                                            this.props.actions.setAsUnique(row.id, () => {
                                                                this.setState({leads: leads.filter(l => l.id !== row.id)})
                                                            })
                                                        }
                                                    }} className="pointer">
                                                        <FontAwesomeIcon icon={"check"} className="bigger"/>
                                                    </Link>
                                                </>}
                                </span>
                                        }}
                                    >
                                        Options
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            <div>
                                <GoogleMapLoader
                                    containerElement={
                                        <div
                                            id="leadsMap"
                                            className="remaining vertical-responsive-height"
                                        />
                                    }
                                    googleMapElement={
                                        <GoogleMap
                                            ref={it => this.refGoogleMap(it, leads.filter(lead => lead.latitude), (res) => {
                                                this.map = it;
                                            })}
                                            onIdle={() => {
                                                this.delayedInBounds()
                                            }}
                                            {...defaultMapParams(leads, center, null, client)}
                                        >
                                            {leads.map((lead, index) => {
                                                const employee = select(employees, lead.person_id);
                                                const employeeColor = (employee ? employee.color : '#3a87ad');
                                                return lead.latitude && (
                                                    <Marker
                                                        key={lead.id}
                                                        position={{lat: lead.latitude, lng: lead.longitude}}
                                                        icon={getBasicMarker({selected: lead.selected, color: employeeColor, label: (index + 1).toString()})}
                                                        title={lead.customer_name}
                                                        onClick={() => {
                                                            const page = this.refs.table.getPageByRowKey(lead.id);
                                                            this.setState({page});
                                                            this.selectMarker('leads')(lead);
                                                        }}
                                                        zIndex={lead.selected ? 1000 : null}
                                                    />
                                                )
                                            })}
                                        </GoogleMap>
                                    }
                                />
                            </div>
                        </SplitPane>
                    </Row>
                }
                {showDuplicatesModal &&
                    <DuplicatesModal
                        customer={this.state.customer}
                        findDuplicates={this.props.actions.showDuplicates}
                        onHide={() => this.setState({showDuplicatesModal: false})}
                        redirectFunction={() => browserHistory.push(`${this.state.selectedRow.proposal_id ? 'proposal_lead' : 'lead'}/${this.state.selectedRow.id}`)}
                        enableRedirect={true}
                        mergeCustomer={this.props.actions.mergeCustomer}
                    />
                }
            </Grid>
        );
    }
}

Leads.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Leads.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        roles: state.auth.roles,
        auth: state.auth,
        client: state.client.customerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
