import {addAuthorizationHeader, addTimeZoneHeader, apiCall} from "../../../common/apiActionHandler";
import axios from "axios";
import * as FileSaver from "file-saver";
import {addAlert} from "../../App/actions";

export const getBackups = (callback) => (dispatch, getState) => {
    const config = {
        method: "get",
        url: `/api/backups`
    };

    apiCall("Getting Backups", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const createBackup = (name, callback) => (dispatch, getState) => {
    const config = {
        method: "POST",
        url: `/api/backups/dump`,
        data: {name}
    };

    apiCall("Creating Backup", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const updateBackup = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "PUT",
        url: `/api/backups/update`,
        data: {id}
    };

    apiCall("Updating Backup", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadBackup = (id, callback) => (dispatch, getState) => {
    const config = {
        method: "GET",
        url: `/api/backups/load`,
        params: {id}
    };

    apiCall("Loading Backup", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const downloadBackup = (id, filename) => async (dispatch, getState) => {
    let config = {
        method: "GET",
        url: `/api/backups/download`,
        params: {id},
        responseType: 'blob'
    };
    config = addAuthorizationHeader(config, getState);
    config = addTimeZoneHeader(config, getState);
    try {
        const response = await axios.request(config);
        const blob = new Blob([response.data], {
            type: 'application/zip',
        });
        FileSaver.saveAs(blob, `${filename}.zip`);
    } catch (exception) {
        dispatch(
            addAlert({
                message: "Unable to download backup" + exception.toString(),
            })
        );
        throw exception
    }
};