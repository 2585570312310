import {addAlert} from "../containers/App/actions";
import request from "superagent";
import Compress from "compress.js";
import EXIF from "exif-js";
import JSZip from 'jszip';
import moment from "moment-timezone";
import {findEXIFinHEIC} from "../utilities/heic-exif";
import {UPLOAD_FORMAT_DATE_ONLY, UPLOAD_FORMAT_FULL, UPLOAD_FORMAT_TIME_ONLY} from "../constants";
import config from "../config/environment";
import heic2any from "heic2any";


export const dataUpload = (url, acceptedFiles, callback, customAlert, data, showSuccessMessage = true) => (dispatch, getState) => {
        showSuccessMessage && dispatch(
            addAlert({
                message: "Uploading. Please wait...",
                mood: "warning",
                dismissAfter: 3000,
            }));
    function convertHeic(file, callback) {
        const blob = new Blob([file], {type: 'image/heic'})
        const promise1 = new Promise((resolve, reject) => {
            resolve(blob);
        });

        promise1.then((blob) =>
            /*eslint no-undef: "off"*/
            heic2any({
                blob,
                toType: "image/jpeg",
                quality: 1,
            })
        )
            .then((conversionResult) => {
                callback(conversionResult)
            })
            .catch((e) => {
                console.log(e)
            });

    }

        function doUpload(file) {
            let req = request
                .post(`${config.apiUrl}${url}`)
                .set("Authorization", getState().auth.token)

            if (data) {
                req.field(data)
            }
            req.attach("file", file);

            req.then(
                response => {
                    showSuccessMessage && dispatch(
                        addAlert({
                            message: customAlert ? customAlert(response) : "Uploaded",
                            mood: "success",
                            dismissAfter: 1500,
                        })
                    );
                    callback && callback(response.body);
                },
                error => {
                    if (error instanceof TypeError) {
                        dispatch(addAlert({message: error.toString()}));
                        console.error('Network error or CORS issue:', error);
                    } else if (error.name === 'AbortError') {
                        dispatch(addAlert({message: error.toString()}));
                        console.error('Request was aborted:', error);
                    } else if (!error.status) {
                        console.log('Request was aborted:', error);
                    } else {
                        dispatch(addAlert({message: error.toString()}));
                        console.error("Upload failed", error);
                    }
                }
            )
        }

        let targetFile;

        if (acceptedFiles.constructor === Array || acceptedFiles.constructor === FileList) {
            targetFile = acceptedFiles[0];
        } else {
            targetFile = acceptedFiles
        }

        if (!targetFile.type && (targetFile.name.includes('.heic') || targetFile.name.includes('.heif'))
            || (targetFile.type.includes('image/heic') || targetFile.type.includes('image/heif'))) {
            convertHeic(targetFile, (result) => {
                result.name = targetFile.name;
                compressAndUpload(result, 1)
            })
        }
        else if (targetFile.type.includes("video/webm")) {
            if (!targetFile.name){
                targetFile.name = "issue_video.webm"
            }
            doUpload(targetFile)
        }
        else if (targetFile.type.includes('image/')) {
            const compress = new Compress();
            compress.compress([targetFile], {
                size: 4,
                quality: .75,
                maxWidth: 1920,
                maxHeight: 1920,
                resize: true,
            }).then((result) => {
                const compressed = result[0];
                const file = Compress.convertBase64ToFile(compressed.data, compressed.ext);
                file.name = targetFile.name;
                doUpload(file);
            });
        } else if (targetFile.name && targetFile.name.includes('.key')) {
            let zip = new JSZip();
            zip.file(`${targetFile.name}`, targetFile);
            zip.generateAsync({type: "blob"})
                .then((file) => {
                    file.name = targetFile.name
                    doUpload(file)
                });
        } else {
            doUpload(targetFile);
        }

    function compressAndUpload(targetFile, orientation) {
        const img = new Image;
        img.src = URL.createObjectURL(targetFile);
        if (targetFile.type.includes('image/')) {
            const compress = new Compress();
            compress.compress([targetFile], {
                size: 4,
                quality: .75,
                maxWidth: 1920,
                maxHeight: 1920,
                resize: true
            }).then((result) => {
                const compressed = result[0];
                const file = Compress.convertBase64ToFile(compressed.data, compressed.ext);
                file.name = targetFile.name;

                if (!file.name) {
                    file.name = acceptedFiles[0].name
                }
                doUpload(file, orientation);
            });
        } else {
            doUpload(targetFile);
        }
    }
    }
;


export const handleUpload = (url, acceptedFiles, callback, customAlert, fileName = null, override_name = false, clientTimezone, timestampType, asHidden = false, isAsset = false) => (dispatch, getState) => {
    const noDate = timestampType === "no_date";
    const dateOnly = timestampType === "created_at_d" || timestampType === "taken_at_d";
    const timeOnly = timestampType === "created_at_t" || timestampType === "taken_at_t";

    console.time("### upload time")

    dispatch(
        addAlert({
            message: "Uploading. Please wait...",
            mood: "warning",
            dismissAfter: 3000,
        }));

    function doUpload(file, orientation, takenAtDateFormatted) {
        let orient = orientation ? orientation : 1
        let req;
        let takenAtTimestampZoned = moment(takenAtDateFormatted).format();
        let uploadedAtTimestampZoned = moment().format();

        if (clientTimezone) {
            takenAtTimestampZoned = moment(takenAtDateFormatted).tz(clientTimezone).format();
            uploadedAtTimestampZoned = moment().tz(clientTimezone).format();
        }

        let saveFormat = UPLOAD_FORMAT_FULL;

        if (timeOnly && !noDate) {
            saveFormat = UPLOAD_FORMAT_TIME_ONLY;
        }

        if (dateOnly && !noDate) {
            saveFormat = UPLOAD_FORMAT_DATE_ONLY;
        }

        if ((takenAtTimestampZoned || uploadedAtTimestampZoned) && isAsset) {
            if (takenAtTimestampZoned === "Invalid date" || takenAtDateFormatted === null) {
                dispatch(
                    addAlert({
                        message: "Uploaded image has no Taken At date",
                        mood: "warning",
                        dismissAfter: 3000,
                    }));
            }
            req = request
                .post(`${config.apiUrl}${url}`)
                .field('orientation', orient)
                .field('timestamp_taken_at', takenAtTimestampZoned)
                .field('timestamp_uploaded_at', uploadedAtTimestampZoned)
                .field('saveFormat', saveFormat)
                .field('timestamp_type', timestampType || "")
                .field('as_hidden', asHidden)
                .set("Authorization", getState().auth.token);
        } else {
            req = request
                .post(`${config.apiUrl}${url}`)
                .field('orientation', orient)
                .field('as_hidden', asHidden)
                .set("Authorization", getState().auth.token);
        }

        req.attach("file", file);
        req.then(
            response => {
                dispatch(
                    addAlert({
                        message: customAlert ? customAlert(response) : "Uploaded",
                        mood: "success",
                        dismissAfter: 1500,
                    })
                );
                console.timeEnd("### upload time")
                callback && callback(response.body);
            },
            error => {
                dispatch(addAlert({message: error.toString()}));
                console.error("Upload failed", error);
            }
        )
    }

    function convertHeic(file, callback) {
        const blob = new Blob([file], {type: 'image/heic'})
        const promise1 = new Promise((resolve, reject) => {
            resolve(blob);
        });

        promise1.then((blob) =>
            /*eslint no-undef: "off"*/
            heic2any({
                blob,
                toType: "image/jpeg",
                quality: 1,
            })
        )
            .then((conversionResult) => {
                callback(conversionResult)
            })
            .catch((e) => {
                console.log(e)
            });

    }

    if (acceptedFiles[0].type === 'application/pdf') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/pdf'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (typeof acceptedFiles === 'string' || acceptedFiles instanceof String) {
        let base64 = acceptedFiles.split(';base64,')[1]
        let ext = acceptedFiles.substring("data:image/".length, acceptedFiles.indexOf(";base64"))
        let type = acceptedFiles.substring("data:".length, acceptedFiles.indexOf(";base64"))
        const file = Compress.convertBase64ToFile(base64, type);
        file.name = `new_name.${ext}`

        if (!file.name) {
            file.name = acceptedFiles[0].name
        }
        doUpload(file);
    } else if (acceptedFiles[0].type === "image/heic" || acceptedFiles[0].type === "image/heif" ||
        acceptedFiles[0].name.includes('.heic') || acceptedFiles[0].name.includes('.heif')) {
        const reader = new FileReader();
        reader.onload = function () {
            let takenAtDateFormatted = null;
            try {
                const tags = findEXIFinHEIC(reader.result)
                const takenAtDate = tags?.DateTime;
                const inputFormat = 'YYYY:MM:DD HH:mm:ss';
                const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
                takenAtDateFormatted = takenAtDate ? moment(takenAtDate, inputFormat).format(outputFormat) : null;
            } catch (e) {
                console.log(e)
            }
            convertHeic(acceptedFiles[0], (result) => {
                compressAndUpload(result, 1, takenAtDateFormatted)
            })
        }
        reader.readAsArrayBuffer(acceptedFiles[0]);
    } else if (acceptedFiles[0].type === 'text/csv') {
        const blob = new Blob([acceptedFiles[0]], {type: 'text/csv'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/msword') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/msword'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/vnd.ms-excel') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/vnd.ms-excel'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'text/plain') {
        const blob = new Blob([acceptedFiles[0]], {type: 'text/plain'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/x-iwork-pages-sffpages') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/x-iwork-pages-sffpages'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === 'application/x-iwork-numbers-sffnumbers') {
        const blob = new Blob([acceptedFiles[0]], {type: 'application/x-iwork-numbers-sffnumbers'})
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else if (acceptedFiles[0].type === '') {
        const blob = new Blob([acceptedFiles[0]])
        blob.name = acceptedFiles[0]?.name ? acceptedFiles[0].name : fileName
        doUpload(blob)
    } else {
        EXIF.getData(acceptedFiles[0], function () {
            const orientation = EXIF.getTag(this, "Orientation");
            const takenAtDate = EXIF.getTag(this, "DateTime");
            const inputFormat = 'YYYY:MM:DD HH:mm:ss';
            const outputFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
            const takenAtDateFormatted = moment(takenAtDate, inputFormat).format(outputFormat);

            compressAndUpload(acceptedFiles[0], orientation, takenAtDateFormatted);
        })
    }

    function compressAndUpload(targetFile, orientation, takenAtDateFormatted) {
        const img = new Image;
        img.src = URL.createObjectURL(targetFile);
        if (targetFile.type.includes('image/')) {
            const compress = new Compress();
            compress.compress([targetFile], {
                size: 4,
                quality: .75,
                maxWidth: 1920,
                maxHeight: 1920,
                resize: true
            }).then((result) => {
                const compressed = result[0];
                const file = Compress.convertBase64ToFile(compressed.data, compressed.ext);
                file.name = targetFile.name;

                if (!file.name) {
                    file.name = acceptedFiles[0].name
                }
                if(override_name){
                    file.name = fileName
                }
                doUpload(file, orientation, takenAtDateFormatted);
            });
        } else {
            doUpload(targetFile, null, takenAtDateFormatted);
        }
    }
};
