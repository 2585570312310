import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as MyActions from './actions'
import {addAlert} from '../App/actions'
import {Grid, Row, Col, ControlLabel, FormGroup, Button, Panel} from 'react-bootstrap'
import PageHeader from '../../components/PageTitle'
import {LinkContainer} from 'react-router-bootstrap'
import Helmet from 'react-helmet'
import Leads from './Leads'
import Approvable from './Approvable'
import GoToProposal from './GoToProposal'
import Select from "react-select";
const Actions = {...MyActions, addAlert}

class SalesDashboard extends Component {

  componentWillMount() {
    //should we refresh the page (or call the actions?) every x seconds?
    this.props.actions.fetchSalesArboristStats()
  }

render() {
    const {
      dollars,
      numProposals,
      searchStatusMessage,
      searchProposalNo,
      dollarsProposed,
      numProposed,
      theme,
      leads,
      dollarsAccepted,
      confirmedLeads,
      followUpCount,
      initialSendCount,
      followUpToDo,
      nextWeeksLeads,
      confirmedNext,
    } = this.props
    const themes = ['cerulean', 'cosmo', 'cyborg', 'darkly', 'flatly',
      'journal', 'lumen', 'paper', 'readable', 'sandstone', 'simplex',
      'slate', 'solar', 'spacelab', 'superhero', 'united', 'yeti'].map(x => ({value: x, label: x}));

    return (
    <Grid fluid>
      <Helmet title="Sales Dashboard"/>

      <PageHeader
        pageName="Sales Dashboard"
        pageDetail=""

      />
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Leads
                leads={leads}
                numProposed={numProposed}
                dollarsProposed={dollarsProposed}
                dollarsAccepted={dollarsAccepted}
                confirmedLeads={confirmedLeads}
                followUpCount={followUpCount}
                initialSendCount={initialSendCount}
                followUpToDo={followUpToDo}
                nextWeeksLeads={nextWeeksLeads}
                confirmedNext={confirmedNext}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Approvable dollars={dollars} numProposals={numProposals}/>
            </Col>
            <Col md={6}>
              <GoToProposal searchStatusMessage={searchStatusMessage} searchProposalNo={searchProposalNo} onGoToProposal={this.props.actions.onGoToProposal} updateProposalSearchText={this.props.actions.updateProposalSearchText}/>
            </Col>

          </Row>
          <Row>
            <Col md={6}>
              <Panel bsStyle='default' className="text-center">
                <h3 className="text-success">Reports</h3>
                <br/>
                <Row>
                  <Col md={6}>
                    <Panel>
                      <LinkContainer to='/new_sales_dashboard_v1'>
                        <Button bsSize="xs">Report Dashboard</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/my_proposals'>
                        <Button bsSize="xs">Sales Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/my_accepted'>
                        <Button bsSize="xs">Accepted Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/proposed_services'>
                        <Button bsSize="xs">Services Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/active_customers'>
                        <Button bsSize="xs">Active Customers</Button>
                      </LinkContainer>
                    </Panel>
                  </Col>
                  <Col md={6}>
                    <Panel>
                      <LinkContainer to='/referral_report'>
                        <Button bsSize="xs">Referral Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/completed_proposals'>
                        <Button bsSize="xs">Completed PHC Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/activities'>
                        <Button bsSize="xs">Activity Report</Button>
                      </LinkContainer>
                    </Panel>
                    <Panel>
                      <LinkContainer to='/renewals'>
                        <Button bsSize="xs">Renewals</Button>
                      </LinkContainer>
                    </Panel>
                  </Col>
                </Row>
              </Panel>
            </Col>
            <Col md={6}>
              <p>
                <LinkContainer to='/mapview'>
                  <Button bsStyle="primary">Create Proposal</Button>
                </LinkContainer>
              </p>
            </Col>

            <Col md={3}>

            </Col>
            <Col md={3}>
              <FormGroup bsSize="sm">
                <ControlLabel>Theme</ControlLabel><br/>
                <Select className="Select" classNamePrefix="select"
                  value={{value: theme, label: theme}}
                  options={themes}
                  onChange={e => this.props.actions.updateTheme(e.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>



          </Row>
        </Col>
      </Row>


    </Grid>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    name: state.auth.name,
    dollars: state.sales.dollars,
    numProposals: state.sales.numProposals,
    dollarsProposed: state.sales.dollarsProposed,
    numProposed: state.sales.numProposed,
    searchStatusMessage: state.sales.searchStatusMessage,
    searchProposalNo: state.sales.searchProposalNo,
    theme: state.theme.theme,
    leads: state.sales.leads,
    dollarsAccepted: state.sales.dollarsAccepted,
    confirmedLeads: state.sales.confirmedLeads,
    followUpCount: state.sales.followUpCount,
    initialSendCount: state.sales.initialSendCount,
    followUpToDo: state.sales.followUpToDo,
    nextWeeksLeads: state.sales.nextWeeksLeads,
    confirmedNext: state.sales.confirmedNext,
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard)
