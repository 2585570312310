import React, {useEffect, useRef, useState} from "react";
import ProposalServiceMarkers from "./ProposalServiceMarkers";


const Markers = ({proposal_services, ...props}) => {
    const [spiderfiedSiteId, setSpiderfiedSiteId] = useState();
    const [spiderfiedLocation, setSpiderfiedLocation] = useState({lat: null, lng: null});
    const spiderfiedIndexRef = useRef(0);
    const spiderfiedCountRef = useRef(0);
    spiderfiedIndexRef.current = 0;

    useEffect(() => {
        let spiderfiedCount = 0;
        if (spiderfiedSiteId) {
            proposal_services.forEach((data) => Object.values(data).forEach(marker => {
                if (marker[0] && marker[0].site_id === spiderfiedSiteId && marker[0].scheduler_event_guest_id) {
                    spiderfiedCount += 1;
                }
            }));
        }
        if (spiderfiedLocation.lat && spiderfiedLocation.lng) {
            proposal_services.forEach((data) => Object.values(data).forEach(marker => {
                if (marker[0] && spiderfiedLocation.lat === marker[0].latitude && spiderfiedLocation.lng === marker[0].longitude) {
                    spiderfiedCount += 1;
                }
            }));
        }
        spiderfiedCountRef.current = spiderfiedCount;
    }, [spiderfiedSiteId, spiderfiedLocation]);

    return proposal_services && proposal_services.length > 0 && proposal_services.map((data, index) => {
        return <ProposalServiceMarkers
            {...props}
            key={'psm' + index}
            data={data}
            spiderfiedSiteId={spiderfiedSiteId}
            spiderfiedLocation={spiderfiedLocation}
            spiderfy={setSpiderfiedSiteId}
            setSpiderfiedLocation={setSpiderfiedLocation}
            spiderfiedIndexRef={spiderfiedIndexRef}
            spiderfiedCountRef={spiderfiedCountRef}
            proposal_services={proposal_services}
        />
    })
}
export default Markers;
