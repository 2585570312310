import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Grid,
    OverlayTrigger,
    Panel, Radio,
    Row,
    Tooltip
} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import {mapPersonSearch, optionsForSelect, select, swapElement} from "../../../common/commonHandlers";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import * as actions from "./UserProfileApi"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import TimePicker from "react-bootstrap-time-picker";
import Select from "react-select";
import moment from "moment-timezone";
import Well from "react-bootstrap/lib/Well";
import './UserProfile.scss'


const dateFormats = [
    {value: 'days', label: 'days (valid DD days) '},
    {value: 'months', label: 'months (valid MM months DD days) '},
    {value: 'date', label: 'date (valid until MM/DD/YYYY) '}
]
const daysOptions = [
    {value: 1, label: 'Monday'},
    {value: 2, label: 'Tuesday'},
    {value: 3, label: 'Wednesday'},
    {value: 4, label: 'Thursday'},
    {value: 5, label: 'Friday'},
    {value: 6, label: 'Saturday'},
    {value: 7, label: 'Sunday'},
    {value: [6, 7], label: 'Weekend'},
    {value: 8, label: 'Holidays'},
]
const isMobile = window.screen.width <= 1024;

class UserProfile extends ResourceComponent {
    state = {
        resource: {map_view_settings: ['Services', 'Assets', 'Map']},
        hidePrices: false,
        workOrderCompletedNotification: false,
        attachPdfToEmail: false,
        serviceCompletedNotification: false,
        jobCompletedNotification: false,
        geolocation_interval: null,
        useCompanyLocation: false,
        useWoPrintOptions: false,
        arboristAsTechnicianCc: false,
        start_date: null,
        blockCrewsModalAccess: false,
        isArboristRecipient: true,
        recipients: [],
        employees: [],
        syncCalendarsSettings: false,
        displayPriceForSubconstractor: false,
        priceVisibility: 100,
        hourEventDuration: false,
        showCompleteServicesModal: true,
        customerViewOptions: {},
        autoQBSync: false,
        recipientsWO: [],
        recipientsDB: [],
        recipientsCR: [],
        daysDB: [],
        dailyBriefing: false,
        daily_briefing_time: null,
        daily_briefing_days_back: 3,
        client_timezone: '',
        sendRemindOfReview: false,
        daysBeforeRemindOfReview: 1,
        recipientsTruckPayments: [],
        recipientsJob: [],
        asset_duplication_options: {},
        sendChecklistReminder: false,
        createEquipmentChecklist: false,
        isSchedulerRecipientReminder: false,
        isArboristRecipientReminder: false,
        recipientsReminder: [],
        auto_consolidated_report: false,
        consolidated_frequency: null,
        consolidated_report_time: null
    };

    constructor(props, context) {
        super(props, context);

        this.guestSearch = {
            searchOptions: () => [],
            search: () => {
            }
        }
    }

    componentDidMount() {
        const {loadUserProfile, search, getEmployees} = this.props.actions
        const {
            geolocation_interval,
            hidden_prices,
            attach_pdf_to_email,
            work_order_completed_notification,
            arborist_as_technician_cc,
            service_completed_notification,
            job_completed_notification,
            use_company_location,
            start_date,
            use_wo_print_options,
            block_crews_modal_access,
            is_sales_arborist,
            recipients,
            sync_calendar_settings,
            display_subcontractor_price,
            price_percent_value,
            hour_event_duration,
            customer_view_options,
            auto_qb_sync,
            is_arborist_recipient_wo,
            recipients_wo,
            recipients_db,
            recipients_cr,
            days_db,
            daily_briefing,
            daily_briefing_time,
            daily_briefing_days_back,
            client_timezone,
            send_remind_of_review,
            days_before_remind_of_review,
            recipients_remind_of_review,
            recipients_truck_payments,
            is_scheduler_recipient_job,
            is_arborist_recipient_job,
            is_site_contact_recipient_job,
            recipients_job,
            asset_duplication_options,
            send_checklist_reminder,
            create_equipment_checklist,
            is_scheduler_recipient_reminder,
            is_arborist_recipient_reminder,
            recipients_reminder,
            auto_consolidated_report,
            consolidated_frequency,
            consolidated_report_time
        } = this.props.state.client.customerInfo;

        let selectedRecipients = []
        let selectedWoRecipients = []
        let selectedDbRecipients = []
        let selectedDbDays = []
        let selectedCrRecipients = []
        let selectedRemindReviewRecipients = []
        let selectedTruckPayments = []
        let selectedJobRecipients = []
        let selectedRecipientsReminder = []

        if (recipients && recipients.length > 0) {
            recipients.map(r => {
                selectedRecipients.push(r)
            })
        }

        if (recipients_remind_of_review && recipients_remind_of_review.length > 0) {
            recipients_remind_of_review.map(r => {
                selectedRemindReviewRecipients.push(r)
            })
        }

        if (recipients_wo && recipients_wo.length > 0) {
            recipients_wo.map(r => {
                selectedWoRecipients.push(r)
            })
        }
        if (recipients_db && recipients_db.length > 0) {
            recipients_db.map(r => {
                selectedDbRecipients.push(r)
            })
        }
        if (recipients_cr && recipients_cr.length > 0) {
            recipients_cr.map(r => {
                selectedCrRecipients.push(r)
            })
        }
        if (days_db && days_db.length > 0) {
            days_db.map(r => {
                selectedDbDays.push(r)
            })
        }
        if (recipients_truck_payments && recipients_truck_payments.length > 0) {
            recipients_truck_payments.map(r => {
                selectedTruckPayments.push(r)
            })
        }
        if (recipients_job && recipients_job.length > 0) {
            recipients_job.map(r => {
                selectedJobRecipients.push(r)
            })
        }
        if (recipients_reminder && recipients_reminder.length > 0) {
            recipients_reminder.map(r => {
                selectedRecipientsReminder.push(r)
            })
        }

        loadUserProfile(resource => this.setState({
            resource: {
                ...resource,
                recipients: selectedRecipients,
                recipientsWO: selectedWoRecipients,
                recipientsDB: selectedDbRecipients,
                recipientsCR: selectedCrRecipients,
                daysDB: selectedDbDays,
                recipientsOfRemindReview: selectedRemindReviewRecipients,
                recipientsTruckPayments: selectedTruckPayments,
                recipientsJob: selectedJobRecipients,
                recipientsReminder: selectedRecipientsReminder,
            }
        }));
        getEmployees(employees => {
            this.setState({employees})
        })

        this.guestSearch = this.buildSearchComponent(
            'recipients',
            search,
            mapPersonSearch
        );
        this.setState({
            hidePrices: hidden_prices,
            workOrderCompletedNotification: work_order_completed_notification,
            attachPdfToEmail: attach_pdf_to_email,
            serviceCompletedNotification: service_completed_notification,
            jobCompletedNotification: job_completed_notification,
            geolocation_interval,
            useCompanyLocation: use_company_location,
            start_date: start_date,
            useWoPrintOptions: use_wo_print_options,
            blockCrewsModalAccess: block_crews_modal_access,
            isArboristRecipient: is_sales_arborist,
            syncCalendarsSettings: sync_calendar_settings,
            displayPriceForSubconstractor: display_subcontractor_price,
            priceVisibility: price_percent_value,
            hourEventDuration: hour_event_duration,
            customerViewOptions: JSON.parse(customer_view_options),
            autoQBSync: auto_qb_sync,
            isArboristRecipientWO: is_arborist_recipient_wo,
            arboristAsTechnicianCc: arborist_as_technician_cc,
            dailyBriefing: daily_briefing,
            daily_briefing_time: daily_briefing_time,
            daily_briefing_days_back: daily_briefing_days_back,
            client_timezone: client_timezone,
            sendRemindOfReview: send_remind_of_review,
            daysBeforeRemindOfReview: days_before_remind_of_review,
            isArboristRecipientJob: is_arborist_recipient_job,
            isSchedulerRecipientJob: is_scheduler_recipient_job,
            isSiteContactRecipientJob: is_site_contact_recipient_job,
            asset_duplication_options: asset_duplication_options,
            sendChecklistReminder: send_checklist_reminder,
            createEquipmentChecklist: create_equipment_checklist,
            isSchedulerRecipientReminder: is_scheduler_recipient_reminder,
            isArboristRecipientReminder: is_arborist_recipient_reminder,
            auto_consolidated_report: auto_consolidated_report,
            consolidated_frequency: consolidated_frequency,
            consolidated_report_time: consolidated_report_time
        });
    }

    renderComponents() {
        const {resource} = this.state;
        const SortableItem = SortableElement(({value}) => <Panel key={value}>{value}</Panel>);
        const SortableList = SortableContainer(({items}) =>
            <div>
                {items.map((key, i) => (
                    <SortableItem key={i} sortIndex={i} index={i} value={key}/>
                ))}
            </div>
        );
        return <SortableList pressDelay={100} items={resource.map_view_settings} onSortEnd={({oldIndex, newIndex}) => {
            swapElement(resource.map_view_settings, oldIndex, newIndex);
            this.setState({resource});
        }}/>
    }

    renderTooltip = (text) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                {text}
            </div>
        </Tooltip>
    );

    render() {
        const {
            resource,
            hidePrices,
            attachPdfToEmail,
            arboristAsTechnicianCc,
            workOrderCompletedNotification,
            serviceCompletedNotification,
            geolocation_interval,
            useCompanyLocation,
            start_date,
            useWoPrintOptions,
            blockCrewsModalAccess,
            isArboristRecipient,
            recipients,
            employees,
            syncCalendarsSettings,
            displayPriceForSubconstractor,
            priceVisibility,
            hourEventDuration,
            showCompleteServicesModal,
            customerViewOptions,
            autoQBSync,
            isArboristRecipientWO,
            recipientsWO,
            recipientsDB,
            daysDB,
            dailyBriefing,
            daily_briefing_time,
            daily_briefing_days_back,
            client_timezone,
            sendRemindOfReview,
            daysBeforeRemindOfReview,
            recipientsTruckPayments,
            isArboristRecipientJob,
            isSchedulerRecipientJob,
            isSiteContactRecipientJob,
            jobCompletedNotification,
            recipientsJob,
            asset_duplication_options,
            sendChecklistReminder,
            createEquipmentChecklist,
            isSchedulerRecipientReminder,
            isArboristRecipientReminder,
            recipientsReminder,
            auto_consolidated_report,
            consolidated_frequency,
            consolidated_report_time
        } = this.state;
        const {client_id} = this.props.state.auth;

        const changeColorText = "When checkbox is selected then all colors settings are loaded from client's admin otherwise every user can use self settings"
        const attachPdfToEmailText = "This option turns on/off the automatic attaching PDF to invoice emails. When checkbox is enabled option is turned on."
        const arboristAsTechnicianCcText = "This option allows Sales Arborist receive a copy of Technician emails. When checkbox is enabled option is turned on."
        const workOrderCompletedNotificationText = "This option turns on/off the automatic notification email when a whole WO is marked completed. When checkbox is enabled option is turned on."
        const serviceCompletedNotificationText = "This option turns on/off the automatic notification email when a single service within a WO is marked completed. When checkbox is enabled option is turned on."
        const jobCompletedNotificationText = "This option turns on/off the automatic notification email when a user update job status/complete job. When checkbox is enabled option is turned on."
        const changeLocationText = "This option sets the starting point of the route (user or company location)"
        const changeWoPrintOptionsText = "This option sets print options for work orders. If it is unchecked, each work order will use default print options. In case this option is checked - each work order has its own print options which will be saved (eg like on proposal print)."
        const syncCalendarsText = "This option saves settings of upper filters for both calendar modes: Classic and Modern when checkbox is selected"
        const subcontractorPriceText = "This option allows to display prices for subcontractor user class"
        const subcontractorPriceValueText = "This option allow to set percentage value of total price for subcontractor workorder"
        const hourEventDurationText = "This option set default event duration to an hour"
        const proposalValidityText = "This option allows to display on customer view information about how long proposal is valid"
        const syncWithQuickBooksText = "This option allows to automatically sync new customers with QuickBooks"
        const dailyBriefingText = "This option turns on/off the automatic notification email every day to users if they have any things to do."
        const remindReviewText = "This option turns on/off the automatic notification email before review."
        const pastDueInvoicesText = "This option shows/hides past due invoices modal"
        const newCustomerViewText = "This option enable/disable new layout of Customer View"
        const sendCustomerFeedbackEmail = "The option enables/disables the automatic sending of Review Email after proposals are marked as Paid"
        const autoConsolidatedReportText = "The option enables/disables the automatic sending of Consolidated Report to the users"

        let recipient_guests = []
        if (employees && employees.length > 0) {
            employees.map(e => {
                recipient_guests.push({value: e.id, label: `${e.first_name} ${e.last_name}`})
            })
        }

        const handleConsolidatedFrequencyChange = (value) => {
            const {consolidated_frequency} = this.state
            let frequencyArray = consolidated_frequency ? consolidated_frequency.split(',') : [];

            if (frequencyArray.includes(value)) {
                frequencyArray = frequencyArray.filter(f => f !== value)
            } else {
                frequencyArray.push(value)
            }

            this.setState({consolidated_frequency: frequencyArray.join(',')})
        }

        const renderMapViewConfiguration = () => {
            return <Col md={4} sm={12}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Map View
                        Configuration</h4>
                    <FormGroup className='margin10 mt15'
                               validationState={resource.gcal_authorized ? 'success' : ''}>
                        <h5>Components Order</h5>
                        {this.renderComponents()}
                    </FormGroup>
                    <h5 className={"marginLeft10 " + `${!isMobile ? 'font12' : ''}`}>Duplicating asset config</h5>
                    <FormGroup className={'margin10 align-text-end'}>
                        <Checkbox
                            onChange={() => this.setState({
                                asset_duplication_options: {
                                    ...asset_duplication_options,
                                    notes: !asset_duplication_options?.notes
                                }
                            })}
                            checked={asset_duplication_options?.notes}
                        >
                            Duplicate Notes
                        </Checkbox>
                    </FormGroup>
                    <FormGroup className={'margin10 align-text-end'}>
                        <Checkbox
                            onChange={() => this.setState({
                                asset_duplication_options: {
                                    ...asset_duplication_options,
                                    location: !asset_duplication_options?.location
                                }
                            })}
                            checked={asset_duplication_options?.location}
                        >
                            Duplicate Location
                        </Checkbox>
                    </FormGroup>
                    <FormGroup className={'margin10 align-text-end'}>
                        <Checkbox
                            onChange={() => this.setState({
                                asset_duplication_options: {
                                    ...asset_duplication_options,
                                    plant_stats_histories: !asset_duplication_options?.plant_stats_histories
                                }
                            })}
                            checked={asset_duplication_options?.plant_stats_histories}
                        >
                            Duplicate Plant History
                        </Checkbox>
                    </FormGroup>
                </Well>
            </Col>
        }

        const renderScheduler = () => {
            return <Col md={4}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Scheduler</h4>
                    <FormGroup className={'margin10 align-text-end'}>
                        <Checkbox
                            onChange={() => this.setState({syncCalendarsSettings: !syncCalendarsSettings})}
                            inline
                            checked={syncCalendarsSettings}
                        >
                            Sync Modern & Classic Calendar Settings
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(syncCalendarsText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>
                    <FormGroup className={'margin10 align-text-end'}>
                        <Checkbox
                            onChange={() => this.setState({hourEventDuration: !hourEventDuration})}
                            inline
                            checked={hourEventDuration}
                        >
                            Set event duration to an hour by default
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(hourEventDurationText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>
                    <h5 className={"marginLeft10 mt27 " + `${!isMobile ? 'font12' : ''}`}>Scheduler start
                        time</h5>
                    <FormGroup className="marginLeft10 width120 no-margin">
                        <TimePicker
                            step={15}
                            className={!isMobile ? 'pointer marginLeft10 font10' : 'pointer marginLeft10'}
                            onChange={e => {
                                this.setState({start_date: e});
                            }}
                            value={start_date}
                        />
                    </FormGroup>
                    <Row className='ml10 align-text-end'>
                        <Col md={12} xs={12} className="margin10 mt0 bottom0">
                            <h5 className={!isMobile ? 'font11' : ''}>Master timezone:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={optionsForSelect(moment.tz.names())}
                                    placeholder="Select timezone"
                                    value={select(optionsForSelect(moment.tz.names()), client_timezone)}
                                    onChange={(e) => {
                                        this.setState({client_timezone: e.value});
                                    }}
                            />
                        </Col>
                    </Row>
                </Well>
            </Col>
        }

        const renderEmails = () => {
            return <Col md={4}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Emails</h4>
                    <FormGroup className='ml-10 align-text-end mt0 bottom0'>
                        <Checkbox
                            onChange={() => this.setState({attachPdfToEmail: !attachPdfToEmail})}
                            inline
                            checked={attachPdfToEmail}
                        >
                            Automatically attach the PDF to invoice emails
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(attachPdfToEmailText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>
                    <FormGroup className='ml-10 align-text-end mt0 bottom0'>
                        <Checkbox
                            onChange={() => this.setState({workOrderCompletedNotification: !workOrderCompletedNotification})}
                            inline
                            checked={workOrderCompletedNotification}
                        >
                            Email notification about completed work order
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(workOrderCompletedNotificationText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>
                    <FormGroup className='ml-10 align-text-end mt0 bottom0'>
                        <Checkbox
                            onChange={() => this.setState({serviceCompletedNotification: !serviceCompletedNotification})}
                            inline
                            checked={serviceCompletedNotification}
                        >
                            Email notification about completed service
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(serviceCompletedNotificationText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>

                    <Row className='ml-10 mt-2 align-text-end'>
                        <Col className={`d-flex ${isMobile ? 'flex-column-start' : ''}`}>
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : 'font14'}`}>Completed
                                work order/services email recipients:</h5>
                            <FormGroup className={isMobile ? 'mt0 ml0 bottom0' : 'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({isArboristRecipientWO: !isArboristRecipientWO})}
                                    inline
                                    className="reset-top-padding"
                                    checked={isArboristRecipientWO}
                                >
                                    Sales arborist
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='ml10 align-text-end'>
                        <Col md={12} xs={12} className="margin10 mt0 bottom0 ">
                            <h5 className={!isMobile ? 'font11' : ''}>Additional recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipientsWO)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsWO')}
                            />
                        </Col>
                    </Row>

                    <hr className='mt-5 bottom0'/>
                    <FormGroup className='ml-10 align-text-end mt0 bottom0'>
                        <Checkbox
                            onChange={() => this.setState({jobCompletedNotification: !jobCompletedNotification})}
                            inline
                            checked={jobCompletedNotification}
                        >
                            Email notification about updated job status/completed job
                        </Checkbox>
                        {!isMobile && <OverlayTrigger placement="right"
                                                      overlay={this.renderTooltip(jobCompletedNotificationText)}>
                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                        </OverlayTrigger>}
                    </FormGroup>

                    <Row className='ml-10 mt-2 align-text-end'>
                        <Col className={`d-flex ${isMobile ? 'flex-column-start' : ''}`}>
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : 'font14'}`}>Completed
                                work order/services email recipients:</h5>
                            <FormGroup className={isMobile ? 'mt0 ml0 bottom0' : 'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({isArboristRecipientJob: !isArboristRecipientJob})}
                                    inline
                                    className="reset-top-padding"
                                    checked={isArboristRecipientJob}
                                >
                                    Sales arborist
                                </Checkbox>
                            </FormGroup>
                            <FormGroup className={isMobile ? 'mt0 ml0 bottom0' : 'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({isSchedulerRecipientJob: !isSchedulerRecipientJob})}
                                    inline
                                    className="reset-top-padding"
                                    checked={isSchedulerRecipientJob}
                                >
                                    Scheduler
                                </Checkbox>
                            </FormGroup>
                            <FormGroup className={isMobile ? 'mt0 ml0 bottom0' : 'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({isSiteContactRecipientJob: !isSiteContactRecipientJob})}
                                    inline
                                    className="reset-top-padding"
                                    checked={isSiteContactRecipientJob}
                                >
                                    Site Contact
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='ml10 align-text-end'>
                        <Col md={12} xs={12} className="margin10 mt0 bottom0 ">
                            <h5 className={!isMobile ? 'font11' : ''}>Additional recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipientsJob)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsJob')}
                            />
                        </Col>
                    </Row>

                    <hr className='mt-5 bottom0'/>

                    <Row className='ml-10 mt-2 align-text-end bottom0'>
                        <Col className="d-flex">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : 'font14'}`}>Email
                                payment notifications recipients:</h5>
                            <FormGroup className={'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({isArboristRecipient: !isArboristRecipient})}
                                    inline
                                    className="reset-top-padding"
                                    checked={isArboristRecipient}
                                >
                                    Sales arborist
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="ml10 mt-2 align-text-end">
                        <Col md={12} xs={12} className="margin10 mt0 bottom0">
                            <h5 className={!isMobile ? 'font11' : ''}>Additional recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipients)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipients')}
                            />
                        </Col>
                    </Row>
                </Well>
            </Col>
        }

        const renderCWO = () => {
            return <Col md={4}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Crew Work Order</h4>
                    <Row className="margin10 ml-20 mt15">
                        <FormGroup>
                            <Checkbox
                                onChange={() => this.setState({hidePrices: !hidePrices})}
                                inline
                                checked={hidePrices}
                            >
                                Users can't see crew work order prices
                            </Checkbox>
                        </FormGroup>
                        <FormGroup className='align-text-end'>
                            <Checkbox
                                onChange={() => this.setState({arboristAsTechnicianCc: !arboristAsTechnicianCc})}
                                inline
                                checked={arboristAsTechnicianCc}
                            >
                                Sales Arborist receive a copy of Technician emails
                            </Checkbox>
                            {!isMobile && <OverlayTrigger placement="right"
                                                          overlay={this.renderTooltip(arboristAsTechnicianCcText)}>
                                <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                            </OverlayTrigger>}
                        </FormGroup>


                        <FormGroup className={'align-text-end'}>
                            <Checkbox
                                onChange={() => this.setState({useCompanyLocation: !useCompanyLocation})}
                                inline
                                checked={useCompanyLocation}
                            >
                                Use company location
                            </Checkbox>
                            {!isMobile && <OverlayTrigger placement="right"
                                                          overlay={this.renderTooltip(changeLocationText)}>
                                <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                            </OverlayTrigger>}
                        </FormGroup>
                        <FormGroup className={'align-text-end'}>
                            <Checkbox
                                onChange={() => this.setState({useWoPrintOptions: !useWoPrintOptions})}
                                inline
                                checked={useWoPrintOptions}
                            >
                                Use saved print options for work orders
                            </Checkbox>
                            {!isMobile && <OverlayTrigger placement="right"
                                                          overlay={this.renderTooltip(changeWoPrintOptionsText)}>
                                <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                            </OverlayTrigger>}
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                onChange={() => this.setState({blockCrewsModalAccess: !blockCrewsModalAccess})}
                                inline
                                checked={blockCrewsModalAccess}
                            >
                                Lock edititing tree detail modal data by Crew leader users
                            </Checkbox>
                        </FormGroup>
                        <FormGroup className='align-text-end'>
                            <Checkbox
                                onChange={() => this.setState({displayPriceForSubconstractor: !displayPriceForSubconstractor})}
                                inline
                                checked={displayPriceForSubconstractor}
                            >
                                Display the price of Workorder for the "Subcontractor" class
                            </Checkbox>
                            {!isMobile && <OverlayTrigger placement="right"
                                                          overlay={this.renderTooltip(subcontractorPriceText)}>
                                <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                            </OverlayTrigger>}
                        </FormGroup>
                        <FormGroup className='align-text-end'
                                   style={{display: 'flex', alignItems: 'center'}}>
                            <FormControl
                                type="number"
                                step={1}
                                disabled={!displayPriceForSubconstractor}
                                placeholder="Price"
                                name="price_visiblility"
                                className={!isMobile ? 'font10' : ''}
                                onChange={e => this.setState({priceVisibility: e.target.value})}
                                value={priceVisibility}
                            />
                            {!isMobile && <OverlayTrigger placement="right"
                                                          overlay={this.renderTooltip(subcontractorPriceValueText)}>
                                <FontAwesomeIcon icon={faInfoCircle}
                                                 className={"marginLeft10 font16 " + `${!isMobile ? 'mr-5' : ''}`}/>
                            </OverlayTrigger>}
                        </FormGroup>
                        <hr className='mt-5 bottom0'/>
                        <FormGroup className="bottom0">
                            <Checkbox
                                onChange={() => this.setState({createEquipmentChecklist: !createEquipmentChecklist})}
                                inline
                                checked={createEquipmentChecklist}
                            >
                                Create equipment checklist
                            </Checkbox>
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                onChange={() => this.setState({sendChecklistReminder: !sendChecklistReminder})}
                                inline
                                checked={sendChecklistReminder}
                            >
                                Send equipment checklist email reminder
                            </Checkbox>
                        </FormGroup>
                        <Row className='mt-2 align-text-end'>
                            <Col className="d-flex">
                                <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : 'font14'}`}>Recipients of
                                    equipment checklist reminder:</h5>
                                <FormGroup className={'margin10 mt0 bottom0'}>
                                    <Checkbox
                                        onChange={() => this.setState({isArboristRecipientReminder: !isArboristRecipientReminder})}
                                        inline
                                        className="reset-top-padding"
                                        checked={isArboristRecipientReminder}
                                    >
                                        Sales arborist
                                    </Checkbox>
                                </FormGroup>
                                <FormGroup className={'margin10 mt0 bottom0'}>
                                    <Checkbox
                                        onChange={() => this.setState({isSchedulerRecipientReminder: !isSchedulerRecipientReminder})}
                                        inline
                                        className="reset-top-padding"
                                        checked={isSchedulerRecipientReminder}
                                    >
                                        Scheduler
                                    </Checkbox>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='ml10 align-text-end'>
                            <Col md={12} xs={12} className="no-left-padding">
                                <h5 className={!isMobile ? 'font11' : ''}>Additional recipients:</h5>
                                <Select className="Select mt-5"
                                        classNamePrefix="select"
                                        options={recipient_guests}
                                        isClearable
                                        placeholder="Add recipient"
                                        value={select(recipient_guests, resource.recipientsReminder)}
                                        isMulti
                                        onInputChange={this.guestSearch.searchGuess}
                                        onChange={this.selectResourceAttr('recipientsReminder')}
                                />
                            </Col>
                        </Row>
                    </Row>
                </Well>
            </Col>
        }

        const renderEmployee = () => {
            return <Col md={4}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Employee</h4>
                    <Row className='margin10 mt2 align-text-end bottom0'>
                        <Col className="d-flex">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : 'font14'}`}>Reminder
                                of reviews for employees:</h5>
                            <FormGroup className={'margin10 mt0 bottom0'}>
                                <Checkbox
                                    onChange={() => this.setState({sendRemindOfReview: !sendRemindOfReview})}
                                    inline
                                    className="reset-top-padding"
                                    checked={sendRemindOfReview}
                                >
                                    Remind of review
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(remindReviewText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="margin10 mt0 bottom0 align-text-end">
                        <Col md={12} className="no-padding">
                            <h5 className={'bottom0 ' + `${!isMobile ? 'font11 mt0' : 'mt-2'}`}>Days
                                before:</h5>
                            <Col md={3} className='no-padding'>
                                <FormControl
                                    type="number"
                                    step={1}
                                    name="days"
                                    className={!isMobile ? 'font10 height30' : ''}
                                    onChange={e => this.setState({
                                        daysBeforeRemindOfReview: parseInt(e.target.value)
                                    })}
                                    value={daysBeforeRemindOfReview}
                                />
                            </Col>
                            <Col md={2} className='no-padding margin10 mt0 bottom0'>
                                <ControlLabel>{daysBeforeRemindOfReview === 1 ? "day" : "days"}</ControlLabel>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="margin10 align-text-end">
                        <Col md={12} xs={12} className="no-padding">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font11' : ''}`}>Recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipientsOfRemindReview)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsOfRemindReview')}
                            />
                        </Col>
                    </Row>
                    <Row className='margin10 mt-2 align-text-end bottom0'>
                        <Col className="d-flex mt-2">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : ''}`}>Daily
                                Briefing:</h5>
                            <FormGroup className={'margin10 mt0 bottom0 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({dailyBriefing: !dailyBriefing})}
                                    inline
                                    className="reset-top-padding"
                                    checked={dailyBriefing}
                                >
                                    Enable Daily Briefing
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(dailyBriefingText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={'margin10 align-text-end ' + `${!isMobile ? 'mt0' : ''}`}>
                        <Col md={12} xs={12} className="no-padding">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font11' : ''}`}>Recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipientsDB)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsDB')}
                            />
                        </Col>
                    </Row>
                    <Row className={'margin10 align-text-end ' + `${!isMobile ? 'mt0' : ''}`}>
                        <Col md={12} xs={12} className="no-padding">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font11' : ''}`}>Days:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={daysOptions}
                                    placeholder="Add day"
                                    value={select(daysOptions, resource.daysDB)}
                                    isMulti
                                    onChange={this.selectResourceAttr('daysDB')}
                            />
                        </Col>
                    </Row>
                    {!isMobile ?
                        <>
                            <Row className='margin10 align-text-end'>
                                <Col md={6} className="no-padding">
                                    <h5 className='mt-2 bottom0 font11'>Time email is sending:</h5>
                                    <TimePicker
                                        step={15}
                                        className={!isMobile ? 'pointer font10' : 'pointer'}
                                        onChange={e => {
                                            this.setState({daily_briefing_time: e});
                                        }}
                                        value={daily_briefing_time}
                                    />
                                </Col>
                                <Col md={6}>
                                    <h5 className='mt-2 bottom0 font11'>Missed events days back:</h5>
                                    <FormGroup className="max_width_70 no-margin">
                                        <FormControl
                                            type="number"
                                            step={1}
                                            name="daily_briefing_days_back"
                                            className={!isMobile ? 'font10' : ''}
                                            onChange={e => this.setState({daily_briefing_days_back: e.target.value})}
                                            value={daily_briefing_days_back}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                        : <>
                            <Row className='margin10 align-text-end'>
                                <Col md={6} className="no-padding">
                                    <h5 className='mt-2 bottom0'>Time email is sending:</h5>
                                    <TimePicker
                                        step={15}
                                        className={!isMobile ? 'font10 pointer' : ' pointer'}
                                        onChange={e => {
                                            this.setState({daily_briefing_time: e});
                                        }}
                                        value={daily_briefing_time}
                                    />
                                </Col>
                                <Col md={6}>
                                    <h5 className='mt-2 bottom0'>Missed events days back:</h5>
                                    <FormGroup className="max_width_70 no-margin">
                                        <FormControl
                                            type="number"
                                            step={1}
                                            name="daily_briefing_days_back"
                                            className='height30'
                                            onChange={e => this.setState({daily_briefing_days_back: e.target.value})}
                                            value={daily_briefing_days_back}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>}
                    <Row className='margin10 mt-2 align-text-end bottom0'>
                        <Col className="d-flex mt-2">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : ''}`}>Truck upcoming payments
                                recipients:</h5>
                        </Col>
                    </Row>
                    <Row className={'margin10 align-text-end ' + `${!isMobile ? 'mt0' : ''}`}>
                        <Col md={12} xs={12} className="no-padding">
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    value={select(recipient_guests, resource.recipientsTruckPayments)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsTruckPayments')}
                            />
                        </Col>
                    </Row>
                    <Row className='ml-10'>
                        <Col md={12}>
                            <FormGroup className={'d-flex bottom0'}>
                                <h5 className={"mr-5 " + `${!isMobile ? 'font12' : 'font14'}`}>Location
                                    update interval:</h5>
                                <FormControl
                                    type="number"
                                    step={1}
                                    className={"max_width_70 height30 " + `${!isMobile ? 'font10' : ''}`}
                                    name="geolocation_interval"
                                    onChange={e => this.setState({geolocation_interval: e.target.value})}
                                    value={geolocation_interval}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='margin10 mt-2 align-text-end bottom0'>
                        <Col className="d-flex mt-2">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font12' : ''}`}>Consolidated Report:</h5>
                            <FormGroup className={'margin10 mt0 bottom0 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({auto_consolidated_report: !auto_consolidated_report})}
                                    inline
                                    className="reset-top-padding"
                                    checked={auto_consolidated_report}
                                >
                                    Enable Auto Consolidated Report
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(autoConsolidatedReportText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={'margin10 align-text-end ' + `${!isMobile ? 'mt0' : ''}`}>
                        <Col md={12} xs={12} className="no-padding">
                            <h5 className={'mt-2 bottom0 ' + `${!isMobile ? 'font11' : ''}`}>Recipients:</h5>
                            <Select className="Select mt-5"
                                    classNamePrefix="select"
                                    options={recipient_guests}
                                    isClearable
                                    placeholder="Add recipient"
                                    menuPlacement="auto"
                                    value={select(recipient_guests, resource.recipientsCR)}
                                    isMulti
                                    onInputChange={this.guestSearch.searchGuess}
                                    onChange={this.selectResourceAttr('recipientsCR')}
                            />
                        </Col>
                    </Row>
                    <Row className='margin10 align-text-end'>
                        <Col md={12} xs={12} className="no-padding">
                            <div className="d-flex">
                                <h5 className='mt-2 bottom0 font11'>
                                    Frequency:
                                </h5>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={
                                                                  <Tooltip id="tooltip">
                                                                      <div className="text-left">
                                                                          <p>The option enables you to configure how
                                                                              often the Consolidated Report is sent:</p>
                                                                          <p><strong>Daily</strong>: Sends a report every day, covering the previous day's data.</p>
                                                                          <p><strong>Weekly</strong>: Sends a report every Sunday, summarizing the data from the previous week.</p>
                                                                          <p><strong>Monthly</strong>: Sends a report on the first day of each month, summarizing the data from the previous month.</p>
                                                                      </div>
                                                                  </Tooltip>
                                                              }>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </div>
                            <FormGroup className="no-margin">
                                <Checkbox
                                    name="consolidated_frequency"
                                    id="frequencyDaily"
                                    className={`checkbox-inline no-padding padding-top-10 ${!isMobile ? 'font10' : ''}`}
                                    onChange={() => handleConsolidatedFrequencyChange('daily')}
                                    checked={consolidated_frequency && consolidated_frequency.split(',').includes('daily')}
                                >
                                    Daily
                                </Checkbox>
                                <Checkbox
                                    name="consolidated_frequency"
                                    id="frequencyWeekly"
                                    className={`checkbox-inline no-padding padding-top-10 ${!isMobile ? 'font10' : ''}`}
                                    onChange={() => handleConsolidatedFrequencyChange('weekly')}
                                    checked={consolidated_frequency && consolidated_frequency.split(',').includes('weekly')}
                                >
                                    Weekly
                                </Checkbox>
                                <Checkbox
                                    name="consolidated_frequency"
                                    id="frequencyMonthly"
                                    className={`checkbox-inline no-padding padding-top-10 ${!isMobile ? 'font10' : ''}`}
                                    onChange={() => handleConsolidatedFrequencyChange('monthly')}
                                    checked={consolidated_frequency && consolidated_frequency.split(',').includes('monthly')}
                                >
                                    Monthly
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='margin10 align-text-end'>
                        <Col md={6} className="no-padding">
                            <h5 className='mt-2 bottom0 font11'>Time email is sending:</h5>
                            <TimePicker
                                step={15}
                                className={!isMobile ? 'pointer font10' : 'pointer'}
                                onChange={e => {
                                    this.setState({consolidated_report_time: e});
                                }}
                                value={consolidated_report_time}
                            />
                        </Col>
                    </Row>
                </Well>
            </Col>
        }

        const renderCustomer = () => {
            return <Col md={4}>
                <Well className={!isMobile ? 'full-height bottom0 padding4' : ''}>
                    <h4 className={'mt0 hcenter ' + `${!isMobile ? 'col-header' : ''}`}>Customer</h4>
                    <div className="customer-panel">
                        <Row className='margin10 mt-15'>
                            <h5 className='font14'>Customer view: Proposal Validity</h5>
                            <FormGroup className={'ml-2 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({
                                        customerViewOptions: {
                                            ...customerViewOptions,
                                            proposalExpirationDate: !customerViewOptions.proposalExpirationDate
                                        }
                                    })}
                                    inline
                                    checked={customerViewOptions.proposalExpirationDate}
                                >
                                    Proposal expiration date
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(proposalValidityText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                            {customerViewOptions.proposalExpirationDate &&
                                <FormGroup className='ml-2'>
                                    <h5>Display date format</h5>
                                    <Select className="Select mt-5 mr-10"
                                            classNamePrefix="select"
                                            options={dateFormats}
                                            placeholder="Display format"
                                            value={select(dateFormats, customerViewOptions.dateFormat)}
                                            onChange={(e) => this.setState({
                                                customerViewOptions: {
                                                    ...customerViewOptions,
                                                    dateFormat: e.value
                                                }
                                            })}
                                    />
                                    {customerViewOptions.dateFormat === 'months' ?
                                        <>
                                            <Col md={3} className='no-padding'>
                                                <FormControl
                                                    type="number"
                                                    step={1}
                                                    name="months"
                                                    className={!isMobile ? 'font10' : ''}
                                                    onChange={e => this.setState({
                                                        customerViewOptions: {
                                                            ...customerViewOptions,
                                                            months: e.target.value
                                                        }
                                                    })}
                                                    value={customerViewOptions.months}
                                                /> </Col>
                                            <Col md={2}
                                                 className='no-padding'><ControlLabel>months</ControlLabel></Col>
                                        </> : <>
                                            <Col md={3} className='no-padding mt5'>
                                                <FormControl
                                                    type="number"
                                                    step={1}
                                                    name="days"
                                                    className={!isMobile ? 'font10' : ''}
                                                    onChange={e => this.setState({
                                                        customerViewOptions: {
                                                            ...customerViewOptions,
                                                            days: e.target.value
                                                        }
                                                    })}
                                                    value={customerViewOptions.days}
                                                /></Col>
                                            <Col md={2}
                                                 className='mt5 no-padding ml-2'><ControlLabel>days</ControlLabel></Col>
                                        </>
                                    }
                                </FormGroup>}
                            <FormGroup className={'ml-2 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({
                                        customerViewOptions: {
                                            ...customerViewOptions,
                                            showPastDueInvoices: !customerViewOptions.showPastDueInvoices
                                        }
                                    })}
                                    inline
                                    checked={customerViewOptions.showPastDueInvoices}
                                >
                                    Show past due invoices
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(pastDueInvoicesText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                            <FormGroup className={'ml-2 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({
                                        customerViewOptions: {
                                            ...customerViewOptions,
                                            newCustomerView: !customerViewOptions.newCustomerView
                                        }
                                    })}
                                    inline
                                    checked={customerViewOptions.newCustomerView}
                                >
                                    New Customer View
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(newCustomerViewText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                            <FormGroup className={'ml-2 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({
                                        customerViewOptions: {
                                            ...customerViewOptions,
                                            sendCustomerFeedbackEmail: !customerViewOptions.sendCustomerFeedbackEmail
                                        }
                                    })}
                                    inline
                                    checked={customerViewOptions.sendCustomerFeedbackEmail}
                                >
                                    Send Customer Feedback Email
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(sendCustomerFeedbackEmail)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>

                        </Row>
                        <Row className='margin10 mt-15'>
                            <h5 className='font14'>Customer Info:</h5>
                            <FormGroup className={'ml-2 align-text-end'}>
                                <Checkbox
                                    onChange={() => this.setState({
                                        autoQBSync: !autoQBSync
                                    })}
                                    inline
                                    checked={autoQBSync}
                                >
                                    Sync with QuickBooks
                                </Checkbox>
                                {!isMobile && <OverlayTrigger placement="right"
                                                              overlay={this.renderTooltip(syncWithQuickBooksText)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                </OverlayTrigger>}
                            </FormGroup>
                        </Row>
                    </div>
                </Well>
            </Col>
        }

        return (
            <Grid fluid className={!isMobile ? 'mt10 font10' : 'mt10'}>
                <Form horizontal onSubmit={e => {
                    e.preventDefault();
                    const clientSettings = {
                        client_id: client_id,
                        hidden_prices: hidePrices,
                        attach_pdf_to_email: attachPdfToEmail,
                        work_order_completed_notification: workOrderCompletedNotification,
                        service_completed_notification: serviceCompletedNotification,
                        job_completed_notification: jobCompletedNotification,
                        geolocation_interval: geolocation_interval,
                        use_company_location: useCompanyLocation,
                        start_date: start_date,
                        use_wo_print_options: useWoPrintOptions,
                        block_crews_modal_access: blockCrewsModalAccess,
                        is_arborist_recipient: isArboristRecipient,
                        recipients: resource.recipients,
                        sync_calendar_settings: syncCalendarsSettings,
                        display_subcontractor_price: displayPriceForSubconstractor,
                        price_percent_value: priceVisibility,
                        hour_event_duration: hourEventDuration,
                        customer_view_options: customerViewOptions,
                        auto_qb_sync: autoQBSync,
                        is_arborist_recipient_wo: isArboristRecipientWO,
                        arborist_as_technician_cc: arboristAsTechnicianCc,
                        recipients_wo: resource.recipientsWO,
                        recipients_db: resource.recipientsDB,
                        days_db: resource.daysDB,
                        daily_briefing: dailyBriefing,
                        daily_briefing_time: daily_briefing_time,
                        daily_briefing_days_back: daily_briefing_days_back,
                        send_remind_of_review: sendRemindOfReview,
                        days_before_remind_of_review: daysBeforeRemindOfReview,
                        recipients_remind_of_review: resource.recipientsOfRemindReview,
                        timezone: client_timezone,
                        recipients_truck_payments: resource.recipientsTruckPayments,
                        is_arborist_recipient_job: isArboristRecipientJob,
                        is_scheduler_recipient_job: isSchedulerRecipientJob,
                        is_site_contact_recipient_job: isSiteContactRecipientJob,
                        recipients_job: resource.recipientsJob,
                        asset_duplication_options: asset_duplication_options,
                        send_checklist_reminder: sendChecklistReminder,
                        create_equipment_checklist: createEquipmentChecklist,
                        is_scheduler_recipient_reminder: isSchedulerRecipientReminder,
                        is_arborist_recipient_reminder: isArboristRecipientReminder,
                        recipients_reminder: resource.recipientsReminder,
                        auto_consolidated_report: auto_consolidated_report,
                        recipients_cr: resource.recipientsCR,
                        consolidated_report_time: consolidated_report_time,
                        consolidated_frequency: consolidated_frequency
                    }

                    this.props.actions.saveClientSettings(clientSettings);
                    this.props.actions.saveUserProfile(resource);
                }}>
                    <Row className={!isMobile ? "hright d-flex" : "hright mr-0-ml-0"}>
                        <Button
                            block
                            type="submit"
                            bsStyle="success"
                            className="max_width_70 padding4"
                        >
                            Save
                        </Button>
                    </Row>
                    {isMobile ? <>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderMapViewConfiguration()}
                        </Row>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderScheduler()}
                        </Row>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderEmails()}
                        </Row>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderCWO()}
                        </Row>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderEmployee()}
                        </Row>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderCustomer()}
                        </Row>
                    </> : <>
                        <Row className={!isMobile ? "mt10 d-flex bottom10" : "mt10"}>
                            {renderMapViewConfiguration()}
                            {renderScheduler()}
                            {renderEmails()}
                        </Row>
                        <Row className={!isMobile ? "d-flex bottom10" : ""}>
                            {renderCWO()}
                            {renderEmployee()}
                            {renderCustomer()}
                        </Row>
                    </>}
                </Form>
            </Grid>
        );
    }
}

UserProfile.propTypes = {
    //myProp: PropTypes.object.isRequired
};

UserProfile.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        state: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
