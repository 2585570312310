import React, {Component} from 'react';
import PropTypes from "prop-types"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import classNames from 'classnames'
import {addAlert} from "../App/actions"
import {showDock} from "../../components/dock/actions"
import * as MyActions from "./actions"
import "./fixedTable.scss"
import "./CustomerViewPage.scss"
import {
    Badge,
    Button,
    Carousel,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    Modal,
    Panel,
    Row,
    Table,
    Well
} from "react-bootstrap"
import PaymentModal from '../CustomerViewInvoice/Payment/PaymentModal'
import Dollars from "../../components/Dollars"
import {Circle, GoogleMap, InfoWindow, Marker, Polygon, Polyline, Rectangle} from "react-google-maps"
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import StaticMap from "../PrintProposalPage/StaticMap";
import moment from "moment-timezone"
import {Link} from "react-router";
import {getUniqueAssets} from "../../utilities/commonProposals";
import {getLabel} from "../MapViewPage/IconProvider";
import Conversation from "../Stickies/Conversation";
import Dock from "../../components/dock/Dock";
import {checkRectangleCoordinates, colors, defaultDateFormat,defaultDateFormatShort, sortAssetsLabels} from "../../common/commonHandlers";
import PlantName from "../../components/PlantName";
import CustomerViewMenu from './CustomerViewMenu'
import CustomerViewTabs from "./CustomerViewTabs";
import CustomerViewProposalHeader from "./CustomerViewProposalHeader";
import messageIcon from './email.png'
import SignatureCanvas from "react-signature-canvas";
import MDSpinner from "react-md-spinner";
import _ from 'lodash';
import {getBasicAssetMarker} from "../../utilities";
import {filterProposalServices} from "../../common/pdfPrintUtils";

const Actions = {...MyActions, addAlert, showDock}


const isMobile = window.screen.width < 1024

//NOTE: Page must be bootstrap xs={} for printing to not do weird things

const styles = {
    printWidget: {
        float: "left",
        width: "175px",
        minHeight: "155px",
        marginRight: "20px",
    },
    clearFix: {
        clear: "both",
    },
    signature: {
        textAlign: "center",
        borderTop: "1px solid black",
    },
}

class CustomerViewPage extends Component {
    state = {
        proposalTotal: true,
        servicePrices: true,
        allDone: false,
        stickies: [],
        open: null,
        loading: true,

        proposalServiceNotes: true,
        assetNotes: true,
        proposalCustomerNote: true,

        maps: true,

        locationDescription: true,
        largeAssetPhotos: true,
        assInline: true,

        selectedAsset: null,
        dbh: false,

        servicePriorities: true,
        status: true,
        serviceDateRanges: true,
        servicesNotOffered: false,
        servicesDeclined: false,
        servicesAccepted: false,
        servicesProposed: false,
        servicesCompleted: false,
        servicesInvoiced: false,
        servicesPaid: false,

        showPaymentPopUp: false,

        isSubmitted: false,
        tos: true,
        proposal_footer: true,
        include_maps: {},
        arboristInfo: true,
        accepted: [],
        declined: [],
        customerViewOptions: null,
        activeTab: 1,
        invoices: [],
        toggle_bundle_services: [],
        isSignature: false,
        first_name: "",
        last_name: "",
        acceptedTerms: false,
        acceptedProposalButtonPressed: false,
        showInfoPopUp: false,
        infoPopUpMessage: "",

        downloadInProgress: false
    };

    sigPadRef = React.createRef()

    resize = () => this.forceUpdate();

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    getInvoices = () => {
        this.props.actions.loadInvoices(this.props.params.token, false, null, res => {
            this.setState({invoices: res.customer_invoices})
        })
    }

    allOverProposed = proposal => {
        return proposal?.proposal_services?.every(ps => ps.status !== 'Proposed')
    }

    anyOverProposed = proposal => {
        return proposal?.proposal_services?.some(ps => !['Draft', 'Proposed'].includes(ps.status))
    }

    componentWillMount = () => {
        if (window.location.href.split("?")[1] === "contact_arborist=true") {
            setTimeout(() => {
                this.props.actions.showDock("notes")
            }, 500)
        }
        if (this.props.params.token) {
            setTimeout(() => {
                this.setState({show: true})
            }, 500)
            this.props.actions.fetchProposal(this.props.params.token, res => {
                this.setState({
                    customerViewOptions: JSON.parse(res.customer_view_options),
                    printOptions: res.print_options
                })
            })
            this.props.actions.getConversation(this.props.params.token,
                result => this.setState({stickies: result}));
            // fetch site and site map settings for a proposal
            this.getInvoices()
        } else {
            //should never get here because router should not mount this page and instead 404
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.proposal) {
            // fetch site and site map settings for a proposal
            this.setState({
                allDone: nextProps.proposal.accepted_date !== null
                    || nextProps.proposal.declined_date !== null
            });
            this.setState({
                accepted: nextProps.proposal.proposal_services && nextProps.proposal.proposal_services
                    .filter(ps => !['Proposed', 'Not Offered', 'Declined'].includes(ps.status))
                    .map(ps => ps.proposal_service_id)
            });
            this.setState({
                declined: nextProps.proposal.proposal_services && nextProps.proposal.proposal_services
                    .filter(ps => ps.status === 'Declined')
                    .map(ps => ps.proposal_service_id)
            });

            let include_maps = {};
            // include main site by default
            include_maps[0] = true;

            this.setState({include_maps: include_maps, loading: false}, () => setTimeout(this.resize, 0))
        }


        if (!this.state.isSubmitted) {
            if (window.location.href.split("?")[1] === "accept=true" && !nextProps.proposal.accepted_date && !nextProps.proposal.declined_date) {
                const {proposal} = nextProps;
                let notAcceptedIds = this.getNotAcceptedIds(proposal);
                this.setState({acceptedTerms: true})
                this.setState({accepted: this.state.accepted.concat(notAcceptedIds), declined: []});
                this.showAcceptPopUp(true)
            } else if (window.location.href.split("?")[1] === "decline=true" && !nextProps.proposal.accepted_date && !nextProps.proposal.declined_date) {
                const {proposal} = nextProps;
                let notDeclinedIds = this.getNotDeclinedIds(proposal);
                this.setState({acceptedTerms: true})
                this.setState({declined: this.state.declined.concat(notDeclinedIds), accepted: []});
                this.showDeclinePopUp(true)
            }
        }

        if (nextProps.customerSiteMaps) {
            let include_maps = {}
            // include main site by default
            include_maps[0] = true
            const siteId = this.props.proposal.site_id || nextProps.proposal.site_id
            const siteMaps = nextProps.customerSiteMaps[siteId]
            if (siteMaps && siteMaps.length > 0) {
                siteMaps.map(siteMap => {
                    include_maps[siteMap.id] = siteMap.include_when_print
                })

                this.setState({include_maps: include_maps})
            }
        }
    }


    onMarkerClick = (ass) => {
        this.setState({selectedAsset: ass.asset_number});
    };

    infoWindow = (ass, lat, lng) => {

        if (this.state.selectedAsset === ass.asset_number) {
            return (
                <InfoWindow
                    key={this.state.selectedAsset}
                    position={{lat: lat ? lat : ass.latitude, lng: lng ? lng : ass.longitude}}
                    onCloseclick={() => this.setState({selectedAsset: null})}>
                    <div className="info-window no_white-space">
                        <div>
                            <Row>
                                {this.props.proposal?.print_options?.assetPhotosCustomerView && ass.photos.length > 0 &&
                                    <Col md={4}>
                                        {ass.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).slice(0, 1).map(i =>
                                            <span className="text-success pointer"
                                                  onClick={() => this.showPhotoPopUp(ass)}><img width={50}
                                                                                                alt="tree"
                                                                                                src={i.url}/></span>)}
                                    </Col>}
                                <Col md={8}>
                                    <b>#{ass.asset_label}{" "}<PlantName plant={ass.name}/></b><br/>
                                    {ass.dbh ? `DBH: ${ass.dbh}"` : ""}
                                    {ass.dbh ? <br/> : null}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </InfoWindow>
            )
        }
    }

    handleFormChange = e => {
        this.setState({
            [e.target.name]: e.target.type === "checkbox"
                ? e.target.checked
                : e.target.value === "true",
        })
    }
    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    }

    renderPrintOptionsHeader = () => {
        return (
            <Panel
                bsStyle="primary"
                collapsible
                defaultExpanded={true}
                header="Print Options"
                className="hidden-print"
            >
                <Row>
                    <Col xs={12}>
                        <p>
                            The Proposal can be customized before printing. Checked items are
                            included in the printout.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Prices</h5>
                            <FormGroup>
                                <Checkbox
                                    name="proposalTotal"
                                    checked={this.state.proposalTotal}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposal Total
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePrices"
                                    checked={this.state.servicePrices}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Subtotals
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Notes</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetNotes"
                                    checked={this.state.assetNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalServiceNotes"
                                    checked={this.state.proposalServiceNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalCustomerNote"
                                    checked={this.state.proposalCustomerNote}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposal Note
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Maps</h5>
                            <FormGroup>
                                <Checkbox
                                    name="maps"
                                    checked={this.state.maps}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Maps
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Assets</h5>
                            <FormGroup>
                                <Checkbox
                                    name="largeAssetPhotos"
                                    checked={this.state.largeAssetPhotos}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Photos
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="locationDescription"
                                    checked={this.state.locationDescription}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Location Description
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="dbh"
                                    checked={this.state.dbh}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    DBH
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Services</h5>
                            <FormGroup>
                                <Checkbox
                                    name="serviceDateRanges"
                                    checked={this.state.serviceDateRanges}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Date Ranges
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesNotOffered"
                                    checked={this.state.servicesNotOffered}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Not Offered
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePriorities"
                                    checked={this.state.servicePriorities}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Priorities
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesDeclined"
                                    checked={this.state.servicesDeclined}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Declined
                                </Checkbox>
                                <Checkbox
                                    name="servicesAccepted"
                                    checked={this.state.servicesAccepted}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Accepted
                                </Checkbox>
                                <Checkbox
                                    name="servicesProposed"
                                    checked={this.state.servicesProposed}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposed
                                </Checkbox>
                                <Checkbox
                                    name="servicesCompleted"
                                    checked={this.state.servicesCompleted}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Completed
                                </Checkbox>
                                <Checkbox
                                    name="servicesInvoiced"
                                    checked={this.state.servicesInvoiced}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Invoiced
                                </Checkbox>
                                <Checkbox
                                    name="servicesPaid"
                                    checked={this.state.servicesPaid}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Paid
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small" style={styles.printWidget}>
                            <h5>Options</h5>
                            <FormGroup>
                                <Checkbox
                                    name="arboristInfo"
                                    checked={this.state.arboristInfo}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Arborist Info
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="tos"
                                    checked={this.state.tos}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Terms
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="proposal_footer"
                                    checked={this.state.proposal_footer}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Footer
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="assInline"
                                    checked={this.state.assInline}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Assets Inline
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <div style={styles.printWidget}>
                            <Button
                                bsSize="small"
                                onClick={() => window.print()}
                                bsStyle="primary"
                            >
                                Print
                            </Button>
                        </div>
                        <div style={styles.clearFix}/>
                    </Col>
                </Row>
            </Panel>
        )
    }

    toggleIncludeMap = (index) => {
        const include_maps = this.state.include_maps
        include_maps[index] = !include_maps[index]
        this.setState({include_maps: include_maps})
    }

    _renderMapSettings = (proposal) => {
        const {customerSiteMaps} = this.props
        const siteMaps = customerSiteMaps[proposal.site_id]
        if (siteMaps) {
            return siteMaps.map((siteMap, index) =>
                <div key={siteMap.id + "_settings"}
                     className={classNames('print_site_map', 'avoid_page_breaking_inside', {'hidden-print': !this.state.include_maps[siteMap.id]})}>
                    <hr className="hidden-print"/>
                    <p className="hidden-print">
                        <input type="checkbox" checked={this.state.include_maps[siteMap.id]} id="check"
                               onChange={this.toggleIncludeMap.bind(this, siteMap.id)}/>
                        <label htmlFor="check">&nbsp;Include when printing</label>
                    </p>
                    {this._renderSiteMap(siteMap, proposal, this.props.client)}
                </div>)
        }
    }

    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.lat
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.lng
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.asset_label)}
                strokeColor={"#ffffff"}
                zIndex={1000}
            >
                {this.infoWindow(ass, averageLat, averageLng)}
            </Marker>
        )
    }

    _renderSiteMap = (siteMap, proposal, client) => {
        return (
            <div className="text-center">
                <StaticMap
                    size="600x600"
                    zoom={siteMap.default_zoom || 20}
                    maptype={siteMap.map_type || 'hybrid'}
                    center={
                        `${siteMap.latitude || ((client && client.organization_latitude) ?
                            client.organization_latitude
                            : 40.0)},
                        ${siteMap.longitude || ((client && client.organization_longitude) ?
                            client.organization_longitude
                            : -105.0)}`}
                    proposalId={proposal.id}
                />
            </div>
        )
    };

    renderSiteMap = (proposal, mapNum) => {
        if (proposal.proposal_services === undefined) {
            return null
        }
        let markers = [];
        proposal.proposal_services.forEach(ps => {
            ps.assets.forEach(a => {
                let marker = {};
                marker.id = a.asset_number;
                marker.label = a.asset_label;
                marker.latitude = a.lat;
                marker.longitude = a.lng;
                marker.color = a.plant_color;
                markers.push(marker);
            })
        });
        return (
            <div className="text-center top25">
                <StaticMap
                    size="600x600"
                    markers={markers}
                    maptype={proposal.site_map_type || "hybrid"}
                    proposalId={proposal.id}
                />
            </div>
        );
    };
    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (ass.shapeOpacity && polygon?.type_polygon === 'polyLine') {
            return 0.35
        } else if (ass.shapeOpacity) {
            return 0.2
        } else if (ass.opacity && polygon?.type_polygon === 'polyLine') {
            return 0.75
        } else if (ass.opacity) {
            return 0.5
        } else {
            return 1
        }
    }

    renderLiveMap = (proposal, mapNum = 1) => {
        if (proposal.proposal_services === undefined) {
            return null
        }

        let coordinates = [];
        let selectedServices;

        if (proposal) {
            selectedServices = proposal.proposal_services.filter(ps => ps.status !== "Not Offered")
            proposal.proposal_services = selectedServices
        }

        getUniqueAssets(proposal).forEach(a => {
            if (a?.polygons?.length > 0) {
                const polygon = a.polygons.find(p => p.chosen)
                if (polygon.type_polygon === 'circle') {
                    let centerSfo = new window.google.maps.LatLng(polygon.polygon_points[0].latitude, polygon.polygon_points[0].longitude);
                    let circle = new window.google.maps.Circle({radius: polygon.radius, center: centerSfo});
                    let bounds = circle.getBounds()
                    let lat = Object.values(bounds)[0]
                    let long = Object.values(bounds)[1]
                    if (bounds && lat && long) {
                        coordinates.push(
                            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[0]]},
                            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[1]]},
                            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[1]]},
                            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[0]]})
                    }
                } else {
                    for (let p of polygon.polygon_points) {
                        coordinates.push({latitude: p.latitude, longitude: p.longitude});
                    }
                }
            } else {
                coordinates.push({latitude: a.lat, longitude: a.lng});
            }

        })

        const {hoverAsset} = this.state;
        const defaultCenter = {
            lat: proposal.site_lat,
            lng: proposal.site_lng,
        };
        const center = hoverAsset ? {lat: hoverAsset.lat, lng: hoverAsset.lng} : defaultCenter;
        return (
            <>
                <GoogleMapLoader
                    containerElement={
                        <div
                            id="map_view"
                            style={{
                                height: `${(window.innerHeight / 1.18) - 70}px`,
                                width: `${isMobile ? null : (window.innerWidth > 990) ? (window.innerWidth / 2.5) : (window.innerWidth / 1.1)}px`
                            }}
                        />
                    }
                    googleMapElement={
                        <GoogleMap
                            ref={(map) => {
                                const bounds = new window.google.maps.LatLngBounds();

                                coordinates.map((ass, idx) => {
                                    bounds.extend(new window.google.maps.LatLng(ass.latitude, ass.longitude))

                                });
                                map && map.fitBounds(bounds)
                            }}
                            defaultZoom={mapNum === 1 ? proposal.site_zoom : 20}
                            mapTypeId={proposal.site_map_type || "hybrid"}
                            tilt={0}
                            center={center}
                        >
                            {getUniqueAssets(proposal).map((ass, i) => {
                                const polygon = ass.polygons.find(p => p.chosen)
                                const opacity = this.setShapeOpacity(ass)

                                if (polygon && polygon.type_polygon !== 'marker') {

                                    const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                    if (polygon.type_polygon === 'circle') {
                                        return (<Circle
                                            ref={(ref) => {
                                                this [i] = ref;
                                            }}
                                            onClick={(e) => this.onMarkerClick(ass, this [i], 'circle')}
                                            defaultOpacity={1}
                                            defaultCenter={{
                                                lat: polygon.polygon_points[0].latitude,
                                                lng: polygon.polygon_points[0].longitude
                                            }}
                                            options={{
                                                fillOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                            key={ass.asset_number}
                                            radius={polygon.radius}
                                        />)
                                    } else if (polygon.type_polygon === 'polyLine') {
                                        polygon.polygon_points.map(p => {
                                            p.lng = p.longitude
                                            p.lat = p.latitude
                                        })
                                        return (<Polyline
                                            ref={(ref) => {
                                                this [i] = ref;
                                            }}
                                            onClick={(e) => this.onMarkerClick(ass, this [i], 'polyline')}
                                            key={ass.asset_number}
                                            path={polygon.polygon_points}
                                            options={{
                                                strokeOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                        />)
                                    } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                        return (<Rectangle
                                            ref={(ref) => {
                                                this [i] = ref;
                                            }}
                                            onClick={(e) => this.onMarkerClick(ass, this [i], 'rectangle')}
                                            key={ass.asset_number}
                                            bounds={{
                                                north: polygon.polygon_points[0].latitude,
                                                south: polygon.polygon_points[3].latitude,
                                                east: polygon.polygon_points[1].longitude,
                                                west: polygon.polygon_points[0].longitude
                                            }}
                                            options={{
                                                fillOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                        />)
                                    } else {
                                        polygon.polygon_points.map(p => {
                                            p.lng = p.longitude
                                            p.lat = p.latitude
                                        })
                                        return (<Polygon
                                            ref={(ref) => {
                                                this [i] = ref;
                                            }}
                                            onClick={(e) => this.onMarkerClick(ass, this [i], 'polygon')}
                                            path={polygon.polygon_points}
                                            key={ass.asset_number}
                                            options={{
                                                fillOpacity: opacity,
                                                strokeColor: color,
                                                fillColor: color
                                            }}
                                        />)
                                    }
                                } else {
                                    return (
                                        <Marker
                                            key={ass.asset_number}
                                            position={{lat: ass.lat, lng: ass.lng}}
                                            icon={getBasicAssetMarker({
                                                asset: ass,
                                                highlighted: this.state.selectedAsset && this.state.selectedAsset === ass.asset_number,
                                                label: ass.asset_label.toString()
                                            })}
                                            draggable={false}
                                            onClick={() => this.onMarkerClick(ass)}
                                        >
                                            {this.infoWindow(ass)}
                                        </Marker>
                                    )
                                }
                            })}
                            {getUniqueAssets(proposal).map((ass) => {
                                const polygon = ass.polygons.find(p => p.chosen)
                                if (polygon && polygon.type_polygon !== 'marker') {
                                    return (
                                        this.markerPolygon(ass)
                                    )
                                }
                            })}
                        </GoogleMap>
                    }
                />
            </>
        )
    }

    renderPhotos = proposal => {
        if (proposal.proposal_services === undefined) {
            return null
        }

        let photos = [];
        const assets = getUniqueAssets(proposal);
        assets.forEach(a => {
            a.photos_small.forEach(p => {
                photos.push(
                    <Table bordered key={p}>
                        <thead/>
                        <tbody>
                        <tr>
                            <td className="text-center">
                                <img alt="tree" src={p}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                #{a.asset_label} {a.name}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )
            })
        })

        if (photos.length === 0) {
            photos = null
        } else {
            return (
                <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <h5>Photos</h5>
                        {photos}
                    </Col>
                </Row>
            )
        }
    }

    handleToggle = (ps, acceptOrDecline) => e => {
        const checked = e.target.checked;
        const addCollection = acceptOrDecline ? 'accepted' : 'declined';
        const removeCollection = acceptOrDecline ? 'declined' : 'accepted';
        const newState = {};
        if (checked) {
            this.state[addCollection].push(ps.proposal_service_id);
            newState[addCollection] = this.state[addCollection];
            newState[removeCollection] = this.state[removeCollection].filter(x => x !== ps.proposal_service_id);
        } else {
            newState[removeCollection] = this.state[removeCollection].filter(x => x !== ps.proposal_service_id);
            newState[addCollection] = this.state[addCollection].filter(x => x !== ps.proposal_service_id);
        }
        this.setState(newState);
    };

    rowFormatter = proposal => {
        const proposal_services = filterProposalServices(proposal.proposal_services, proposal.print_options)
        const last_proposal_service = proposal?.proposal_services?.length - 1
        return (
            <Table striped hover fill condensed className="mt-13 header-fixed customer-view">
                <tr className="margin-2 font12 space-between-end" style={{background: '#dddddd'}}>
                    <strong className="ml-15">Proposal details</strong>
                    <div className="d-flex mr-9">
                        <div className="vertical-align">
                            <Checkbox
                                onChange={() => {
                                    let notAcceptedIds = this.getNotAcceptedIds(proposal);
                                    if (this.areAllAccepted(proposal)) {
                                        this.setState({accepted: []})
                                    } else {
                                        this.setState({
                                            accepted: this.state.accepted.concat(notAcceptedIds),
                                            declined: []
                                        });
                                    }
                                }}
                                inline
                                disabled={(this.state.allDone && this.allOverProposed(proposal)) || this.anyOverProposed(proposal)}
                                checked={this.areAllAccepted(proposal)}
                            />
                            <span className="mr-9">Accept</span>
                        </div>
                        <div className="vertical-align">
                            <Checkbox
                                onChange={() => {
                                    let notDeclinedIds = this.getNotDeclinedIds(proposal);
                                    if (this.areAllDeclined(proposal)) {
                                        this.setState({declined: []})
                                    } else {
                                        this.setState({
                                            declined: this.state.declined.concat(notDeclinedIds),
                                            accepted: []
                                        });
                                    }
                                }}
                                inline
                                disabled={(this.state.allDone && this.allOverProposed(proposal)) || this.anyOverProposed(proposal)}
                                checked={this.areAllDeclined(proposal)}
                            />
                            <span>Decline</span>
                        </div>
                    </div>
                </tr>
                <tbody>
                {proposal_services &&
                    proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            if (
                                !proposal.print_options.servicesNotOffered &&
                                ps.status === "Not Offered"
                            ) {
                                return <div className="skip_not_offered"/>
                            }
                            let rowClass = this.state.accepted.includes(ps.proposal_service_id) ? 'accepted' : '';
                            rowClass += this.state.declined.includes(ps.proposal_service_id) ? ' declined' : '';
                            return (
                                <div className={`mt-5 full-width ${rowClass}`}>
                                    <tr id='row-striped'
                                        style={{backgroundColor: ps.assets.find(a => a.asset_number === this.state.selectedAsset) && '#f4ff93'}}
                                        key={ps.service_no} className={rowClass}>
                                        <td>
                                            <div className="ml-15">
                                                <strong className="pointer" onClick={() => {
                                                    const open = this.state.open !== ps.service_no ? ps.service_no : null;
                                                    this.setState({open});
                                                }}>
                                                    #{ps.service_no}{" "}{ps.service_name}
                                                </strong>
                                            </div>
                                            {!this.state.declined.includes(ps.proposal_service_id) &&
                                                <Panel className="no-border pre-line" collapsible
                                                       expanded={this.state.open !== ps.service_no}>
                                                    {proposal.print_options.proposalServiceNotes && ps.proposal_service_note}
                                                </Panel>}
                                            {!this.state.declined.includes(ps.proposal_service_id) && ps.assets
                                                .sort((a, b) => sortAssetsLabels(a, b, 'asset_label'))
                                                .map((a, i) => (
                                                    <div key={a.asset_number}
                                                         className={proposal.print_options.assInline ? "inline-assets ml-15 line_height15" : ""}>
                                                        {(proposal.print_options.assetPhotosCustomerView && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                            <span
                                                                onClick={() => this.showPhotoPopUp(a)}>
                             <span className='text-success pointer'><img src={p.url}
                                                                         className="asset-thumbnail"/></span>
                          </span>) : ""}
                                                        <small
                                                            onClick={() => this.setState({selectedAsset: a.asset_number})}
                                                            className="pointer"
                                                            style={{color: (this.state.selectedAsset && this.state.selectedAsset === a.asset_number ? '#FF9E00' : '')}}>
                                                            <PlantName plant={a.name}/>{" "}(#{a.asset_label}){" "}
                                                        </small>

                                                        {a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}
                                                        {proposal.print_options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                        {a.plant_count > 1 || (proposal.print_options.dbh && a.dbh)
                                                            ? " "
                                                            : null}
                                                        <span>{proposal.print_options.condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                        <span>{proposal.print_options.lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                        <span>{proposal.print_options.lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                        <span>{proposal.print_options.height && a.height ? ` Height: ${a.height}` : null}</span>
                                                        <span>{proposal.print_options.width && a.width ? ` Width: ${a.width}` : null}</span>
                                                        <span>{proposal.print_options.stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                        <span>{proposal.print_options.factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                        {proposal.print_options.locationDescription && a.location
                                                            ? `Location: ${a.location}`
                                                            : null}
                                                        {proposal.print_options.locationDescription ? "" : null}
                                                        {proposal.print_options.assetNotes && a.note && a.note.length > 1
                                                            ? `Note: ${a.note}  `
                                                            : null}
                                                        {proposal.print_options.assInline ? ((ps.assets.length - 1) == i) ? null : "," : ""}
                                                    </div>
                                                ))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={isMobile && "line_height15"}>
                                            <h6 className="text-warning ml-15 bottom0 line_height15">
                                                {this.state.status && ps.status
                                                    ? `Status: ${ps.status}`
                                                    : null}
                                            </h6>
                                            {this.state.status && ps.status
                                                ? <br/>
                                                : null}

                                            <h6 className="text-warning ml-15 bottom0 line_height15">
                                                {proposal.print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                {proposal.print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                    ps.priorities.map(p => <span
                                                        key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                            </h6>
                                        </td>
                                    </tr>
                                    {proposal.print_options.serviceDateRanges && ps.date_ranges.length
                                        ? ps.date_ranges.map(dr => (
                                            <div key={dr.id} className="pad-left">
                                                Typical Timing:
                                                {" "}
                                                {moment(dr.from_date).format("MMMM Do")}
                                                {" "}
                                                -
                                                {" "}
                                                {moment(dr.to_date).format("MMMM Do")}
                                            </div>
                                        ))
                                        : null}
                                    <tr>
                                        <td className="pad-left full-width">Accepted:</td>
                                        <td>
                                            <Checkbox
                                                onChange={this.handleToggle(ps, true)}
                                                inline
                                                disabled={this.state.allDone && this.allOverProposed(proposal)}
                                                checked={this.state.accepted.includes(ps.proposal_service_id)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="bottom10">
                                        <td className="pad-left full-width">Declined:</td>
                                        <td>
                                            <Checkbox
                                                onChange={this.handleToggle(ps, false)}
                                                inline
                                                disabled={this.state.allDone && this.allOverProposed(proposal)}
                                                checked={this.state.declined.includes(ps.proposal_service_id)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="text-right">
                                        {proposal.print_options.servicePrices
                                            ? <div className="mt-15 bottom10 nmr-15"><Dollars amount={ps.price}/></div>
                                            : null}
                                    </tr>
                                    <hr className={proposal.proposal_services && proposal.proposal_services[last_proposal_service] ? 'bottom0 mt10' : 'mb-10-mt-0'}/>
                                </div>
                            )
                        })}

                </tbody>
            </Table>
        )
    }

    renderServicesTable = proposal => {
        const {toggle_bundle_services} = this.state

        const handleDropDown = (bundleProposalId) => {
            let value = ''
            const findBundleService = toggle_bundle_services.indexOf(bundleProposalId)

            if (findBundleService < 0) {
                value = bundleProposalId
            } else {
                toggle_bundle_services.splice(findBundleService, 1)
                value = toggle_bundle_services
            }

            this.setState({
                toggle_bundle_services:
                    [...toggle_bundle_services, value]
            })
        }

        const handlePanelExpanded = (bundleProposalId) => {
            let filterBundleServices = toggle_bundle_services.filter(tbs => tbs === bundleProposalId).length > 0
            return filterBundleServices
        }

        const proposal_services = filterProposalServices(proposal.proposal_services, proposal.print_options)
        return (
            <Table striped hover fill condensed className="header-fixed customer-view">
                <thead>
                <tr>
                    <th width="40%">Service</th>
                    <th width="15%">Timing</th>
                    <th width="15%">Price</th>
                    <th width="15%">
                        <Checkbox
                            onChange={() => {
                                let notAcceptedIds = this.getNotAcceptedIds(proposal);
                                if (this.areAllAccepted(proposal)) {
                                    this.setState({accepted: []})
                                } else {
                                    this.setState({accepted: this.state.accepted.concat(notAcceptedIds), declined: []});
                                }
                            }}
                            inline
                            disabled={(this.state.allDone && this.allOverProposed(proposal)) || this.anyOverProposed(proposal)}
                            checked={this.areAllAccepted(proposal)}
                        />
                        <span>Accept</span>
                    </th>
                    <th width="15%">
                        <Checkbox
                            onChange={() => {
                                let notDeclinedIds = this.getNotDeclinedIds(proposal);
                                if (this.areAllDeclined(proposal)) {
                                    this.setState({declined: []})
                                } else {
                                    this.setState({declined: this.state.declined.concat(notDeclinedIds), accepted: []});
                                }
                            }}
                            inline
                            disabled={(this.state.allDone && this.allOverProposed(proposal)) || this.anyOverProposed(proposal)}
                            checked={this.areAllDeclined(proposal)}
                        />
                        <span>Decline</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {proposal_services &&
                    proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            if (
                                !proposal.print_options.servicesNotOffered &&
                                ps.status === "Not Offered"
                            ) {
                                return <div className="skip_not_offered"/>
                            }
                            let rowClass = this.state.accepted.includes(ps.proposal_service_id) ? 'accepted' : '';
                            rowClass += this.state.declined.includes(ps.proposal_service_id) ? ' declined' : '';
                            return (
                                <tr id='row-striped'
                                    style={{backgroundColor: ps.assets.find(a => a.asset_number === this.state.selectedAsset) && '#f4ff93'}}
                                    key={ps.service_no} className={rowClass}>
                                    <td width="40%">
                                        <>
                                            <strong className="pointer"
                                                    onClick={() => {
                                                        const open = this.state.open !== ps.service_no ? ps.service_no : null;
                                                        this.setState({open});
                                                    }}>
                                                #{ps.service_no}{" "}{ps.service_name}
                                            </strong>
                                            <br/>
                                            {proposal.print_options.servicePurchaseOrder && ps.purchase_order? <span>
                                                <strong>PO:</strong>
                                                {" "}{ps.purchase_order}
                                            </span> : null}
                                            {!this.state.declined.includes(ps.proposal_service_id) &&
                                                <Panel
                                                    className={`no-border pre-line ${ps.is_bundle_service && 'bundle-service'}`}
                                                    collapsible={true}
                                                    expanded={this.state.open !== ps.service_no}
                                                >
                                                    {proposal.print_options.proposalServiceNotes && ps.proposal_service_note}
                                                    {ps.is_bundle_service &&
                                                        <>
                                                            <Panel className="no-border pre-line" collapsible
                                                                   expanded={this.state.open !== ps.service_no}>
                                                                {ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map((bs, index) => {
                                                                    return (
                                                                        <div id="bundle-service-customer-view">
                                                                            <strong
                                                                                className={`pointer ${toggle_bundle_services.includes(bs.id) ? "" : "blue-underline-text"}`}
                                                                                onClick={() => handleDropDown(bs.id)}>
                                                                                {bs.name}
                                                                            </strong>
                                                                            <Panel
                                                                                className="no-border pre-line"
                                                                                style={{padding: '0px'}}
                                                                                collapsible
                                                                                expanded={handlePanelExpanded(bs.id)}>
                                                                                {proposal.print_options.proposalServiceNotes && <p className={toggle_bundle_services.includes(bs.id) ? "" : "bs-hidden-notes"}>{bs.notes}</p>}
                                                                                <div
                                                                                    className='inline-assets mt3'>
                                                                                    {bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, index) => {
                                                                                        return (
                                                                                            <div
                                                                                                className='customer-assets__photos'>
                                                                                                {proposal.print_options.assetPhotosCustomerView && a?.photos.map(p => {
                                                                                                    return (
                                                                                                        <img
                                                                                                            src={p.url}/>
                                                                                                    )
                                                                                                })}
                                                                                                <small
                                                                                                    onClick={() => this.setState({selectedAsset: a.asset_number})}
                                                                                                    className="pointer"
                                                                                                    style={{color: (this.state.selectedAsset && this.state.selectedAsset === a.asset_number ? '#FF9E00' : '')}}>
                                                                                                    <PlantName
                                                                                                        plant={a.name}/><i>{" "}(#{a.asset_label}){" "}</i>
                                                                                                </small>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>

                                                                            </Panel>

                                                                        </div>
                                                                    )
                                                                })}
                                                            </Panel>
                                                        </>}
                                                </Panel>}
                                            {!this.state.declined.includes(ps.proposal_service_id) && ps.assets
                                                .sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(asset => {
                                                    if (asset.image_order) {
                                                        let order = JSON.parse(asset.image_order);
                                                        asset.photos = asset.photos.map(img => {
                                                            img.order = order.findIndex((o) => o === img.id)
                                                            return img
                                                        })
                                                        return asset
                                                    } else {
                                                        asset.photos = asset.photos.map((img, index) => {
                                                            img.order = index
                                                            return img
                                                        })
                                                        return asset
                                                    }
                                                })
                                                .map((a, i) => (
                                                    <div key={a.asset_number}
                                                         className={proposal.print_options.assInline ? "inline-assets" : ""}>
                                                        {(proposal.print_options.assetPhotosCustomerView && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                            <span
                                                                onClick={() => this.showPhotoPopUp(a)}>
                                        <span className='text-success pointer'><img src={p.url}
                                                                                    className="asset-thumbnail"/></span>
                                        </span>) : ""}
                                                        <small
                                                            onClick={() => this.setState({selectedAsset: a.asset_number})}
                                                            className="pointer"
                                                            style={{color: (this.state.selectedAsset && this.state.selectedAsset === a.asset_number ? '#FF9E00' : '')}}>
                                                            <PlantName plant={a.name}/>{" "}(#{a.asset_label}){" "}
                                                        </small>

                                                        {a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}
                                                        {proposal.print_options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                        {a.plant_count > 1 || (proposal.print_options.dbh && a.dbh)
                                                            ? " "
                                                            : null}
                                                        <span>{proposal.print_options.condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                        <span>{proposal.print_options.lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                        <span>{proposal.print_options.lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                        <span>{proposal.print_options.height && a.height ? ` Height: ${a.height}` : null}</span>
                                                        <span>{proposal.print_options.width && a.width ? ` Width: ${a.width}` : null}</span>
                                                        <span>{proposal.print_options.stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                        <span>{proposal.print_options.factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                        {proposal.print_options.locationDescription && a.location
                                                            ? ` Location: ${a.location}`
                                                            : null}
                                                        {proposal.print_options.locationDescription ? "" : null}
                                                        {proposal.print_options.assetNotes && a.note && a.note.length > 1
                                                            ? ` Note: ${a.note}  `
                                                            : null}
                                                        {proposal.print_options.assInline ? ((ps.assets.length - 1) == i) ? null : "," : ""}
                                                    </div>
                                                ))}
                                        </>


                                    </td>
                                    <td width="15%">
                                        <h6 className="text-warning">
                                            {this.state.status && ps.status
                                                ? `Status: ${ps.status}`
                                                : null}
                                        </h6>
                                        {this.state.status && ps.status
                                            ? <br/>
                                            : null}

                                        <h6 className="text-success">
                                            {proposal.print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                            {proposal.print_options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                ps.priorities.map(p => <span
                                                    key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                        </h6>

                                        {proposal.print_options.serviceDateRanges && ps.date_ranges.length
                                            ? ps.date_ranges.map(dr => (
                                                <div key={dr.id}>
                                                    Typical Timing:
                                                    {" "}
                                                    {moment(dr.from_date).format("MMMM Do")}
                                                    {" "}
                                                    -
                                                    {" "}
                                                    {moment(dr.to_date).format("MMMM Do")}
                                                </div>
                                            ))
                                            : null}
                                    </td>
                                    <td width="15%">
                                        <h6>
                                            {proposal.print_options.servicePrices
                                                ? <Dollars amount={ps.price}/>
                                                : null}
                                        </h6>
                                    </td>
                                    <td width="15%">
                                        <Checkbox
                                            onChange={this.handleToggle(ps, true)}
                                            inline
                                            disabled={(this.state.allDone && this.allOverProposed(proposal)) || !['Draft', 'Proposed'].includes(ps.status)}
                                            checked={this.state.accepted.includes(ps.proposal_service_id)}
                                        />
                                    </td>
                                    <td width="15%">
                                        <Checkbox
                                            onChange={this.handleToggle(ps, false)}
                                            inline
                                            disabled={(this.state.allDone && this.allOverProposed(proposal)) || !['Draft', 'Proposed'].includes(ps.status)}
                                            checked={this.state.declined.includes(ps.proposal_service_id)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}

                </tbody>
            </Table>


        )
    }

    renderProposalNote = proposal => {
        if (proposal?.print_options?.proposalCustomerNote) {
            return (<Row className="avoid_page_breaking_inside">
                {proposal.proposal_customer_note && <Col xs={12}>
                    <p>&nbsp;</p>
                    <em style={{whiteSpace: 'pre-line'}}>
                        {proposal.proposal_customer_note || ""}
                    </em>
                    <p>&nbsp;</p>
                </Col>}
            </Row>)
        }
    }

    renderPageHeader = (proposal, newMessages, stickiesIds) => {
        const {accepted, declined, acceptedTerms, downloadInProgress} = this.state;

        return (
            <>
                <div className="header-price-date">
                    {!proposal.accepted_date && this.state.customerViewOptions && this.state.customerViewOptions.proposalExpirationDate && this.renderValidateDate(proposal)}
                    {proposal.print_options.proposalTotal && this.renderProposalTotal(proposal)}
                </div>
                <div className="vertical-align">
                    <div className='image-button'>
                        <img alt='message' src={messageIcon} style={{height: 40}}
                             onClick={() => {
                                 this.props.actions.readUserMessages(this.props.token, stickiesIds, () => {
                                     this.props.actions.getConversation(this.props.params.token, result => this.setState({stickies: result}))
                                     this.props.actions.showDock("notes")

                                 })
                             }}/>
                        {newMessages && newMessages.length > 0 &&
                            <Badge variant="danger" className="message-notification-view"><span
                                className="font13 text-white">{newMessages && newMessages.length}</span></Badge>}
                    </div>
                    <div className="proposal-button">
                        {proposal.proposal_services && !(this.state.allDone && this.allOverProposed(proposal)) &&
                            <Button
                                className="proposal-button"
                                onClick={() => declined.length > 0 && accepted.length === 0 ? this.showDeclinePopUp(true) : acceptedTerms ? this.showAcceptPopUp(true) : this.showTermsPopUp(true)}
                                disabled={!(declined.length > 0 || accepted.length > 0)}>
                                {declined.length > 0 && accepted.length === 0 ? 'Decline proposal' : 'Submit Selected Services'}
                            </Button>}
                        <Button
                            disabled={downloadInProgress}
                            onClick={() => {
                                this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(proposal,
                                    () => this.setState({downloadInProgress: false}),
                                    () => this.setState({downloadInProgress: false})
                                ))
                            }}
                            className="proposal-button"
                        >
                            {downloadInProgress ?
                                <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    renderTermsAndConditions = proposal => {
        return (
            <Row className="avoid_page_breaking_inside top50">
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <div dangerouslySetInnerHTML={{__html: proposal.terms}}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    renderValidateDate = (proposal) => {
        const currentDate = moment()
        let validUntil, date, days, months, months_days_left

        if (this.state.customerViewOptions.dateFormat === 'days') {
            validUntil = moment(proposal.proposal_date).add(this.state.customerViewOptions.days, this.state.customerViewOptions.dateFormat)
            days = validUntil.diff(currentDate, 'days')
        } else if (this.state.customerViewOptions.dateFormat === 'months') {
            validUntil = moment(proposal.proposal_date).add(this.state.customerViewOptions.months, this.state.customerViewOptions.dateFormat)
            months = validUntil.diff(currentDate, 'months')
            currentDate.add(months, 'months');
            months_days_left = validUntil.diff(currentDate, 'days');
        } else {
            validUntil = moment(proposal.proposal_date).add(this.state.customerViewOptions.days, 'days')
            date = moment(proposal.proposal_date).add(this.state.customerViewOptions.days, 'days').format(defaultDateFormat)
        }


        return (
            <Col xs={12}>
                <Row>
                    <Col className='justify-flex-end'>
                        {this.state.customerViewOptions.dateFormat === 'days' &&
                            <Col md={12} className='justify-flex-end no-padding font16'><p
                                className={`${days >= 0 ? 'text-success' : 'text-danger'}`}>
                                <strong>{days >= 0 ? <>Proposal valid for {days} days</> : <>Proposal
                                    expired</>}</strong>
                            </p></Col>}
                        {this.state.customerViewOptions.dateFormat === 'months' &&
                            <Col md={12} className='justify-flex-end no-padding font16'><p
                                className={`${months >= 0 ? 'text-success' : 'text-danger'}`}>
                                <strong>{months >= 0 ? <>Proposal valid
                                    for {months > 0 && <>{months} months</>} {months_days_left} days</> : <>Proposal
                                    expired</>}</strong></p>
                            </Col>}
                        {this.state.customerViewOptions.dateFormat === 'date' &&
                            <Col md={12} className='justify-flex-end no-padding font16'><p
                                className={`${validUntil.diff(currentDate, 'days') >= 0 ? 'text-success' : 'text-danger'}`}>
                                <strong>{validUntil.diff(currentDate, 'days') >= 0 ? <>Proposal valid
                                    until {date}</> : <>Proposal expired</>}</strong></p></Col>}
                    </Col>
                </Row>
            </Col>

        )
    }
    renderProposalTotal = proposal => {
        if (proposal.proposal_services === undefined) {
            return null
        }


        const total = proposal.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);

        const {declined, accepted} = this.state

        const acceptedTotal = proposal.proposal_services
            .filter(ps => this.state.accepted.includes(ps.proposal_service_id))
            .reduce((total, ps) => {
                return total + (ps.status !== "Not Offered" ? ps.price : 0)
            }, 0);


        if (isMobile) {
            return (
                <>
                    {proposal.proposal_services && !(this.state.allDone && this.allOverProposed(proposal)) ?
                        <div className="justify-flex-end pl-10">
                            <p className="text-success text-right"><strong className="font12">You Are
                                Accepting:</strong></p>
                            <p className="text-success font12 price">{<Dollars amount={acceptedTotal}/>}</p>
                        </div>
                        :
                        declined.length > 0 && accepted.length === 0 ?
                            <div className="justify-flex-end proposal-status pl-10">
                                <Glyphicon className="mr-5 text-danger" glyph='remove'/>
                                <span className="text-danger">Proposal Declined</span>
                            </div>
                            :
                            <div className="justify-flex-end proposal-status pl-10">
                                <Glyphicon className="mr-5" glyph='ok'/>
                                <span className="text-success">Proposal Accepted</span>
                            </div>}
                    <div className="justify-flex-end pl-10">
                        <p className="text-primary text-right"><strong className="font12">Total Price:</strong></p>
                        <p className="text-primary font12 price">{<Dollars
                            amount={this.state.allDone && this.allOverProposed(proposal) ? (acceptedTotal + acceptedTotal * proposal.tax) : (total + total * proposal.tax)}/>}</p>
                    </div>
                </>
            )
        }
        return (
            <>
                {proposal.proposal_services && !(this.state.allDone && this.allOverProposed(proposal)) ?
                    <div className="justify-flex-end pl-10">
                        <p className="text-success text-right"><strong className="font12">You Are Accepting:</strong>
                        </p>
                        <p className="text-success font12 price">{<Dollars amount={acceptedTotal}/>}</p>
                    </div>
                    :
                    declined.length > 0 && accepted.length === 0 ?
                        <div className="justify-flex-end proposal-status pl-10">
                            <Glyphicon className="mr-5 text-danger" glyph='remove'/>
                            <span className="text-danger">Proposal Declined</span>
                        </div>
                        :
                        <div className="justify-flex-end proposal-status pl-10">
                            <Glyphicon className="mr-5" glyph='ok'/>
                            <span className="text-success">Proposal Accepted</span>
                        </div>}
                <div className="justify-flex-end pl-10">
                    <p className="text-primary text-right"><strong className="font12">Total Price:</strong></p>
                    <p className="text-primary font12 price justify-flex-end align-text-end">{<Dollars
                        amount={this.state.allDone && this.allOverProposed(proposal) ? (acceptedTotal + acceptedTotal * proposal.tax) : (total + total * proposal.tax)}/>}</p>
                </div>
            </>
        )
    }

    renderCustomerSignatureLine = (proposal) => (
        <div className="avoid_page_breaking_inside">
            {
                proposal.print_options.proposal_footer &&
                <Row className="top25">
                    {proposal.proposal_footer}
                </Row>
            }
        </div>
    )

    renderClientHeader = proposal => (
        <Row>
            <Col xs={12} className="text-left">
                <img
                    alt="company header"
                    className="logoHeader"
                    src={proposal.client_header}
                />
            </Col>
        </Row>
    )

    renderPaymentPopUp = proposal => {
        const invoicesList = []
        proposal.proposal_services.filter(ps => ps.invoice_no).map(ps => {
            if (!invoicesList.some(item => item.label === ps.invoice_no)) {
                const invoice = {
                    label: ps.invoice_no,
                    value: ps.invoice_token
                }
                invoicesList.push(invoice)
            }
        })
        return <PaymentModal show={this.state.showPaymentPopUp} reload={() => this.showPaymentPopUp(false)}
                             invoicesList={invoicesList} onHide={() => this.showPaymentPopUp(false)}/>
    }

    renderPhotoPopUp = asset => {

        return (
            <div className="static-modal">
                <Modal
                    id="customer-view-modal"
                    style={{zIndex: 99999}}
                    animation={false}
                    show={true}
                    onHide={() => this.showPhotoPopUp(null)}
                >
                    <Modal.Header>
                        <Modal.Title>Photos for Tree #{asset.asset_label} {asset.name} </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Carousel>
                            {
                                asset.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map((p, id) =>
                                    <Carousel.Item key={id}>
                                        <img src={p.url} className='modal-image'/>
                                        {this.state.printOptions.locationDescription && <Carousel.Caption>
                                            <h5 style={{color: 'white', backgroundColor: 'black', opacity: 0.5}}>
                                                #{asset.asset_label} {asset.name}<br/>
                                                {asset.location}<br/>
                                                {asset.description}
                                            </h5>
                                        </Carousel.Caption>}
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button bsStyle="primary" onClick={() => this.showPhotoPopUp(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    renderInfoPopUp = () => {
        return (
            <div>
                <Modal
                    id="customer-view-info-modal"
                    style={{zIndex: 99999}}
                    animation={false}
                    show={true}
                    onHide={() => this.showInfoPopUp(null)}
                >
                    <Modal.Header>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h4>{this.state.infoPopUpMessage}</h4>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button bsStyle="success" onClick={() => this.showInfoPopUp(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    updateAttr = e => {
        this.state[e.target.name] = e.target.value;
        this.setState(this.state);
    }

    renderAcceptPopUp = proposal => {
        const {isSignature, first_name, last_name} = this.state
        const emptySignature = () => {
            this.setState({isSignature: false, first_name: "", last_name: ""})
        }
        const clear = () => {
            this.sigPadRef.current.clear()
            this.setState({isSignature: false})
        }

        return (
            <div className="static-modal">
                <Modal
                    style={{zIndex: 99999}}
                    bsSize="large"
                    animation={false}
                    show={true}
                    className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                >
                    <Modal.Header className="text-center">
                        <h3>Confirmation and Signature</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Important notice:</h4>
                        <p>After accepting selected services there will be no option to modify the order.</p>
                        <p>Please make sure your selection is correct.</p>
                        <p>If you have any question please send us a message through the Communicate button on the
                            order
                            page.</p>
                        <p className='vcenter'>By accepting this proposal you accept&nbsp;<Link
                            className="inline-link pointer"
                            onClick={() => this.showTermsPopUp(true)}> terms &
                            conditions</Link>
                        </p>
                        <h4 className="top25">Enter your full name:</h4>
                        <div className="margin-5">
                            <Row>
                                <Col xs={5}>
                                    <FormGroup>
                                        <ControlLabel>
                                            <span>First Name</span>
                                        </ControlLabel>
                                        <FormControl
                                            name="first_name"
                                            onChange={(e) => this.updateAttr(e)}
                                            value={first_name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={7}>
                                    <FormGroup>
                                        <ControlLabel>
                                            <span>Last Name</span>
                                        </ControlLabel>
                                        <FormControl
                                            name="last_name"
                                            onChange={(e) => this.updateAttr(e)}
                                            value={last_name}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <h4>Your signature:</h4>
                        <div style={{background: 'lightgray'}} className="margin-5">
                            <SignatureCanvas
                                ref={this.sigPadRef}
                                penColor='black'
                                canvasProps={{
                                    height: 250,
                                    className: 'full-width'
                                }}
                                onEnd={() => this.setState({isSignature: true})}
                            />
                        </div>
                        <button type="button" onClick={clear} className="btn btn-primary">Clear Signature</button>
                    </Modal.Body>

                    <Modal.Footer>
                        <Grid fluid>
                            <Row>
                                <Col md={6} className="pull-right">
                                    <Button onClick={() => {
                                        this.showAcceptPopUp(false);
                                        emptySignature();
                                        this.setState({accepted: [], declined: []});
                                    }}>
                                        I'm not sure yet
                                    </Button>
                                    <Button
                                        disabled={!isSignature || first_name.length === 0 || last_name.length === 0 || this.state.acceptedProposalButtonPressed}
                                        bsStyle={this.state.declined.length > 0 && this.state.accepted.length === 0 ? "danger" : "success"}
                                        onClick={() => this.setState({acceptedProposalButtonPressed: true}, () => {
                                            this.setState({isSubmitted: true})
                                            this.props.actions.submit(
                                                this.props.params.token,
                                                this.state.accepted,
                                                this.state.declined,
                                                {
                                                    signature: this.sigPadRef.current.toDataURL('image/png'),
                                                    edited_by: this.props.proposal?.billing_email,
                                                    first_name: first_name,
                                                    last_name: last_name
                                                }, (res) => {
                                                    this.setState({
                                                        allDone: true,
                                                        acceptedProposalButtonPressed: false
                                                    }, () => {
                                                        this.props.actions.fetchProposal(this.props.params.token);
                                                        this.showAcceptPopUp(false)
                                                        emptySignature()
                                                    });
                                                    if (res.customer_with_no_email) {
                                                        this.setState({
                                                            showInfoPopUp: true,
                                                            infoPopUpMessage: res.message
                                                        })
                                                    }
                                                })
                                        })}
                                        className="pointer"
                                    >
                                        {this.state.declined.length > 0 && this.state.accepted.length === 0 ? 'I decline the proposal' : 'I Accept the proposal'}
                                    </Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    renderDeclinePopUp = proposal => {
        return (
            <div className="static-modal">
                <Modal
                    style={{zIndex: 99999}}
                    bsSize="large"
                    animation={false}
                    show={true}
                    className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                >
                    <Modal.Header className="text-center">
                        <h3>Decline Proposal</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Important notice:</h4>
                        <p>Are you sure you wish to decline this proposal?</p>
                        <p>If you have any question please send us a message through the Communicate button on the
                            order
                            page.</p>

                    </Modal.Body>

                    <Modal.Footer>
                        <Grid fluid>
                            <Row>
                                <Col md={6} className="pull-right">
                                    <Button onClick={() => {
                                        this.showDeclinePopUp(false);
                                        this.setState({accepted: [], declined: []});
                                    }}>
                                        I'm not sure yet
                                    </Button>
                                    <Button
                                        disabled={this.state.acceptedProposalButtonPressed}
                                        bsStyle={this.state.declined.length > 0 && this.state.accepted.length === 0 ? "danger" : "success"}
                                        onClick={() => this.setState({acceptedProposalButtonPressed: true}, () => {
                                            this.setState({isSubmitted: true})
                                            this.props.actions.submit(
                                                this.props.params.token,
                                                this.state.accepted,
                                                this.state.declined,
                                                {
                                                    edited_by: this.props.proposal?.billing_email,
                                                }, (res) => {
                                                    this.setState({allDone: true}, () => {
                                                        this.props.actions.fetchProposal(this.props.params.token);
                                                        this.showDeclinePopUp(false)
                                                    });
                                                    if (res.customer_with_no_email) {
                                                        this.setState({
                                                            showInfoPopUp: true,
                                                            infoPopUpMessage: res.message
                                                        })
                                                    }
                                                })
                                        })}
                                        className={`pointer`}
                                    >
                                        {'I decline the proposal'}
                                    </Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    renderTermsPopUp = proposal => {
        const {acceptedTerms} = this.state
        if (proposal.proposal_services === undefined) {
            return null
        }
        return (
            <div className="static-modal">
                <Modal
                    style={{zIndex: 99999}}
                    bsSize="large"
                    animation={false}
                    show={true}
                >

                    <Modal.Body>
                        {this.renderTermsAndConditions(proposal)}
                        {!acceptedTerms && <Row>
                            <Col md={6} className="pull-right text-right pb-10">
                                <Button bsStyle="warning"
                                        className="mr-3"
                                        onClick={() => this.showTermsPopUp(false)}
                                >
                                    I'm not sure yet
                                </Button>
                                <Button bsStyle="success"
                                        onClick={() => {
                                            this.setState({acceptedTerms: true})
                                            this.showTermsPopUp(false)
                                            this.showAcceptPopUp(true)
                                        }}
                                >
                                    I Accept Terms & Conditions
                                </Button>
                            </Col>
                        </Row>}
                    </Modal.Body>
                    {acceptedTerms && <Modal.Footer>
                        <Grid fluid>
                            <Row>
                                <Col md={6} className="pull-right">
                                    <Button bsStyle="success"
                                            onClick={() => this.showTermsPopUp(false)}
                                    >
                                        OK
                                    </Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Footer>}
                </Modal>
            </div>
        )
    }

    getNotAcceptedIds(proposal) {
        if (!proposal.proposal_services) {
            return [];
        }
        return proposal.proposal_services.filter(ps => !this.state.accepted.includes(ps.proposal_service_id) && ps.status !== 'Not Offered')
            .map(ps => ps.proposal_service_id);
    }

    getNotDeclinedIds(proposal) {
        if (!proposal.proposal_services) {
            return [];
        }
        return proposal.proposal_services.filter(ps => !this.state.declined.includes(ps.proposal_service_id) && ps.status !== 'Not Offered')
            .map(ps => ps.proposal_service_id);
    }

    areAllAccepted(proposal) {
        return proposal.proposal_services && this.state.accepted.length === proposal.proposal_services.length
    }

    areAllDeclined(proposal) {
        return proposal.proposal_services && this.state.declined.length === proposal.proposal_services.length
    }

    showPhotoPopUp = showPhotoPopUp => {
        this.setState({showPhotoPopUp});
    };

    showInfoPopUp = showInfoPopUp => {
        this.setState({showInfoPopUp});
    };

    showPaymentPopUp = showPaymentPopUp => {
        this.setState({showPaymentPopUp})
    };

    showAcceptPopUp = showAcceptPopUp => {
        this.setState({showAcceptPopUp});
    };
    showDeclinePopUp = showDeclinePopUp => {
        this.setState({showDeclinePopUp});
    };

    showTermsPopUp = showTermsPopUp => {
        this.setState({showTermsPopUp});
    };

    render() {
        const {proposal, customerSiteMaps} = this.props
        const {client} = this.props;
        const {
            showPhotoPopUp,
            showPaymentPopUp,
            showTermsPopUp,
            showAcceptPopUp,
            showDeclinePopUp,
            stickies,
            declined,
            accepted,
            showInfoPopUp
        } = this.state;
        let customerId = proposal.customer_id
        let title = 'Actions'
        let assets = null
        if (proposal && proposal.proposal_services) {
            assets = proposal.proposal_services.map(ps => ps.assets).join()
        }

        if (this.state.loading || _.isEmpty(proposal)) {
            return <div className='loading-screen'><MDSpinner size={100}/></div>;
        }

        let messages;
        let newMessages;
        const allMessages = stickies;
        const messagesReplies = stickies && stickies.map(sticky => sticky.replies).reduce((arr, b) => arr.concat(b), []);

        if (proposal && allMessages && allMessages.length > 0 && messagesReplies && messagesReplies.length === 0) {
            messages = allMessages;
            messages.sort((a, b) => a.id - b.id);
            const customerMessages = messages?.length > 0 && messages.filter(x => x.created_by.includes(proposal.customer_name?.split(" ")[1]));
            const lastCustomerMsgId = customerMessages?.length > 0 && customerMessages.pop().id;

            newMessages = messages.filter(message => message.id > lastCustomerMsgId && message.user_message_read !== true)
        } else if (proposal && allMessages && allMessages.length > 0 && messagesReplies && messagesReplies.length > 0) {
            messages = allMessages.concat(messagesReplies);
            messages.sort((a, b) => a.id - b.id);
            const customerMessages = messages && messages.filter(x => x.created_by.includes(proposal.customer_name?.split(" ")[1]));
            const lastCustomerMsgId = customerMessages && customerMessages.pop().id;

            newMessages = messages.filter(message => message.id > lastCustomerMsgId && message.user_message_read !== true)
        }

        const stickiesIds = stickies && stickies.map(sticky => sticky.id)
        const clientLogo = proposal ? proposal.customer_view_client_header : ''
        const newViewEnabled = this.state.customerViewOptions.newCustomerView
        return (proposal && <div id='customerView' className={`${isMobile ? "customerView--Mob" : "customerView"}`}>
                {declined && <Dock type="notes" reload={false}>
                    <Conversation stickies={stickies}
                                  save={
                                      (note, callback) => {
                                          this.props.actions.addNote(this.props.params.token,
                                              note, (element, list) => this.setState({stickies: list}));
                                          callback();
                                      }}>
                        <Grid fluid>
                            <Row>
                                <Col md={12}>
                                    <p className="margin10">
                                        Please use this note field to note any scheduling requests, if you must
                                        present at the time of service or if you would like to be added to our no notice
                                        list
                                    </p>
                                </Col>
                            </Row>
                        </Grid>
                    </Conversation>
                </Dock>}
                <CustomerViewProposalHeader proposal={proposal}
                                            newMessages={newMessages}
                                            stickiesIds={stickiesIds}
                                            clientLogo={clientLogo}
                                            isMobile={isMobile}
                                            renderPageHeader={this.renderPageHeader}/>
                {newViewEnabled && <Row className="customerView__tabs">
                    {isMobile
                        ? <Col xs={12} sm={12} className='menu-column'>
                            <CustomerViewMenu token={this.props.params.token}/>
                        </Col>
                        :
                        <Col>
                            <CustomerViewTabs token={this.props.params.token}/>
                        </Col>
                    }
                </Row>}
                <Row className='customerView__content'>
                    {isMobile
                        ? <Col xs={12} sm={12} className={`customerView__map ${this.state.show ? 'showMap' : ''}`}>
                            {this.renderLiveMap(proposal)}
                        </Col>
                        : <Col md={5} className={`customerView__map ${this.state.show ? 'showMap' : ''}`}
                               style={{borderRightColor: "black"}}>
                            {this.renderLiveMap(proposal)}
                        </Col>
                    }
                    <Col md={isMobile ? 12 : 7} sm={12} id='#sheet-table'
                         className={isMobile ? "customerView__items" : "customerView__items sheet-table"}>
                        {proposal?.print_options?.proposalCustomerNote &&
                            this.renderProposalNote(proposal)}
                        {isMobile ? this.rowFormatter(proposal) : this.renderServicesTable(proposal)}
                    </Col>
                </Row>
                {showPaymentPopUp && this.renderPaymentPopUp(proposal)}
                {showPhotoPopUp && this.renderPhotoPopUp(showPhotoPopUp)}
                {showTermsPopUp && this.renderTermsPopUp(proposal)}
                {showAcceptPopUp && this.renderAcceptPopUp(proposal)}
                {showDeclinePopUp && this.renderDeclinePopUp(proposal)}
                {showInfoPopUp && this.renderInfoPopUp()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        proposal: state.customerView.proposal,
        customerSiteMaps: state.siteMaps.siteMaps,
        client: state.client.customerInfo
    }
}

CustomerViewPage.propTypes =
    {
        proposal: PropTypes.object.isRequired,
    }

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewPage)
