import React from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Helmet from "react-helmet"
import * as MyActions from "./CompletedProposalsApi"
import {Link} from "react-router"
import Dollars from "../../components/Dollars"
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table"
import Select from 'react-select'
import {
    Button,
    Col,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Popover,
    Row,
} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import moment from "moment/moment"
import {saveNewCsvDownload, defaultDateFormat, defaultDateFormatShort, select} from "../../common/commonHandlers";
import ResourceComponent from "../../components/ResourceComponent";
import DateRangeFilter from "../../components/DateRangeFilter";
import './CompletedProposals.scss';
import {debounce} from "throttle-debounce";

const Actions = {...MyActions, saveNewCsvDownload}

class CompletedProposals extends ResourceComponent {
    state = {
        selectedService: [],
        resource: {
            page: 1,
            per_page: 25,
            sort: "desc",
            sort_by: "proposal_no"
        },
        proposals: null,
        total_entries: null
    }

    tableRef = React.createRef()

    componentDidMount() {
        this.props.actions.load(response => {
            this.setState(response)
        })
    }

    handleRangeDateChange = (start, end) => {
        let {resource} = this.state
        this.state.resource.proposalProposedStart = (start && start !== '') ? start : null;
        this.state.resource.proposalProposedEnd = (end && end !== '') ? end : null;
        this.setState({resource})
    }

    render() {
        const {resource, proposals, services, salesArborists, total_entries} = this.state;
        const {proposalProposedStart, proposalProposedEnd, salesArboristId, selectedService} = this.state.resource;
        return (
            <Grid fluid id="completed-phc-proposals">
                <Helmet title="Completed Proposals"/>
                    <Row>
                        <Col md={12}>
                            <h3 className="text-primary title-bar">
                                {`Completed PHC Proposals ${proposals ? `${total_entries} found.` : ""} `}
                            </h3>
                            <FormGroup bsSize="sm" className="filters-bar">
                                <DateRangeFilter
                                    promisedStart={proposalProposedStart ? moment(proposalProposedStart) : ""}
                                    promisedEnd={proposalProposedEnd ? moment(proposalProposedEnd) : ""}
                                    upsertPromise={(id, start, end) => this.handleRangeDateChange(start, end)}
                                    serviceNo={null}
                                    index={1}
                                    format={defaultDateFormat}
                                />
                                <Select className="Select" classNamePrefix="select"
                                        name="sales-arborist-select"
                                        isClearable
                                        value={select(salesArborists, salesArboristId)}
                                        options={salesArborists}
                                        onChange={this.selectResourceAttr('salesArboristId')}
                                        placeholder="Sales Arborist"
                                />
                                <Select className="Select" classNamePrefix="select"
                                        placeholder="Service"
                                        isMulti
                                        simpleValue={true}
                                        value={select(services, selectedService)}
                                        onChange={this.selectResourceAttr('selectedService')}
                                        options={services}
                                />
                                <div className="text-right">
                                    <Button type="submit" onClick={() => {
                                        this.setState({resource: {...resource, page: 1}}, () => {
                                            this.fetchCompletedProposals()
                                        })
                                    }} className="find-proposals-button" bsStyle="success">
                                        Find Proposals
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                <Row>
                    <Col md={12}>
                        {this._renderTable(proposals)}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}/>
                </Row>
            </Grid>
        )
    }

    fetchCompletedProposals = e => {
        const {resource} = this.state;
        this.props.actions.fetchCompletedProposals(resource, response => {
            this.setState({
                proposals: response.completed_phc_proposals,
                total_entries: response.total_entries
            })
            document.getElementsByClassName('react-bs-table')[0].scrollTo(0,0)
        })
    }

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _dollarFormatter = cell => <Dollars amount={cell}/>

    onSearchTextUpdate = debounce(1000, ()=>{
        const {resource} = this.state
        resource.page = 1
        this.setState({resource}, this.fetchCompletedProposals)
    })

    _renderTable = data => {
        const {total_entries} = this.state
        const {page, per_page, sort, sort_by} = this.state.resource
        const options = {
            paginationPanel: this.renderPaginationPanel,
            page: page,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            sizePerPageList: [
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '75', value: 75},
            ],
            sizePerPage: per_page,
            onPageChange: (page, per_page) => {
                let {resource} = this.state;
                if (per_page !== resource.per_page) {
                    page = 1
                }
                resource = {...resource, page, per_page};
                this.setState({resource}, this.fetchCompletedProposals);
            },
            sortName: sort_by,
            sortOrder: sort,
            onSortChange: (sort_by, sort) => {
                let {resource} = this.state;
                resource = {...resource, sort_by, sort};
                this.setState({resource}, this.fetchCompletedProposals);
            },
            onSearchChange: (text) => {
                let {resource} = this.state
                resource.searchText = text
                this.setState({resource}, this.onSearchTextUpdate)
            }
        }

        data && data.forEach(element => {
            element.site_name = `${element.site_name}`
            element.site_address = `${element.site_address}`
            element.arborist = `${element.arborist}`
            element.services = element.proposal_services?.map(
                ps =>
                    `Status: ${ps.status} $${parseFloat(ps.price).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2})} #${ps.service_no} ${ps.service_name} Completed On: ${`${ps.completed_date ? moment(ps.completed_date).format(defaultDateFormatShort) : "N/A"}`} ${ps.assets ? ps.assets.map(ass =>
                        `#${ass.asset_number} ${ass.name} ${ass.plant_count > 1 ? `[${ass.plant_count}]` : ""} ${ass.dbh > 1 ? `DBH: ${ass.dbh}` : ""}`
                    ) : ""}`
            ).join(', \r\n')

            element.proposed_date = element.proposed_date ? moment(element.proposed_date).format(defaultDateFormatShort) : ''
            element.completed_date = moment(element.completed_date).format(defaultDateFormatShort)
        })


        return (
            <BootstrapTable
                ref={this.tableRef}
                data={data}
                remote={true}
                striped={true}
                bordered={false}
                hover={true}
                exportCSV={true}
                pagination={true}
                search={true}
                fetchInfo={{dataTotalSize: total_entries}}
                csvFileName={() => {
                    const fileName = `completed-proposals-${moment().format()}.csv`
                    if (this.tableRef?.current) {
                        this.props.actions.saveNewCsvDownload(this.tableRef.current, fileName, this.state.resource, "completed proposals")
                    }
                    return fileName
                }}
                options={options}
            >
                <TableHeaderColumn
                    width="15%"
                    dataField="name"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._nameFormatter}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Customer Name
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="site_name"
                    hidden
                    export
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="site_address"
                    hidden
                    export
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="arborist"
                    hidden
                    export
                >
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="5%"
                    dataField="proposal_no"
                    dataFormat={this._proposalLinkFormatter}
                    isKey={true}
                    dataSort={true}
                    columnClassName='menu-show-fixer'
                >
                    Proposal No
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="5%"
                    dataField="proposed_date"
                    dataFormat={this._stackDateFormater}
                    dataSort={true}>
                    Status Dates
                </TableHeaderColumn>

                <TableHeaderColumn
                    width="25%"
                    export={false}
                    dataField="proposal_services"
                    tdStyle={{whiteSpace: 'normal'}}
                    thStyle={{whiteSpace: 'normal'}}
                    dataFormat={this._serviceFormatter}
                >
                    Services
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="services"
                    hidden
                    export
                >
                </TableHeaderColumn>

            </BootstrapTable>
        )
    }

    _nameFormatter(cell, row) {
        return (
            <div style={{display: 'grid'}}>
                <div>
                    <strong>
                        {row.name}
                    </strong>
                </div>
                <div>
                    <small>
                        <strong>Site: </strong>
                        {row.site_address}
                    </small>
                </div>
                <div>
                    <small>
                        <strong>Arborist: </strong>
                        {row.arborist}
                    </small>
                </div>
            </div>
        );
    }

    _serviceFormatter(cell, row) {
        return (
            `${row.proposal_services?.map(s =>
                `
        <div style={ { display: 'grid' } }>
          <div>
              <h4>${s.status} ${" "} $${s.price.toFixed(2)}</h4>
              
              #${s.service_no}
             
              ${s.service_name}
              <small>Completed On: ${s.completed_date ? moment(s.completed_date).format(defaultDateFormatShort) : "N/A"}</small>
          </div>
          <div>
            ${s.assets ? s.assets.map(ass =>
                    `${" "}${ass.name}(#${ass.asset_number}) ${ass.plant_count > 1 ? `[${ass.plant_count}]` : ""}  ${ass.dbh > 1 ? `DBH: ${ass.dbh}` : ""} ${ass.note ? `Tree Note: ${ass.note}` : ""}`
                ) : ""}
          </div>
          <div>
             <small>${s.proposal_service_note ? s.proposal_service_note : ""}</small>
          </div>
          <div>
             <small>${s.ps_work_order_note ? s.ps_work_order_note : ""}</small>
          </div>
        </div>
        `
            )
            }`
        );
    }

    _stackDateFormater(cell, row) {
        const popoverRight = (
            <Popover id="popover-positioned-top" title="Status Dates">
                <div style={{display: 'grid'}}>
                    <div>
                        <strong>
                            <small>
                                {row.proposed_date ?
                                    `Proposed: ${row && moment(row.proposed_date).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <small>
                                {row.accepted_date ?
                                    `Accepted: ${row && moment(row.accepted_date).format(defaultDateFormatShort)}`
                                    : null}
                            </small>
                        </strong>
                    </div>
                </div>
            </Popover>
        );
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverRight}>
                <h6
                    className="text-small text-info text-center"
                >
                    {`${row && moment(row.proposed_date).format(defaultDateFormatShort)}`}
                </h6>
            </OverlayTrigger>
        );
    }

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={`${row.name}`}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/customer/info/${row.id}`}>
                    <MenuItem bsSize="small">Info</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/proposals/${row.id}`}>
                    <MenuItem bsSize="small">Proposals</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/sites/${row.id}`}>
                    <MenuItem bsSize="small">Sites</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_orders/${row.id}`}>
                    <MenuItem bsSize="small">Work Orders</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/invoices/${row.id}`}>
                    <MenuItem bsSize="small">Invoices</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/customer/work_history/${row.id}`}>
                    <MenuItem bsSize="small">Work History</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _proposalLinkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                eventKey={2}
                title={cell}
                id={`proposal-menu`}
                className="menu-show-fixer proposal-menu-padding-reset"
            >
                <LinkContainer to={`/mapview/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Proposal Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.proposal_id}`}>
                    <MenuItem bsSize="small">Print Proposal</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedProposals)
