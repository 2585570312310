import React, {Component} from "react"
import {
    Button,
    Col,
    Grid,
    MenuItem,
    Nav,
    NavDropdown,
    Row,
    FormGroup,
    FormControl,
    Checkbox,
    Modal, ControlLabel
} from "react-bootstrap"
import {Link} from "react-router"
import {LinkContainer} from "react-router-bootstrap"
import * as Actions from "./actions"
import * as sitesActions from "../NewCustomerInfo/NewCustomerInfoPageApi"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Select from "react-select"
import moment from "moment-timezone"
import {Circle, GoogleMap, Marker, Polygon, Polyline, Rectangle} from "react-google-maps"
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import Dollars from "../../../components/Dollars"
import AssetEditorModal from "../../AssetEditorModal"
import {CustomerSiteHeader} from "./CustomerSiteHeader"
import {getLabel} from "../../MapViewPage/IconProvider";
import {
    colors, defaultDateFormat, defaultDateFormatShort,
    checkRectangleCoordinates,
    mapForSelect,
    optionsForSelect,
    select, sortAssetsLabels
} from "../../../common/commonHandlers";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import './CustomerSites.scss'
import {utils, writeFileXLSX} from "xlsx";
import {getBasicAssetMarker, getBasicMarker} from "../../../utilities";
import CustomerSitesEditModal from "../NewCustomerInfo/NewCustomerInfoComponents/CustomerSitesEditModal";

const smMobile = window.screen.width <= 450;
const isMobile = window.screen.width <= 1024;
const reportTypes = [{value: 'TRA', label: 'Tree Risk Assessment'},
    {value: 'TPP', label: 'Tree Protection Plan'},
    {value: 'TCR', label: 'Tree Condition Report'},
    {value: 'TA', label: 'Tree Appraisal'},
]
const personalPronouns = [{value: 'he', label: 'he'},
    {value: 'she', label: 'she'},
    {value: 'they', label: 'they'},
]
const driplines = [{value: 1, label: '1x'},
    {value: 1.5, label: '1.5x'},
    {value: 2, label: '2x'},
]

class CustomerSites extends Component {
    state = {
        site: {},
        asset: {},
        showAsMarkers: false,
        selectedSiteMap: null,
        isReportSelectMode: false,
        reportAssets: [],
        showReportModal: false,
        dripline: null,
        leadDate: {month: null, year: null},
        currentZooms: {},
        ordinances: [],
        ordinance_id: null,
        customerId: null
    };

    componentDidMount = () => {
        const query = this.props.location.query;
        if (query.site_id) {
            this._updateSelectedSite({value: +query.site_id})
        }
        if (this.props.params && this.props.params.id) {
            this.setState({customerId: this.props.params.id})
            this.props.actions.fetchCustomerById(this.props.params.id)
            this._loadCustomerInfo(this.props.params.id)
        }
        window._googleMapComponent = [];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedSiteInfo, customerSites, sitesAssets} = this.props;
        if (!this.props.selectedSite && this.props.customerSites) {
            this._updateSelectedSite({value: this.props.customerSites.length > 0 ? this.props.customerSites[0]?.id : null})
        }
        if (sitesAssets && this.state.site && customerSites && customerSites.length > 0 && selectedSiteInfo && customerSites[0].id !== selectedSiteInfo.id && customerSites[0].id !== this.state.site.id) {
            const {site} = this.state;
            this.props.actions.getSite(customerSites[0].id, (sites) => {
                this.setState({site: Object.values(sites)[0]});
                this.props.actions.getSiteAssets(Object.values(sites)[0].id, (assets) => {
                    this.setState({asset: Object.values(assets)[0][0]});
                });
            });
        }
        if(this.props.params.id !== prevProps.params.id){
            const query = this.props.location.query;
            if (query.site_id) {
                this._updateSelectedSite({value: +query.site_id})
            }
            this.setState({customerId: this.props.params.id})
            this.props.actions.fetchCustomerById(this.props.params.id)
            this._loadCustomerInfo(this.props.params.id)
        }
        if (prevProps.editingSiteId !== this.props.editingSiteId) {
            this.props.actions.fetchCustomerSites()
        }
        if (this.props.selectedSiteInfo && prevProps.selectedSiteInfo !== this.props.selectedSiteInfo){
            this.setState({ordinance_id: this.props.selectedSiteInfo?.ordinance_id})
        }
    }

    setShowReportModal = (show) => {
        this.setState({showReportModal: show})
    }

    render() {
        const {
            customer,
            proposals,
            customerSites,
            selectedSite,
            foundCustomers,
            sitesAssets,
            editingAssetId,
            editingSiteId,
            selectedSiteInfo,
            customerSiteMaps,
        } = this.props;

        let customerSitesOptions = []
        if (customerSites?.length > 0) {
            customerSitesOptions = mapForSelect(customerSites);
        }

        const {customerId} = this.state
        const siteMaps = customerSiteMaps[selectedSite]
        if (customerId === null) {
            return (<span>I need a customerid parameter. I can't go on</span>)
        }

        if (customer === null) {
            return (<span>Loading...</span>)
        }
        const generateArrayOfYears = () => {
            let max = moment().year()
            let min = max - 9
            let years = []

            for (let i = max; i >= min; i--) {
                years.push({value: i, label: i})
            }
            return years
        }
        return (
            <div>
                <Grid fluid id='leads'>
                    <Row>
                        <Col md={12}>
                        </Col>
                    </Row>
                    <Row className="vcenter">
                        <Col md={4} className="vcenter">
                            <h2 className="no-top-margin mr-9">Customer sites</h2>
                            <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{customerSites?.length} returned</h5>
                        </Col>
                        <Col md={8} className="text-right">
                            <h5>
                                <span style={{color: "#f89406"}}>Customer Arborist:</span>
                                {" "}
                                {customer.sales_arborist || "None Assigned"}
                            </h5>
                        </Col>
                    </Row>
                    <hr className={'mb-10-mt-0'}/>
                    <Row className="no-left-margin no-right-margin mt-20">
                        <Col md={12} className='no-padding'>
                            <CustomerSiteHeader
                                customerId={parseInt(customerId, 10)}
                                customerList={foundCustomers.map(customer => ({
                                    value: customer.id,
                                    label: customer.name,
                                }))}
                                onUpdateCustomerSearch={this.props.actions.updateCustomerSearch}
                                onSelectCustomer={this.props.actions.updateSelectedCustomer}
                                title="Customer Sites"
                                salesArborist={customer.sales_arborist}
                                selectedSite={selectedSite}
                                selectedSiteInfo={selectedSiteInfo}
                                sites={customerSites}
                                customer={customer}
                                assets={sitesAssets}
                                editingSiteId={editingSiteId}
                                saveAllMapSettings={this._saveAllMapSettings}
                                createNewSite={this._newSite}
                                roles={this.props.roles}
                                actions={this.props.actions}
                                newSiteMap={this._newSiteMap}
                                applyHistoryFilter={this._applyHistoryFilter}
                                onChangeSite={this._updateSelectedSite}
                                customerSitesOptions={customerSitesOptions}
                                editSite={this._editSite}
                                setShowReportModal={this.setShowReportModal}
                            />
                        </Col>
                    </Row>
                    <Row className="no-left-margin no-right-margin mt-20">
                        <Col>
                            <div>
                                <strong>Billing Address:</strong>
                                {" "}
                                {customer.address_1},
                                {customer.address_2}{" "}
                                {customer.city}, {customer.state}{" "}
                                {customer.zip}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <Button className={`${this.state.isReportSelectMode ? 'btn-primary' : 'btn'}`}
                                    onClick={() => this.setState({isReportSelectMode: !this.state.isReportSelectMode}, () => this.setState({reportAssets: []}))}>
                                {this.state.isReportSelectMode ? 'Remove Report Assets' : 'Select Report Assets'}
                            </Button>
                        </Col>
                    </Row>

                    <Row className="no-left-margin no-right-margin mt-30">
                        {this._renderProposalMapUi(sitesAssets, proposals)}
                        {siteMaps && this._renderMapSettings(sitesAssets)}
                    </Row>
                    {customerSites?.length === 0 && <h3>Customer has no sites!</h3>}

                    {editingAssetId !== null
                        ? <AssetEditorModal
                            editingAssetId={editingAssetId}
                            doneEditingAsset={this._cancelModal}
                            updateAssetAttribute={this.props.actions.updateAssetAttribute}
                            onSuccess={this._onSuccess}
                        />
                        : null}

                    {editingSiteId !== null && <CustomerSitesEditModal
                        onSuccess={() => {
                            const site = customerSites.find(s => s.id === selectedSite.id);
                            this._updateSelectedSite(site ? {value: site.id, label: site.name} : null)
                            this._loadCustomerInfo(customer.id);
                        }}
                        customerId={customer.id}
                        onSave={this.props.actions.saveSite}
                        getMovedProposals={this.props.actions.getMovedProposals}
                        editedSite={this.props.sites.find(s => s.id === editingSiteId)}
                        onHide={() => this.props.actions.editingSiteId(null)}
                        onDelete={this.props.actions.deleteSite}
                        customerContacts={this.props.contacts}
                        uploadSiteAttachment={this.props.actions.uploadSiteAttachment}
                        deleteSiteAttachment={this.props.actions.deleteSiteAttachment}
                        reorderSieAttachment={this.props.actions.reorderSieAttachment}
                        selectSiteAttachmentForPrint={this.props.actions.selectSiteAttachmentForPrint}
                        getSites={this.props.actions.fetchSites}
                        onCustomerSearch={this.props.actions.searchForCustomer}
                        onCustomerContactSearch={this.props.actions.fetchContacts}
                        roles={this.props.roles}
                        fetchStateAndCityForSite={this.props.actions.fetchStateAndCityForCustomer}
                        getProposalsForInactive={this.props.actions.getProposalsForInactive}
                        fetchOrdinances={this.props.actions.fetchOrdinances}
                    />}

                </Grid>
                {this.state.showReportModal && <Modal
                    bsSize="large"
                    animation={false}
                    show={true}
                    onHide={() => this.setShowReportModal(false)}
                    backdrop="static"
                    className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Report Generator
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <ControlLabel>
                                Report Type
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(reportTypes, this.state.reportType)}
                                    options={reportTypes}
                                    onChange={(e) => this.setState({reportType: e?.value})}
                                    placeholder="Report Type"
                                    isClearable
                                    required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Personal pronoun
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(personalPronouns, this.state.personalPronoun)}
                                    options={personalPronouns}
                                    onChange={(e) => this.setState({personalPronoun: e?.value})}
                                    placeholder="Personal pronoun"
                                    isClearable
                                    required
                            />
                        </FormGroup>
                        {this.state.reportType === 'TPP' && <FormGroup>
                            <ControlLabel>
                                Dripline
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(driplines, this.state.dripline)}
                                    options={driplines}
                                    onChange={(e) => this.setState({dripline: e?.value})}
                                    placeholder="Dripline"
                                    isClearable
                                    required
                            />
                        </FormGroup>}
                        <FormGroup>
                            <ControlLabel>
                                Lead date
                            </ControlLabel>
                            <Row>
                                <Col md={6}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(optionsForSelect(moment.months()), this.state.leadDate.month)}
                                            options={optionsForSelect(moment.months())}
                                            onChange={(e) => this.setState({
                                                leadDate: {
                                                    ...this.state.leadDate,
                                                    month: e?.value
                                                }
                                            })}
                                            placeholder="Month"
                                            isClearable
                                            required
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select className="Select" classNamePrefix="select"
                                            value={select(generateArrayOfYears(), this.state.leadDate.year)}
                                            options={generateArrayOfYears()}
                                            onChange={(e) => this.setState({
                                                leadDate: {
                                                    ...this.state.leadDate,
                                                    year: e?.value
                                                }
                                            })}
                                            placeholder="Year"
                                            isClearable
                                            required
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Ordinance
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    value={select(this.state.ordinances, this.state.ordinance_id)}
                                    options={this.state.ordinances}
                                    onChange={(e) => this.setState({ordinance_id: e?.value})}
                                    placeholder={this.state.ordinances.length ? "Select ordinance" : "No matching ordinances"}
                                    isClearable
                                    isDisabled={!this.state.ordinances.length}
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col className="text-left" xs={6}>
                                <Button
                                    bsSize="small"
                                    bsStyle="warning"
                                    onClick={() => this.setShowReportModal(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>

                            <Col className="text-right" xs={6}>
                                <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    disabled={!this.state.reportType || !this.state.personalPronoun || (this.state.reportType === 'TPP' && !this.state.dripline) || !this.state.leadDate.month || !this.state.leadDate.year || this.state.loading}
                                    onClick={() => {
                                        this.setState({loading: true})
                                        this.props.actions.generateReport(selectedSiteInfo, this.state.reportAssets, this.state.reportType, this.state.personalPronoun, this.state.dripline, this.state.leadDate, this.state.ordinance_id, () => {
                                            this.setState({loading: false})
                                        })
                                    }}
                                    type="submit"
                                >
                                    {this.state.loading ? 'Generating...' : 'Generate Report'}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>}
            </div>
        )
    }

    _onSuccess = () => {
        this.props.actions.fetchCustomerAssets()
    }

    _cancelModal = siteId => {
        this.props.actions.doneEditingAsset(siteId) //dismiss the modal, reload mapview assets for site which the asset editor modal depends on to show correct data
        this.props.actions.fetchCustomerAssets() //refresh them in case
    }

    _renderBillingAddress = customer => (
        <div>
            <strong>Billing Address:</strong>
            {" "}
            {customer.address_1},
            {customer.address_2}{" "}
            {customer.city}, {customer.state}{" "}
            {customer.zip}
        </div>
    )

    _applyHistoryFilter = () => {
        this.props.actions.applyHistoryFilter()
    }


    _renderFilterBar = (
        customerSites,
        selectedSite,
        selectedSiteInfo,
        sitesAssets,
        customer,
    ) => {
        const customerSitesOptions = mapForSelect(customerSites);

        let primaryAddress = [];
        if (customerSites && selectedSiteInfo && customerSites.id !== selectedSiteInfo.id) {
            primaryAddress = [{label: selectedSiteInfo.name, value: customerSites[0].id}]
        }

        return (
            <Row>
                {customerSites.length > 1 ?
                    <Col md={6}>
                        <Select className="Select" classNamePrefix="select"
                                name="select_site"
                                value={select(customerSitesOptions, selectedSite)}
                                options={customerSitesOptions}
                                onChange={this._updateSelectedSite}
                                placeholder="All Sites"
                        />
                    </Col>
                    : selectedSiteInfo &&
                    <Col md={6}>
                        <FormGroup controlId="formControlsSelect">
                            <FormControl componentClass="select" placeholder="select">
                                {primaryAddress.map(address => (
                                    <option value={address.value}>{address.label}</option>
                                ))}
                            </FormControl>
                        </FormGroup>
                    </Col>

                }

                <Col md={2}>
                    <Button bsStyle="success" onClick={this._applyHistoryFilter}>
                        Filter
                    </Button>
                </Col>
                <Col md={3}>
                    {selectedSite &&
                        <Button onClick={this._newSiteMap} bsStyle="info">
                            New Map
                        </Button>
                    }
                    {" "}
                    {selectedSite &&
                        <Button onClick={this._saveAllMapSettings} bsStyle="success">
                            Save All Map Settings
                        </Button>
                    }
                </Col>
                <Col md={2}>
                    <Nav>
                        <NavDropdown
                            title="More Stuff"
                            id={selectedSite}
                            className="no-caret-in-dropdown"
                        >
                            <MenuItem>
                                <Link onClick={this._newSite}>
                                    New Site
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                {selectedSite &&
                                    <Link onClick={this._editSite}>
                                        Edit Site
                                    </Link>
                                }
                            </MenuItem>
                            <MenuItem>
                                <LinkContainer disabled={!(customer && selectedSite)}
                                               to={`/mapview?customer_id=${customer.id}&site_id=${selectedSite}`}>
                                    <MenuItem>New Proposal</MenuItem>
                                </LinkContainer>
                            </MenuItem>
                        </NavDropdown>
                    </Nav>
                </Col>
                <Col md={1}>
                    {selectedSite && sitesAssets && sitesAssets.length && customer && selectedSiteInfo && this.props.roles.find(role => role === "admin")
                        ?
                        <Button
                            className="btn btn-warning btn-xs"
                            onClick={async () => {
                                const data = sitesAssets.map(a => {
                                    return {...a, images: a.images.map(i => i.url).join(',')}
                                }).sort((a, b) => sortAssetsLabels(a, b, 'label'))
                                const ws1 = utils.json_to_sheet(data);
                                const wb = utils.book_new();
                                utils.book_append_sheet(wb, ws1, "Site Assets");
                                writeFileXLSX(wb, `${customer.name}-Site Assets For-${selectedSiteInfo.name}.xlsx`);
                            }}>
                            Download Assets
                        </Button>
                        : null
                    }
                </Col>
            </Row>
        )
    }

    _updateSelectedSite = selected => {
        this.props.actions.updateSelectedSite(
            selected === null ? null : selected.value
        )
        if (selected && selected.value) {
            this.props.actions.fetchSiteMaps(selected.value)
            this.props.actions.fetchOrdinances(selected.value, (res) => {
                this.setState({ordinances: res})
            })
        }
    }


    _renderProposalMapUi = (assets, proposals) => {
        const {selectedSiteInfo, customerSites, sitesAssets} = this.props;

        return (
            <Col md={6} className="no-padding mt-25">
                {selectedSiteInfo &&

                    <p className='vertical-align bottom3'>
                        <Checkbox
                            type="checkbox"
                            id="check"
                            className='no-margin'
                            value={selectedSiteInfo.include_when_print}
                            onChange={this.handleIncludeMapChanged.bind(this, 0)}
                        />
                        <span>Include when printing</span>
                    </p>

                }
                {assets && assets.length && selectedSiteInfo
                    ? this._renderAssetMap(assets, selectedSiteInfo)
                    : <span>No assets to map</span>}
            </Col>
        )
    }

    _renderMapSettings = (assets) => {
        const {customerSiteMaps, selectedSite} = this.props
        const siteMaps = customerSiteMaps[selectedSite]

        if (siteMaps) {
            return siteMaps
                .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
                .map((siteMap, i) => {
                    return (
                        <Col key={siteMap.id + "_settings"} md={6}
                             className={`bottom15 no-left-padding ${i % 2 !== 1 ? 'customer-sites-map pl-15' : 'no-right-padding'}`}>
                            <div
                                className={`space-between customer-sites-map-options ${(i % 2 !== 1 && !isMobile) && 'pl-12'}`}>
                                <div className='d-flex'>
                                    <p className="mr-20 vertical-align">
                                        <Checkbox
                                            type="checkbox"
                                            id="check"
                                            onChange={this.handleIncludeMapChanged.bind(this, siteMap.id)}
                                            checked={siteMap.include_when_print}/>
                                        <span>Include when printing</span>
                                    </p>
                                    {assets?.length >= 100 &&
                                        <p className='vertical-align'>
                                            <Checkbox
                                                type="checkbox"
                                                id="check"
                                                onChange={e => {
                                                    const {saveMarkersRenderingType} = this.props.actions
                                                    this._saveAllMapSettings()
                                                    saveMarkersRenderingType({
                                                        site_map: siteMap.id,
                                                        show_as_markers: e.target.checked
                                                    }, map => {
                                                        siteMap.show_as_markers = map['site_map'].show_as_markers
                                                        this.setState({showAsMarkers: siteMap.show_as_markers})
                                                    })
                                                }}
                                                checked={siteMap.show_as_markers}/>
                                            <span>Show as markers</span>
                                        </p>}
                                </div>
                                <p className="customer-sites-delete-map"
                                   onClick={this.handleDeleteSiteMap.bind(this, siteMap.id)}>Delete</p>
                            </div>
                            {assets && assets.length && this._renderSiteMap(siteMap, assets)}
                        </Col>)
                })
        }
    }

    dataFormat = (field, row) => {
        const start = row.promised_date_start
        const end = row.promised_date_end

        if (!end || !start) return field

        if ((row.status === 'In Work Order') || (row.status === 'Accepted') || (row.status === 'Scheduled')) {
            return (
                <div>
                    <div>{field}</div>
                    <div className="text-warning">
                        {moment(start).format(defaultDateFormat)} - {moment(end).format(defaultDateFormat)}
                    </div>
                </div>
            )
        } else {
            return field
        }
    }

    _dollarFormatter = cell => <Dollars amount={cell}/>

    _dateFormatter = cell => cell && moment(cell).format(defaultDateFormatShort)

    _linkFormatter = (cell, row) => (
        <Nav>
            <NavDropdown
                title={cell || "..."}
                id={`proposal-menu-${row.id}`}
                className="no-caret-in-dropdown"
            >
                <LinkContainer to={`/mapview/${row.id}`}>
                    <MenuItem>Edit Proposal</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/proposal_service_status/${row.id}`}>
                    <MenuItem>Proposal Service Statuses</MenuItem>
                </LinkContainer>
                <LinkContainer to={`/print_proposal/${row.id}`}>
                    <MenuItem>Print Proposal</MenuItem>
                </LinkContainer>
                <MenuItem divider/>
                <LinkContainer to={`/work_orders/${row.id}`}>
                    <MenuItem>Manage Work Orders</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _filterRowFormatter = (selectedSite) => (
        <Nav>
            <NavDropdown
                title="Actions"
                className="no-caret-in-dropdown"
            >
                <LinkContainer to={`this._newSite`}>
                    <MenuItem><Button onClick={this._newSite} bsStyle="success">
                        New Site
                    </Button>
                        {selectedSite &&
                            <Button onClick={this._editSite} bsStyle="primary">
                                Edit Site
                            </Button>
                        }</MenuItem>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )

    _stateFormatter = (cell, row) => {
        if (cell === "In Work Order") {
            return (
                <div>
                    <div>{cell}</div>
                    <Link
                        to={`/work_orders/${row.proposal_id}`}

                        className="wo-link"
                    >
                        #{row.work_order_no}
                    </Link>
                </div>
            )
        } else {
            return (<div>{cell}</div>)
        }
    }

    _onMarkerClick = id => {
        this.props.actions.onMarkerClick(id)
    }
    addAssetToReport = id => {
        const {reportAssets} = this.state
        if (reportAssets.includes(id)) {
            this.setState({reportAssets: [...this.state.reportAssets.filter(ass_id => ass_id !== id)]})
        } else {
            this.setState({reportAssets: [...this.state.reportAssets, id]})
        }

    }

    _resetMap = site => {
        if (site === null || site === undefined) {
            console.log("Not resetting map because site is not a useful object.")
            return false
        }
        console.log("resetting map to site ", site)
        window._googleMapComponent[0] &&
        window._googleMapComponent[0].panTo({
            lat: site.latitude,
            lng: site.longitude,
        })
        //this._googleMapComponent.setZoom(site.defautZoom)
    }
    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.latitude
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.longitude
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.label)}
                strokeColor={"#ffffff"}
                onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                zIndex={1000}
            />
        )
    }

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (polygon.type_polygon === 'polyLine') {
            return 0.75
        } else {
            return 0.5
        }
    }

    _renderAssetMap = (assets, siteInfo, client) => (
        <GoogleMapLoader
            containerElement={
                <div
                    id="work_map_view_site"
                    className="map_styles"
                />
            }
            googleMapElement={
                <GoogleMap
                    ref={it => {
                        this.refSiteMap(it, 0)
                    }}
                    style={{height: `calc(100vh - 250px)`, width: "600px"}}
                    zoom={siteInfo.default_zoom || 20}
                    mapTypeId={siteInfo.map_type || 'hybrid'}
                    tilt={0}
                    defaultCenter={
                        assets
                            ? {lat: siteInfo.latitude, lng: siteInfo.longitude}
                            : {
                                lat: assets[0].latitude || ((client && client.organization_latitude) ?
                                    client.organization_latitude
                                    : 40.0),
                                lng: assets[0].longitude || ((client && client.organization_longitude) ?
                                    client.organization_longitude
                                    : -105.0)
                            }
                    }
                >
                    {assets && assets.map((ass, i) => {
                        const polygon = ass.polygons.find(p => p.chosen)

                        if (polygon && polygon.type_polygon !== 'marker') {
                            const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                            const highlighted = colors[51]
                            const opacity = this.setShapeOpacity(ass)

                            if (polygon.type_polygon === 'circle') {
                                return (<><Circle
                                    defaultOpacity={1}
                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                    defaultCenter={{
                                        lat: polygon.polygon_points[0].lat,
                                        lng: polygon.polygon_points[0].lng
                                    }}
                                    editable={false}
                                    label={ass.label}
                                    options={{
                                        fillOpacity: opacity,
                                        strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                        fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                    }}
                                    radius={polygon.radius}
                                    key={i}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else if (polygon.type_polygon === 'polyLine') {
                                return (<><Polyline
                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                    path={polygon.polygon_points}
                                    key={i}
                                    label={ass.label}
                                    options={{
                                        strokeOpacity: opacity,
                                        strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                        fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                    }}
                                    editable={false}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                return (<><Rectangle
                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                    bounds={{
                                        north: polygon.polygon_points[0].lat,
                                        south: polygon.polygon_points[3].lat,
                                        east: polygon.polygon_points[1].lng,
                                        west: polygon.polygon_points[0].lng
                                    }}
                                    key={i}
                                    label={ass.label}
                                    options={{
                                        fillOpacity: opacity,
                                        strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                        fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                    }}
                                    editable={false}
                                />
                                    {this.markerPolygon(ass)}
                                </>)
                            } else {
                                return (<>
                                    <Polygon
                                        onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                        path={polygon.polygon_points}
                                        key={i}
                                        label={ass.label}
                                        options={{
                                            fillOpacity: opacity,
                                            strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                            fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                        }}
                                        editable={false}
                                    />
                                    {this.markerPolygon(ass)}
                                </>)
                            }
                        } else {
                            return (
                                <Marker
                                    key={ass.id}
                                    position={{lat: ass.latitude, lng: ass.longitude}}
                                    zIndex={null}
                                    icon={getBasicAssetMarker({asset: ass, highlighted: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id), label: ass.label})}
                                    title={
                                        ass.plant_name +
                                        (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                    }
                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                >
                                </Marker>

                            )
                        }
                    })}
                </GoogleMap>
            }
        />
    )

    refSiteMap = (it, mapNum) => {
        window._googleMapComponent[mapNum] = it
    }

    handleDeleteSiteMap = (mapNum) => {
        const {selectedSite} = this.props
        if (mapNum > 0) {
            this.props.actions.deleteSiteMap(selectedSite, mapNum)
        }
    }

    handleIncludeMapChanged = (mapNum) => {
        const {customerSiteMaps, selectedSiteInfo, selectedSite} = this.props
        if (mapNum == 0) {
            this.props.actions.toggleIncludeSite(selectedSiteInfo.id)
        } else {
            this.props.actions.toggleIncludeSiteMap(selectedSite, mapNum)
        }
    }

    handleCenterChanged = (mapNum) => {
        const {customerSiteMaps, selectedSiteInfo, selectedSite} = this.props
        const siteMaps = customerSiteMaps[selectedSite] || null
        const map = window._googleMapComponent[mapNum]
        if (map) {
            const center = map.getCenter()
            let siteMap = selectedSiteInfo

            if (mapNum > 0) {
                siteMap = siteMaps.find(siteMap => siteMap.id === mapNum)
            }
            if (siteMap) {
                const latitude = center.lat()
                const longitude = center.lng()
                const zoom = map.getZoom()
                const mapType = map.getMapTypeId()
                if (siteMap.default_zoom != zoom ||
                    siteMap.map_type != mapType ||
                    Math.abs(siteMap.latitude - latitude) > 0.00001 ||
                    Math.abs(siteMap.longitude - longitude) > 0.00001) {

                    console.log(mapNum, 'map changed')
                    siteMap.default_zoom = zoom
                    siteMap.map_type = mapType
                    siteMap.latitude = latitude
                    siteMap.longitude = longitude
                    // update map setting
                    if (mapNum == 0) {
                        siteMap.id = selectedSiteInfo.id
                        this.props.actions.updateSiteInfo(siteMap)
                    } else {
                        siteMap.id = mapNum // site map index
                        this.props.actions.updateSiteMap(selectedSite, siteMap)
                    }
                }
            }
        }
    }

    _renderSiteMap = (siteMap, assets, client) => {
        return (
            <GoogleMapLoader
                containerElement={
                    <div
                        id={"work_map_view" + siteMap.id}
                        className="map_styles"
                    />
                }
                googleMapElement={
                    <GoogleMap
                        ref={it => {
                            this.refSiteMap(it, siteMap.id)
                        }}
                        onZoomChanged={() => {
                            const map = window._googleMapComponent[siteMap.id]
                            this.setState({currentZooms: {...this.state.currentZooms, [siteMap.id]: map.getZoom()}})
                        }}
                        defaultZoom={assets?.length > 50 ? 17 : siteMap.default_zoom}
                        mapTypeId={siteMap.map_type || 'hybrid'}
                        tilt={0}
                        defaultCenter={
                            assets
                                ? {lat: parseFloat(siteMap.latitude), lng: parseFloat(siteMap.longitude)}
                                : ((client && client.organization_longitude) ?
                                    {
                                        lat: client.organization_latitude,
                                        lng: client.organization_longitude
                                    }
                                    : {
                                        lat: 40.0,
                                        lng: -105.0
                                    })
                        }
                    >
                        {!siteMap.show_as_markers ?
                            <MarkerClusterer
                                averageCenter
                                enableRetinaIcons
                                maxZoom={18}
                                gridSize={100}
                            >
                                {assets && assets.map((ass, i) => {
                                    const polygon = ass.polygons.find(p => p.chosen)
                                    if (polygon && polygon.type_polygon !== 'marker' && ((this.state.currentZooms[`${siteMap.id}`] ? this.state.currentZooms[`${siteMap.id}`] : assets?.length > 50 ? 17 : siteMap.default_zoom) > 18)) {
                                        const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                        const highlighted = colors[51]
                                        const opacity = this.setShapeOpacity(ass)

                                        if (polygon.type_polygon === 'circle') {
                                            return (<><Circle
                                                defaultOpacity={1}
                                                onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                                defaultCenter={{
                                                    lat: polygon.polygon_points[0].lat,
                                                    lng: polygon.polygon_points[0].lng
                                                }}
                                                editable={false}
                                                label={ass.label}
                                                options={{
                                                    fillOpacity: opacity,
                                                    strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                                    fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                                }}
                                                radius={polygon.radius}
                                                key={i}
                                            />
                                                {this.markerPolygon(ass)}
                                            </>)
                                        } else if (polygon.type_polygon === 'polyLine') {
                                            return (<><Polyline
                                                onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                                path={polygon.polygon_points}
                                                key={i}
                                                label={ass.label}
                                                options={{
                                                    strokeOpacity: opacity,
                                                    strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                                    fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                                }}
                                                editable={false}
                                            />
                                                {this.markerPolygon(ass)}
                                            </>)
                                        } else if (polygon.type_polygon === 'rectangle') {
                                            return (<><Rectangle
                                                onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                                bounds={{
                                                    north: polygon.polygon_points[0].lat,
                                                    south: polygon.polygon_points[3].lat,
                                                    east: polygon.polygon_points[1].lng,
                                                    west: polygon.polygon_points[0].lng
                                                }}
                                                key={i}
                                                label={ass.label}
                                                options={{
                                                    fillOpacity: opacity,
                                                    strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                                    fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                                }}
                                                editable={false}
                                            />
                                                {this.markerPolygon(ass)}
                                            </>)
                                        } else {
                                            return (<>
                                                <Polygon
                                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                                    path={polygon.polygon_points}
                                                    key={i}
                                                    label={ass.label}
                                                    options={{
                                                        fillOpacity: opacity,
                                                        strokeColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color,
                                                        fillColor: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id) ? highlighted : color
                                                    }}
                                                    editable={false}
                                                />
                                                {this.markerPolygon(ass)}
                                            </>)
                                        }
                                    } else {
                                        return (
                                            <Marker
                                                key={ass.id}
                                                position={{lat: ass.latitude, lng: ass.longitude}}
                                                icon={getBasicAssetMarker({asset: ass, highlighted: this.state.isReportSelectMode && this.state.reportAssets.includes(ass.id), label: ass.label})}
                                                zIndex={null}
                                                title={
                                                    ass.plant_name +
                                                    (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                                }
                                                onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                            />

                                        )
                                    }
                                })}
                            </MarkerClusterer> :
                            assets && assets.map(ass => (
                                <Marker
                                    key={ass.id}
                                    position={{lat: ass.latitude, lng: ass.longitude}}
                                    icon={getBasicAssetMarker({asset: ass, label: ass.label})}
                                    zIndex={null}
                                    title={
                                        ass.plant_name +
                                        (ass.plant_count > 1 ? " [" + ass.plant_count + "]" : "")
                                    }
                                    onClick={() => this.state.isReportSelectMode ? this.addAssetToReport(ass.id) : this._onMarkerClick(ass.id)}
                                />
                            ))}
                    </GoogleMap>
                }
            />
        )
    }

    _newSite = () => {
        this.props.actions.createNewSite(this.props.customer.id)
    }

    _editSite = () => {
        const {selectedSite} = this.props
        this.props.actions.editingSiteId(selectedSite)
    }

    _saveAllMapSettings = () => {
        const {selectedSiteInfo, selectedSite, customerSiteMaps} = this.props
        const siteMaps = customerSiteMaps[selectedSite]
        // this.props.actions.saveAllMapSettings(selectedSite)
        this.handleCenterChanged(0)
        let siteMap
        siteMaps.forEach(siteMap => {
            this.handleCenterChanged(siteMap.id)
        })
    }

    _loadCustomerInfo = customerId => {
        const {selectedSite} = this.props
        console.info("Loading customerId", customerId)
        this.props.actions.fetchCustomerInfo(customerId)
        this.props.actions.fetchBillingContact(customerId)
        if (selectedSite) {
            this.props.actions.fetchSiteMaps(selectedSite)
            this.props.actions.fetchOrdinances(selectedSite, (res) => {
                this.setState({ordinances: res})
            })
            this.setState({ordinance_id: this.props.selectedSiteInfo?.ordinance_id})
        }
    }

    _newSiteMap = () => {
        console.log("creating new site map")
        const {selectedSite} = this.props
        this.props.actions.saveSiteMap(selectedSite)
    }

    _updateSiteMap = () => {
        const {selectedSite} = this.props
        const siteMapId = null
        this.props.actions.saveSiteMap(selectedSite, siteMapId)
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customerProposals.customer,
        sites: state.customerInfo.sites,
        proposals: state.customerProposals.proposals,
        contacts: state.customerInfo.contacts,
        roles: state.auth.roles,
        customerSites: state.customerProposals.customerSites,
        customerSiteMaps: state.siteMaps.siteMaps,
        selectedSite: state.customerProposals.selectedSite,
        foundCustomers: state.customerProposals.foundCustomers,
        sitesAssets: state.customerProposals.sitesAssets,
        selectedSiteInfo: state.customerProposals.selectedSiteInfo &&
            state.customerProposals.selectedSiteInfo[
                `site_${state.customerProposals.selectedSite}`
                ],

        editingAssetId: state.mapView.editingAssetId /*forasset editor modal*/,
        editingSiteId: state.siteEditor.editingSiteId,
        client: state.client.customerInfo

    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({...Actions, ...sitesActions}, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSites)
