import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import {
    Col,
    FormControl,
    Glyphicon,
    MenuItem,
    Nav,
    NavDropdown,
    OverlayTrigger,
    Panel,
    Row,
    Tooltip
} from 'react-bootstrap'
import {Link} from "react-router"
import Dollars from '../../components/Dollars'

import {LinkContainer} from "react-router-bootstrap"
import moment from "moment-timezone"
import PromiseUi from "../../components/PromiseUi"
import {defaultDateFormat, sortAssetsLabels} from "../../common/commonHandlers";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "../../actions/workOrders"
import PlantName from "../../components/PlantName";
import BundleServices from "../BundleServices/BundleServices";
import InlineEditable from "../Scheduler/InlineEditable";

const api = {
    ...actions
};

const getColor = status => {
    return ['Accepted', 'Scheduled', 'In Work Order'].includes(status)
        ? undefined
        : 'grey'
}

const ProposalServicesList = ({
                                  proposalServicesInfo,
                                  proposalInfo,
                                  selectedPs,
                                  selectPs,
                                  removePsFromWo,
                                  updateWoNote,
                                  updateAttr,
                                  actions
                              }) => {
    const [proposalServices, setProposalServices] = useState([])
    useEffect(() => {
        setProposalServices(
            proposalServicesInfo
                .slice()
                .sort((a, b) => parseInt(a.serviceNo, 10) - parseInt(b.serviceNo, 10))
        )
    }, [proposalServicesInfo]);

    const handlePurchaseOrderChange = (index, e) => {
        const updatedProposalServices = [...proposalServices];
        updatedProposalServices[index].purchase_order = e.target.value

        setProposalServices(updatedProposalServices);
    };

    const updatePONumberInPS = (i) =>{
        const ps = proposalServices[i]
        actions.updatePONumberInPS(ps.proposal_service_id, ps.purchase_order)
    }


    return (
        <div>
            {proposalServices
                .map((proposalService, index) => {
                    const renderTooltip = proposalService && (<div>
                            {proposalService.created_at &&
                                <span>Draft {moment(proposalService.created_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.proposal_date &&
                                <span>Proposed {moment(proposalService.proposal_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.accepted_date &&
                                <span>Accepted {moment(proposalService.accepted_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.scheduled_date &&
                                <span>Scheduled {moment(proposalService.scheduled_date).format(defaultDateFormat)}<br/></span>}
                            {proposalService.completed_at &&
                                <span>Completed {moment(proposalService.completed_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.invoiced_at &&
                                <span>Invoiced {moment(proposalService.invoiced_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.paid_at &&
                                <span>Paid {moment(proposalService.paid_at).format(defaultDateFormat)}<br/></span>}
                            {proposalService.declined_date &&
                                <span>Declined {moment(proposalService.declined_date).format(defaultDateFormat)}</span>}
                        </div>
                    );
                    let statusDate = null;
                    if (proposalService.proposalServiceStatus === 'Draft')
                        statusDate = proposalService.created_at && moment(proposalService.created_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Invoiced')
                        statusDate = proposalService.invoiced_at && moment(proposalService.invoiced_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Proposed')
                        statusDate = proposalService.proposal_date && moment(proposalService.proposal_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Accepted')
                        statusDate = proposalService.accepted_date && moment(proposalService.accepted_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Scheduled')
                        statusDate = proposalService.scheduled_date && moment(proposalService.scheduled_date).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Completed')
                        statusDate = proposalService.completed_at && moment(proposalService.completed_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Paid')
                        statusDate = proposalService.paid_at && moment(proposalService.paid_at).format(defaultDateFormat);
                    if (proposalService.proposalServiceStatus === 'Declined')
                        statusDate = proposalService.declined_date && moment(proposalService.declined_date).format(defaultDateFormat);
                    return <Panel key={proposalService.id} className="panel-overflow-x">
                        <div
                            style={{
                                color: getColor(proposalService.proposalServiceStatus),
                            }}
                        >
                            <Row>
                                <Col xs={12} md={1}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            disabled={
                                                proposalService.proposalServiceStatus !== 'Accepted'
                                            }
                                            onChange={e => selectPs(e, proposalService.id)}
                                            checked={selectedPs.includes(proposalService.id)}
                                        />
                                        {' '}
                                        #{proposalService.order_number_in_proposal}
                                    </label>
                                </Col>
                                <Col xs={12} md={3}>
                                    <PromiseUi
                                        promisedStart={proposalService.promised_start_at}
                                        promisedEnd={proposalService.promised_end_at}
                                        upsertPromise={actions.upsertPromise}
                                        proposalServiceId={proposalService.id}
                                        serviceNo={proposalService.order_number_in_proposal}
                                    />
                                </Col>
                                <Col xs={12} md={2}>
                                    {proposalService.serviceName}
                                </Col>
                                <Col xs={12} md={1}>
                                    {proposalService.priorities.map(p =>
                                        <span
                                            key={p.id}>{p.name}{proposalService.priorities.length > 1 ? ', ' : ' '}</span>
                                    )}
                                </Col>
                                <Col xs={12} md={1}>
                  <span>
                      <OverlayTrigger placement="top" overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                        <div>
                            <strong>{proposalService.proposalServiceStatus}</strong>{' '}
                            <span>{statusDate}</span>
                        </div>
                    </OverlayTrigger>
                              <span>
                          {
                              <Link
                                  to={`/proposals/${proposalService.proposalId}/invoices`}

                                  style={{textDecoration: 'none', color: '#d9534f'}}
                              >
                                  {proposalService.invoice_no > 0 ? ` #${proposalService.invoice_no}` : null}
                              </Link>
                          }
                              </span>
                       <br/>
                      {proposalService.scheduled_events && proposalService.scheduled_events.map((event, i) => (
                          <div key={i} className="text-warning">
                              <strong>Work on: {moment(event.event_start).format("MMM D")}</strong>
                          </div>
                      ))}
                  </span>
                                </Col>
                                <Col xs={12} md={1}>
                                    <div className="text-success">
                                        {proposalService.isPhc ? `${proposalService.serviceEstHours}Qty` : `${proposalService.serviceEstHours}hrs`}
                                    </div>
                                </Col>
                                <Col xs={12} md={1}>
                                    <Dollars amount={proposalService.serviceCost}/>
                                </Col>
                                <Col xs={12} md={2}>
                                    {proposalService.workOrderNo
                                        ? <span>
                      {['Accepted', 'Scheduled', 'In Work Order'].includes(
                              proposalService.proposalServiceStatus
                          ) &&
                          <a
                              style={{cursor: 'pointer'}}
                              title="Remove from WO"
                              onClick={() => {
                                  removePsFromWo(proposalService.id)
                              }}
                          >
                          <span className="text-danger">
                            <Glyphicon glyph="remove-sign"/>
                          </span>
                          </a>}
                                            <Nav>
                        <NavDropdown
                            eventKey={2}
                            title={`WO #${proposalService.workOrderNo}`}
                            id={`proposal-menu`}
                        >
                            <LinkContainer to={`/complete_work_orders/${proposalService.workOrderNo}`}>
                                <MenuItem bsSize="small">Complete Work Order</MenuItem>
                            </LinkContainer>
                            <LinkContainer to={`/print_work_orders/${proposalService.workOrderNo}`}>
                                <MenuItem bsSize="small">Print Work Order</MenuItem>
                            </LinkContainer>
                          </NavDropdown>
                      </Nav>

                      </span>
                                        : <span>---</span>}
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={12}>
                                <strong>Trees:</strong><br/>
                                {proposalService.plants && proposalService.plants.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(p =>
                                    <span>
                                        <PlantName plant={p.label}/>
                                        {p.plant_count > 1 && `[#${p.plant_count}]`}
                                        {" "}
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} className="preserve-text-area-format">
                                <strong>Service Notes:</strong><br/>
                                {proposalService.notes}
                            </Col>

                            <Col xs={12} md={6} className="preserve-text-area-format">
                                <strong>WO Notes:</strong><br/>
                                <textarea
                                    value={proposalService && proposalService.woNotes}
                                    onChange={e => {
                                        updateAttr(proposalService.id, 'woNotes', e.target.value)
                                    }}
                                    onBlur={e =>
                                        updateWoNote(proposalService.id, e.target.value)}
                                    style={{width: '100%', height: '50px'}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="preserve-text-area-format">
                                <strong>Purchase Order:</strong><br/>
                                <InlineEditable
                                    inputWidth="width-15"
                                    form={() =>
                                        <FormControl
                                            maxLength={30}
                                            name={`purchase_order${index}`}
                                            placeholder="PO number"
                                            value={proposalServices[index].purchase_order}
                                            onBlur={()=>updatePONumberInPS(index)}
                                            onChange={(e) => handlePurchaseOrderChange(index, e)}
                                        />
                                    }
                                    value={
                                        <span className="word-break">{proposalService.purchase_order}</span>
                                    }
                                    length={proposalServices[index].purchase_order ? proposalServices[index].purchase_order.length : 0}
                                    service={proposalService}
                                />
                            </Col>
                        </Row>
                        {proposalService.is_bundle_service &&
                            <BundleServices
                                bundleServices={proposalService.bundle_services}
                                view='ProposalServicesList'
                                markedCheckboxes={selectedPs}
                                proposalServiceId={proposalService.id}
                                onUpdateWoNote={updateWoNote}
                                promisedDates={{
                                    promisedStart: proposalService.promised_start_at,
                                    promisedEnd: proposalService.promised_end_at
                                }}
                            />
                        }
                    </Panel>
                })}
        </div>
    )
}

ProposalServicesList.propTypes = {
    proposalServicesInfo: PropTypes.array.isRequired,
    selectedPs: PropTypes.array.isRequired,
    selectPs: PropTypes.func.isRequired,
    removePsFromWo: PropTypes.func.isRequired,
    updateWoNote: PropTypes.func.isRequired,
    updateAttr: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalServicesList);