import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {downloadPdf} from "../CustomerViewPage/actions"
import {addAlert} from "../App/actions"
import * as MyActions from "./actions"
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    InputGroup,
    Panel,
    Radio,
    Row,
    Well,
} from "react-bootstrap"
import Helmet from "react-helmet"
import PageHeader from "../../components/PageTitle"
import {saveLogoSettings} from "../../actions/printWorkOrders"
import {ProposalPrint} from "./ProposalPrint";
import {showEmailDialog} from "../../components/email/actions"
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {debounce} from "throttle-debounce";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {getValidFontSize, validateFontSize, windowPrint} from "../../common/pdfPrintUtils";
import PrintOptionsHistoryModal from "./PrintOptionsHistoryModal";
import axios from "axios";
import MDSpinner from "react-md-spinner";

const Actions = {...MyActions, addAlert, downloadPdf, showEmailDialog, saveLogoSettings};
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const positionsAttachments = [
    {label: 'Before Services', value: 'before_services'},
    {label: 'After Services', value: 'after_services'},
    {label: 'Before Images', value: 'before_images'},
    {label: 'After Images', value: 'after_images'},
]

class PrintProposalPage extends Component {
    state = {
        bounds: null,
        showLogoHeader: false,
        logoHeight: null,
        logoPosition: "center",
        iframeKey: 0,
        user_print_options: {},
        isPrinted: false,
        errors: {},
        isHistoryModalOpen: false,
        downloadInProgress: false,
        printButtonPushed: false,
        downloadButtonPushed: false,
    };

    constructor(props) {
        super(props);

        this.delayedSave = debounce(1500, this.updatePrintOptions);
    }

    componentWillMount = () => {
        if (this.props.params.proposalId) {
            this.props.actions.fetchProposal(this.props.params.proposalId, (proposal) => {
                proposal.site_maps.forEach(sitemap => {
                    const property = {};
                    property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                    this.setState(property);
                });
                if(proposal.client_header) {
                    axios.get(proposal.client_header).catch(() => {
                        proposal.client_header = 'https://s3-us-east-2.amazonaws.com/treereserve/production/images/trees.150605.1710640155.original.png?1710640155'
                    });
                }
                this.setState({
                    proposal,
                    logoHeight: proposal.logo_height,
                    logoPosition: proposal.logo_position,
                    showLogoHeader: proposal.print_options?.showLogo === undefined ? true : proposal.print_options.showLogo
                })
            })
            this.props.actions.fetchUser(this.props.user.user_id, (user) => {
                this.setState(
                    {user_print_options: user.proposal_print_options}
                )

            })

        } else {
            this.props.actions.addAlert({
                message: "Proposal not given. I can't go on!",
            })
        }
    };

    handleSaveSettings = () => {
        const {logoHeight, logoPosition} = this.state
        this.props.actions.saveLogoSettings(
            this.state.proposal.client_id, logoHeight, logoPosition)
    }

    handleFormChange = e => {
        if (e.target.type === "checkbox") {
            if (e.target.name === "showLogo") {
                this.setState({showLogoHeader: !this.state.showLogoHeader})
            }
            this.state.proposal.print_options[e.target.name] = e.target.checked;
            this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, this.state.proposal.print_options, () => this.setState({proposal: this.state.proposal}));
        } else if (e.target.type === 'radio') {
            this.state.proposal.print_options[e.target.name] = e.target.value;
            this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, this.state.proposal.print_options, () => this.setState({proposal: this.state.proposal}));
        } else {
            this.state.proposal.print_options[e.target.name] = +e.target.value
            this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, this.state.proposal.print_options, () => this.setState({proposal: this.state.proposal}));
        }


        if (e.target.name === 'fitBounds') {
            this.state.proposal.print_options['maps'] = false;
            this.setState({proposal: this.state.proposal}, () => {
                this.state.proposal.print_options['maps'] = true;
                this.setState({proposal: this.state.proposal});
            })
        }
    };
    handleFontSizeOnBlur = (optionName) => {
        const {proposal, errors} = this.state;
        const updatedFontSize = getValidFontSize(proposal.print_options[optionName]);
        const updatedErrors = {...errors};
        if (updatedErrors[optionName]) {
            delete updatedErrors[optionName];
        }
        const updatedPrintOptions = {...proposal.print_options, [optionName]: updatedFontSize}
        this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, updatedPrintOptions, () => this.setState({
            proposal: {
                ...proposal,
                print_options: updatedPrintOptions
            }, errors: updatedErrors
        }));
    }

    handleMapSizeOnBlur = () => {
        this.props.actions.updateProposalPrintOptions(this.props.params.proposalId, this.state.proposal.print_options);
        this.setState({proposal: this.state.proposal});
    }

    makeDefault = () => {
        const print_options = this.state.proposal.print_options
        const current_user_id = this.props.user.user_id
        this.props.actions.updateUserProposalPrintOptions(current_user_id, print_options, (response) => {
            this.setState({user_print_options: response.proposal_print_options})
        });
    }
    resetToDefault = () => {
        const proposal = this.state.proposal
        const user_print_options = this.state.user_print_options
        this.props.actions.resetProposalPrintOptions(this.props.params.proposalId, user_print_options, (response) => {
            this.setState({
                proposal: {...proposal, print_options: response.print_options},
                showLogoHeader: response.print_options.showLogo
            })
        });

    }

    renderPrintOptionsHeader = (proposal) => {
        const printOptions = this.state.proposal.print_options;
        const {mapHeight, mapWidth, marginTop, marginBottom, atTheEnd} = this.state.proposal.print_options;
        const {thumbnailSize, wrapHorizontally} = this.state.proposal.print_options;
        const {email} = this.props;
        const {downloadInProgress, printButtonPushed, downloadButtonPushed} = this.state

        return (
            <Panel
                bsStyle="primary"
                collapsible
                defaultExpanded={true}
                header="Print Options"
                className="hidden-print well-filter"
            >
                <Row>
                    <Col xs={12}>
                        <p>
                            The Proposal can be customized before printing. Checked items are
                            included in the printout.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col className="options" xs={12}>
                        <Well bsSize="small">
                            <h5>Prices</h5>
                            <FormGroup>
                                <Checkbox
                                    name="proposalTotal"
                                    checked={printOptions.proposalTotal || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposal Total
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePrices"
                                    checked={printOptions.servicePrices || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Subtotals
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Notes</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetNotes"
                                    checked={printOptions.assetNotes || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalServiceNotes"
                                    checked={printOptions.proposalServiceNotes || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalServiceStatus"
                                    checked={printOptions.proposalServiceStatus || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Status
                                </Checkbox>
                                <br/>
                                <FormGroup>
                                    <Checkbox
                                        name="proposalCustomerNote"
                                        checked={printOptions.proposalCustomerNote || false}
                                        onChange={this.handleFormChange}
                                        inline
                                    >
                                        <div>
                                            <div>Proposal Note</div>
                                            <Checkbox
                                                name="notesAfterServices"
                                                checked={printOptions.notesAfterServices || false}
                                                disabled={!printOptions.proposalCustomerNote}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                After services
                                            </Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox
                                                name="notesBeforeServices"
                                                checked={printOptions.notesBeforeServices || false}
                                                disabled={!printOptions.proposalCustomerNote}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Before services
                                            </Checkbox>
                                        </div>
                                    </Checkbox>
                                </FormGroup>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Attachments</h5>
                            <FormGroup>
                                <Checkbox
                                    name="customerAttachments"
                                    checked={printOptions.customerAttachments || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>User's Photos</div>
                                    {positionsAttachments.map((position) => (
                                        <div>
                                            <Radio
                                                name="positionCustomerAttachments"
                                                checked={printOptions.positionCustomerAttachments === position.value}
                                                onChange={e => {
                                                    this.setState({positionCustomerAttachments: e.target.value})
                                                    this.handleFormChange(e)
                                                }}
                                                disabled={!printOptions.customerAttachments}
                                                value={position.value}
                                                inline
                                            >{position.label}</Radio>
                                        </div>
                                    ))}
                                </Checkbox>
                            </FormGroup>
                            <FormGroup>
                                <Checkbox
                                    name="sitesAttachments"
                                    checked={printOptions.sitesAttachments || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>Sites Photos</div>
                                    {positionsAttachments.map((position) => (
                                        <div>
                                            <Radio
                                                name="positionSitesAttachments"
                                                checked={printOptions.positionSitesAttachments === position.value}
                                                onChange={e => {
                                                    this.setState({positionSitesAttachments: e.target.value})
                                                    this.handleFormChange(e)
                                                }}
                                                disabled={!printOptions.sitesAttachments}
                                                value={position.value}
                                                inline
                                            >{position.label}</Radio>
                                        </div>
                                    ))}
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <div>

                            </div>
                            <h5>Maps</h5>
                            <FormGroup>
                                <Checkbox
                                    name="maps"
                                    checked={printOptions.maps || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>
                                        <div>Maps</div>
                                        <Checkbox
                                            name="fitBounds"
                                            checked={printOptions.fitBounds || false}
                                            disabled={!printOptions.maps}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Fit Bounds
                                        </Checkbox>
                                    </div>
                                </Checkbox>
                                {proposal.site_maps.map((sitemap, i) => (
                                        <div key={sitemap.id}>
                                            <Checkbox
                                                name={`maps_${sitemap.id}`}
                                                checked={printOptions[`maps_${sitemap.id}`] || false}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Sitemap {i + 1}
                                            </Checkbox>
                                        </div>
                                    )
                                )}
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Assets</h5>
                            <FormGroup>
                                <Checkbox
                                    name="largeAssetPhotos"
                                    checked={printOptions.largeAssetPhotos || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="assetPhotosCustomerView"
                                    checked={printOptions.assetPhotosCustomerView || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Photos in customer view
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="locationDescription"
                                    checked={printOptions.locationDescription || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Location Description
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="dbh"
                                    checked={printOptions.dbh || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    DBH
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="condition"
                                    checked={printOptions.condition || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Condition
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="lat"
                                    checked={printOptions.lat || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Latitude
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="lng"
                                    checked={printOptions.lng || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Longitude
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="height"
                                    checked={printOptions.height || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Height
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="width"
                                    checked={printOptions.width || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Width
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="stems"
                                    checked={printOptions.stems || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Stems
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="factors"
                                    checked={printOptions.factors || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Factors
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="wrapVertically"
                                    checked={printOptions.wrapVertically || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Wrap Vertically
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Services</h5>
                            <FormGroup>
                                <Checkbox
                                    name="serviceDateRanges"
                                    checked={printOptions.serviceDateRanges || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Date Ranges
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesNotOffered"
                                    checked={printOptions.servicesNotOffered || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Also Not Offered
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePriorities"
                                    checked={printOptions.servicePriorities || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Priorities
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePurchaseOrder"
                                    checked={printOptions.servicePurchaseOrder || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Purchase Order
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="thumbAssetPhotos"
                                    checked={printOptions.thumbAssetPhotos || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Thumbnail Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="allowPageBreakingWithinService"
                                    checked={printOptions.allowPageBreakingWithinService}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Allow Breaking Between Pages
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        <Well bsSize="small">
                            <h5>Status</h5>
                            <FormGroup>
                                <Checkbox
                                    name="servicesSpecificallyNotOffered"
                                    checked={printOptions.servicesSpecificallyNotOffered}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Not Offered
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesDraft"
                                    checked={printOptions.servicesDraft}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Draft
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesScheduled"
                                    checked={printOptions.servicesScheduled}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Scheduled
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesInWorkOrder"
                                    checked={printOptions.servicesInWorkOrder}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    In Work Order
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesDeclined"
                                    checked={printOptions.servicesDeclined}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Declined
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesAccepted"
                                    checked={printOptions.servicesAccepted}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Accepted
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesProposed"
                                    checked={printOptions.servicesProposed}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposed
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesCompleted"
                                    checked={printOptions.servicesCompleted}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Completed
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesInvoiced"
                                    checked={printOptions.servicesInvoiced}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Invoiced
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesPayment"
                                    checked={printOptions.servicesPayment}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Payment
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicesPaid"
                                    checked={printOptions.servicesPaid}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Paid
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Charts</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetSummary"
                                    checked={printOptions.assetSummary || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Asset Summary</Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceType"
                                    checked={printOptions.serviceType || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Service Type</Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceSummary"
                                    checked={printOptions.serviceSummary || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Service Summary</Checkbox>
                                <br/>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Options</h5>
                            <FormGroup>
                                <Checkbox
                                    name="arboristInfo"
                                    checked={printOptions.arboristInfo || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Arborist Info
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="tos"
                                    checked={printOptions.tos || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Terms
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="proposal_footer"
                                    checked={printOptions.proposal_footer || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Footer
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="assInline"
                                    checked={printOptions.assInline || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Assets Inline
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showLogo"
                                    checked={this.state.showLogoHeader || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Company Logo
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showPageNumbers"
                                    checked={printOptions.showPageNumbers || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Page Numbers
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showCompanyAddress"
                                    checked={printOptions.showCompanyAddress || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Company Address
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showSignature"
                                    checked={printOptions.showSignature || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Signature
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showSignatureData"
                                    checked={printOptions.showSignatureData || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Signature Data
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        {this.state.showLogoHeader &&
                            <Well bsSize="small">
                                <h5>Logo options</h5>
                                <FormGroup>
                                    <FormControl
                                        name="logoHeight"
                                        type="text"
                                        placeholder="Height"
                                        value={this.state.logoHeight ? this.state.logoHeight : ''}
                                        onChange={e => this.setState({logoHeight: e.target.value})}
                                        onBlur={this.handleSaveSettings}
                                    />
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'left'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'left'}
                                        inline
                                    >Left</Radio>
                                    <br/>
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'center'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'center'}
                                        inline
                                    >Center</Radio>
                                    <br/>
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'right'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'right'}
                                        inline
                                    >Right</Radio>
                                </FormGroup>
                            </Well>}
                        <Well bsSize="small">
                            <h5>Map options</h5>
                            <div className='d-flex'>
                                <div className='columnDirection full-width'>
                                    <FormGroup>
                                        <Checkbox
                                            name="atTheEnd"
                                            checked={atTheEnd || false}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Show at the end
                                        </Checkbox>
                                    </FormGroup>
                                    <FormGroup className='space-between'>
                                        <ControlLabel className='flex-basis-40'>
                                            Margin Top
                                        </ControlLabel>
                                        <FormControl
                                            className='flex-basis-60'
                                            name="marginTop"
                                            type="number"
                                            placeholder="Top"
                                            style={{maxWidth: 120}}
                                            value={marginTop}
                                            onChange={(e) => {
                                                this.setState({
                                                    proposal: {
                                                        ...proposal,
                                                        print_options: {
                                                            ...proposal.print_options,
                                                            marginTop: +e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            onBlur={() => this.handleMapSizeOnBlur()}
                                        />
                                    </FormGroup>
                                    <FormGroup className='space-between'>
                                        <ControlLabel className='flex-basis-40'>
                                            Margin Bottom
                                        </ControlLabel>
                                        <FormControl
                                            className='flex-basis-60'
                                            name="marginBottom"
                                            type="number"
                                            placeholder="Bottom"
                                            style={{maxWidth: 120}}
                                            value={marginBottom}
                                            onChange={(e) => {
                                                this.setState({
                                                    proposal: {
                                                        ...proposal,
                                                        print_options: {
                                                            ...proposal.print_options,
                                                            marginBottom: +e.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                            onBlur={() => this.handleMapSizeOnBlur()}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Well>
                        {printOptions.thumbAssetPhotos &&
                            <Well bsSize="small">
                                <h5>Thumbnail options</h5>
                                <div className='d-flex'>
                                    <div className='columnDirection full-width'>
                                        <FormGroup>
                                            <Checkbox
                                                name="wrapHorizontally"
                                                checked={wrapHorizontally || false}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Wrap Horizontally
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup className='space-between'>
                                            <ControlLabel className='mr-10'>
                                                Size
                                            </ControlLabel>
                                            <FormControl
                                                name="thumbnailSize"
                                                type="text"
                                                placeholder="Size"
                                                style={{maxWidth: 120}}
                                                value={thumbnailSize || 180}
                                                onChange={this.handleFormChange}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </Well>}
                        <Well bsSize="small">
                            <h5>Font options</h5>
                            {['Header', 'Content', 'Footer'].map((label) => {
                                const optionName = label.toLowerCase() + "FontSize";
                                return (<FormGroup>
                                    <div className='space-between'>
                                        <ControlLabel className='flex-basis-30'>
                                            {label}
                                        </ControlLabel>
                                        <InputGroup className='flex-basis-70'>
                                            <FormControl
                                                name={optionName}
                                                type="number"
                                                step={1}
                                                min={13}
                                                max={28}
                                                value={this.state.proposal.print_options[optionName] || ''}
                                                onChange={e => {
                                                    const {proposal, errors} = this.state;
                                                    const newFontSize = +e.target.value;
                                                    this.setState({
                                                        proposal: {
                                                            ...proposal,
                                                            print_options: {
                                                                ...proposal.print_options,
                                                                [optionName]: newFontSize
                                                            }
                                                        },
                                                        errors: validateFontSize(optionName, newFontSize, errors)
                                                    })
                                                }}
                                                onBlur={() => this.handleFontSizeOnBlur(optionName)}
                                            />
                                            <InputGroupAddon>
                                                px
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    {this.state.errors[optionName] &&
                                        <div className='error'>{this.state.errors[optionName]}</div>}
                                </FormGroup>)
                            })}
                        </Well>
                    </Col>
                </Row>
                <Row className='ml0'>
                    <div className="hright flex-wrap mr-15">
                        <Button
                            bsSize="small"
                            onClick={() => this.setState({isHistoryModalOpen: true})}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Options History
                        </Button>
                        <Button
                            bsSize="small"
                            onClick={() => this.resetToDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Reset to Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={() => this.makeDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Make Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            disabled={downloadInProgress}
                            onClick={() => {
                                this.setState({
                                    downloadInProgress: true,
                                    downloadButtonPushed: true
                                }, () => this.props.actions.downloadPdf(proposal,
                                    () => this.setState({downloadInProgress: false, downloadButtonPushed: false}),
                                    () => this.setState({downloadInProgress: false, downloadButtonPushed: false})
                                ))
                            }}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            {downloadButtonPushed ?
                                <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            disabled={downloadInProgress}
                            onClick={() => {
                                isMobile
                                    ? windowPrint()
                                    : this.setState({
                                        isPrinted: true,
                                        downloadInProgress: true,
                                        printButtonPushed: true
                                    }, () => this.props.actions.printPdf(proposal,
                                        () => this.setState({downloadInProgress: false, printButtonPushed: false}),
                                        () => this.setState({downloadInProgress: false, printButtonPushed: false})
                                    ))
                            }}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            {printButtonPushed ?
                                <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Print'}
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={this.showEmailDialog}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Email
                        </Button>
                    </div>
                </Row>
            </Panel>
        )
    };

    showEmailDialog = () => {
        const {email} = this.props;
        const {proposal} = this.state;

        let recipientEmail
        const {contactEmail, contactEmail2, secondContactEmail, secondContactEmail2} = proposal
        const primarySiteContactEmails = (contactEmail && contactEmail2) ? `${contactEmail}, ${contactEmail2}` : contactEmail
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        recipientEmail = `${primarySiteContactEmails || ""}`
        if (secondarySiteContactEmails) recipientEmail += `, ${secondarySiteContactEmails}`

        this.props.actions.showEmailDialog(
            {
                emailType: "proposal",
                referenceId: proposal.id,
                recipient: recipientEmail,
                defaultEmail: email
            });
    };

    handleProposalIdChange = (id) => {
        this.props.actions.fetchProposal(id, (proposal) => {
            proposal.site_maps.forEach(sitemap => {
                const property = {};
                property[`maps_${sitemap.id}`] = sitemap.include_when_print;
                this.setState(property);
            });
            this.setState({proposal})
        })
    };

    getFontSizes = () => {
        const {proposal} = this.state;
        return {
            header: getValidFontSize(proposal.print_options.headerFontSize),
            content: getValidFontSize(proposal.print_options.contentFontSize),
            footer: getValidFontSize(proposal.print_options.footerFontSize),
        }
    }

    render() {
        const {proposal, isHistoryModalOpen} = this.state;
        if (!proposal) {
            return <div>Loading...</div>
        }
        return (
            <div>
                <Grid fluid style={{maxWidth: "100vw"}}>
                    <div className="hidden-print">
                        <ProposalMenu
                            id={proposal.id}
                            expandButton={false}
                            onReload={this.handleProposalIdChange}
                        />
                    </div>
                    <Col md={12}>
                        <Helmet>
                            <title>{`${proposal.download_name}-Proposal #${proposal.proposal_no}`}</title>
                            <style type="text/css">
                                {`
                          @page {
                          size: 8.5in 11in;
                          margin: 0.25in 0.25in 0.25in 0.25in;
                          }
                       `}
                            </style>
                        </Helmet>
                    </Col>
                    <div className="hidden-print">
                        <PageHeader
                            pageName="Print Proposal"
                            pageDetail={`${proposal.download_name}-Proposal #${proposal.proposal_no}`}
                        />
                    </div>

                    {this.renderPrintOptionsHeader(proposal)}
                </Grid>

                <ProposalPrint
                    serviceType={proposal.print_options.serviceType}
                    fontSize={this.getFontSizes()}
                    assetSummary={proposal.print_options.assetSummary}
                    serviceSummary={proposal.print_options.serviceSummary}
                    servicesNotOffered={proposal.print_options.servicesNotOffered}
                    servicesSpecificallyNotOffered={proposal.print_options.servicesSpecificallyNotOffered}
                    servicesDraft={proposal.print_options.servicesDraft}
                    servicesScheduled={proposal.print_options.servicesScheduled}
                    servicesInWorkOrder={proposal.print_options.servicesInWorkOrder}
                    servicesDeclined={proposal.print_options.servicesDeclined}
                    servicesAccepted={proposal.print_options.servicesAccepted}
                    servicesProposed={proposal.print_options.servicesProposed}
                    servicesCompleted={proposal.print_options.servicesCompleted}
                    servicesInvoiced={proposal.print_options.servicesInvoiced}
                    servicesPayment={proposal.print_options.servicesPayment}
                    servicesPaid={proposal.print_options.servicesPaid}
                    isPrinted={this.state.isPrinted}
                    logoHeight={this.state.logoHeight}
                    logoPosition={this.state.logoPosition}
                    showLogoHeader={this.state.showLogoHeader}
                    proposal={proposal}
                    options={proposal.print_options}
                    client={this.props.client}
                    updateTerms={this.props.actions.updateTerms}
                />
                {isHistoryModalOpen && <PrintOptionsHistoryModal
                    isOpen={isHistoryModalOpen}
                    close={() => this.setState({isHistoryModalOpen: false})}
                    proposal={proposal}
                />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.email,
        client: state.client.customerInfo,
        user: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintProposalPage)