import React, {useEffect, useState} from "react";
import {Button, ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import MDSpinner from "react-md-spinner";

const DuplicateClientModal = ({onHide, onDuplicate, client, clientsList, refreshClientsList}) => {
    const [name, setName] = useState("")
    const [duplicatedName, setDuplicatedName] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (clientsList && clientsList.length > 0) {
            const duplicatedName =  clientsList.map(c => c.name).includes(name)
            if (duplicatedName) {
                setDuplicatedName(true)
            } else {
                setDuplicatedName(false)
            }
        }
    }, [name])

    return <Modal
        show={true}
        onHide={() => {
            !loading && onHide()
        }}
        className="heightAuto maxHeight90 modalLeadsFilterBody"
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Duplicate Client
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormGroup bsSize="small">
                <ControlLabel>
                    Duplicate Client Name
                </ControlLabel>
                <FormControl
                    className={duplicatedName ? "input-invalid" : null}
                    disabled={loading}
                    name="name"
                    placeholder="Duplicate Client Name"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle="warning"
                className="mr-5"
                disabled={loading}
                onClick={onHide}
            >
                Close
            </Button>
            <Button
                bsStyle="success"
                disabled={!(name.length > 0) || duplicatedName || loading}
                onClick={() => {
                    setLoading(true)
                    onDuplicate({name: name, client_id: client.id}, () => {
                        setLoading(false)
                        onHide()
                        refreshClientsList()
                    }, () => setLoading(false))
                }}
            >
                {loading ? <div className="vertical-align"><MDSpinner size={20} className="mr-5"/> Processing...</div> : `Duplicate client`}
            </Button>
        </Modal.Footer>
    </Modal>
}

export default DuplicateClientModal;