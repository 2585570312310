import React, {useEffect, useState} from "react";
import * as actions from "./NewSalesDashboardApi";
import {showEmailDialog} from "../../components/email/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Dollar from "../../components/Dollars";
import './NewSalesDashboard.scss'
import NewSalesDashboardTable from "./NewSalesDashboardComponents/NewSalesDashboardTable";
import NewSalesDashboardDock from "./NewSalesDashboardComponents/NewSalesDashboardDock";
import NewSalesDashboardFilterModal from "./NewSalesDashboardComponents/NewSalesDashboardFilterModal";
import moment from "moment/moment";
import {defaultDateFormat, saveNewCsvDownload} from "../../common/commonHandlers";
import {ColumnsButton, DeclinedButton, EmailButton, FilterButton, MapSchedulerButton} from "./NewSalesDashboardComponents/NewSalesDashboardButtons";
import DeclinedModal from "./NewSalesDashboardComponents/NewSalesDashboardDeclinedModal";
import EmailDialog from "../../components/email/EmailDialog";

const apiActions = {
    ...actions,
    saveNewCsvDownload,
    showEmailDialog
}

const defaultColumns = {
    draft: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hours', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priorities', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_dates', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_totals', display: true}, {key: 'service_type_names', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_names', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
    sent: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hours', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priorities', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_dates', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_totals', display: true}, {key: 'service_type_names', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_names', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
    backlog: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hour', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priority', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_date', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_total', display: true}, {key: 'service_type_name', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_name', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
    completed: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hours', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priorities', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_dates', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_totals', display: true}, {key: 'service_type_names', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_names', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
    invoiced: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hours', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priorities', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_dates', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_totals', display: true}, {key: 'service_type_names', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_names', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
    paid: [{key: 'age', display: true}, {key: 'arborist_name', display: true}, {key: 'completed_at', display: true}, {key: 'customer_name', display: true}, {key: 'email_address', display: true}, {key: 'email_count', display: true}, {key: 'invoiced_at', display: true}, {key: 'invoices', display: true}, {key: 'last_follow_up_date', display: true}, {key: 'man_hours', display: true}, {key: 'paid_dates', display: true}, {key: 'phone_number', display: true}, {key: 'priorities', display: true}, {key: 'promised_dates', display: true}, {key: 'proposal_accepted_dates', display: true}, {key: 'proposal_created_at', display: true}, {key: 'proposal_no', display: true}, {key: 'scheduled_on_dates', display: true}, {key: 'scheduler_event_assigned_persons', display: true}, {key: 'sent_date', display: true}, {key: 'service_totals', display: true}, {key: 'service_type_names', display: true}, {key: 'services', display: true}, {key: 'site', display: true}, {key: 'status_names', display: true}, {key: 'stickies_completed', display: true}, {key: 'total_paid', display: true}, {key: 'work_order_notes', display: true}, {key: 'work_orders', display: true}],
}

const NewSalesDashboard = ({actions, defaultEmail}) => {
    const [summary, setSummary] = useState({})
    const [data, setData] = useState([])
    const [selectedRecords, setSelectedRecords] = useState([])
    const [tab, setTab] = useState('draft')
    const [pagination, setPagination] = useState({page: 1, per_page: 25})
    const [sort, setSort] = useState({sort: 'DESC', sort_by: 'proposal_no'})
    const [showDock, setShowDock] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [columns, setColumns] = useState(defaultColumns)
    const [loadingData, setLoadingData] = useState(false)
    const [allSelectedRecords, setAllSelectedRecords] = useState(false)
    const [isDeclinedModalOpened, setIsDeclinedModalOpened] = useState(false)
    const [emailModalOpened, setEmailModalOpened] = useState({show: false, data: {}})
    const [filtersArrays, setFiltersArrays] = useState(null)
    const [filters, setFilters] = useState({
        sent_label_name: null,
        sent_from: null,
        sent_to: null,
        promised_label_name: null,
        promised_from: null,
        promised_to: null,
        scheduled_label_name: null,
        scheduled_from: null,
        scheduled_to: null,
        paid_label_name: null,
        paid_from: null,
        paid_to: null,
        sales_arborist_ids: null,
        service_type_ids: null,
        service_ids: null,
        reason_ids: null,
        equipment_ids: null,
        priority_ids: null,
        city: null,
        zip: null,
        plant_ids: null,
        customer_ids: null,
        customer_type_ids: null,
        proposal_ids: null,
        assignee_ids: null,
        ex_service: null,
        ex_priority: null,
        ex_equipment: null,
        ex_customer_type: null,
        ex_customers: null,
        ex_service_type: null,
        show_only_proposed: true,
        exclude_scheduled: false
    })

    const reload = () => {
        if (!loadingData) {
            setLoadingData(true)
            actions.getData(tab, pagination, columns[tab].filter(c => c.display).map(c => c.key), sort, prepareFilters(), res => {
                setData(res)
                setLoadingData(false)
            }, () => setLoadingData(false))
        }
    }

    const reloadSummary = () => {
        actions.getSummary(prepareFilters(), res => {
            setSummary(res || {})
        })
    }

    const prepareFilters = () => {
        return {
            ...filters,
            sent_label_name: filters.sent_label_name ? filters.sent_label_name.value : null,
            sent_from: filters.sent_from ? moment(filters.sent_from).format(defaultDateFormat) : null,
            sent_to: filters.sent_to ? moment(filters.sent_to).format(defaultDateFormat) : null,
            promised_label_name: filters.promised_label_name ? filters.promised_label_name.value : null,
            promised_from: filters.promised_from ? moment(filters.promised_from).format(defaultDateFormat) : null,
            promised_to: filters.promised_to ? moment(filters.promised_to).format(defaultDateFormat) : null,
            scheduled_label_name: filters.scheduled_label_name ? filters.scheduled_label_name.value : null,
            scheduled_from: filters.scheduled_from ? moment(filters.scheduled_from).format(defaultDateFormat) : null,
            scheduled_to: filters.scheduled_to ? moment(filters.scheduled_to).format(defaultDateFormat) : null,
            paid_label_name: filters.paid_label_name ? filters.paid_label_name.value : null,
            paid_from: filters.paid_from ? moment(filters.paid_from).format(defaultDateFormat) : null,
            paid_to: filters.paid_to ? moment(filters.paid_to).format(defaultDateFormat) : null,
            sales_arborist_ids: filters.sales_arborist_ids ? filters.sales_arborist_ids.map(e => e.value) : null,
            service_type_ids: filters.service_type_ids ? filters.service_type_ids.map(e => e.value) : null,
            service_ids: filters.service_ids ? filters.service_ids.map(e => e.value) : null,
            reason_ids: filters.reason_ids ? filters.reason_ids.map(e => e.value) : null,
            equipment_ids: filters.equipment_ids ? filters.equipment_ids.map(e => e.value) : null,
            priority_ids: filters.priority_ids ? filters.priority_ids.map(e => e.value) : null,
            city: filters.city ? filters.city.map(e => e.value) : null,
            zip: filters.zip ? filters.zip.map(e => e.value) : null,
            plant_ids: filters.plant_ids ? filters.plant_ids.map(e => e.value) : null,
            customer_ids: filters.customer_ids ? filters.customer_ids.map(e => e.value) : null,
            customer_type_ids: filters.customer_type_ids ? filters.customer_type_ids.map(e => e.value) : null,
            proposal_ids: filters.proposal_ids ? filters.proposal_ids.map(e => e.value) : null,
            assignee_ids: filters.assignee_ids ? filters.assignee_ids.map(e => e.value) : null,
            ex_service: filters.ex_service ? filters.ex_service.map(e => e.value) : null,
            ex_priority: filters.ex_priority ? filters.ex_priority.map(e => e.value) : null,
            ex_equipment: filters.ex_equipment ? filters.ex_equipment.map(e => e.value) : null,
            ex_customer_type: filters.ex_customer_type ? filters.ex_customer_type.map(e => e.value) : null,
            ex_customers: filters.ex_customers ? filters.ex_customers.map(e => e.value) : null,
            ex_service_type: filters.ex_service_type ? filters.ex_service_type.map(e => e.value) : null,
        }
    }

    const changeTab = (newTab) => {
        if ((!showDock && !loadingData && newTab !== tab)) {
            setPagination({...pagination, page: 1, per_page: 25})
            setData([])
            setTab(newTab)
        }
    }

    const tableButtons = (e) => {
        return <Col xs={12}>
            <Row className="buttons-wrapper">
                <Col xs={6}>
                    {e.components?.btnGroup}
                    <EmailButton
                        selectedRecords={selectedRecords}
                        tab={tab}
                        setEmailModalOpened={setEmailModalOpened}
                    />
                </Col>
                <Col xs={6} className="text-right">
                    {['backlog'].includes(tab) &&
                        <MapSchedulerButton
                            selectedRecords={selectedRecords}
                        />
                    }
                    {['draft', 'sent'].includes(tab) &&
                        <DeclinedButton
                            setIsDeclinedModalOpened={setIsDeclinedModalOpened}
                            selectedRecords={selectedRecords}
                        />
                    }
                    <FilterButton
                        filtersArrays={filtersArrays}
                        setShowFilterModal={setShowFilterModal}
                        getFilters={actions.getFilters}
                        setFiltersArrays={setFiltersArrays}
                    />
                    <ColumnsButton
                        setShowDock={setShowDock}
                    />
                </Col>
            </Row>
        </Col>
    }

    useEffect(() => {
        reload()
        reloadSummary()
    }, [filters])

    useEffect(() => {
        setAllSelectedRecords(false)
        setSelectedRecords([])
        reload()
    }, [tab, pagination, sort, columns])

    useEffect(() => {
        reloadSummary()
    }, [])

    return <Row id='new-sales-dashboard'>
        <Col xs={12}>
            <Row>
                <Col xs={12} className="vcenter">
                    <h2 className="no-top-margin mr-9">Sales dashboard</h2>
                    <h5 className="text-primary">{data?.length || 0} returned</h5>
                </Col>
            </Row>
            <Row className="summary-row">
                <Col xs={2} className={`summary-tab ${tab === 'draft' ? 'active-' : ''}draft-tab`} onClick={() => changeTab('draft')}>
                    <h4>DRAFT - {summary.draft?.proposal_count || 0}</h4>
                    <Dollar amount={summary.draft?.service_sum}/>
                </Col>
                <Col xs={2} className={`summary-tab ${tab === 'sent' ? 'active-' : ''}sent-tab`} onClick={() => changeTab('sent')}>
                    <h4>SENT - {summary.sent?.proposal_count || 0}</h4>
                    <Dollar amount={summary.sent?.service_sum}/>
                </Col>
                <Col xs={2} className={`summary-tab ${tab === 'backlog' ? 'active-' : ''}backlog-tab`} onClick={() => changeTab('backlog')}>
                    <h4>BACKLOG - {summary.backlog?.proposal_service_count || 0}</h4>
                    <Dollar amount={summary.backlog?.service_sum}/>
                </Col>
                <Col xs={2} className={`summary-tab ${tab === 'completed' ? 'active-' : ''}completed-tab`} onClick={() => changeTab('completed')}>
                    <h4>COMPLETED - {summary.completed?.proposal_count || 0}</h4>
                    <Dollar amount={summary.completed?.service_sum}/>
                </Col>
                <Col xs={2} className={`summary-tab ${tab === 'invoiced' ? 'active-' : ''}invoiced-tab`} onClick={() => changeTab('invoiced')}>
                    <h4>INVOICED - {summary.invoiced?.proposal_count || 0}</h4>
                    <Dollar amount={summary.invoiced?.service_sum}/>
                </Col>
                <Col xs={2} className={`summary-tab ${tab === 'paid' ? 'active-' : ''}paid-tab`} onClick={() => changeTab('paid')}>
                    <h4>PAID - {summary.paid?.proposal_count || 0}</h4>
                    <Dollar amount={summary.paid?.service_sum}/>
                </Col>
            </Row>
        </Col>
        <Col xs={12}>
            <Row>
                <Col xs={12} className="table-wrapper">
                    <NewSalesDashboardTable
                        tab={tab}
                        data={data}
                        page={pagination.page}
                        per_page={pagination.per_page}
                        total={summary[tab]?.proposal_count || summary[tab]?.proposal_service_count}
                        setPagination={setPagination}
                        isBacklog={tab === 'backlog'}
                        columns={columns[tab].filter(c => c.display).map(c => c.key)}
                        loadingData={loadingData}
                        setSort={setSort}
                        sort={sort}
                        saveNewCsvDownload={actions.saveNewCsvDownload}
                        tableButtons={tableButtons}
                        selectedRecords={selectedRecords}
                        setSelectedRecords={setSelectedRecords}
                        allSelectedRecords={allSelectedRecords}
                        setAllSelectedRecords={setAllSelectedRecords}
                        showEmailDialog={actions.showEmailDialog}
                        loadProposalData={actions.loadProposalData}
                        defaultEmail={defaultEmail}
                    />
                </Col>
            </Row>
        </Col>
        {showDock && <NewSalesDashboardDock
            setShowDock={setShowDock}
            currentColumns={columns[tab]}
            saveColumns={(newColumns) => setColumns({...columns, [tab]: newColumns || []})}
        />}
        {showFilterModal && <NewSalesDashboardFilterModal
            onHide={() => setShowFilterModal(false)}
            onSave={newFilters => {
                setFilters(newFilters)
                setPagination({...pagination, page: 1})
            }}
            baseFilters={filters}
            filtersArrays={filtersArrays}
        />}
        {isDeclinedModalOpened && <DeclinedModal
            onHide={() => setIsDeclinedModalOpened(false)}
            selectedRecords={selectedRecords}
            onSave={() => actions.declineProposals(selectedRecords?.map(e => e.proposal_id), tab === 'backlog', () => {
                setIsDeclinedModalOpened(false)
                reload()
                reloadSummary()
            })}
        />}
        {emailModalOpened?.show && <EmailDialog
            defaultEmail={defaultEmail}
            sendEmail={(email) => {
                actions.sendBulkEmail(emailModalOpened?.data?.recipients, email, emailModalOpened?.data?.selectedRows, () => {
                    setEmailModalOpened({show: false, data: {}})
                })
            }}
            sendBulkNotification={(email, callback) => {
                actions.sendBulkNotification(email, emailModalOpened?.data?.recipients, "bulk", (res) => {
                    callback && callback(res.data)
                })
            }}
            templateMode
            referenceId={tab === "invoiced" && selectedRecords?.flatMap(e => JSON.parse(e.invoices)?.map(i => i.invoice_id)).filter(e => e)}
            emailType={tab === "invoiced" ? 'bulk_invoiced' : 'bulk'}
            confirmMessage={true}
            selectedRecords={selectedRecords.map(d => {return {name: d.customer_name, email: tab === 'Invoiced' ? d.billing_email : d.email_address, proposalNo: d.proposal_no}})}
            hideEmailDialog={() => {
                setEmailModalOpened({show: false, data: {}})
            }}
        />}
    </Row>
}

function mapStateToProps(state) {
    return {defaultEmail: state.auth.email}
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(apiActions, dispatch)})

export default connect(mapStateToProps, mapDispatchToProps)(NewSalesDashboard)