import React, {useEffect, useRef, useState} from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../BackupsPage/BackupsApi";
import {connect} from "react-redux";
import {Button, FormControl} from "react-bootstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import config from "../../../config/environment"

const Backups = ({actions}) => {
    const tableRef = useRef()
    const [backupName, setBackupName] = useState("");
    const [backups, setBackups] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        actions.getBackups((b) => {
            setBackups(b);
            setLoading(false);
            setBackupName("");
        });
    }

    const renderOptions = (id, name) => {
        return <div style={{display: "flex", gap: 5}}>
            {config.uat_slave !== 'true' && <button onClick={() => actions.updateBackup(id, () => loadData())}>Update</button>}
            <button onClick={() => actions.loadBackup(id, () => null)}>Load</button>
            {config.uat_slave !== 'true' && <button onClick={() => actions.downloadBackup(id, name)}>Download</button>}
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", paddingTop: 10, }}>
        {config.uat_slave !== 'true' && <div style={{display: "flex", gap: 10, justifyContent: "flex-end"}}>
            <FormControl
                type="text"
                style={{width: 200}}
                placeholder="Backup name"
                onChange={(e) => setBackupName(e.target.value)}
                value={backupName}
            />
            <Button disabled={backupName === ""} onClick={() => actions.createBackup(backupName, () => loadData())}>New backup</Button>
        </div>}
        <div>
            <BootstrapTable
                ref={tableRef}
                data={backups}
                striped={true}
                bordered={false}
                hover={true}
                remote
                className="wrapped"
            >
                <TableHeaderColumn autoValue={true} dataField='id' hidden={true} isKey={true}
                                   width={"10%"}>
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="any"
                    dataFormat={(cell, row, enumObject, index) => <div>{index + 1}</div>}
                    width={"10%"}
                >
                    #
                </TableHeaderColumn>
                <TableHeaderColumn width={"20%"} dataField='name'>
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn width={"40%"} dataField='updated_at'>
                    Updated At (UTC)
                </TableHeaderColumn>
                <TableHeaderColumn width={"40%"} dataField='id' dataFormat={(_, row) => renderOptions(row.id, row.name)}>
                    Actions
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    </div>
}

const mapDispatchToProps = dispatch => (
    {
        actions: bindActionCreators(actions, dispatch),
    }
)

export default connect(null, mapDispatchToProps)(Backups)