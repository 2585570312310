import {Col, ControlLabel, FormControl, FormGroup, InputGroup, Row} from "react-bootstrap";
import Datetime from "react-datetime";
import {defaultDateFormat, defaultTimeFormat, select} from "../../common/commonHandlers";
import moment from "moment";
import Select from "react-select";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import InvoiceFilterModal from "./InvoiceFilterModal";
import React from "react";

const isMobile = window.screen.width <= 1024;

const InvoicesFilterModal = ({
                                 resource, onHide, show, closeButton, clearFiltersButton, onDateTimeChange,
                                 onFocusDateTimeTo, onClickRadioButton, selectOptions, search,
                                 selectResourceAttr, selectCheckboxAttr,
                                 updateResourceAttr, onInputBlur, dollarInputValue
                             }) => {
    return (<InvoiceFilterModal
        show={show}
        onHide={onHide}
        closeButton={closeButton}
        clearFiltersButton={clearFiltersButton}
    >
        <Row className="no-margin">
            <Col className="bottom15 top15">
                <Datetime
                    dateFormat={defaultDateFormat}
                    isClearable
                    timeFormat={defaultTimeFormat}
                    value={moment(resource.from)}
                    inputProps={{placeholder: 'From'}}
                    onChange={(e) => onDateTimeChange(e, "from")}/>
            </Col>
        </Row>
        <Row className="no-margin">
            <Col>
                <Datetime
                    dateFormat={defaultDateFormat}
                    isClearable
                    onFocus={onFocusDateTimeTo}
                    timeFormat={defaultTimeFormat}
                    value={moment(resource.to)}
                    inputProps={{placeholder: 'To'}}
                    onChange={(e) => onDateTimeChange(e, "to")}
                />
            </Col>
        </Row>
        <Row className="">
            <Col className="mt-12-ml-16">
                <span>Filter by:</span>
                <div className="d-flex mt-4">
                    <div className="form-check abc-radio"
                         onClick={() => onClickRadioButton("invoiced_at")}>
                        <input className="form-check-input"
                               type="radio"
                               checked={resource.date_filter === "invoiced_at"}
                        />
                        <label className="form-check-label">
                            Invoiced at
                        </label>
                    </div>
                    <div className="form-check abc-radio"
                         onClick={() => onClickRadioButton("due_date")}>
                        <input className="form-check-input"
                               type="radio"
                               checked={resource.date_filter === "due_date"}
                        />
                        <label className="form-check-label">
                            Due date
                        </label>
                    </div>
                    <div className="form-check abc-radio"
                         onClick={() => onClickRadioButton("paid_at")}>
                        <input className="form-check-input"
                               type="radio"
                               checked={resource.date_filter === "paid_at"}
                        />
                        <label className="form-check-label">
                            Paid at
                        </label>
                    </div>
                </div>
            </Col>
        </Row>
        <hr/>
        <Row className="no-margin">
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.customerOptions, resource.customer_id)}
                        options={selectOptions.customerOptions}
                        placeholder="Customer"
                        isClearable
                        onInputChange={search.customerSearch}
                        onChange={selectResourceAttr('customer_id')}
                />
            </Col>
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.siteOptions, resource.site_id)}
                        options={selectOptions.siteOptions}
                        placeholder="Site"
                        isClearable
                        onInputChange={search.siteSearch}
                        onChange={selectResourceAttr('site_id')}
                />
            </Col>
            <Col className={isMobile ? null : "bottom15"}>
                <Select className="Select" classNamePrefix="select"
                        value={select(selectOptions.arboristOptions, resource.arborist_id)}
                        options={selectOptions.arboristOptions}
                        isClearable
                        onInputChange={search.arboristSearch}
                        onChange={selectResourceAttr('arborist_id')}
                        placeholder="Arborist"
                />
            </Col>
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.proposalTitleOptions, resource.proposal_id)}
                        options={selectOptions.proposalTitleOptions}
                        placeholder="Proposal Title"
                        isClearable
                        onInputChange={search.proposalTitleSearch}
                        onChange={selectResourceAttr('proposal_id')}
                />
            </Col>
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.proposalNoOptions, resource.proposal_no)}
                        options={selectOptions.proposalNoOptions}
                        placeholder="Proposal No"
                        isClearable
                        onInputChange={search.proposalNoSearch}
                        onChange={selectResourceAttr('proposal_no')}
                />
            </Col>
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.workOrderOptions, resource.work_order_id)}
                        options={selectOptions.workOrderOptions}
                        placeholder="Work Order No"
                        isClearable
                        onInputChange={search.workOrderSearch}
                        onChange={selectResourceAttr('work_order_id')}
                />
            </Col>
            <Col className="bottom15 top15">
                <Select className="Select"
                        classNamePrefix="select"
                        value={select(selectOptions.invoiceOptions, resource.invoice_id)}
                        options={selectOptions.invoiceOptions}
                        placeholder="Invoice No"
                        isClearable
                        onInputChange={search.invoiceSearch}
                        onChange={selectResourceAttr('invoice_id')}
                />
            </Col>
        </Row>
        <hr/>
        <Row className="no-margin">
            <Col className="bottom15 top15">
                <FormGroup key="Sent count">
                    <ControlLabel>
                        <div>Sent count</div>
                    </ControlLabel>
                    <div className="flex-gap-10">
                        <Col xs={6} className="no-margin no-padding">
                            <FormControl
                                min={0}
                                type='number'
                                placeholder="Sent count from"
                                name="invoice_sent_count_from"
                                onChange={updateResourceAttr}
                                value={resource.invoice_sent_count_from || ''}
                            />
                        </Col>
                        <Col xs={6} className="no-margin no-padding">
                            <FormControl
                                min={0}
                                type='number'
                                placeholder="Sent count to"
                                name="invoice_sent_count_to"
                                onChange={updateResourceAttr}
                                value={resource.invoice_sent_count_to || ''}
                            />
                        </Col>
                    </div>
                </FormGroup>
            </Col>
            <Col className="bottom15 top15">
                <FormGroup key="Aging">
                    <ControlLabel>
                        <div className='filter-header'>Aging</div>
                    </ControlLabel>
                    <div className="flex-gap-10">
                        <Col xs={6} className="no-margin no-padding">
                            <FormControl
                                type='number'
                                placeholder="Aging from"
                                name="aging_from"
                                onChange={updateResourceAttr}
                                value={resource.aging_from || ''}
                            />
                        </Col>
                        <Col xs={6} className="no-margin no-padding">
                            <FormControl
                                type='number'
                                placeholder="Aging to"
                                name="aging_to"
                                onChange={updateResourceAttr}
                                value={resource.aging_to || ''}
                            />
                        </Col>
                    </div>
                </FormGroup>
            </Col>
            <Col className="bottom15 top15">
                <FormGroup key="Balance">
                    <ControlLabel>
                        <div className='filter-header'>Balance</div>
                    </ControlLabel>
                    <div className="flex-gap-10">
                        <Col xs={6} className="no-margin no-padding">
                            <InputGroup>
                                <InputGroupAddon>
                                    $
                                </InputGroupAddon>
                                <FormControl
                                    type='text'
                                    placeholder="Balance from"
                                    onBlur={(e) => onInputBlur(e, 'balance_from')}
                                    name="balance_from"
                                    onChange={updateResourceAttr}
                                    value={dollarInputValue("balance_from")}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs={6} className="no-margin no-padding">
                            <InputGroup>
                                <InputGroupAddon>
                                    $
                                </InputGroupAddon>
                                <FormControl
                                    type='text'
                                    placeholder="Balance to"
                                    onBlur={(e) => onInputBlur(e, 'balance_to')}
                                    name="balance_to"
                                    onChange={updateResourceAttr}
                                    value={dollarInputValue("balance_to")}
                                />
                            </InputGroup>
                        </Col>
                    </div>
                </FormGroup>
            </Col>
            <Col className="bottom15 top15">
                <FormGroup key="Cost">
                    <ControlLabel>
                        <div className='filter-header'>Cost</div>
                    </ControlLabel>
                    <div className="flex-gap-10">
                        <Col xs={6} className="no-margin no-padding">
                            <InputGroup>
                                <InputGroupAddon>
                                    $
                                </InputGroupAddon>
                                <FormControl
                                    type='text'
                                    placeholder="Cost from"
                                    onBlur={(e) => onInputBlur(e, 'cost_from')}
                                    name="cost_from"
                                    onChange={updateResourceAttr}
                                    value={dollarInputValue("cost_from")}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs={6} className="no-margin no-padding">
                            <InputGroup>
                                <InputGroupAddon>
                                    $
                                </InputGroupAddon>
                                <FormControl
                                    type='text'
                                    placeholder="Cost to"
                                    onBlur={(e) => onInputBlur(e, 'cost_to')}
                                    name="cost_to"
                                    onChange={updateResourceAttr}
                                    value={dollarInputValue("cost_to")}
                                />
                            </InputGroup>
                        </Col>
                    </div>
                </FormGroup>
            </Col>
        </Row>
        <hr/>
        <Row className="no-margin">
            <ColorCheckbox value={resource.inactive}
                           className="marginBottom8"
                           label='Show inactive invoices'
                           onChange={(e) => selectCheckboxAttr(e, "inactive")}
            />
        </Row>
    </InvoiceFilterModal>)
}

export default InvoicesFilterModal