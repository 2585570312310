import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Modal, Row} from "react-bootstrap";

const DeclinedModal = ({onHide, selectedRecords, onSave}) => {
    return <Modal
        id="decline-sales-dashboard-id"
        show={true}
        onHide={onHide}
        backdrop="static"
        closeButton={onHide}
        className={"heightAuto maxHeight90 modalLeadsFilterBody fontIOS"}
    >
        <Modal.Header closeButton>
            <Modal.Title>Decline Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="mt-5">
                <Col xs={2} className="text-center mt10">
                    <FontAwesomeIcon size="2x" color="red" icon={faExclamationTriangle}/>
                </Col>
                <Col xs={10}>
                    <p>Number of records selected: <b>{selectedRecords && selectedRecords.length}</b></p>
                    <p>Yes. Move all selected proposals and services to <span className="text-danger">DECLINED</span> status</p>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsSize="small"
                bsStyle="danger"
                onClick={() => onHide()}
            >
                Cancel
            </Button>
            <Button
                bsSize="small"
                bsStyle="success"
                onClick={() => onSave()}
            >
                Save
            </Button>
        </Modal.Footer>
    </Modal>
}

export default DeclinedModal;