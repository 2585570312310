import React, {useEffect, useState} from 'react'
import PageHeader from "../../../components/PageTitle";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import {Button, Col, Grid, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import './AppSettings.css'
import {bindActionCreators} from "redux";
import * as actions from "../AppSettings/AppSettingsApi";
import {connect} from "react-redux";

const initialSettings = {
    allowRegistration: false
}

const AppSettings = ({actions}) => {
    const [settings, setSettings] = useState(initialSettings)
    useEffect(() => {
        actions.getAppSettings(settings => setSettings(settings))
    }, [])


    const updateSettings = (key) => {
        settings[key] = !settings[key]
        actions.updateAppSettings(settings, ({settings}) => setSettings(settings))
    }

    const renderTooltip = (text) => (
        <Tooltip id="tooltip">
            <div className="text-left">
                {text}
            </div>
        </Tooltip>
    );

    const allowRegisterText = "Allow register new clients"

    return (
        <>
            <PageHeader pageName="App Settings" pageDetail={`Treehub`}/>
            <Grid fluid className="top25">
                <Row className='app-settings-wrapper'>
                    <Col>
                        <ColorCheckbox value={settings.allowRegistration}
                                       label="Allow new clients registration"
                                       onChange={() => updateSettings('allowRegistration')}
                        />
                    </Col>
                    <OverlayTrigger placement="right" overlay={renderTooltip(allowRegisterText)}>
                        <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16 pointer"/>
                    </OverlayTrigger>
                </Row>
            </Grid>
        </>
    )
}

const mapStateToProps = state => ({
    name: state.auth.name,
    client: state.client.customerInfo,
})

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch),})

export default connect(mapStateToProps, mapDispatchToProps)(AppSettings)
